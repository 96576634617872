import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Grid,
	Paper,
	Typography,
	useMediaQuery,
	SwipeableDrawer as Drawer,
} from "@mui/material";
import { Formik, Form } from "formik";
import { api, sendFormData } from "../../../../config/axios";
import Swal from "sweetalert2";

// ICONOS
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
// COMPONENTES
import TextInput from "../../../../components/inputs/TextInput";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../../theme";
import MyMoneyInput from "../../../../components/inputs/MyMoneyInput";
import Cookies from "js-cookie";
import MyInputFile from "../../../../components/inputs/MyInputFile";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { baseURL } from "../../../../config/url";

// const MySelect = lazy(() => import('../../../../components/inputs/MySelect'));

const ModalGastos = ({ open, data = {}, yupObject, onClose, refresh }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const isNonMobile = useMediaQuery("(min-width:600px)");
	const [driverCode, setDriverCode] = useState("");
	const [show, setShow] = useState(false);
	const [formData, setFormData] = useState({});
	useEffect(() => {
		window.scrollTo(0, 0);
		if (Object.keys(data).length !== 0 && data !== formData) {
			setFormData(data);
			// buscarOrdenes();
		}
	}, [data, formData]);

	// ===============================================
	// ORDENES DE MCLEOD
	// ===============================================
	// const toggleDrawer = (anchor, open) => (event) => {
	// 	if (
	// 		event &&
	// 		event.type === "keydown" &&
	// 		(event.key === "Tab" || event.key === "Shift")
	// 	) {
	// 		return;
	// 	}

	// 	setState({ ...state, [anchor]: open });
	// };
	const handleDelete = async (id) => {
		onClose();
		const url = "delete/gastos/operador";
		Swal.fire({
			title: "¿Estás seguro de eliminar este gasto?",
			text: "¡Esta acción no se puede revertir!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: colors.blueAccent[700],
			cancelButtonColor: colors.redAccent[700],
			confirmButtonText: "Si, eliminar",
			cancelButtonText: "Cancelar",
		}).then(async (result) => {
			// setDisplay("flex");
			if (result.isConfirmed) {
				const response = await api.delete(url, { params: { id: id } });
				if (response.data.success === true) {
					Swal.fire({
						icon: response.data.icon || "success",
						title: response.data.text,
					});
					refresh(data.rel_orden);
				}
			}
		});
	};
	// const memoizedValueOrden = useMemo(() => {
	//     return ordenes.find((orden) => orden.id === formData.rel_orden);
	// }, [ordenes, formData.rel_orden]); // Dependencies

	// useEffect(() => {
	//     if(formData.rel_orden !== undefined){
	//         setValueOrden(memoizedValueOrden);
	//     }
	// }, [memoizedValueOrden, formData.rel_orden]);
	// ===============================================
	// SUBMIT DEL FORMULARIO
	// ===============================================
	const [display, setDisplay] = useState("flex");
	const handleSubmit = async (values) => {
		const data = JSON.parse(Cookies.get("usuarioData"));
		values.rel_orden = formData.rel_orden;
		values.rel_operador = data.entity_id;
		values.despacho   = 0;
		const url 	 = values.id ? "update/gastos/operador" : "create/gastos/operador" ;
		const method = values.id ? "put" : "post";
		try {
			const result = await sendFormData(url, values,method); //

			if (result.success === true) {
				Swal.fire({
					icon: result.icon || "success",
					title: result.text,
				});
				// setValueOrden(null);
				onClose();
				refresh(values.rel_orden);
			}
		} catch (error) {
			console.error("Upload failed:", error);
		}
	};
	const getFile = (ruta) => {
		if (!ruta) return;
		ruta = ruta.replaceAll(/\\/g, "/");
		const file = ruta.split("/")[2];

		const file2 = file.split("%");

		const finalFile = file2[0] + "%25" + file2[1];

		return `${baseURL}viaticos/comprobantes/gastos/operador/${finalFile}`;
		// setSrc(`${baseURL}formatos/${finalFile}`);
	};
	const Content = () => (
		<Box
			sx={{
				width: "auto",
				borderRadius: "10px",
			}}
			role="presentation"
			// onClick={toggleDrawer(anchor, false)}
			// onKeyDown={toggleDrawer(anchor, false)}
		>
			<Formik
				initialValues={formData}
				validationSchema={yupObject}
				onSubmit={(values, { setSubmitting }) => {
					handleSubmit(values);
				}}
				enableReinitialize={true}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					setFieldValue,
				}) => (
					<Paper
						sx={{
							width: "100%",
							p: 3,
							bgcolor: colors.blueAccent[900],
							borderRadius: "10px",
						}}
					>
						<Form encType="multipart/form-data">
							<Typography
								variant="h3"
								align="center"
								paddingBottom={"2rem"}
							>
								{data.id ? "Editar gasto" : "Registrar Gasto"}
							</Typography>
							<Grid
								container
								spacing={2}
								paddingBottom={"0.1rem"}
							>
								{/* tipo gasto */}
								<Grid item md={12} xs={12}>
									<TextInput
										label="Tipo de gasto"
										name="tipo_gasto"
										placeholder="Ej. Comida, costo de peaje, etc."
									/>
								</Grid>
								{/* MONTO */}
								<Grid item md={12} xs={12}>
									<MyMoneyInput
										label={"Monto"}
										name={"monto"}
										key={"monto"}
										placeholder={"Ingrese el monto gastado"}
										inputProps={{ maxLength: 16 }}
									/>
								</Grid>

								{/* COMENTARIOS */}

								<Grid item md={12} xs={12}>
									<TextInput
										label="Comentarios"
										name="comentario"
										multiline
										rows={3}
										placeholder="Ingrese comentarios"
									/>
								</Grid>
								<Grid item md={12} xs={12}>
									{formData.ruta ? (
										<Button
											variant="contained"
											color="primary"
											href={getFile(formData.ruta)}
											target="_blank"
											rel="noopener noreferrer"
										>
											Ver Archivo
										</Button>
									) : (
										<>
											<Typography
												variant="caption"
												align="left"
											>
												Comprobante
											</Typography>
											<MyInputFile name="ruta" />
										</>
									)} 
								</Grid>
								<Grid item md={12} xs={12}>
									<Collapse in={show}>
										<Alert
											severity="error"
											action={
												<IconButton
													aria-label="close"
													color="inherit"
													size="small"
													onClick={() => {
														setShow(false);
													}}
												>
													<CloseIcon fontSize="inherit" />
												</IconButton>
											}
											sx={{ mb: 2 }}
										>
											Favor de adjuntar el comprobante
										</Alert>
									</Collapse>
								</Grid>
								<Grid
									item
									md={data.id ? 4 : 6}
									xs={data.id ? 4 : 6}
								>
									<Button
										variant="contained"
										color="primary"
										sx={{
											color: "white",
											marginRight: "10px",
											width: "100%",
											borderRadius: "8px",
										}}
										onClick={onClose}
										startIcon={<CloseIcon />}
									>
										Cancelar
									</Button>
								</Grid>
								{data.id ? (
									<Grid
										item
										md={data.id ? 4 : 6}
										xs={data.id ? 4 : 6}
									>
										<Button
											variant="contained"
											color="error"
											sx={{
												color: "white",
												marginRight: "10px",
												width: "100%",
												borderRadius: "8px",
											}}
											onClick={() =>
												handleDelete(data.id)
											}
											startIcon={<DeleteIcon />}
										>
											Borrar
										</Button>
									</Grid>
								) : null}
								<Grid
									item
									md={data.id ? 4 : 6}
									xs={data.id ? 4 : 6}
								>
									<Button
										type="submit"
										variant="contained"
										sx={{
											width: "100%",
											color: "white",
											borderRadius: "8px",
										}}
										color="success"
										startIcon={<SaveIcon />}
									>
										Guardar
									</Button>
								</Grid>
							</Grid>
						</Form>
					</Paper>
				)}
			</Formik>
		</Box>
	);
	return (
		<Drawer
			anchor={"bottom"}
			open={open}
			onClose={onClose}
			sx={{
				"& .MuiDrawer-paper": {
					borderTopLeftRadius: 24,
					borderTopRightRadius: 24,
				},
			}}
		>
			<Content />
		</Drawer>
	);
};

export default ModalGastos;
