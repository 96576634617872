import React, {useState, useEffect} from 'react';
import {api, sendFormData} from '../../../../config/axios';
import { Box, Button, Grid  } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MenuItem from '@mui/material/MenuItem';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";

// ICONOS
import SaveIcon from '@mui/icons-material/Save';
import BorderColorIcon from '@mui/icons-material/BorderColor';

// COMPONENTES
import TextInput from '../../../../components/inputs/TextInput';
import MyRadioButton from '../../../../components/inputs/MyRadioButton';
import MySelect from '../../../../components/inputs/MySelect';
import MyDateInput from '../../../../components/inputs/MyDateInput';
import MyPhoneInput from '../../../../components/inputs/MyPhoneInput';
import MyInputFile from '../../../../components/inputs/MyInputFile';
import ModalFirmas from './modals/ModalFirmas';

const EmpleadoDatos = ({data = {}}) => {

    const navigate = useNavigate();
    // ===============================================
    // FUNCION PARA ABRIR EL MODAL DE FIRMAS
    // ===============================================
    const [openFirmas, setOpenFirmas] = useState(false);
    // ===============================================
    // FORM DATA
    // ===============================================

    const [formdata, SetFormData] = useState({

        nombre: '',
        apellido_p: '',
        apellido_m: '',
        sexo: '',
        empresa: '',
        sucursal: '',
        departamento: '',
        puesto: '',
        telefono: '',
        correo: '',
        fecha_nacimiento: '',
        fecha_ingreso: '',
        foto: ''

    })

    useEffect(() => {

        window.scrollTo(0, 0);

        if(Object.keys(data).length !== 0 ){

            data.fotoActual = data.foto;

            SetFormData(data);

            buscarEmpresas();

            buscarSucursales(data.empresa);

            buscarDepartamentos(data.sucursal);

            buscarPuestos(data.departamento);

        }

    },[data])

    // ===============================================
    // OPCIONES PARA LA EMPRESA
    // ===============================================

    const [empresas, setEmpresas] = useState([]);

    const buscarEmpresas = () => {

        api.get('get/empresas')
        .then(result => {
    
            if(result.data.success === true && result.data.success !== 'Sin resultados')
            {
                setEmpresas(result.data.result);
            }
    
        })
        .catch(error => {
        
            console.log(error)
        
        })

    }

    // ===============================================
    // OPCIONES PARA LA SUCURSAL
    // ===============================================

    const [sucursales, setSucursales] = useState([]);

    const buscarSucursales = (empresa) => {

        api.get(`get/sucursales/empresa/${empresa}`)
        .then(result => {
    
            if(result.data.success === true && result.data.success !== 'Sin resultados')
            {
                setSucursales(result.data.result);
            }else{
                setSucursales([]);
            }
    
        })
        .catch(error => {
        
            console.log(error)
        
        })

    }

    // ===============================================
    // OPCIONES PARA LOS DEPARTAMENTOS
    // ===============================================

    const [departamentos, setDepartamentos] = useState([])

    const buscarDepartamentos = (sucursal) => {

        api.get(`get/departamentos/sucursal/${sucursal}`)
        .then(result => {
    
            if(result.data.success === true && result.data.success !== 'Sin resultados')
            {
                setDepartamentos(result.data.result);
            }else{
                setDepartamentos([])
            }
    
        })
        .catch(error => {
        
            console.log(error)
        
        })

    }

    // ===============================================
    // OPCIONES PARA LOS PUESTOS
    // ===============================================

    const [puestos, setPuestos] = useState([]);

    const buscarPuestos = (departamento) => {

        api.get(`get/puestos/departamento/${departamento}`)
        .then(result => {
    
            if(result.data.success === true && result.data.success !== 'Sin resultados')
            {
                setPuestos(result.data.result);
            }else{
                setPuestos([])
            }
    
        })
        .catch(error => {
        
            console.log(error)
        
        })

    }

    // ===============================================
    // OPCIONES PARA EL RADIOBUTTON
    // ===============================================

    const options = [

        { value: 'Hombre', label: 'Hombre' },
        { value: 'Mujer', label: 'Mujer' },

    ];

    // ===============================================
    // SUBMIT DEL FORMULARIO
    // ===============================================

    const handleSubmit = async (values) => {

        const url = 'update/empleado';

        try {

            const result = await sendFormData(url, values, "PUT"); // 

            if(result.success === true){

                Swal.fire({
                    icon: 'success',
                    title: 'Se ha editado el registro!',
                })
                
                navigate('/empleados');

            }

        } catch (error) {

            console.error("Upload failed:", error);

        }

    }

    return (

        <Box paddingRight={3}>
            <Formik
                initialValues={formdata}
                enableReinitialize={true}
                validationSchema={Yup.object({
                    nombre: Yup.string().required('Requerido'),
                    apellido_p: Yup.string().required('Requerido'),
                    sexo: Yup.string().required('Requerido'),
                    empresa: Yup.string().required('Requerido'),
                    sucursal: Yup.string().required('Requerido'),
                    departamento: Yup.string().required('Requerido'),
                    puesto: Yup.string().required('Requerido'),
                    correo: Yup.string().email('Correo Invalido').required('Requerido'),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values)
                }}
            >

            {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (

                <Form encType="multipart/form-data">

                <Grid container spacing={2} paddingBottom={"50px"}>

                    {/* NOMBRE DEL EMPLEADO */}

                    <Grid item md={4} xs={12}>

                        <TextInput
                            label="Nombre"
                            name="nombre"
                            placeholder="Ingrese nombre"
                        />

                    </Grid>

                    {/* APELLIDO PATERNO */}

                    <Grid item md={4} xs={12}>

                        <TextInput
                            label="Apellido Paterno"
                            name="apellido_p"
                            placeholder="Ingrese apellido"
                        />

                    </Grid>

                    {/* APELLIDO MATERNO */}

                    <Grid item md={4} xs={12}>

                        <TextInput
                            label="Apellido Materno"
                            name="apellido_m"
                            placeholder="Ingrese apellido"
                        />

                    </Grid>

                    {/* CORREO */}
                    
                    <Grid item md={6} xs={12}>

                        <TextInput
                            label="Correo Electronico"
                            name="correo"
                            placeholder="Ingrese correo"
                        />

                    </Grid>

                    {/* SEXO */}

                    <Grid item md={6} xs={12}>

                        <MyRadioButton
                            name="sexo"
                            label="Seleccione el sexo"
                            options={options}
                        />

                    </Grid>

                    {/* EMPRESA */}

                    <Grid item md={6} xs={12}>

                        <MySelect
                            label="Empresa"
                            name="empresa"
                            onChange={(event) => {
                                handleChange(event);
                                buscarSucursales(event.target.value);
                                SetFormData({
                                    ...formdata, 
                                    ["sucursal"]: '',
                                    ["departamento"]: '',
                                    ["puesto"]: ''
                                });
                            }}
                        >
                        {

                            Array.isArray(empresas)
                            ?     
                            empresas.map((op) => (

                                <MenuItem value={op.id} key={op.id}>{op.nombre_comercial}</MenuItem>

                            ))
                            :
                            null
                        }
                        </MySelect>

                    </Grid>

                    {/* SUCURSAL */}

                    <Grid item md={6} xs={12}>

                        <MySelect
                            label="Sucursal"
                            name="sucursal"
                            onChange={(event) => {
                                handleChange(event);
                                buscarDepartamentos(event.target.value);
                                SetFormData({
                                    ...formdata, 
                                    ["departamento"]: '',
                                    ["puesto"]: ''
                                });
                            }}
                        >
                        {   
                            Array.isArray(sucursales)
                            ?                          
                            sucursales.map((op) => (

                                <MenuItem value={op.id} key={op.id}>{op.nombre_sucursal}</MenuItem>

                            ))                         
                            :
                            null
                        }
                        </MySelect>

                    </Grid>

                    {/* DEPARTAMENTO */}

                    <Grid item md={6} xs={12}>

                        <MySelect
                            label="Departamento"
                            name="departamento"
                            onChange={(event) => {
                                handleChange(event);
                                buscarPuestos(event.target.value);
                                SetFormData({
                                    ...formdata, 
                                    ["puesto"]: ''
                                });
                            }}
                        >
                        {
                            Array.isArray(departamentos)
                            ?
                            departamentos.map((op) => (

                                <MenuItem value={op.id} key={op.id}>{op.nombre_depa}</MenuItem>

                            ))
                            :
                            null
                        }
                        </MySelect>

                    </Grid>

                    {/* PUESTO */}

                    <Grid item md={6} xs={12}>

                        <MySelect
                            label="Puesto"
                            name="puesto"
                        >
                        {
                            Array.isArray(puestos)
                            ?
                            puestos.map((op) => (

                                <MenuItem value={op.id} key={op.id}>{op.nombre_puesto}</MenuItem>

                            ))
                            :
                            null
                        }
                        </MySelect>

                    </Grid>

                    {/* FECHA NACIMIENTO */}
                    
                    <Grid item md={6} xs={12}>

                        <MyDateInput
                            label="Fecha Nacimiento"
                            name="fecha_nacimiento"
                            format="YYYY/MM/DD"
                        />


                    </Grid>

                    {/* FECHA INGRESO */}

                    <Grid item md={6} xs={12}>

                        <MyDateInput
                            name="fecha_ingreso"
                            label="Fecha Ingreso"
                            format="YYYY/MM/DD"
                        />

                    </Grid>

                    {/* TELEFONO */}

                    <Grid item md={6} xs={12}>

                        <MyPhoneInput
                            label="Telefono"
                            name="telefono"
                            autoComplete="on"
                        />

                    </Grid>
                    {/* TELEFONO USA */}
                    <Grid item md={6} xs={12}>

                        <MyPhoneInput
                            label="Telefono Oficina USA"
                            name="telefono_usa"
                            defaultCountry="US"
                            autoComplete="on"
                        />

                    </Grid>
                    {/* TELEFONO MEX */}
                    <Grid item md={6} xs={12}>

                        <MyPhoneInput
                            label="Telefono Oficina MEX"
                            name="telefono_mex"
                            autoComplete="on"
                        />

                    </Grid>
                    {/* FOTO */}

                    <Grid item md={6} xs={12}>

                        <MyInputFile
                            name="foto"
                            label="Subir Foto"
                            accept=".jpg, .jpeg, .png"
                        />

                    </Grid>

                    {/* SUBMIT */}

                    <Grid container justifyContent="flex-end">
                        <Button onClick={() => setOpenFirmas(true)} variant="contained" sx={{color: "white", margin: '0.5rem'}} color="info" startIcon={<BorderColorIcon />}>
                            Generar Firma
                        </Button>
                        <Button type="submit" variant="contained" sx={{color: "white", margin: '0.5rem'}} color="success" startIcon={<SaveIcon />}>
                            Guardar
                        </Button>
                    </Grid>

                </Grid>

            </Form>

            )}
                
            </Formik>
            <ModalFirmas open={openFirmas} onClose={() => setOpenFirmas(false)} data={data} />
        </Box>

    )

}

export default EmpleadoDatos