import { Box} from "@mui/material";
// import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { useState,useEffect } from "react";
// import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import { api } from "../../../../../config/axios";
import { marcarURL } from "../../../../../config/functions";
import * as Yup from 'yup';
import ModalPeticion from "../../modals/ModalPeticion";
import Cookies from "js-cookie";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Swal from "sweetalert2";
import GridView from "../../../../../components/Gridview";
import getCellIcon from "../../../icons/exportIcons";

export default function Peticiones(){
    const [data, setData] = useState([]);
    const [selectedRowId, setSelectedRowId] = useState([]);
    const [readonly, setReadonly] = useState(false);
    const handleRowSelection = (id) => {
        setSelectedRowId(id);
    };
    useEffect(() => {

        buscarPeticiones();

        marcarURL('peticiones');

    },[])

    const buscarPeticiones = async () => {
        const response = await api.get('get/peticiones');
        if(response.data.success === true && response.data.result !== "Sin resultados"){
            setData(response.data.result);
        }else{
            setData([]);
        }
    }

    const [formData, setFormData] = useState({
        rel_usuario_req : Cookies.get('user'),
        entity_id       : '',
        entity_type     : '',
        cantidad        : '',
        justificacion   : ''
    });
    const [open, setOpen] = useState(false);
    // Funcion para redireccionar a la vista de crear peticion
    const handleOnClick = () => {
        setReadonly(false);
        setFormData({
            rel_usuario_req : Cookies.get('user'),
            entity_id       : '',
            entity_type     : '',
            cantidad        : '',
            justificacion   : ''
        })
        setOpen(true);
    };
    
    const handleRowClick = (params) => {
        //const row = JSON.stringify(params.row);
        const newDisable = params.row.id_estatus === 2 ? false : true;
        setReadonly(newDisable);
        setFormData(params.row);
        setOpen(true);
        // navigate(`/editar_peticion?data=${data}`)

    }
    const handleCloseModal = () => {
        setOpen(false);
    }

    const yupObject = Yup.object({
        entity_id       : Yup.string().required('Requerido'),
        entity_type     : Yup.string().required('Requerido'),
        cantidad        : Yup.number().required('Requerido'),
        justificacion   : Yup.string().required('Requerido')
    });
    // Estas son las columnas del grid
    const columns = [

        {
            field: "id",
            headerName: "#",
            flex: 1,
            headerAlign: "center",
            align: "center",
            checkboxSelection: true, // Enable checkbox selection
        },
        {
            field: "nombreCompletoUsuario",
            headerName: "Solicito",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "tipo",
            headerName: "Tipo",
            flex: 1,
            headerAlign: "center",
            align: "center",        
        },
        {
            field: "descripcion",
            headerName: "Descripcion",
            flex: 3,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "id_estatus",
            headerName: "Estatus",
            flex: 2,
            headerAlign: "center",
            align: "center",
            renderCell: getCellIcon
        },

    ];
    const handleDeleteAction = () => {
        if(!selectedRowId.length){
            Swal.fire({
                title: "Seleccione al menos un registro",
                icon: "warning",
            });
            return;
        }
        if(readonly){
            Swal.fire({
                title: "Ya no se puede eliminar esta peticion",
                icon:  "error",
            });
            return;
        }
        const id   =  selectedRowId[0];
        const url  = `baja/peticion/${id}`;
        Swal.fire({
            title: "Estas seguro de esta accion?",
            text: "No se podra revertir",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, estoy seguro!",
        }).then((result) => {
            if (result.isConfirmed) {
                api.put(url).then((response) => {
                    if(response.data.success === true){
                        Swal.fire("Eliminada", "Tu peticion ha sido eliminada", "success");
                        buscarPeticiones();
                    }
                    }).catch((error) => {
                        console.log("ocurrio un error: " +error);
                    })           
            }
        });
    };
    const actions = [
        { icon: <AddIcon />,    name: 'addPeticion'   , tooltipTitle: 'Nuevo',    onClick: handleOnClick },
        { icon: <DeleteIcon />, name: 'deletePeticion', tooltipTitle: 'Eliminar', onClick: handleDeleteAction},
    ];
    return(
        <Box
            sx={{
                position: "relative",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <GridView 
                data={data}
                columns={columns}
                handleRowClick={handleRowClick}
                handleRowSelection={handleRowSelection}
                selectedRowId={selectedRowId}
                actions={actions}
            />
            <ModalPeticion
                open={open}
                disabled={readonly}
                onClose={handleCloseModal}
                data={formData}
                yupObject={yupObject}
                refresh={buscarPeticiones}
            />
        </Box>
    )
}