import React, { useEffect, useState } from "react";
// import "./styles.css";
import { Epg, Layout } from "planby";
// Import hooks
import { useApp } from "./useApp";
// Import components
import { Timeline, UserItem, ProgramItem } from "./components";
import { Box, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import ModalBusqueda from "./modals/ModalBusqueda";
import Header from "../../components/Header";
import { format, addDays, subDays } from "date-fns";
import es from "date-fns/locale/es";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ModalReporte from "./modals/ModalReporte";
import { DonutLarge } from "@mui/icons-material";
import img from "../../assets/spinner.svg";
function Horarios() {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [filters, setFilters] = useState({
		usuarios: [],
		departamentos: [],
		tipo: 0,
		fecha: [new Date()],
	}); // Asume que los filtros son un objeto
	const [isGeneratingReport, setIsGeneratingReport] = useState(false);
	const [data, setData] = useState({});
	const { isLoading, getEpgProps, getLayoutProps, handleFetchResources } = useApp(filters, setData);
	const [open, setOpen] = useState(false);
	const [openReporte, setOpenReporte] = useState(false);

	const [selectedDate, setSelectedDate] = useState(new Date());

	const handleDateChange = (date) => {
		setSelectedDate(new Date(date));
		setFilters({ ...filters, fecha: [date] });
	};

	const formattedDate = selectedDate ? format(selectedDate, "EEE, dd MMM yy", { locale: es }).toUpperCase() : '';
	const handleArrowDateChange = (op) => {
		return () => {
			setSelectedDate(op);
			setFilters({ ...filters, fecha: [op] });
		}
	}
	useEffect(() => {
		const intervalId = setInterval(() => {
			handleFetchResources();
		}, 20 * 60 * 1000);

		// Limpiar el intervalo cuando el componente se desmonte
		return () => clearInterval(intervalId);
	}, [handleFetchResources]);
	return (
		<>
			<Box m="20px" style={{ height: "75vh", width: "100%" }}>
				<Header title="Horarios" subtitle="Monitoreo de horarios" />
				{
					!isGeneratingReport ? (
						<>
							<Grid container direction="row" alignItems="center" spacing={2}>
							{selectedDate ? (
								<>
									<Grid item>
										<IconButton onClick={handleArrowDateChange(subDays(selectedDate,1))}>
											<KeyboardArrowLeftIcon />
										</IconButton>
									</Grid>
									<Grid item>
										<Typography  variant="h4" fontWeight='bold' onClick={() => setSelectedDate(null)}>
											{formattedDate}
										</Typography>
									</Grid>
									<Grid item>
										<IconButton onClick={handleArrowDateChange(addDays(selectedDate, 1))}>
											<KeyboardArrowRightIcon />
										</IconButton>
									</Grid>
								</>
							) : (
								<Grid item>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker
											// defaultValue={yesterday}
											sx={{width: '200px'}}
											views={['year', 'month', 'day']}
											renderInput={(props) => <TextField {...props} fullWidth label="Fecha inicial" InputProps={{ style: { height: 20, padding: '0 10px' } }} />}
											// onChange={(value) => setFechaInicio(dayjs(value).format('YYYY/MM/DD'))}
											onChange={handleDateChange}
										/>
									</LocalizationProvider>
							</Grid>
							)}
							<Grid item>
								<Button
									startIcon={<SearchIcon />}
									sx={{
										color: "white",
										fontWeight: "bold",
										backgroundColor: colors.blueAccent[700],
									
										width: "180px",
										height: "35px",
									}}
									onClick={() => setOpen(true)}
								>
									Busqueda Avanzada
								</Button>
							</Grid>
							<Grid item>
								<Button
									startIcon={<DonutLarge />}
									sx={{
										color: "white",
										fontWeight: "bold",
										backgroundColor: colors.greenAccent[700],
										width: "180px",
										height: "35px",
									}}
									onClick={() => setOpenReporte(true)}
								>
									Generar Reporte
								</Button>
							</Grid>
							{/* </Grid> */}
							{/* DESPLIEGUE DE FILTROS */}
								
							</Grid>
							<Epg isLoading={isLoading} {...getEpgProps()}>
								<Layout
									{...getLayoutProps()}
									renderTimeline={(props) => <Timeline {...props} />}
									renderProgram={({ program, ...rest }) => (
										<ProgramItem
											key={program.data.id}
											program={program}
											{...rest}
										/>
									)}
									renderChannel={({ channel }) => (
										<UserItem key={channel.uuid} channel={channel} />
									)}
								/>
							</Epg>
						</>
					) : (
						<Box
							display="flex"
							flexDirection="column"
							justifyContent="center"
							alignItems="center"
							height="100%"
						>	
							<>
								<img
									alt="Cargando..."
									width="250px"
									height="250px"
									src={img}
									style={{ cursor: "pointer", borderRadius: "50%" }}
								/>
								<Typography variant="h4" fontWeight='bold' color="textPrimary">
									Estamos generando tu reporte...
								</Typography>
							</>
						</Box>
					)
					
				}
				<ModalBusqueda
					open={open}
					onClose={() => setOpen(false)}
					filters={filters}
					onApplyFilters={setFilters}
				/>
				<ModalReporte
					open={openReporte}
					onClose={() => setOpenReporte(false)}
					filters={filters}
					data={data}
					setIsGeneratingReport={setIsGeneratingReport}
					onApplyFilters={setFilters}
				/>
			</Box>
		</>
	);
}

export default Horarios;
