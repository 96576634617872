import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, Grid, useTheme  } from "@mui/material";
import { tokens } from '../../../theme';
import Avatar from '@mui/material/Avatar';
import { api } from '../../../config/axios';
import { baseURL } from '../../../config/url';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";

// ICONOS
import InfoIcon from '@mui/icons-material/Info';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

// COMPONENTES
import EditarDatosTema from './EditarDatosTema';
import PreguntasTema from '../preguntasTemas/PreguntasTema';
import MultimediaTemas from '../multimedia/MultimediaTemas';

const VistaTemas = () => {

    // ===============================================
    // OBTENER INFORMACION DEL CURSO
    // ===============================================

    const location = useLocation();
    const { info } = location.state;

    const [datosTema, setDatosTema] = useState([]);

    useEffect(() => {

        window.scrollTo(0, 0);

        const temp = JSON.parse(info);

        setDatosTema(temp);

    },[])

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // CATALOGO DE COMPONENTES
    // ===============================================

    const views = {

        datos: EditarDatosTema,
        preguntas: PreguntasTema,
        multimedia: MultimediaTemas,

    }

    // ===============================================
    // OPCION DE COMPONENTE 
    // ===============================================

    const [opcion, setOpcion] = useState('datos');

    // ===============================================
    // COMPONENTE DINAMICO
    // ===============================================

    const ComponenteActual = views[opcion];

    // ===============================================
    // FUNCION PARA DAR DE BAJA EL TEMA
    // ===============================================

    const bajaTema = () => {

    }

    return (

        <Box m="20px">

            <Grid container spacing={2} padding={2}>

                {/* AREA DE FORMULARIOS */}

                <Grid item md={9}>

                    <ComponenteActual data={datosTema}/>

                </Grid>

                {/* AREA PARA BOTONES */}

                <Grid
                    item 
                    md={3}
                    sx={{
                        paddingLeft: '0px !important',
                        backgroundColor: colors.grey[900],
                        borderRadius: '15px',
                    }}
                >

                    {/* DATOS DEL TEMA */}

                    <Grid item container justifyContent="center" marginBottom={5}>
                        <Button 
                            variant="contained" 
                            sx={{color: "white", height: "50px", fontWeight: 'bold'}}
                            startIcon={<InfoIcon />}
                            size="large"
                            onClick={() => setOpcion('datos')}
                        >
                            General
                        </Button>
                    </Grid>

                    {/* PREGUNTAS */}

                    <Grid item container justifyContent="center" marginBottom={5}>
                        <Button 
                            variant="contained" 
                            sx={{color: "white", height: "50px", fontWeight: 'bold'}}
                            startIcon={<QuestionMarkIcon />}
                            size="large"
                            onClick={() => setOpcion('preguntas')}
                        >
                            Preguntas
                        </Button>
                    </Grid>

                    {/* MULTIMEDIA */}

                    <Grid item container justifyContent="center" marginBottom={5}>
                        <Button 
                            variant="contained" 
                            sx={{color: "white", height: "50px", fontWeight: 'bold'}}
                            startIcon={<OndemandVideoIcon />}
                            size="large"
                            onClick={() => setOpcion('multimedia')}
                        >
                            Multimedia
                        </Button>
                    </Grid>

                    {/* BOTON DE BAJA */}

                    <Grid item container justifyContent="center" marginBottom={3}>
                        <Button 
                            variant="contained" 
                            color="error"
                            sx={{color: "white", height: "50px", fontWeight: 'bold'}}
                            startIcon={<DoDisturbIcon />}
                            size="large"
                            onClick={bajaTema}
                        >
                            Desactivar Tema
                        </Button>  
                    </Grid>

                </Grid>

            </Grid>

        </Box>

    )

}

export default VistaTemas