import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { List, ListItem, Grid, Typography, ListItemIcon } from '@mui/material';
import { ErrorMessage } from 'formik';
import DescriptionIcon from '@mui/icons-material/Description';

const MyDropZone = ({ field, form, label }) => {
    const dropzoneStyles = {
        border: '2px dashed #cccccc',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
    };
    
    const errorStyles = {
        color: 'red',
        fontSize: '0.8rem',
    };

    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        // Puedes realizar la lógica de manejo de archivos aquí
        // acceptedFiles contiene los archivos aceptados
        // fileRejections contiene los archivos rechazados (por ejemplo, archivos no válidos)
        
        form.setFieldValue(field.name, acceptedFiles); // Actualiza el valor del campo en Formik
    }, [field.name, form]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: true, // Permite cargar múltiples archivos
        noKeyboard: true, // Evita la selección de archivos mediante el teclado
    });

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                <Typography variant="h6">{label}</Typography>
                </Grid>
                <Grid item xs={12}>
                <div {...getRootProps()} style={dropzoneStyles}>
                    <input {...getInputProps()} />
                    <p>Arrastra y suelta archivos aquí o haz clic para seleccionar archivos.</p>
                </div>
                </Grid>
                <Grid item xs={12}>
               
                {field.value && field.value.length > 0 && (
                    <List>
                    {field.value.map((file, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                <DescriptionIcon/>
                            </ListItemIcon>
                            {file.name}
                        </ListItem>
                    ))}
                    </List>
                )}
                </Grid>
            </Grid>
            <ErrorMessage name={field.name} component="div" style={errorStyles} />
        </div>
    )
}
export default MyDropZone;