import React, { useEffect } from 'react';
import { Box } from "@mui/material";
import { marcarURL } from '../../../config/functions';

// ICONOS

// COMPONENTES
import Header from '../../../components/Header';


// ===============================================

const Extensiones = () => {

    useEffect(() => {

        marcarURL('extensiones');

    })

    // ===============================================

    return (

        <Box m="20px">

            <Header title="Extensiones" subtitle="Extensiones Disponibles EBTSuite" />

        </Box>

    )

}

export default Extensiones