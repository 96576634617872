import { useState } from 'react';
import { Formik, Form } from 'formik';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { tokens } from '../../../../theme';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { api, sendFormData} from '../../../../config/axios';

import TextInput from '../../../../components/inputs/TextInput';
import MySelect from '../../../../components/inputs/MySelect';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@emotion/react';
import Swal from 'sweetalert2';
import MyMoneyInput from '../../../../components/inputs/MyMoneyInput';
import MyInputFile from '../../../../components/inputs/MyInputFile';
import SaveIcon from '@mui/icons-material/Save';
import Cookies from 'js-cookie';
import CancelIcon from '@mui/icons-material/Cancel';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import React from 'react';
import Slide from '@mui/material/Slide';

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SideBarForm(props) {
    const theme  = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const vertical = 'bottom';
    const horizontal = 'right';

    const [formData, setFormData] = useState({});
    const [yupObject, setYupObject] = useState({});
    const handleComplete = props.handleComplete;
    const handleClose    = props.handleClose;
    const handleOnClose = () => {
        handleClose();
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        setFormData(props.initialValues);
        setYupObject(props.yupObject);
        buscarProveedores();
        buscarMonedas();

    },[props.initialValues, props.yupObject, props.selectedItem]);
    const [proveedores, setProveedores] = useState([]);
    const buscarProveedores = async () => {
        const response = await api.get('get/proveedores');
        if(response.data.success === true && response.data.result !== "Sin resultados"){
            setProveedores(response.data.result);
        }else{
            setProveedores([]);
        }
    };
    const [monedas, setMonedas] = useState([]);
    const buscarMonedas = async () => {
        const response = await api.get('get/monedas');
        if(response.data.success === true && response.data.result !== "Sin resultados"){
            setMonedas(response.data.result);
        }else{
            setMonedas([]);
        }
    };
    const handleCloseSB = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };
    const handleSubmit = async (values) => {
        if(!values.archivo_cotizacion){
            setOpenSnackbar(true);
            return;
        }
        values.rel_usuario_cotizacion = Cookies.get('user');
        values.rel_requerimiento = props.dataRow.id;
        values.rel_articulo = props.selectedItem.id || props.selectedItem.entity_id;
        
        const url = 'create/cotizacion';

        try {

            const result = await sendFormData(url, values); // 

            if(result.success === true){

                Swal.fire({
                    icon: 'success',
                    title: result.text,
                })
                handleComplete();
                // onClose();
                // refresh();
            }

        } catch (error) {

            console.error("Upload failed:", error);

        }

    }
    return (
        <>
            <Drawer  
                className="MuiDrawer-root" 
                anchor="right" 
                open={props.open} 
                onClose={props.handleClose} 
                ModalProps={{
                    BackdropProps: {
                        onClick: () => {},
                    },
                }}
            >
                <Box sx={{ backgroundColor: colors.blueAccent[900], width: '30vw', height: '100vh', p: 3 }}>
                    <Typography variant="h3" align="center" paddingBottom={'2rem'}>
                        Llenado de informacion del articulo
                    </Typography>
                    <Typography variant="h4" align="center" color="textSecondary">
                        {props.selectedItem.articulo || props.selectedItem.descripcion}
                    </Typography>
                    <Formik
                        initialValues={formData}
                        validationSchema={yupObject}
                        onSubmit={(values, { setSubmitting }) => {
                            handleSubmit(values)
                        }}
                        enableReinitialize={true}
                    >
                    {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                        <Box sx={{ width: isNonMobile ? '100%' : '90%', p: 3, bgcolor: colors.blueAccent[900] }}>
                            <Form encType="multipart/form-data">
                            <Grid paddingBottom={"0.1rem"} container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item md={12} xs={12}>
                                    <MySelect
                                        label="Proveedor"
                                        name="rel_proveedor"
                                    >
                                    {
                                        proveedores.map((op) => (
                                            <MenuItem value={op.id} key={op.id}>{op.proveedor}</MenuItem>
                                        ))
                                    }
                                    </MySelect>

                                </Grid>
                                <Grid item md={6} xs={6}>

                                    <MySelect
                                        label="Moneda"
                                        name="rel_moneda"
                                    >
                                    {
                                        monedas.map((op) => (
                                            <MenuItem value={op.value} key={op.value}>{op.text}</MenuItem>
                                        ))
                                    }
                                    </MySelect>

                                    </Grid>

                                    <Grid item md={6} xs={12}>

                                    <MyMoneyInput
                                        label="Costo"
                                        name="costo"
                                        placeholder="Ingrese costo"
                                        
                                    />

                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <TextInput
                                        label="Descripcion"
                                        name="descripcion"
                                        multiline
                                        rows={2}
                                        placeholder="Ingrese una descripcion tecnica"
                                    />
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <TextInput
                                        label="URL"
                                        name="url"
                                        multiline
                                        rows={4}
                                        placeholder="Ingrese la URL del articulo"
                                    />
                                </Grid>
        
                                <Grid item md={12} xs={12}>
                                    <MyInputFile
                                        name="archivo_cotizacion"
                                        label="Subir Archivo"
                                        accept=".pdf"
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10, marginLeft: 'auto' }}>
                                <Button size='large' onClick={handleOnClose} variant="contained" sx={{color: "white",marginRight: '1rem', borderRadius: '10px', fontSize: '1rem'}} color="error" startIcon={<CancelIcon />}>
                                    <strong> Cerrar </strong>
                                </Button>
                                <Button type='submit' size='large' variant="contained" sx={{color: "white", borderRadius: '10px', fontSize: '1rem'}} color="success" startIcon={<SaveIcon />}>
                                    <strong> Guardar </strong>
                                </Button>
                            </Box>
                            </Form>
                        </Box>
                    )}
                    </Formik> 
                </Box>
            </Drawer>
            <Snackbar anchorOrigin={{ vertical, horizontal }} TransitionComponent={SlideTransition} open={openSnackbar} autoHideDuration={2500} onClose={handleCloseSB}>
                <Alert onClose={handleCloseSB} severity="error" sx={{ width: '100%', fontSize: '16px'}}>
                    Adjunte el archivo de cotizacion
                </Alert>
            </Snackbar>
        </>
    );
};