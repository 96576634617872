// LIBRERIAS
import React from 'react';
import { Formik, Form } from 'formik';
import { styled } from '@mui/system'
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';
import Swal from 'sweetalert2';
import { Button, Modal, Paper, Typography, Box, Container, Grid  } from '@mui/material';
import { sendFormData } from '../../../../config/axios';
import * as Yup from 'yup';

import TextInput from '../../../../components/inputs/TextInput';
import MyInputFile from '../../../../components/inputs/MyInputFile';

import SaveIcon from '@mui/icons-material/Save';

const CrearMultimediaTema = ({formdata, open, onClose, refresh}) => {

    // COMPONENTE QUE FUNCIONARA COMO BODY DEL MODAL

    const ScrollableContainer = styled(Container)({
        maxHeight: '60vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',
    });

    // VALIDAR SI SE ESTA VISUALIZANDO DESDE UN DISPOSITIVO MOVIL

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // COLORES PARA LOS ESTILOS

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // FUNCION PARA EL SUBMIT

    const handleSubmit = async (values) => {

        console.log(values)

        const url = 'create/curso/multimedia';

        try {

            const result = await sendFormData(url, values); // 

            if(result.success === true){

                Swal.fire({
                    icon: 'success',
                    title: 'Se ha creado el registro!',
                })

                onClose();

            }

        } catch (error) {

            console.error("Upload failed:", error);

        }

    }

    return (

        <Modal
            open={open}
            onClose={onClose}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >

            <Formik
                initialValues={formdata}
                validationSchema={Yup.object({
                    // rel_tema: Yup.string().required('Requerido'),
                    nombre: Yup.string().required('Requerido'),
                    ruta: Yup.mixed().required('Requerido'),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values)
                }}
            >

                <Paper sx={{ width: isNonMobile ? `40%` : '90%', p: 3, bgcolor: colors.blueAccent[900] }}>

                    <Form encType="multipart/form-data">

                        {/* HEADER DEL MODAL */}

                        <Typography variant="h3" align="center">
                            Crear Multimedia
                        </Typography>

                        {/* BODY DEL MODAL */}

                        <ScrollableContainer>

                            {/* Inputs go here */}

                            <Grid container spacing={2} marginTop={"10px"} marginBottom={"10px"}>

                                {/* CORREO */}
                    
                                <Grid item md={12} xs={12}>

                                    <TextInput
                                        label="Nombre Archivo"
                                        name="nombre"
                                        placeholder="Ingrese nombre del archivo"
                                    />

                                </Grid>

                                {/* FOTO */}

                                <Grid item md={12} xs={12}>

                                    <MyInputFile
                                        name="ruta"
                                        label="Subir Archivo"
                                        accept=".mp4, .pdf"
                                    />

                                </Grid>

                            </Grid>

                        </ScrollableContainer>

                        {/* FOOTER DEL MODAL */}

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>

                            <Button variant="contained" color="primary" onClick={onClose}>
                                Cancelar
                            </Button>

                            <Button type="submit" variant="contained" sx={{color: "white"}} color="success" startIcon={<SaveIcon />}>
                                Guardar
                            </Button>

                        </Box>

                    </Form>

                </Paper>

            </Formik>

        </Modal>

    )

}

export default CrearMultimediaTema