import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../../theme";
import {api} from '../../../../config/axios';
import * as Yup from 'yup';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

//COMPONENTES
import Header from '../../../../components/Header';
import ModalCreateGM from '../../../../components/modals/ModalCreateGM';
import ModalEditGM from '../../../../components/modals/ModalEditGM';

const EmpleadosContacto = ({data}) => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {

        window.scrollTo(0, 0);

        if(Object.keys(data).length !== 0 ){

            setInicialFormValues({...data, ["rel_empleado"]: data.id});

            buscarContactos()

        }

    },[data])

    // ===============================================
    // FUNCION PARA BUSCAR LOS CONTACTOS
    // ===============================================

    const [contactos, SetContactos] = useState([])

    const buscarContactos = async () => {

        const idEmpleado = data.id;

        const response = await api.get(`get/contactos/empleado/${idEmpleado}`);

        if(response.data.success === true && response.data.result !== "Sin resultados"){

            SetContactos(response.data.result);

        }else{

            SetContactos([]);

        }

    } 

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        {
            field: "nombre_contacto",
            headerName: "Contacto",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "telefono_contacto",
            headerName: "Telefono",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "parentezco_contacto",
            headerName: "Parentezco",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },

    ];

    // ===============================================
    // VALORES PARA SUBMIT DEL FORMULARIO
    // ===============================================

    const [initialFormValues, setInicialFormValues] = useState({

        rel_empleado: '',
        nombre_contacto: '',
        telefono_contacto: '',
        parentezco_contacto: '',

    })

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL CREARA
    // ===============================================

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {

        setInicialFormValues({
            ...initialFormValues,
            ["nombre_contacto"]: '',
            ["telefono_contacto"]: '',
            ["parentezco_contacto"]: '',
        })

        setIsOpen(true);
    };
    
    const handleCloseModal = () => {
        setIsOpen(false);
    };

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL EDITAR
    // ===============================================

    const [isOpenEdit, setIsOpenEdit] = useState(false);

    const handleOpenModalEdit = () => {
        setIsOpenEdit(true);
    };
    
    const handleCloseModalEdit = () => {
        setIsOpenEdit(false);
    };

    // ===============================================
    // INPUTS PARA ARMAR EL FORMULARIO
    // ===============================================

    const inputForms = [

        {
            componente: "input",
            label: "Nombre Contacto",
            name: "nombre_contacto",
            placeholder: "Ingrese Nombre del Contacto",
            md: 12,
            xs: 12
        },
        {
            componente: "phone",
            label: "Telefono",
            name: "telefono_contacto",
            md: 12,
            xs: 12
        },
        {
            componente: "input",
            label: "Parentezco",
            name: "parentezco_contacto",
            placeholder: "Ingrese parentezco del Contacto",
            md: 12,
            xs: 12
        },   

    ];

      // ===============================================
    // VALIDACIONES PARA EL FORMULARIO
    // ===============================================

    const yupValidation = Yup.object().shape({

        nombre_contacto: Yup.string().required('Requerido'),
        parentezco_contacto: Yup.string().required('Requerido'),
        telefono_contacto: Yup.string().required('Requerido')

    })

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const handleRowClick = (params) => {

        setInicialFormValues(params.row);
        handleOpenModalEdit();

    }

    // ===============================================

    return (

        <Box marginRight={2}>
            
            <Header title="Contactos" subtitle="Contactos de Emergencia" />

            {/* BOTON PARA ABRIL MODAL */}

            <Button variant="contained" 
                sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.blueAccent[700]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={handleOpenModal}
            >
                Agregar Contacto
            </Button>

            {/* TABLA  */}

            <Box
                m="40px 0 0 0"
                height="75vh"
                width="100%"
                sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`
                }
                }}
            >
                <DataGrid 
                    rows={contactos} 
                    columns={columns} 
                    components={{ Toolbar: GridToolbar}}
                    onRowClick={handleRowClick}
                />
            </Box>

            {/* MODAL PARA CREAR SUCURSAL */}

            <ModalCreateGM
                modalTitle="Crear Contacto"
                swalTitle="Se ha guardado el contacto!"
                url="create/contacto/empleado"
                open={isOpen} 
                onClose={handleCloseModal} 
                size='40'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarContactos}
            />

            {/* MODAL PARA EDITAR SUCURSAL */}

            <ModalEditGM
                modalTitle="Editar Contacto"
                swalTitle="Se ha editado el contacto!"
                url="update/contacto/empleado"
                urlDelete="delete/contacto/empleado/"
                open={isOpenEdit} 
                onClose={handleCloseModalEdit} 
                openModal={handleOpenModalEdit}
                size='40'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarContactos}
            />
            
        </Box>

    )

}

export default EmpleadosContacto