import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Box, Button, Card, CardActions, CardContent, Grid, List, ListItem,ListItemText, Typography } from "@mui/material";
import Link from '@mui/material/Link';
import { useTheme } from "@emotion/react";
import { tokens } from "../../../../theme";
import MyInputFile from "../../../../components/inputs/MyInputFile";

const DragDropComponent = (props) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const formatCurrency = (value) => {
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
		return formatter.format(value);
	};
	const Content = (item)=>{
		return (
			<Box>
				<Grid item xs={12} sm container>
					<Grid item xs container direction="column" spacing={2}>
						<Grid item xs>
						<Typography gutterBottom sx={{ mb: 1 }} color="text.secondary">
							{item.articulo} x {item.cantidad}
						</Typography>
						<Typography 
							variant="h5"
							style={{
								display: "-webkit-box",
								WebkitLineClamp: 2,
								WebkitBoxOrient: "vertical",
								overflow: "hidden",
								textOverflow: "ellipsis",
							}}
						>
							<Link color="secondary" href={item.url} target="_blank" rel="noopener noreferrer">{item.descripcion}</Link>
						</Typography>
						</Grid>
					</Grid>
					<Grid item>
						<Typography gutterBottom sx={{ mb: 1 }}>
							{formatCurrency(item.subtotal)}
						</Typography>
					</Grid>
				</Grid>

				<CardActions>
					<Button size="small" color="info" variant="outlined"  onClick={() => handleClickCard(item)}>Editar</Button>
				</CardActions>
			</Box>
		)
	}
	const { columns, setColumns, handleClickCard, showTotal, isDragDisabled, content } = props;
	const onDragEnd = (result, columns, setColumns) => {
		if (!result.destination) return;
		const { source, destination } = result;

		if (source.droppableId !== destination.droppableId) {
			const sourceColumn = columns[source.droppableId];
			const destColumn = columns[destination.droppableId];
			const sourceItems = [...sourceColumn.items];
			const destItems = [...destColumn.items];
			const [removed] = sourceItems.splice(source.index, 1);
			destItems.splice(destination.index, 0, removed);
			setColumns({
			...columns,
			[source.droppableId]: {
				...sourceColumn,
				items: sourceItems
			},
			[destination.droppableId]: {
				...destColumn,
				items: destItems
			}
			});
		} else {
			const column = columns[source.droppableId];
			const copiedItems = [...column.items];
			const [removed] = copiedItems.splice(source.index, 1);
			copiedItems.splice(destination.index, 0, removed);
			setColumns({
			...columns,
			[source.droppableId]: {
				...column,
				items: copiedItems
			}
			});
		}
	};
	
	return (
		<div
			style={{ display: "flex", justifyContent: "center", height: "100%" }}
		>
			<DragDropContext
				onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
			>
			{columns && Object.entries(columns).map(([columnId, column], index) => {
				return (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center"
					}}
					key={columnId}
				>
					<Typography variant="h3"><strong>{column.name}</strong></Typography>
					<Card style={{ margin: 10 }}>
						<Droppable droppableId={columnId} key={columnId}>
							{(provided, snapshot) => {
							const total = column.items.reduce((acc, item) => acc + item.subtotal,0);
							return (
								<List
									{...provided.droppableProps}
									ref={provided.innerRef}
									style={{
										background: snapshot.isDraggingOver
										? column.hvBgColor
										: column.bgColor,
										padding: 4,
										width: 400,
										height: 500,
										minHeight: 500,
										overflowY: "scroll" 
									}}
								>
								{showTotal ?
									<Card style={{ backgroundColor: column.extra, padding: 16, margin: "0 0 8px 0",}}>								
										<Typography gutterBottom sx={{ mb: 1 }}>
											Total
										</Typography>
										<Grid item xs={12} sm container>
											<Typography variant="h4">
												{formatCurrency(total)}
											</Typography>


										</Grid>
										
									</Card>
								: null}
								{column.items && column.items.map((item, index) => {
									return (
									<Draggable
										key={item._dragid}
										draggableId={item._dragid}
										index={index}
										isDragDisabled={isDragDisabled}
									>
										{(provided, snapshot) => {
										return (
											<Card
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={{
													userSelect: "none",
													padding: 16,
													margin: "0 0 8px 0",
													minHeight: "50px",
													backgroundColor: snapshot.isDragging
													? column.color
													: column.hvColor,
													color: "white",
													...provided.draggableProps.style
												}}
											>	
											{/* HACER UN COMPONENT DINAMICO QUE ME PINTE EL CONTENIDO DE CADA CARD */}
												{content(item)}
											</Card>
										);
										}}
									</Draggable>
									);
								})}
								{provided.placeholder}
								</List>
							);
							}}
						</Droppable>
					</Card>
				</div>
				);
			})}
			</DragDropContext>
		</div>
	)
};
export default DragDropComponent;