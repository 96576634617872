import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../theme";
import {api} from '../../../config/axios';
import { marcarURL } from '../../../config/functions';
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

//COMPONENTES
import Header from '../../../components/Header';
import ModalCreateGM from '../../../components/modals/ModalCreateGM';

// ===============================================

const CrearCursos = () => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();

    // ===============================================
    // SEGMENTO PARA BUSCAR CURSOS
    // ===============================================

    const [data, setData] = useState([]);

    useEffect(() => {

        window.scrollTo(0, 0);

        marcarURL('crear_curso');

        buscarCursos();

    },[])

    // BUSCAR EMPLEADOS

    const buscarCursos = async () => {

        const response = await api.get('get/cursos');

        if(response.data.success === true && response.data.result !== "Sin resultados"){

            setData(response.data.result);

        }else{

            setData([]);

        }

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        {
            field: "nombreCurso",
            headerName: "Curso",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "nombre_depa",
            headerName: "Departamento",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "estado",
            headerName: "Estado",
            flex: 2,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => 
                params.value === 1 
                ?
                <Button variant="contained" color="success" sx={{color: "white"}}><b>Activo</b></Button>
                :
                <Button variant="contained" color="error"><b>Inactivo</b></Button>
        },

    ];

    // ===============================================
    // VALORES PARA SUBMIT DEL FORMULARIO
    // ===============================================

    const [initialFormValues, setInicialFormValues] = useState({

        nombreCurso: '',
        departamento: ''

    })

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL CREARA
    // ===============================================

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {

        setInicialFormValues({

            nombreCurso: '',
            departamento: ''
    
        })

        setIsOpen(true);
    };
    
    const handleCloseModal = () => {
        setIsOpen(false);
    };

    // ===============================================
    // SEGMENTO PARA TRAER DEPARTAMENTOS
    // ===============================================

    const [departamentos, setDepartamentos] = useState([]);

    useEffect(() => {
        
        api.get('get/departamentos')
        .then(result => {
    
            if(result.data.success == true)
            {
                const temp = result.data.result;

                temp.unshift({id: 0, nombre_depa: 'Todos los Departamentos'});

                setDepartamentos(temp);
            }
    
        })
        .catch(error => {
        
            console.log(error)
        
        })
    
    },[]);

    // ===============================================
    // INPUTS PARA ARMAR EL FORMULARIO
    // ===============================================

    const inputForms = [

        {
            componente: "select",
            label: "Departamento",
            name: "departamento",
            options: departamentos,
            value: "id",
            text: "nombre_depa",
            md: 12,
            xs: 12
        },
        {
            componente: "input",
            label: "Nombre Curso",
            name: "nombreCurso",
            placeholder: "Ingrese Nombre del Curso",
            md: 12,
            xs: 12
        },

    ];

    // ===============================================
    // VALIDACIONES PARA EL FORMULARIO
    // ===============================================

    const yupValidation = Yup.object().shape({

        nombreCurso: Yup.string().required('Requerido'),
        departamento: Yup.string().required('Requerido')

    })

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const handleRowClick = (params) => {

        let info = JSON.stringify(params.row);

        navigate('/temas_curso', { state: { info: info } });

    }

    // ===============================================

    return (

        <Box m="20px">

            <Header title="Crear Cursos" subtitle="Cursos y Temas" />

            <Button variant="contained" 
                sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.blueAccent[700]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={handleOpenModal}
            >
                Agregar Curso
            </Button>

            {/* TABLA  */}

            <Box
                m="40px 0 0 0"
                height="75vh"
                width="100%"
                sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`
                }
                }}
            >
                <DataGrid 
                    
                    rows={data} 
                    columns={columns} 
                    components={{ Toolbar: GridToolbar}}
                    onRowClick={handleRowClick}
                />
            </Box>

            {/* MODAL PARA CREAR TEMA */}

            <ModalCreateGM
                modalTitle="Crear Curso"
                swalTitle="Se ha guardado el curso!"
                url="create/curso"
                open={isOpen} 
                onClose={handleCloseModal} 
                size='40'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarCursos}
            />

        </Box>

    )

}

export default CrearCursos