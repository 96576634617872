import React from 'react';
import { useField } from 'formik';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';

const MyMultipleSelect = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const { value } = meta;
    const { setValue } = helpers;

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                multiple
                {...field}
                {...props}
                value={value || []} // Ensure value is an array
                onChange={handleChange}
                renderValue={(selected) => (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {selected.map((item) => (
                            <div 
                                key={item}
                                style={{
                                    background: 'white',
                                    color: 'black',
                                    fontWeight: 'bold',
                                    borderRadius: '8px',
                                    padding: '2px 8px',
                                    margin: '2px',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                {item.split('.')[1]}
                            </div>
                        ))}
                    </div>
                )}
                sx={{ width: '100%' }}
            >
                {props.options.map((option) =>
                    option.type === 'subheader' ? (
                        <ListSubheader key={option.label}>{option.label}</ListSubheader>
                    ) : (
                        <MenuItem 
                            key={option.value} 
                            value={option.value} 
                            style={{backgroundColor: value.includes(option.value) ? 'rgb(93, 35, 186)' : null,}}
                        >
                            {option.label}
                        </MenuItem>
                    )
                )}
            </Select>
            {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
        </FormControl>
    );
};

export default MyMultipleSelect;
