import { api } from "../../../config/axios";

// export const fetchChannels = async (groups) => {
//   console.log(groups);
// 	const response = await api.get("get/usuarios/qb", { params: { departamento: groups } });
// 	if (response.data.success === true) {
// 		return response.data.result;
// 	} else {
// 		return [];
// 	}
// };
// new Promise((res) => setTimeout(() => res(channels), 400));

export const fetchEpg = async (filters) => {
	const response = await api.get("get/usuarios/time", { params: { filters: filters },});
	if (response.data.success === true) {
		return response.data.result;
	} else {
		return [];
	}
};
