import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import React, {useEffect} from "react";
import Cookies from 'js-cookie';
import { marcarURL } from "../../config/functions";
import Image from "../../assets/img/fondo_ebt_dash-removebg.png";
const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {

    marcarURL('dashboard')

  })
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-45%, -45%)',
  };
  return (
    <Box m="20px" sx={style}>
      {/* HEADER */}
     
        <img src={Image} alt="Descripción de la imagen" style={{ width: '90rem', height: '40rem' }} />
      {/* <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
         {/* Imagen centrada */}
      
        {/* <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box> 

      </Box> */}
    </Box>
  );
};

export default Dashboard;