import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../../theme";
import {api} from '../../../../config/axios';
import * as Yup from 'yup';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

//COMPONENTES
import Header from '../../../../components/Header';
import CrearComentario from './modales/CrearComentario';
import Cookies from 'js-cookie';
import EditarComentario from './modales/EditarComentario';

const EmpleadosComentarios = ({data}) => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {

        window.scrollTo(0, 0);

        if(Object.keys(data).length !== 0 ){

            let idUserActual = Cookies.get('user')

            setInicialFormValues({...initialFormValues, 
                ["empleado_comentario"]: data.id,
                ["empleado_responsable"]: idUserActual
            });

            buscarComentarios()

        }

    },[data])

    // ===============================================
    // FUNCION PARA BUSCAR LOS CONTACTOS
    // ===============================================

    const [comentarios, SetComentarios] = useState([])

    const buscarComentarios = async () => {

        const idEmpleado = data.id;

        const response = await api.get(`get/empleado/comentarios/${idEmpleado}`);

        if(response.data.success === true && response.data.result !== "Sin resultados"){

            SetComentarios(response.data.result);

        }else{

            SetComentarios([]);

        }

    } 

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        {
            field: "asunto",
            headerName: "Asunto",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "nombreResponsable",
            headerName: "Asignado Por",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "timestamp",
            headerName: "Fecha",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },

    ];

    // ===============================================
    // VALORES PARA SUBMIT DEL FORMULARIO
    // ===============================================

    const [initialFormValues, setInicialFormValues] = useState({

        empleado_comentario: '',
        empleado_responsable: '',
        asunto: '',
        comentario: '',
        evidencia: ''

    })

    console.log(initialFormValues)

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL CREARA
    // ===============================================

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {

        setInicialFormValues({
            ...initialFormValues,
            ["asunto"]: '',
            ["comentario"]: '',
            ["evidencia"]: '',
        })

        setIsOpen(true);
    };
    
    const handleCloseModal = () => {
        setIsOpen(false);
    };

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL EDITAR
    // ===============================================

    const [isOpenEdit, setIsOpenEdit] = useState(false);

    const handleOpenModalEdit = () => {
        setIsOpenEdit(true);
    };
    
    const handleCloseModalEdit = () => {
        setIsOpenEdit(false);
    };

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const handleRowClick = (params) => {

        let data = params.row;

        data.archivoActual = data.evidencia;

        setInicialFormValues(data);

        handleOpenModalEdit();

    }

    // ===============================================

    return (

        <Box marginRight={2}>
            
            <Header title="Comentarios" subtitle="" />

            {/* BOTON PARA ABRIL MODAL */}

            <Button variant="contained" 
                sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.blueAccent[700]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={handleOpenModal}
            >
                Agregar Contacto
            </Button>

            {/* TABLA  */}

            <Box
                m="40px 0 0 0"
                height="75vh"
                width="100%"
                sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`
                }
                }}
            >
                <DataGrid 
                    rows={comentarios} 
                    columns={columns} 
                    components={{ Toolbar: GridToolbar}}
                    onRowClick={handleRowClick}
                />
            </Box>

            {/* MODAL PARA CREAR SUCURSAL */}

            <CrearComentario
                open={isOpen} 
                onClose={handleCloseModal}
                initialValues={initialFormValues}
                refresh={buscarComentarios}
            />

            {/* MODAL PARA EDITAR SUCURSAL */}

            <EditarComentario
                open={isOpenEdit} 
                onClose={handleCloseModalEdit} 
                initialValues={initialFormValues}
                refresh={buscarComentarios}
            />
            
        </Box>

    )

}

export default EmpleadosComentarios