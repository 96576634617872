import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../theme";
import {api} from '../../../config/axios';
import * as Yup from 'yup';
import { marcarURL } from '../../../config/functions';
import Cookies from 'js-cookie';
// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
//COMPONENTES
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Header from '../../../components/Header';
import ModalFormatos from './modals/ModalFormatos';
import { baseURL } from '../../../config/url';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import Swal from 'sweetalert2';


// ===============================================

const Formatos = () => {
    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // SEGMENTO PARA STATE DEL LA SELECCION DE LA TABLA
    // ===============================================
    const [selectedRow, setSelectedRow] = useState(null);
    const [data, setData] = useState([]);
    const departamento = useState(Cookies.get('depa'));
    const [permisos, setPermisos] = useState([]);
    useEffect(() => {
        buscarFormatos();
        marcarURL('formatos');
        // Buscar permisos 
		buscarPermisos(Cookies.get('modulo'), Cookies.get('id_perfil'));
    },[])
    // ===============================================
    // BUSCAR PERMISOS
    // ===============================================
    const buscarPermisos = async (modulo, perfil) => {
		const response = await api.get(`get/permisos`, {params: {modulo, perfil}});
		if(response.data.success === true && response.data.result !== "Sin resultados"){
			setPermisos(response.data.result);
		}else{
			setPermisos([]);
		}
	}
    // ===============================================
    const buscarFormatos = async () => {
        const data = Cookies.get('usuarioData');
        const gerente = JSON.parse(data).gerente;
        const response = await api.get('get/formatos',{params: {departamento: departamento, gerente: gerente}});

        if(response.data.success === true && response.data.result !== "Sin resultados"){

            setData(response.data.result);

        }else{

            setData([]);

        }

    }
    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    function RenderMenu(params){
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const hasPrivileges = true;

        const handleClick = (event, rowData) => {
            setAnchorEl(event.currentTarget);
            setSelectedRow(rowData);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleSee = () => {
            const url = getFile(params.row.archivo);
            if (typeof window !== 'undefined') {
              window.open(url, "_blank", "noopener,noreferrer");
            }
        }

        const handleEdit = () => {
            handleRowClick(params);
            handleClose();
        }
        
        const handleDelete = () => {
            Swal.fire({
                    title: '¿Está seguro de eliminar este formato?',
                    text: "¡Esta acción no se puede revertir!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: colors.blueAccent[700],
                    cancelButtonColor: colors.redAccent[700],
                    confirmButtonText: 'Si, eliminar',
                    cancelButtonText: 'Cancelar'
                }).then(async(result) => {
                    if (result.isConfirmed) {
                        const response = await api.delete(`delete/formato`,{params: {id: params.row.id}});
                        if(response.data.success === true){
                            Swal.fire({
                                icon: 'success',
                                title: response.data.text,
                            })
                            buscarFormatos();
                        }
                    }
                })
            handleClose();
        }
        return (
            <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="grid"
                onClick={(event) => handleClick(event, params.row)}
                >
                <MenuIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: 48 * 4.5,
                        width: '20ch',
                    },
                }}
            >
                <MenuItem onClick={handleSee} disabled={!permisos.read} >
                    <VisibilityIcon style={{ marginRight: '10px' }}  />
                    Ver
                </MenuItem>
                <MenuItem onClick={handleEdit} disabled={!permisos.update} >
                    <EditIcon  style={{ marginRight: '10px' }}/>
                    Editar
                </MenuItem>
                <MenuItem onClick={handleDelete} disabled={!permisos.delete}>
                    <DeleteIcon style={{ marginRight: '10px' }} />
                    Eliminar
                </MenuItem>
            </Menu>
            </div>
        );
    }
    const columns = [

        {
            field: "id",
            headerName: "#",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "nombre",
            headerName: "Nombre",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "descripcion",
            headerName: "Descripción",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "tipoFormato",
            headerName: "Tipo formato",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: 'actions',
            headerName: 'Acciones',
            sortable: false,
            width: 100,
            renderCell: RenderMenu
        }
    ];

     // ===============================================
    // VALORES PARA SUBMIT DEL FORMULARIO
    // ===============================================

    const [initialFormValues, setInicialFormValues] = useState({  
        rel_tipo_formato: '',
        ids_departamentos: '',
        nombre: '',
        descripcion: '',
        aplicaGerencia: '',
        rel_id_usuario_crea: Cookies.get('user'),
    })
    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL CREAR
    // ===============================================

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {

        setInicialFormValues({
            rel_tipo_formato: '',
            ids_departamentos: '',
            nombre: '',
            descripcion: '',
            aplicaGerencia: '',
            rel_id_usuario_crea: Cookies.get('user'),
        })

        setIsOpen(true);
    };
    
    const handleCloseModal = () => {
        setIsOpen(false);
    };
    // FUNCION PARA EL EVENTO ROW CLICK
    const handleRowClick = (params) => {
        if(!permisos.update) return;
        setIsOpen(true);
        
        setInicialFormValues({
            id: params.row.id,
            rel_tipo_formato: params.row.rel_tipo_formato,
            ids_departamentos: params.row.departamentos,
            nombre: params.row.nombre,
            descripcion: params.row.descripcion,
            aplicaGerencia: params.row.aplicaGerencia,
            rel_id_usuario_crea: Cookies.get('user'),
            archivo: params.row.archivo,
        })
    }

    // ===============================================
    // OBJETO DE VALIDACIONES
    // ===============================================
    const yupObject = Yup.object().shape({
        nombre: Yup.string().required('Requerido'),
        rel_tipo_formato: Yup.string().required('Requerido'),
		// descripcion: Yup.string().required('Requerido'),
    })
    // ===============================================
    // FUNCION PARA TRAER LOS SELECCIONADOS
    // ===============================================
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [count, setCount] = useState(0);
    const handleRowSelection = (newSelection, details) => {
        // setSelectedRows(newSelection);
        setCount(newSelection.length);
        getRowData(newSelection);
    };

    useEffect(() => {
        console.log(selectedRows);
    }, [selectedRows]);
    
    // ===============================================
    // FUNCION PARA DESCARGAR ARCHIVOS
    // ===============================================
    const getFile = (ruta) => {
        if(!ruta) return;
		ruta = ruta.replaceAll(/\\/g, "/");
		const file = ruta.split("/")[2];

		const file2 = file.split("%");

		const finalFile = file2[0] + "%25" + file2[1];
        
        return `${baseURL}formatos/${finalFile}`;;
		// setSrc(`${baseURL}formatos/${finalFile}`);
	};
    const getRowData = (ids) => {
        // setSelectedRows(selectedRows.map((id) => data.find((row) => row.id === id)));
        setSelectedRows(ids.map((id) => data.find((row) => row.id === id)))
    };
    
    /**
     * Downloads selected files as a zip or directly depending on the number of files selected.
     * @function
     * @name handleDownloadFiles
     * @returns {void}
     */
    const handleDownloadFiles = async() => {
        // Si es mas de 1 archivo se descarga en un zip
        if(count > 1){
            // Objeto zip
            const zip = new JSZip();
            for (const row of selectedRows) {
                const filePath = getFile(row.archivo)
                const response = await fetch(filePath);
                const blob = await response.blob();
                zip.file(filePath.split('/').pop(), blob);
            }
            const content = await zip.generateAsync({ type: 'blob' });
            saveAs(content, 'formatos.zip');
        }else{ // Si es solo 1 archivo se descarga directamente
            // selectedRows.forEach(async(row) => {
            //     const filePath = getFile(row.archivo)
            //     const response = await fetch(filePath);
            //     const blob = await response.blob();
            //     saveAs(blob, filePath.split('/').pop());
            // });
            const filePath = getFile(selectedRows[0].archivo)
            const response = await fetch(filePath);
            const blob = await response.blob();
            saveAs(blob, filePath.split('/').pop());
        }
    };
    
    return (

        <Box m="20px">
            <Header title="Formatos" subtitle="Listado de Formatos" />
             {/* BOTON PARA ABRIL MODAL */}

            <Button variant="contained" 
                sx={{
                    fontWeight:"bold",
                    backgroundColor:colors.blueAccent[700]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={handleOpenModal}
                disabled={permisos && !permisos.create}
            >
                Agregar Formato
            </Button>
            {count > 0 &&
                <Button variant="contained" 
                    sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.blueAccent[700],
                        marginLeft: '20px'
                    }} 
                    startIcon={<CloudDownloadIcon />}
                    onClick={handleDownloadFiles}
                >
                    Descargar {count} Formatos
                </Button>
                
            }    
            {/* TABLA  */}

            <Box
                m="40px 0 0 0"
                height="75vh"
                width="100%"
                sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`
                }
                }}
            >
                <DataGrid
                    rows={data} 
                    columns={columns} 
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            csvOptions: { utf8WithBom: true },
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                    hideFooterSelectedRowCount
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    onRowDoubleClick={handleRowClick}
                    checkboxSelection
                    onRowSelectionModelChange={handleRowSelection}
                
                />
            </Box>

            {/* MODAL PARA FORMATOS */}
            <ModalFormatos
                 open={isOpen}
                 onClose={handleCloseModal}
                 data={initialFormValues}
                 yupObject={yupObject}
                 refresh={buscarFormatos}
                 permisos={permisos}
            />
            
            {/* <ModalViewer
                open={openViewer}
                onClose={() => handleOpenViewer(false)}
                row={selectedRow}
            /> */}

            
        </Box>

    )

}

export default Formatos