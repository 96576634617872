import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
//ICONS
import { api, sendFormData } from '../../../../config/axios';
import { Box, Button, Chip } from '@mui/material';
import { marcarURL } from '../../../../config/functions';
import CollapsibleTable from '../components/CollapsibleTable';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../../theme';
import ModalSelectedCotizaciones from '../modals/ModalSelectCotizaciones';
import ModalComprobantes from "../modals/ModalComprobantes";
import ModalViewer from '../modals/ModalViewer';
import Swal from 'sweetalert2';


const Compras = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [openComprobantes, setOpenComprobantes] = useState(false);
    const [openViewer, setOpenViewer] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState([]);
    
    useEffect(() => {

        buscarCompras();

        marcarURL('compras');

    },[])

    const buscarCompras = async () => {
        const response = await api.get('get/compras');
        if(response.data.success === true && response.data.result !== "Sin resultados"){
            setData(response.data.result);
        }else{
            setData([]);
        }
    }
    const verComprobante = (row) => {
        setSelectedRowId(row);
        setOpenViewer(true);
    }
    const handleOpenComprobantes = (row) => {
        setSelectedRowId(row);
        setOpenComprobantes(true);
    }
    const renderCell = (row) => {
        const  value = row.tieneComprobante === 1 ? true : false;
        return value ?  <Button 
                            size='small' 
                            variant="contained" 
                            sx={{color: "white",marginRight: '1rem', borderRadius: '10px', fontSize: '0.8rem'}} 
                            color="info"
                            onClick={() => verComprobante(row)}><strong> Ver </strong></Button> 
                    :   <Button 
                            size='small' 
                            variant="contained" 
                            sx={{color: "white",marginRight: '1rem', borderRadius: '10px', fontSize: '0.8rem'}} 
                            color="secondary"
                            onClick={()=> handleOpenComprobantes(row)}><strong> Subir </strong></Button>
    }
    const columns = [
        { id: 'id',   				label: '#',       		minWidth: 170 },
        { id: 'nombre_depa',        label: 'Departamento',  minWidth: 170},

		// { id: 'descripcion',        label: 'Descripcion',   minWidth: 170 },
		{ id: 'estatus',			label: 'Estatus',       minWidth: 170 },
		{ id: 'fecha',   			label: 'Fecha',       	minWidth: 170 },
		{ id: 'nombreEmpleado',   	label: 'Empleado',      minWidth: 170 },
        { id: 'total',  		 	label: 'Total',    		currency:true,  minWidth: 100 },
        { id: 'tieneComprobante',   label: 'Comprobante',   minWidth: 170,  hasRenderCell: true, renderCell: renderCell },
    ];
    const detailColumns = [
        { id: 'id',    				label: '#',   			minWidth: 170 },
        { id: 'articulo',          	label: 'Descripcion',   minWidth: 100 },
        { id: 'cantidad',          	label: 'Cantidad',      minWidth: 100 },
		{ id: 'total',  		 	label: 'Total',    		currency:true,  minWidth: 100 },
	];

    const [checks, setChecks] = useState([]);
    const handleChecks = (checks) => {
        setChecks([...checks]);
    }
   
    const handleOnClick = (params) => {
        let data = JSON.stringify(checks);
        navigate(`/vista_compras?data=${data}`)
    }

    const handleCloseModal = () => {
        setOpen(false);
    }
    const handleOpenModal = () => {
        setOpen(true);
    }
   
    const handleSubmit = async (values) => {
        values.id           = selectedRowId.id;
        values.comprobante  = values.comprobante[0];
        delete values.rel_proveedor;
        
        const url = 'update/comprobante/compra';
        try {           
            const result = await sendFormData(url, values, "PUT");

            if(result.success === true){

                Swal.fire({
                    icon: 'success',
                    title: result.text,
                })
                setOpenComprobantes(false);
                buscarCompras();
                // onClose();
                // refresh();
            }

        } catch (error) {

            console.error("Upload failed:", error);

        }
    };
    return (
        <Box m="20px">
            <Button variant="contained" 
                sx={{
                    fontWeight:"bold",
                    backgroundColor:colors.blueAccent[700],
                    marginBottom: '20px',
                }} 
                startIcon={<AddCircleIcon />}
                onClick={handleOpenModal}
            >
                Realizar nueva compra
            </Button>
            <CollapsibleTable
                rows={data}
                columns={columns}
                detailColumns={detailColumns}
                handleChecks={handleChecks}
                showCheck={false}
                header="Detalles de compra"
            />
            <ModalSelectedCotizaciones
                open={open}
                onOpen={()=>{setOpen(true)}}
                onClose={handleCloseModal}
            />
            <ModalComprobantes
				open={openComprobantes}
				onClose={() => setOpenComprobantes(false)}
				handleSubmit={handleSubmit}
				// validationSchema={validationSchema}
			/>
            <ModalViewer
                open={openViewer}
                onClose={() => setOpenViewer(false)}
                row={selectedRowId}
                
            />
        </Box>
    );
};

export default Compras;
