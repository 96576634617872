import React, { useEffect } from 'react';
import { Box } from "@mui/material";
import { marcarURL } from '../../../config/functions';

// ICONOS

// COMPONENTES
import Header from '../../../components/Header';

// ===============================================

const CalificarCursos = () => {

    useEffect(() => {

        marcarURL('calificar_curso');

    })

    // ===============================================

    return (

        <Box m="20px">

            <Header title="Calificar Cursos" subtitle="Cursos y Temas" />

        </Box>
        
    )

}

export default CalificarCursos