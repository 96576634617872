import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar, esES, GridImag } from "@mui/x-data-grid";
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../theme";
import { api } from '../../../config/axios';
import * as Yup from 'yup';
import { marcarURL } from '../../../config/functions';
import Cookies from 'js-cookie';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
//COMPONENTES
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Header from '../../../components/Header';
import { baseURL } from '../../../config/url';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
import ModalAddAccesoDirecto from './modals/ModalAddAccesoDirecto';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import { es } from 'date-fns/locale';

function AccesosDirectos() {

  // ===============================================
  // COLORES PARA ESTILOS
  // ===============================================

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState([]);
  const [permisos, setPermisos] = useState([]);

  const [modalName, setModalName] = useState("Crear Acceso Directo");

  useEffect(() => {
    buscarAccesosDirectos();
    marcarURL('accesos_directos');

    // Buscar permisos 
    buscarPermisos(Cookies.get('modulo'), Cookies.get('id_perfil'));
  }, [])
  // ===============================================
  // BUSCAR PERMISOS
  // ===============================================
  const buscarPermisos = async (modulo, perfil) => {
    const response = await api.get(`get/permisos`, { params: { modulo, perfil } });
    if (response.data.success === true && response.data.result !== "Sin resultados") {
      let data = response.data.result[0];
      data.create = data.create === 1 ? true : false;
      data.read = data.read === 1 ? true : false;
      data.update = data.update === 1 ? true : false;
      data.delete = data.delete === 1 ? true : false;
      setPermisos(data);
    } else {
      setPermisos([]);
    }
  }

  // ===============================================
  // BUSCAR ACCESOS DIRECTOS
  // ===============================================
  const buscarAccesosDirectos = async () => {
    const response = await api.get('get/accesosDirectos');

    if (response.data.success === true && response.data.result !== "Sin resultados") {

      setData(response.data.result);

    } else {

      setData([]);

    }

  }

  // ===============================================
  // COLUMNAS PARA LA TABLA
  // ===============================================

  function RenderMenu(params) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const hasPrivileges = true;

    const handleClick = (event, rowData) => {
      setAnchorEl(event.currentTarget);
      setSelectedRow(rowData);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleEdit = () => {
      handleRowClick(params);
      handleClose();
    }

    const handleDelete = () => {
      Swal.fire({
        title: '¿Está seguro de eliminar este acceso directo?',
        text: "¡Esta acción no se puede revertir!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colors.blueAccent[700],
        cancelButtonColor: colors.redAccent[700],
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await api.delete(`delete/accesoDirecto`, { params: { id: params.row.id } });
          if (response.data.success === true) {
            Swal.fire({
              icon: 'success',
              title: response.data.text,
            })
            buscarAccesosDirectos();
          }
        }
      })
      handleClose();
    }
    return (
      <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="grid"
          onClick={(event) => handleClick(event, params.row)}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: '20ch',
            },
          }}
        >
          <MenuItem onClick={handleEdit} disabled={!permisos.update} >
            <EditIcon style={{ marginRight: '10px' }} />
            Editar
          </MenuItem>
          <MenuItem onClick={handleDelete} disabled={!permisos.delete}>
            <DeleteIcon style={{ marginRight: '10px' }} />
            Eliminar
          </MenuItem>
        </Menu>
      </div>
    );
  }

  const columns = [

    {
      field: "id",
      headerName: "#",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "nombre_acceso",
      headerName: "Nombre",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "enlace_acceso",
      headerName: "Enlace",
      flex: 2,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <LinkRender value={params.value} />
    },
    {
      field: "logo_acceso",
      headerName: "Logotipo",
      width: 200,
      align: "center",
      renderCell: (params) => <ImageRenderer value={params.value} />
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      sortable: false,
      width: 100,
      renderCell: RenderMenu
    }
  ];

  // FUNCION PARA EL EVENTO ROW CLICK
  const handleRowClick = (params) => {
    setIsOpen(true);
    setModalName("Editar Acceso Directo");

    setInicialFormValues({
      id: params.row.id,
      nombre_acceso: params.row.nombre_acceso,
      enlace_acceso: params.row.enlace_acceso,
      logo_acceso: params.row.logo_acceso,
      rel_id_usuario_crea: Cookies.get('user'),
    })
  }

  // ===============================================
  // VALORES PARA SUBMIT DEL FORMULARIO
  // ===============================================

  const [initialFormValues, setInicialFormValues] = useState({
    nombre_acceso: '',
    enlace_acceso: '',
    logo_acceso: '',
    rel_id_usuario_crea: Cookies.get('user'),
  })
  // ===============================================
  // FUNCION PARA ABRIR O CERRAR EL MODAL CREAR
  // ===============================================

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {

    setInicialFormValues({
      nombre_acceso: '',
      enlace_acceso: '',
      logo_acceso: '',
      rel_id_usuario_crea: Cookies.get('user'),
    })

    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setModalName("Crear Acceso Directo");
  };

  // ===============================================
  // FUNCION PARA TRAER LOS SELECCIONADOS
  // ===============================================
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [count, setCount] = useState(0);
  const handleRowSelection = (newSelection, details) => {
    // setSelectedRows(newSelection);
    setCount(newSelection.length);
    getRowData(newSelection);
  };

  const getRowData = (ids) => {
    // setSelectedRows(selectedRows.map((id) => data.find((row) => row.id === id)));
    setSelectedRows(ids.map((id) => data.find((row) => row.id === id)))
  };

  // ===============================================
  // OBJETO DE VALIDACIONES
  // ===============================================
  const yupObject = Yup.object().shape({
    nombre_acceso: Yup.string().required('Requerido'),
    enlace_acceso: Yup.string().required('Requerido'),
    logo_acceso: initialFormValues.id ?  Yup.mixed()
    .test('tipo-archivo', 'Subir Imagen', (value) => {
      // Verifica si se proporcionó un archivo
      if (!value) {
        return true; // Permitir si no hay archivo proporcionado
      }else{
        return true;
      }

      /* // Obtiene la extensión del archivo
      const extension = value.name.split('.').pop().toLowerCase();

      // Lista de extensiones permitidas
      const extensionesPermitidas = ['image/png', 'image/jpeg', 'image/jpg'];

      // Verifica si la extensión está en la lista permitida
      return extensionesPermitidas.includes(extension); */

    }) : Yup.mixed()
    .test('fileType', 'Formato de archivo no válido', (value) => {
    return value && ['image/png', 'image/jpeg', 'image/jpg'].includes(value.type);
    }).required('Requerido')
  })
  // ===============================================
  // FUNCION QUE RENDERIZA UNA IMAGEN EN LA TABLA
  // ===============================================
  function ImageRenderer({ value }) {
    let ruta = getFile(value);
    //return <img src={ruta} alt="Imagen" width={'200px'} height={'200px'}/>;
    return <Avatar
    alt="logo image"
    src={ruta}
    sx={{ width: 56, height: 50 }}
    variant='square'
  />
  }

  function LinkRender({ value }) {
    //return <img src={ruta} alt="Imagen" width={'200px'} height={'200px'}/>;
    return <Link href={value} underline="hover" target="_blank" rel="noopener noreferrer" color={colors.greenAccent[400]}>
    {value}
    </Link>
  }

  const getFile = (ruta) => {
    if(!ruta) return;
    ruta = ruta.replaceAll(/\\/g, "/");
    const file = ruta.split("/")[2];

    const file2 = file.split("%");

    const finalFile = file2[0] + "%25" + file2[1];
        
      return `${baseURL}logos-AccesoDirecto/${finalFile}`;
  };

  return (
    <Box m="20px">
      <Header title="Accesos Directos" subtitle="Listado de Accesos directos" />
      {/* BOTON PARA ABRIL MODAL */}

      <Button variant="contained"
        sx={{
          fontWeight: "bold",
          backgroundColor: colors.blueAccent[700]
        }}
        startIcon={<AddCircleIcon />}
        onClick={handleOpenModal}
        disabled={permisos && !permisos.create}
      >
        Agregar Acceso Directo
      </Button>
      {/* TABLA  */}

      <Box
        m="40px 0 0 0"
        height="75vh"
        width="100%"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`
          }
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              csvOptions: { utf8WithBom: true },
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          hideFooterSelectedRowCount
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          onRowDoubleClick={handleRowClick}
          onRowSelectionModelChange={handleRowSelection}

        />
      </Box>

      {/* MODAL PARA FORMATOS */}
      <ModalAddAccesoDirecto
                 open={isOpen}
                 onClose={handleCloseModal}
                 data={initialFormValues}
                 yupObject={yupObject}
                 refresh={buscarAccesosDirectos}
                 permisos={permisos}
                 modalName={modalName}
            />


    </Box>
  )
}

export default AccesosDirectos