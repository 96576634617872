import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Modal, Paper, Typography, useMediaQuery } from "@mui/material";
import { Formik, Form } from 'formik';
import MenuItem from '@mui/material/MenuItem';
import { api, sendFormData } from '../../../../config/axios';
import Swal from 'sweetalert2';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

// COMPONENTES
import TextInput from '../../../../components/inputs/TextInput';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../../theme';
import MyInputFile from '../../../../components/inputs/MyInputFile';
import { baseURL } from '../../../../config/url';
import { file } from 'jszip';

function ModalAddAccesoDirecto({ open, data = {}, yupObject, onClose, refresh, disabled, modalName }) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const [formData, setFormData] = useState({});
    const [file, setFile] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (Object.keys(data).length !== 0) {
            console.log(data);
            data.logo_actual = data.logo_acceso;
            setFormData(data);
            // buscarEntidades(data.entity_type);
        }
    }, [data]);

    // ===============================================
    // SUBMIT DEL FORMULARIO
    // ===============================================

    const handleSubmit = async (values) => {
        console.log(values);
        const url  = values.id ? 'update/accesoDirecto' : 'create/accesoDirecto';
        let method = values.id ? 'put' : 'post';
        try {

            const result = await sendFormData(url, values, method);

            if(result.success === true){

                Swal.fire({
                    icon: 'success',
                    title: result.text,
                })
                onClose();
                refresh();
            }

        } catch (error) {

            console.error("Upload failed:", error);

        }

    }

    const getFile = (ruta) => {
        if(!ruta) return;
        ruta = ruta.replaceAll(/\\/g, "/");
        const file = ruta.split("/")[2];
    
        const file2 = file.split("%");
    
        const finalFile = file2[0] + "%25" + file2[1];
            
          return `${baseURL}logos-AccesoDirecto/${finalFile}`;
      };
    return (
        <Modal
            open={open}
            onClose={onClose}
            BackdropProps={{ onClick: (event) => event.stopPropagation }}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Formik
                initialValues={formData}
                validationSchema={yupObject}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values)
                }}
                enableReinitialize={true}
            >
                {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                    <Paper sx={{ width: isNonMobile ? '50%' : '90%', p: 3, bgcolor: colors.blueAccent[900] }}>

                        <Form encType="multipart/form-data">
                            <Typography variant="h3" align="center" paddingBottom={'2rem'}>
                                {modalName}
                            </Typography>
                            <Grid container spacing={2} paddingBottom={"0.1rem"}>
                                {/* NOMBRE */}

                                <Grid item md={6} xs={6}>

                                    <TextInput
                                        label="Nombre del acceso directo"
                                        name="nombre_acceso"
                                        placeholder="Ingrese el nombre del acceso directo"
                                    />
                                </Grid>

                                {/* ENLACE */}
                                <Grid item md={6} xs={6}>

                                    <TextInput
                                        label="Enlace"
                                        name="enlace_acceso"
                                        placeholder="Ingrese el enlace"
                                    />
                                </Grid>

                                {/* ARCHIVO */}
                                <Grid item md={12} xs={12}>
                                {formData.logo_acceso ? (
                                        <>
                                            <Grid item md={12} xs={12}>
                                                <MyInputFile name="logo_acceso" label="Subir imagen" accept=".jpg, .jpeg, .png" />
                                            </Grid>
                                            <Grid item md={12} xs={12} >
                                                <center>
                                                <h3>Imagen cargada:</h3>
                                                <img src={getFile(formData.logo_acceso)} style={{ width: '200px', height: '200px', display:'inline-block' }} />
                                                </center>
                                                
                                            </Grid>
                                        </>
                                    ) : (
                                    <MyInputFile name="logo_acceso" label="Subir imagen" accept=".jpg, .jpeg, .png" />
                                    )
                                }

                                </Grid>

                                {/* SUBMIT */}
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, marginLeft: 'auto' }}>
                                    <Button variant='contained' color='error' sx={{ color: "white", marginRight: '10px' }} onClick={onClose} startIcon={<CloseIcon />}>
                                        Cancelar
                                    </Button>
                                    <Button type="submit" variant="contained" sx={{ color: "white" }} color="success" startIcon={<SaveIcon />}>
                                        Guardar
                                    </Button>
                                </Box>
                            </Grid>
                        </Form>
                    </Paper>
                )}
            </Formik>
        </Modal>
    )
}

export default ModalAddAccesoDirecto