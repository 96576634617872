import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, Drawer } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import fotoEBT from "../../assets/img/plantilla/Foto de Perfil EBT.png";
import { modulos, iconos } from "../../data/modulos";
import Cookies from "js-cookie";

// ICONOS EBTSUITE
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AppsIcon from "@mui/icons-material/Apps";

const Item = ({ title, to, icon, selected, setSelected }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	return (
		<MenuItem
			active={selected === title}
			style={{
				color: colors.grey[100],
			}}
			onClick={() => setSelected(title)}
			icon={icon}
		>
			<Typography>{title}</Typography>
			<Link to={to} />
		</MenuItem>
	);
};

const Sidebar = () => {
	const [validModuleValues, setValidModuleValues] = useState([]);
	const [isCollapsed, setIsCollapsed] = useState(false);
	const [selected, setSelected] = useState("Dashboard");
	const [isMobile, setIsMobile] = useState(false);
	const [userLogin, setUserLogin] = useState({});
	useEffect(() => {
		const menus = JSON.parse(Cookies.get("menu"));
		const user = JSON.parse(Cookies.get("usuarioData"));
		setUserLogin(user);
		setValidModuleValues(menus);
	}, []);

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const toggleSidebar = () => {
		setIsCollapsed(!isCollapsed);
	};

	// Define una función para verificar si el dispositivo es un móvil
	const checkIsMobile = () => {
		setIsMobile(window.innerWidth < 768); // Puedes ajustar este valor según tus necesidades
	};

	// Registra un listener para verificar si el dispositivo es un móvil cuando la ventana se redimensiona
	useEffect(() => {
		checkIsMobile();
		window.addEventListener("resize", checkIsMobile);
		return () => {
			window.removeEventListener("resize", checkIsMobile);
		};
	}, []);

	return (
		<Box
			sx={{
				position: "flex",
				display: "flex",
				height: "100vh",
				// top: 0,
				// bottom: 0,
				// zIndex: 10000,
				"& .pro-sidebar-inner": {
					background: `${colors.primary[400]} !important`,
					paddingRight: isCollapsed ? "0px" : "50px",
				},
				"& .pro-icon-wrapper": {
					backgroundColor: "transparent !important",
				},
				"& .pro-inner-item": {
					padding: "5px 35px 5px 20px !important",
				},
				"& .pro-inner-item:hover": {
					color: `${colors.blueAccent[500]} !important`,
				},
				"& .pro-menu-item.active": {
					color: `${colors.blueAccent[500]} !important`,
				},
				minHeight: "100vh",
				// overflowY: "auto",
				// overflowX: "hidden",
			}}
		>
			{isMobile ? ( // Muestra un botón de menú en lugar del sidebar en dispositivos móviles
				<IconButton
					sx={{
						zIndex: 1,
						position: "absolute",
						top: "20px",
						left: "20px",
						color: colors.grey[100],
					}}
					onClick={toggleSidebar}
				>
					<MenuOutlinedIcon />
				</IconButton>
			) : (
				<ProSidebar collapsed={isCollapsed} sx={{ width: "100%" }}>
					<Menu iconShape="square">
						{/* LOGO AND MENU ICON */}
						<MenuItem
							onClick={() => setIsCollapsed(!isCollapsed)}
							icon={
								isCollapsed ? <MenuOutlinedIcon /> : undefined
							}
							style={{
								margin: "10px 0 20px 0",
								color: colors.grey[100],
							}}
						>
							{!isCollapsed && (
								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									ml="15px"
								>
									<Typography
										variant="h3"
										color={colors.grey[100]}
									>
										EBTSuite +
									</Typography>
									<IconButton
										onClick={() =>
											setIsCollapsed(!isCollapsed)
										}
									>
										<MenuOutlinedIcon />
									</IconButton>
								</Box>
							)}
						</MenuItem>

						{!isCollapsed && (
							<Box mb="25px">
								<Box
									display="flex"
									justifyContent="center"
									alignItems="center"
								>
									<img
										alt="profile-user"
										width="100px"
										height="100px"
										src={fotoEBT}
										style={{
											cursor: "pointer",
											borderRadius: "50%",
										}}
									/>
								</Box>
								<Box textAlign="center">
									<Typography
										variant="h2"
										color={colors.grey[100]}
										fontWeight="bold"
										sx={{ m: "10px 0 0 0" }}
									>
										{userLogin && userLogin.nombre && userLogin.nombre.split(" ")[0]}
									</Typography>
									<Typography
										variant="h5"
										color={colors.greenAccent[500]}
									>
										{userLogin.puesto}
									</Typography>
								</Box>
							</Box>
						)}

						{/*  */}

						<Box paddingLeft={isCollapsed ? undefined : "10%"}>
							{/* DASHBOARD */}

							<Item
								title="Dashboard"
								to="/dashboard"
								icon={<HomeOutlinedIcon />}
								selected={selected}
								setSelected={setSelected}
							/>

							{/* GENERAL */}

							{/* CATALOGOS */}

							<Item
								title="Catalogos"
								to="/catalogos"
								icon={<AppsIcon />}
								selected={selected}
								setSelected={setSelected}
							/>

							{/* INICIO DEL MENU DINAMICO */}

							{modulos.map((modulo, index) => {
								// VERIFICAMOS QUE EXISTA EL SUBMENU
								// Elimina los espacios en blanco y convierte a minusculas para aquellos modulos que estan compuestos por mas de 2 palabras
								let nombreModulo = modulo.label.toLowerCase().replace(/\s+/g, '');
								const existe = validModuleValues.some((elemento) => elemento.startsWith(nombreModulo));

								// VALIDAMOS QUE EL OBJETO SEA UN SUBHEADER PARA RENDERIZAR EL SUBMENU

								if (modulo.type === "subheader" && modulo.label !== "Catalogo" && existe) {
									// CREAMOS EL ICONO DINAMICO PARA EL SUBMENU
									const IconoDinamicoSubmenu = iconos[modulo.icono];
									return (
										<SubMenu
											key={index}
											title={modulo.label}
											icon={<IconoDinamicoSubmenu />}
										>
											{/* RENDERIZAMOS LOS ITEMS DEL MENU */}

											{modulos.slice(index + 1).map((subModulo, subIndex) => {
												// SI EL ITEM LO TENEMOS ASIGNADO LO RENDERIZAMOS
												if (validModuleValues.includes(subModulo.value) && subModulo.value.startsWith(nombreModulo)) {
													const IconoDinamico = iconos[subModulo.icono];

													return (
														<Item
															key={subIndex}
															title={subModulo.label}
															to={`/${subModulo.url}`}
															icon={<IconoDinamico />}
															selected={selected}
															setSelected={setSelected}
														/>
													);
												}

												return null;
											})}
										</SubMenu>
									);
								}
							})}
						</Box>
					</Menu>
				</ProSidebar>
			)}

			{isMobile && ( // Muestra el sidebar en un Drawer en dispositivos móviles
				<Drawer
					anchor="left"
					open={isCollapsed}
					onClose={toggleSidebar}
					variant="temporary"
				>
					<ProSidebar collapsed={isCollapsed} sx={{ width: "100%" }}>
						<Menu iconShape="square">
							{/* LOGO AND MENU ICON */}
							<MenuItem
								onClick={() => setIsCollapsed(!isCollapsed)}
								icon={isCollapsed ? (<MenuOutlinedIcon />) : undefined}
								style={{
									margin: "10px 0 10px 0",
									color: colors.grey[100],
								}}
							>
								{!isCollapsed && (
									<Box
										display="flex"
										justifyContent="space-between"
										alignItems="center"
										ml="15px"
									>
										<Typography
											variant="h3"
											color={colors.grey[100]}
										></Typography>
										<IconButton
											onClick={() =>
												setIsCollapsed(!isCollapsed)
											}
										>
											<MenuOutlinedIcon />
										</IconButton>
									</Box>
								)}
							</MenuItem>

							{!isCollapsed && (
								<Box mb="25px">
									<Box
										display="flex"
										justifyContent="center"
										alignItems="center"
									>
										<img
											alt="profile-user"
											width="100px"
											height="100px"
											src={fotoEBT}
											style={{
												cursor: "pointer",
												borderRadius: "50%",
											}}
										/>
									</Box>
									<Box textAlign="center">
										<Typography
											variant="h2"
											color={colors.grey[100]}
											fontWeight="bold"
											sx={{ m: "10px 0 0 0" }}
										>
											{userLogin && userLogin.nombre && userLogin.nombre.split(" ")[0]}
										</Typography>
										<Typography
											variant="h5"
											color={colors.greenAccent[500]}
										>
											{userLogin.puesto}
										</Typography>
									</Box>
								</Box>
							)}

							{/*  */}

							<Box paddingLeft={isCollapsed ? undefined : "10%"}>
								{/* DASHBOARD */}

								<Item
									title="Dashboard"
									to="/dashboard"
									icon={<HomeOutlinedIcon />}
									selected={selected}
									setSelected={setSelected}
								/>

								{/* CATALOGOS */}

								<Item
									title="Catalogos"
									to="/catalogos"
									icon={<AppsIcon />}
									selected={selected}
									setSelected={setSelected}
								/>

								{/* INICIO DEL MENU DINAMICO */}

								{modulos.map((modulo, index) => {
									// VERIFICAMOS QUE EXISTA EL SUBMENU
									let nombreModulo = modulo.label.toLowerCase().replace(/\s+/g, '');
									const existe = validModuleValues.some((elemento) => elemento.startsWith(nombreModulo));

									// VALIDAMOS QUE EL OBJETO SEA UN SUBHEADER PARA RENDERIZAR EL SUBMENU

									if (modulo.type === "subheader" && modulo.label !== "Catalogo" && existe) {
										// CREAMOS EL ICONO DINAMICO PARA EL SUBMENU

										const IconoDinamicoSubmenu = iconos[modulo.icono];

										return (
											<SubMenu
												key={index}
												title={modulo.label}
												icon={<IconoDinamicoSubmenu />}
											>
												{/* RENDERIZAMOS LOS ITEMS DEL MENU */}

												{modulos.slice(index + 1).map((subModulo,subIndex) => {
													// SI EL ITEM LO TENEMOS ASIGNADO LO RENDERIZAMOS
														if (validModuleValues.includes(subModulo.value) && subModulo.value.startsWith(nombreModulo)) {
															const IconoDinamico = iconos[subModulo.icono];
															return (
																<Item
																	key={subIndex}
																	title={subModulo.label}
																	to={`/${subModulo.url}`}
																	icon={<IconoDinamico />}
																	selected={selected}
																	setSelected={setSelected}
																/>
															);
														}
														return null;
													}
												)}
											</SubMenu>
										);
									}
								})}
							</Box>
						</Menu>
					</ProSidebar>
				</Drawer>
			)}
		</Box>
	);
};

export default Sidebar;
