import React, { useEffect, useState } from 'react';
import { Box, Button, Grid  } from "@mui/material";
import { Formik, Form } from 'formik';
import {api} from '../../../../config/axios';
import Swal from 'sweetalert2';
import MenuItem from '@mui/material/MenuItem';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';

// COMPONENTES
import TextInput from '../../../../components/inputs/TextInput';
import MyMoneyInput from '../../../../components/inputs/MyMoneyInput';
import MySelect from '../../../../components/inputs/MySelect';
import Header from '../../../../components/Header';

const OperadoresDatosFiscales = ({data}) => {

  // ===============================================
  // FORM DATA
  // ===============================================

  const [formdata, SetFormData] = useState({

    rel_operador: '',
    rfc: '',
    curp: '',
    imss: '',
    monto_imss: '',
    cuenta_bancaria: '',
    clabe_interbancaria: '',
    banco: '',
    monto_isr: '',
    monto_infonavit: '',
    cant_pension_alimenticia: '',
    bono_imss_isr: '',
    sbc: '',
    sdi: ''

  })

  // ===============================================
  // LLAMAR DATOS
  // ===============================================

  useEffect(() => {

    buscarDatos();

    buscarBancos();

    SetFormData({...formdata, ["rel_operador"]: data.id})

  },[data]);

  // ===============================================
  // BUSCAR DATOS DEL OPERADOR
  // ===============================================

  const [submitOption, setSubmitOption] = useState('crear');

  const buscarDatos = async () => {

    const response = await api.get(`get/operadores/datos/fiscales/${data.id}`);

    if(response.data.success === true && response.data.result !== "Sin resultados"){

      SetFormData(response.data.result[0]);

      setSubmitOption('editar');

    }
    else if(response.data.success === true && response.data.result === "Sin resultados"){
      setSubmitOption('crear');

    }

  }

  // ===============================================
  // OPCIONES PARA LA SUCURSAL
  // ===============================================

  const [bancos, setBancos] = useState([]);

  const buscarBancos = (empresa) => {

    api.get('get/bancos')
    .then(result => {

      if(result.data.success == true)
      {
        setBancos(result.data.result);
      }

    })
    .catch(error => {
    
      console.log(error)
    
    })

  }

  // ===============================================
  // FUNCION PARA CREAR
  // ===============================================

  const crearRegistro = (values) => {

    const url = 'create/operador/datos/fiscales';

    api.post(url, values)
    .then((response) => {

      if(response.data.success === true){

        Swal.fire({
            icon: 'success',
            title: 'Se ha creado el registro',
        })

        setSubmitOption('editar');

      }

    })
    .catch((error) => {
        console.log("ocurrio un error: " +error);
    })

  }

  // ===============================================
  // FUNCION PARA EDITAR
  // ===============================================

  const editarRegistro = (values) => {console.log(values)

    const url = 'update/operador/datos/fiscales';

    api.put(url, values)
    .then((response) => {

      if(response.data.success === true){

        Swal.fire({
            icon: 'success',
            title: 'Se ha editado el registro!',
        })

      }

    })
    .catch((error) => {
        console.log("ocurrio un error: " +error);
    })

  }

  // ===============================================
  // SUBMIT DEL FORMULARIO
  // ===============================================

  const handleSubmit = async (values) => {

    if(submitOption === 'crear'){

      crearRegistro(values);

    }

    if(submitOption === 'editar'){

      editarRegistro(values);

    }

  }

  // ===============================================
  // FORMULARIO
  // ===============================================

  return (

    <Box paddingRight={3}>

      <Header title="Documentos Operador" subtitle="" />

      <Formik
        initialValues={formdata}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values)
        }}
      >

        {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
        
          <Form>

          <Grid container spacing={2} paddingBottom={"50px"}>
          
              {/* RFC OPERADOR */}

              <Grid item md={6} xs={12}>

                <TextInput
                    label="RFC"
                    name="rfc"
                    placeholder="Ingrese RFC del operador"
                />

              </Grid>

              {/* CURP */}

              <Grid item md={6} xs={12}>

                <TextInput
                    label="Curp"
                    name="curp"
                    placeholder="Ingrese Curp del operador"
                />

              </Grid>

              {/* IMSS */}

              <Grid item md={6} xs={12}>

                <TextInput
                    label="IMSS"
                    name="imss"
                    placeholder="Ingrese IMSS del operador"
                />

              </Grid>

              {/* MONTO IMSS */}
              
              <Grid item md={6} xs={12}>

                <MyMoneyInput
                    label="Monto IMSS"
                    name="monto_imss"
                />

              </Grid>

              {/* CUENTA BANCARIA */}
              
              <Grid item md={6} xs={12}>

                <TextInput
                    label="Cuenta Bancaria"
                    name="cuenta_bancaria"
                    placeholder="Ingrese cuenta bancaria"
                />

              </Grid>

              {/* CLABE BANCARIA */}
              
              <Grid item md={6} xs={12}>

                <TextInput
                    label="Clabe Bancaria"
                    name="clabe_interbancaria"
                    placeholder="Ingrese clabe bancaria"
                />

              </Grid>

              {/* BANCOS */}

              <Grid item md={6} xs={12}>

                <MySelect
                    label="Banco"
                    name="banco"
                    onChange={(event) => {
                        handleChange(event);
                    }}
                >
                {
                    bancos.map((op) => (

                        <MenuItem value={op.idBanco} key={op.idBanco}>{op.nombreCorto}</MenuItem>

                    ))
                }
                </MySelect>

              </Grid>

              {/* MONTO ISR */}
              
              <Grid item md={6} xs={12}>

                <MyMoneyInput
                    label="Monto ISR"
                    name="monto_isr"
                />

              </Grid>

              {/* MONTO INFONAVIT */}
              
              <Grid item md={6} xs={12}>

                <MyMoneyInput
                    label="Monto INFONAVIT"
                    name="monto_infonavit"
                />

              </Grid>

              {/* CANTIDAD PENSION ALIMENTICIA */}
              
              <Grid item md={6} xs={12}>

                <MyMoneyInput
                    label="Pension Alimenticia"
                    name="cant_pension_alimenticia"
                />

              </Grid>

              {/* BONO IMSS ISR */}
              
              <Grid item md={6} xs={12}>

                <MyMoneyInput
                    label="Bono IMSS ISR"
                    name="bono_imss_isr"
                />

              </Grid>

              {/* SALARIO BASE DE COTIZACION */}
              
              <Grid item md={6} xs={12}>

                <MyMoneyInput
                    label="Salario base de cotizacion"
                    name="sbc"
                />

              </Grid>

              {/* SALARIO DIARIO INTEGRADO */}
              
              <Grid item md={6} xs={12}>

                <MyMoneyInput
                    label="Salario diario integrado"
                    name="sdi"
                />

              </Grid>

              {/* SUBMIT */}

              <Grid item md={2} xs={12} style={{marginLeft: 'auto'}}>

                  <Button type="submit" variant="contained" sx={{color: "white"}} color="success" startIcon={<SaveIcon />}>
                      Guardar
                  </Button>

              </Grid>

          </Grid>

          </Form>

      )}
          
      </Formik>

    </Box>
    
  )

}

export default OperadoresDatosFiscales