export const theme = {
	primary: {
		600: "#1a202c",
		900: "#171923",
	},
	grey: { 300: "#d1d1d1" },
	white: "#fff",
	green: {
		300: "#2c7a7b",
	},
	scrollbar: {
		border: "#ffffff",
		thumb: {
			bg: "#e1e1e1",
		},
	},
	loader: {
		teal: "#5DDADB",
		purple: "#3437A2",
		pink: "#F78EB6",
		bg: "#171923db",
	},
	gradient: {
		blue: {
			300: "#002eb3",
			600: "#002360",
			900: "#051937",
		},
	},

	text: {
		grey: {
			300: "#a0aec0",
			500: "#718096",
		},
	},

	timeline: {
		divider: {
			bg: "#718096",
		},
	},
};
