import React, {useState, useEffect} from 'react';
import { Button, Box, Grid  } from '@mui/material';
import { useTheme } from "@mui/material";
import { tokens } from '../../../theme';

import Header from '../../../components/Header';
import CrearMultimediaTema from './modales/CrearMultimediaTema';

import AddCircleIcon from '@mui/icons-material/AddCircle';

// 

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';


// 

const MultimediaTemas = ({data = {}}) => {

    const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

    // ===============================================
    // FORM DATA
    // ===============================================

    const [formdata, SetFormData] = useState({

        rel_tema: '',
        nombre: '',
        ruta: '',

    })

    useEffect(() => {

        window.scrollTo(0, 0);

        if(Object.keys(data).length !== 0 ){

            SetFormData({...formdata, ["rel_tema"]: data.id});
      
        }

    },[data]);

    const buscarMultimedia = () => {}

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL CREARA
    // ===============================================

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {

        SetFormData({

            rel_tema: data.id,
            nombre: '',
            ruta: '',
    
        })

        setIsOpen(true);
    };
    
    const handleCloseModal = () => {
        setIsOpen(false);
    };

    // COLORES PARA LOS ESTILOS

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (

        <Box>

            <Header title="Multimedia del tema" />

            <Button variant="contained" 
                sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.blueAccent[700]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={handleOpenModal}
            >
                Agregar Multimedia
            </Button>
            
            {/* AREA PARA TARJETAS DE ARCHIVOS */}

            <Grid container spacing={2} padding={2}>

                <Grid item md={6}>

                <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            R
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title="Shrimp and Chorizo Paella"
        subheader="September 14, 2016"
      />
      <CardMedia
        component="img"
        height="100"
        image="/static/images/cards/paella.jpg"
        alt="Paella dish"
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          This impressive paella is a perfect party dish and a fun meal to cook
          together with your guests. Add 1 cup of frozen peas along with the mussels,
          if you like.
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
      </CardActions>

    </Card>
                    
                </Grid>

                <Grid item md={6}>

                <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            R
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title="Shrimp and Chorizo Paella"
        subheader="September 14, 2016"
      />
      <CardMedia
        component="img"
        height="100"
        image="/static/images/cards/paella.jpg"
        alt="Paella dish"
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          This impressive paella is a perfect party dish and a fun meal to cook
          together with your guests. Add 1 cup of frozen peas along with the mussels,
          if you like.
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
      </CardActions>

    </Card>
                    
                </Grid>

            </Grid>

            {/* MODAL PARA AGREGAR MULTIMEDIA */}

            <CrearMultimediaTema
                formdata={formdata}
                open={isOpen} 
                onClose={handleCloseModal}
                refresh={buscarMultimedia}
            />

        </Box>

    )

}

export default MultimediaTemas