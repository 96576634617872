import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ReplyIcon from '@mui/icons-material/Reply';
import PendingIcon from '@mui/icons-material/Pending';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import InfoIcon from '@mui/icons-material/Info';
import { Chip } from '@mui/material';

export default function getCellIcon(params){
    const status = params.row.id_estatus;

    let icon;
    let text = params.row.estatus;
    let color = 'default';
    if ([1, 8, 9].includes(status)) {
        // pendiente
        icon  = <QueryBuilderIcon sx={{ color: '#FFD700' }} />;
        color = 'warning'; 
    } else if ([2].includes(status)) {
        // en proceso
        icon = <FindInPageIcon sx={{ color: '#87CEEB' }} />;
        color= 'info';
    } else if ([3, 6, 11, 14].includes(status)) {
        // exitoso
        icon = <CheckCircleIcon sx={{ color: '#008000' }} />;
        color = 'success';
    } else if ([4, 7].includes(status)) {
        // cancelado
        icon = <CancelIcon sx={{ color: '#FF0000' }} />;
        color = 'error';
    } else if ([5].includes(status)) {
        // devuelto
        icon = <ReplyIcon sx={{ color: '#FFA500' }} />;
        color = 'warning';
    } else if ([10].includes(status)) {
        icon = <PendingIcon sx={{ color: '#FFD700' }} />;
        color = 'warning'; 
    } else if ([12].includes(status)) {
        // Asignado
        icon = <AssignmentTurnedInIcon sx={{ color: '#008000' }} />;
        color = 'secondary';
    } else if ([13].includes(status)) {
        // Parcialmente completado
        icon = <InfoIcon sx={{ color: '#FFD700' }} />;
        color = 'info';
    }
    
    return (
        <Chip icon={icon} label={text} color={color} variant='outlined'/>
    );
};
