import React, { useState } from "react";
import ReactImage from "react-image";
import { Box, Button, Grid, Typography } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default function DocumentViewer({ type, src }) {
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	function changePage(offset) {
		const newPageNumber = pageNumber + offset;
		if (newPageNumber >= 1 && newPageNumber <= numPages) {
			setPageNumber(newPageNumber);
		}
	}

	const previousPage = () => {
		changePage(-1);
	};

	const nextPage = () => {
		changePage(1);
	};

	if (type === "pdf") {
		return (
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				padding={1}
  				margin={1}
			>
				<Box
					border="1px solid"
					borderColor="divider"
					borderRadius={1}
					boxShadow={1}
					overflow="hidden"
					style={{ position: 'relative'}}
				>
					<style>
					{`
						.react-pdf__Page__annotations,
						.react-pdf__Page__textContent {
						display: none !important;
						}
					`}
					</style>
					<Grid container justifyContent="center">
						<Button
							variant="contained"
							color="primary"
							disabled={pageNumber <= 1}
							onClick={previousPage}
							style={{ margin: 8 }}
							startIcon={<ChevronLeftIcon />}
						>
							Anterior
						</Button>
						<Typography variant="subtitle1" sx={{marginTop:'10px'}}>
							Pagina {pageNumber} de {numPages}
						</Typography>
						<Button
							variant="contained"
							color="primary"
							disabled={pageNumber >= numPages}
							onClick={nextPage}
							style={{ margin: 8 }}
							endIcon={<ChevronRightIcon />}
						>
							Siguiente
						</Button>
					</Grid>
					<Document file={src} onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error}>
						<Page pageNumber={pageNumber} size="A4" scale={0.80} />
					</Document>
				</Box>
			</Box>
		);
	} else if (type === "image") {
		return <ReactImage src={src} />;
	} else {
		return <div>Unsupported document type</div>;
	}
}
