import { Alert, Box, Button, Grid, Paper, Typography} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../../theme";
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '@mui/icons-material/Help';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function StepRevision({handleNext, dataRow, articulos}){
    const [data, setData] = useState([]);
    const [articles, setArticulos] = useState([]);
    useEffect(() => {
        setData(dataRow);
        setArticulos(articulos);
    },[dataRow])

    const theme  = useTheme();
    const colors = tokens(theme.palette.mode);
    
    
    const style = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {flexDirection: 'row',},
        bgcolor: colors.blueAccent[900],
        boxShadow: 24,
        p: 4,
        borderRadius: '15px',
    }; 
    
    const Group = ({label, valor, md}) => {
        return(
            <Grid item md={md}>
                <Typography variant="h5" color="textSecondary">
                    {label}
                </Typography>
                <Typography variant="h4">{valor}</Typography>
            </Grid>
        )
    }
    const handleOnClick = (button) => {
        handleNext(button);
    };

    const Info = () => {
        return (
            <Alert variant="outlined" severity="info" color="info">

                <Typography variant="h6">Se genera una requisicion por cada articulo incluido</Typography> 
            </Alert>
        );
    };
    return(
        <Box>
            <Paper sx={style}>
                <Grid container>
                    <Typography id="modal-modal-title" variant="h2"> Detalles del requerimiento #{data.id}</Typography>
                    <br/>
                    <Group label='Nombre del solicitante' valor={data.nombreCompletoUsuario} md={12}/>
                
                    <Group label='Cantidad' valor={data.cantidad} md={6}/> 
                    <Group label='Descripcion' valor={data.descripcion} md={6}/>
                    <Group label='Justificacion' valor={data.justificacion} md={12}/>
                    <Grid item md={12}/>
                    
                        { articles && articles.length > 0 ? (
                            <Button sx={{marginTop:'15px'}} color="info" startIcon={<HelpIcon/>}>
                                <strong>Cotizacion individual</strong>    
                            </Button>
                            ) : null}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, marginLeft: 'auto' }}>
                            <Button variant='contained' color='error' sx={{color: "white", marginRight: '10px'}} onClick={() => handleOnClick('rechazar')} startIcon={<CancelIcon />}>
                                <strong>Rechazar</strong> 
                            </Button>
                            <Button variant="contained" sx={{color: "white", marginRight: '10px'}} color="secondary" onClick={() => handleOnClick('asignar')} startIcon={<AssignmentTurnedInIcon  />}>
                                <strong>Asignar equipo</strong> 
                            </Button>
                            <Button variant="contained" sx={{color: "white"}} color="success" onClick={() => handleOnClick('autorizar')} startIcon={<CheckCircleIcon />}>
                                <strong>Autorizar</strong>  
                            </Button>
                        </Box>
                </Grid>
            </Paper>
        
        </Box>
    )
}