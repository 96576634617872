import React from 'react';
import { useField } from 'formik';
import { TextField } from "@mui/material";
import Swal from 'sweetalert2';

const MyInputFile = ({ label, accept, ...props }) => {

    const [field, meta, helpers] = useField(props);
    
    const handleChange = (event) => {
        const file = event.currentTarget.files[0];

        if (file) {
            if (!accept || accept.split(',').some(type => file.name.toLowerCase().endsWith(type.trim()))) {
                helpers.setValue(file);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Archivo no válido',
                    text: 'Seleccione otro archivo',
                });
                event.currentTarget.value = null; // Limpiar el valor del input
                helpers.setValue(null); // Limpiar el valor del campo
            }
        }
    };

    return (

        <TextField
            sx={{ width: "100%" }}
            variant="outlined"
            className="file-input"
            autoComplete="off"
            label={label}
            type="file"
            inputProps={{ accept: props.accept || '.jpg, .jpeg, .png, .gif, .pdf' }}
            onChange={handleChange}
            error={meta.touched || Boolean(meta.error)}
            helperText={meta.touched || meta.error}
        />

    )

}

export default MyInputFile