import { baseURL } from "./url";
import axios from "axios";
import Cookies from "js-cookie";
import { logOutFunction } from "./functions";

// ===============================================
// AXIOS PARA LOS ENDPOINTS
// ===============================================

const api = axios.create({
    baseURL: baseURL+"api/",
});

api.interceptors.request.use(
    (config) => {
        const token = Cookies.get('token');
        if(token){
            config.headers['access-token'] = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)

api.interceptors.response.use(
    (response) => {
        // Aquí puedes acceder al objeto de respuesta y verificar el valor de 'success'
        if (response.data && response.data.success === false) {
            // Realiza las acciones necesarias en caso de que el token no sea válido
            logOutFunction();
        }
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// ===============================================
// AXIOS PARA MANDAR FOTOS Y ARCHIVOS
// ===============================================

const sendFormData = async (url, formData, method = "POST") => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        const response = await api[method.toLowerCase()](url, formData, config);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// ===============================================
// AXIOS PARA DESCARGAR FOTOS Y ARCHIVOS
// ===============================================

const fileDownload = axios.create({
    baseURL: baseURL+"api/",
    responseType: 'blob',
});

fileDownload.interceptors.request.use(
    (config) => {
        const token = Cookies.get('token');
        if(token){
            config.headers['access-token'] = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)

fileDownload.interceptors.response.use(
    (response) => {
        // Aquí puedes acceder al objeto de respuesta y verificar el valor de 'success'
        if (response.data && response.data.success === false) {
            // Realiza las acciones necesarias en caso de que el token no sea válido
            logOutFunction();
        }
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export { api,  sendFormData, fileDownload };
