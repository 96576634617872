import { useEffect, useState } from "react";
import { api } from "../../../config/axios";
import { Box, Button } from "@mui/material";
import Header from "../../../components/Header";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
//iconos
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import PaidIcon from '@mui/icons-material/Paid';

import * as Yup from 'yup';
import ModalAsignarViatico from "./modals/ModalAsignarViaticos";
import React from 'react';
import Swal from "sweetalert2";
import CollapsibleTable from "../../../components/CollapsibleTable";
import ModalGastosOperador from "./modals/ModalGastosOperador";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import ModalAsignarFondos from "./modals/ModalAsignarFondos";

const Viaticos = () => {
	// ======================================================
	// COLORES
	// ======================================================
	const theme  = useTheme();
	const colors = tokens(theme.palette.mode);

  	const [data, setData] = useState([]);

	useEffect(() => {
		buscarSolicitudesViaticos();
	}, []);

	const buscarSolicitudesViaticos = async () => {
		const response = await api.get('get/orden/viaticos');

        if(response.data.success === true && response.data.result !== "Sin resultados"){
            setData(response.data.result);
        }else{
            setData([]);
        }
	};

	
	const handleEdit = (row) => {
		setOpenGastos(true);
		setInicialFormValuesGastos({...row, monto_aprobado: row.monto, comentario_despacho: ''});
	};
	const renderCell = (row) => {
        return  <IconButton onClick={() => handleEdit(row)}>
                    <EditIcon color="info"/>
                </IconButton>
    }
	
	function RenderMenu(params){
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);

        const handleClick = (event, rowData) => {
            setAnchorEl(event.currentTarget);
            // setSelectedRow(rowData);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

		const handleFinalizar = () => {
			Swal.fire({
				title: '¿Estas seguro?',
				text: "No podras revertir esta accion",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: colors.redAccent[700],
				confirmButtonText: 'Si, finalizar'
			}).then(async (result) => {
				if (result.isConfirmed) {
					const response = await api.put(`update/orden/viaticos/${params.id}`, {estado: 0, rel_operador: params.rel_operador, rel_orden: params.rel_orden});
					if(response.data.success === true){
						Swal.fire(
							'Finalizado',
							'La solicitud ha sido finalizada',
							'success'
						)
						buscarSolicitudesViaticos();
					}
				}
			})
		};

        const handleAgregarFondos = () => {
            handleRowClick(params);
            handleClose();
        }
        
        return (
            <div>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="grid"
                    onClick={(event) => handleClick(event, params.row)}
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: 48 * 4.5,
                            width: '25ch',
                        },
                    }}
                >
                    <MenuItem onClick={handleFinalizar}>
                        <DoneOutlineIcon color="info" style={{ marginRight: '10px' }}  />
                        Finalizar
                    </MenuItem>
                    <MenuItem onClick={handleAgregarFondos}>
                        <PaidIcon color="success" style={{ marginRight: '10px' }}/>
                        Agregar mas fondos
                    </MenuItem>
                </Menu>
            </div>
        );
    }
	// ======================================================
	// COLUMNAS DE LA TABLA
	// ======================================================
	const columns = [
		{ id: 'id', 				label: '#', 				minWidth: 100 },
		{ id: 'rel_orden', 			label: 'Orden', 			minWidth: 100 },
		{ id: 'nombre_completo', 	label: 'Usuario', 			minWidth: 100 },
		{ id: 'monto', 	 			label: 'Monto Asignado', 	minWidth: 100, currency:true},
		{ id: 'acciones',         	label: 'Acciones',        	hasRenderCell: true, renderCell: RenderMenu }
	];	
	// ======================================================
	// COLUMNAS DETALLE DE LA TABLA
	// ======================================================
	const detailColumns = [
		{ id: 'driverCode', 		label: 'Driver Code', 		minWidth: 100 },
		{ id: 'nombre_operador',    label: 'Operador',        	minWidth: 170 },
        { id: 'tipo_gasto',     	label: 'Tipo gasto',    	minWidth: 100 },
        { id: 'monto',       		label: 'Monto',      		minWidth: 100, currency: true },
        { id: 'monto_aprobado',     label: 'Monto aprobado',    minWidth: 100, currency: true },
        { id: 'adeudo',          	label: 'Adeudo',         	minWidth: 100, currency: true },
		{ id: 'editar',         	label: 'Editar',        	hasRenderCell: true, renderCell: renderCell }

    ];
	// ======================================================
	// ESTADOS
	// ======================================================
	const [open, setOpen] = useState(false);

	const [initialFormValues, setInicialFormValues] = useState({
        rel_orden: '',
		monto: '',
		comentario: '',
    })
	const [openGastos, setOpenGastos] = useState(false);
	const [initialFormValuesGastos, setInicialFormValuesGastos] = useState({
		monto_aprobado: '',
	});
	const yupValidation = Yup.object().shape({
        monto: Yup.string().required('Requerido'),
		rel_orden: Yup.object().required('Requerido'),
    })
	// ======================================================
	const [openFondos, setOpenFondos] = useState(false);
	const [initialFormValuesFondos, setInicialFormValuesFondos] = useState({
		monto: '',
	});
	const yupValidationFondos = Yup.object().shape({
		monto: Yup.string().required('Requerido'),
	});
	const yupValidationGastos = Yup.object().shape({
		monto_aprobado: Yup.number()
		.required('Requerido')
		.max(initialFormValuesGastos.monto, `El monto no debe exceder ${initialFormValuesGastos.monto}`),
	});


	const handleRowClick = (data) => {
		console.log(data);
		setOpenFondos(true);
		setInicialFormValuesFondos({
			...data,monto: ''	
		});
	};
	const handleNewSolicitud = () => {
		setOpen(true);
		setInicialFormValues({
			rel_orden: '',
			monto: '',
			comentario: '',
		});
	};
	const actions = () => {
		// return (
		// 	<>
		// 		<Select>
		// 			<MenuItem onClick={handleNewSolicitud}>Buscar por </MenuItem>
		// 		</Select>
				
		// 	</>
		// )
	}
	return (
		<Box m={'20px'}>
			<Header title={'Viaticos'} subtitle={'Asignacion de viaticos'}/>
			{/* display the data here */}
			<Button variant="contained" 
                sx={{
					fontWeight:"bold",
					backgroundColor:colors.blueAccent[700]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={handleNewSolicitud}
            >
                Asignar viaticos
            </Button>
			<Box
                m="40px 0 0 0"
                height="75vh"
                width="100%"
                sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`
                }
                }}
            >
				 <CollapsibleTable
					rows={data}
					columns={columns}
					detailColumns={detailColumns}
					handleChecks={()=>{}}
					showCheck={false}
					header="Gastos del operador"
					consecutivo="rel_orden"
					refresh={buscarSolicitudesViaticos}
					actions={actions()}
				/>
				 {/* MODAL PARA CREAR SUCURSAL */}

				 <ModalAsignarViatico
					open={open}
					onClose={() => setOpen(false)}
					data={initialFormValues}
					yupObject={yupValidation}
					// handleDelete={handleDelete}
					refresh={buscarSolicitudesViaticos}
				/>
				<ModalGastosOperador
					open={openGastos}
					onClose={() => setOpenGastos(false)}
					data={initialFormValuesGastos}
					yupObject={yupValidationGastos}
					// handleDelete={handleDelete}
					refresh={buscarSolicitudesViaticos}
				/>
				<ModalAsignarFondos
					open={openFondos}
					onClose={() => setOpenFondos(false)}
					data={initialFormValuesFondos}
					yupObject={yupValidationFondos}
					// handleDelete={handleDelete}
					refresh={buscarSolicitudesViaticos}
				/>
			</Box>
		</Box>
	);
};

export default Viaticos;