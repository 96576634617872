import React, { useEffect, useRef, useState } from "react";
import {
	TimelineWrapper,
	TimelineBox,
	TimelineTime,
	TimelineDivider,
	TimelineDividers,
	useTimeline,
} from "planby";

export function Timeline({
	isBaseTimeFormat,
	isSidebar,
	dayWidth,
	hourWidth,
	numberOfHoursInDay,
	offsetStartHoursRange,
	sidebarWidth,}) {
	const { time, dividers, formatTime } = useTimeline(
		numberOfHoursInDay,
		isBaseTimeFormat
	);
	const [currentTime, setCurrentTime] = useState(new Date());
	const currentTimeRef = useRef(null);

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentTime(new Date());
		}, 1000 * 60);
		if (currentTimeRef.current) {
			currentTimeRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
		}
		return () => clearInterval(interval);
	}, []);

	const renderTime = (index) => (
		<TimelineBox key={index} width={hourWidth} ref={index === currentTime.getHours() ? currentTimeRef : null}>
			<TimelineTime>
				{formatTime(index + offsetStartHoursRange).toLowerCase()}
			</TimelineTime>
			<TimelineDividers>{renderDividers()}</TimelineDividers>
			{/* {index === currentTime.getHours() && (
				<div
					style={{
						position: "absolute",
						height: "1500px",
						width: 2,
						backgroundColor: "red",
						left:
							(currentTime.getMinutes() / 60) * hourWidth + "px",
					}}
				/>
			)} */}
		</TimelineBox>
	);

	const renderDividers = () =>
		dividers.map((_, index) => (
			<TimelineDivider key={index} width={hourWidth} />
		));

	return (
		<TimelineWrapper
			dayWidth={dayWidth}
			sidebarWidth={sidebarWidth}
			isSidebar={isSidebar}
		>
			{time.map((_, index) => renderTime(index))}
		</TimelineWrapper>
	);
}
