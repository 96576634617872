import React from 'react'

const PreguntasTema = () => {

    return (

        <div>PreguntasTema</div>

    )

}

export default PreguntasTema