import React, { useEffect, useState } from 'react';
import { Box, Button, Grid  } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {api} from '../../../config/axios';
import Swal from 'sweetalert2';
import MyNumberInput from '../../../components/inputs/MyNumberInput';
import TextInput from '../../../components/inputs/TextInput';

import SaveIcon from '@mui/icons-material/Save';
import Header from '../../../components/Header';

const EditarDatosTema = ({data = {}}) => {

    // ===============================================
    // FORM DATA
    // ===============================================

    const [formdata, SetFormData] = useState({

        rel_curso: '',
        tema: '',
        intentos: '',
        tiempoExamen: '',

    })

    useEffect(() => {

        window.scrollTo(0, 0);

        if(Object.keys(data).length !== 0 ){

            SetFormData(data)
      
        }

    },[data])

    // ===============================================
    // SUBMIT DEL FORMULARIO
    // ===============================================

    const handleSubmit = (values) => {

        const url = 'update/curso/tema';

        try {

            api.put(url, values)
            .then((response) => {

                if(response.data.success === true){

                    Swal.fire({
                        icon: 'success',
                        title: 'Se ha actualizado el tema!',
                    })

                }

            })
            .catch((error) => {
                console.log("ocurrio un error: " +error);
            })

        } catch (error) {

        console.error("Upload failed:", error);

        }

    }

    return (

        <Box paddingRight={3}>

            <Header title="Datos Generales"/>

            <Formik
                initialValues={formdata}
                enableReinitialize={true}
                validationSchema={Yup.object({
                    tema: Yup.string().required('Requerido'),
                    intentos: Yup.number().required('Requerido'),
                    tiempoExamen: Yup.number().required('Requerido')
                })}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values)
                }}
            >

                {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                    
                    <Form encType="multipart/form-data">

                    <Grid container spacing={2} paddingBottom={"50px"}>
                    
                        {/* TEMA */}

                        <Grid item md={12} xs={12}>

                            <TextInput
                                label="Tema"
                                name="tema"
                                placeholder="Ingrese Nombre del Tema"
                            />

                        </Grid>

                        {/* INTENTOS POR TEMA */}

                        <Grid item md={6} xs={12}>

                            <MyNumberInput
                                label="Intentos"
                                name="intentos"
                            />

                        </Grid>

                        {/* TIEMPO PARA EXAMEN */}

                        <Grid item md={6} xs={12}>

                            <MyNumberInput
                                label="Tiempo Examen (minutos)"
                                name="tiempoExamen"
                            />

                        </Grid>

                        {/* SUBMIT */}

                        <Grid item md={2} xs={12} style={{marginLeft: 'auto'}}>

                            <Button type="submit" variant="contained" sx={{color: "white"}} color="success" startIcon={<SaveIcon />}>
                                Guardar
                            </Button>

                        </Grid>

                    </Grid>

                    </Form>

                )}
                    
                </Formik>

        </Box>
        
    )

}

export default EditarDatosTema