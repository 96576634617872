import ApartmentIcon from '@mui/icons-material/Apartment';
import GroupIcon from '@mui/icons-material/Group';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PublicIcon from '@mui/icons-material/Public';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BadgeIcon from '@mui/icons-material/Badge';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CreateIcon from '@mui/icons-material/Create';
import TocIcon from '@mui/icons-material/Toc';
import RuleIcon from '@mui/icons-material/Rule';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PriceChange from '@mui/icons-material/PriceChange';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import StoreIcon from '@mui/icons-material/Store';
import DescriptionIcon from '@mui/icons-material/Description';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import SummarizeIcon from '@mui/icons-material/Summarize';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import AdsClickIcon from '@mui/icons-material/AdsClick';
/*=============================================
ICONOS PARA EL MODULO DE CATALOGOS
=============================================*/

// * CADA VEZ QUE SE AGREGUE UN MODULO DE CATALOGO AGREGAR EL ICONO EN EL MODULO DE CATALOGOS TAMBIEN*

export const iconos = {

    ApartmentIcon,
    GroupIcon,
    LocalShippingIcon,
    PublicIcon,
    RecentActorsIcon,
    AssignmentIndIcon,
    AccountCircleIcon,
    BadgeIcon,
    TocIcon,
    CreateIcon,
    RuleIcon,
    AutoStoriesIcon,
    MenuBookIcon,
    PriceChange,
    AccountBalanceIcon,
    FormatListNumberedRtlIcon,
    BusinessCenterIcon,
    StoreIcon,
    RequestQuoteIcon,
    DescriptionIcon,
    MonetizationOnIcon,
    LocalPhoneIcon,
    SummarizeIcon,
    LibraryBooksIcon,
    DirectionsBusIcon,
    MonitorHeartIcon,
    QueryBuilderIcon,
    AdsClickIcon
};


/*=============================================
MODULOS PARA EL MENU DINAMICO
=============================================*/

// * CADA VEZ QUE SE AGREGUE UN MODULO NUEVO, FAVOR DE AGREGARLO A ESTE ARRAY PARA PODER UTILIZARLO DE MANERA DINAMICA CON SUS RESPECTIVOS ATRIBUTOS*

export const modulos = [

    // GENERAL
    { type: 'subheader', label: 'General', icono: 'PublicIcon'},
    { value: 'general.Extensiones', label: 'Extensiones', icono: 'LocalPhoneIcon', url: 'extensiones' },
    { value: 'general.Formatos', label: 'Formatos', icono: 'DescriptionIcon', url: 'formatos' },
    { value: 'general.Accesos Directos', label: 'Accesos Directos', icono: 'AdsClickIcon', url: 'accesos_directos' },

    
    // CATALOGO

    { type: 'subheader', label: 'Catalogo', icono: 'TocIcon'},
    
    { value: 'catalogo.Usuarios', label: 'Usuarios', icono: 'AccountCircleIcon', url: 'usuarios' },
    { value: 'catalogo.Empresas', label: 'Empresas', icono: 'ApartmentIcon', url: 'empresas' },
    { value: 'catalogo.Operadores', label: 'Operadores', icono: 'LocalShippingIcon', url: 'operadores' },
    { value: 'catalogo.Empleados', label: 'Empleados', icono: 'GroupIcon', url: 'empleados' },
    { value: 'catalogo.Sucursales', label: 'Sucursales', icono: 'PublicIcon', url: 'sucursales' },
    { value: 'catalogo.Departamentos', label: 'Departamentos', icono: 'RecentActorsIcon', url: 'departamentos' },
    { value: 'catalogo.Puestos', label: 'Puestos', icono: 'AssignmentIndIcon', url: 'puestos' },
    { value: 'catalogo.Perfiles', label: 'Perfiles', icono: 'BadgeIcon', url: 'perfiles' },
    { value: 'catalogo.Proveedores', label: 'Proveedores', icono: 'StoreIcon', url: 'proveedores' },
    { value: 'catalogo.Tipo de Formatos', label: 'Tipo de Formatos', icono: 'DescriptionIcon', url: 'tipoFormatos' },
    { value: 'catalogo.Permisos', label: 'Permisos', icono: 'RuleIcon', url: 'permisos' },
    { value: 'catalogo.Articulos', label: 'Articulos', icono: 'LibraryBooksIcon', url: 'articulos' },
    { value: 'catalogo.Tractores', label: 'Tractores', icono: 'DirectionsBusIcon', url: 'tractores' },


    // CURSOS

    { type: 'subheader', label: 'Cursos', icono: 'MenuBookIcon'},
    { value: 'cursos.Crear Cursos', label: 'Crear Cursos', icono: 'CreateIcon', url: 'crear_curso', submenu: 'Cursos' },
    { value: 'cursos.Calificar Cursos', label: 'Calificar Cursos', icono: 'RuleIcon', url: 'calificar_curso', submenu: 'Cursos' },
    { value: 'cursos.Tomar Cursos', label: 'Tomar Cursos', icono: 'AutoStoriesIcon', url: 'tomar_curso', submenu: 'Cursos' },

    // CONTABILIDAD
    { type:  'subheader', label: 'Contabilidad', icono: 'AccountBalanceIcon'},
    { value: 'contabilidad.Tipo de Cambio', label: 'Tipo de Cambio', icono: 'PriceChange', url: 'tipodecambio', submenu: 'Contabilidad' },
    { value: 'contabilidad.Comparativo', label: 'Comparativo', icono: 'MonetizationOnIcon', url: 'comparativo', submenu: 'Contabilidad' },
    // { value: 'contabilidad.Viaticos', label: 'Viaticos', icono: 'CreateIcon', url: 'viaticos' },
    { value: 'contabilidad.Concentrado', label: 'Concentrado', icono: 'SummarizeIcon', url: 'concentrado' },
    // RECURSOS HUMANOS
    { type: 'subheader', label: 'Recursos Humanos', icono: 'RecentActorsIcon'},
    { value: 'recursoshumanos.Firmas', label: 'Firmas', icono: 'CreateIcon', url: 'firmas', submenu: 'Recursos Humanos' },
    // ADMINISTRACION
    { type: 'subheader', label: 'Administracion', icono: 'BusinessCenterIcon'},
    { value: 'administracion.Requerimientos', label: 'Requerimientos', icono: 'FormatListNumberedRtlIcon', url: 'requerimientos', submenu: 'Administracion' },
    { value: 'administracion.Requisiciones', label: 'Requisiciones', icono: 'RequestQuoteIcon', url: 'requisiciones', submenu: 'Administracion' },
    
    // OPERACION
    { type: 'subheader', label: 'Operacion', icono: 'LocalShippingIcon'},
    { value: 'operacion.Viaticos', label: 'Viaticos', icono: 'AssignmentIndIcon', url: 'viaticos' },
    { value: 'operacion.Gastos', label: 'Gastos', icono: 'MonetizationOnIcon', url: 'gastos' },

    // MONITOREO
    { type: 'subheader', label: 'Monitoreo', icono: 'MonitorHeartIcon'},
    { value: 'monitoreo.Horarios', label: 'Horarios', icono: 'QueryBuilderIcon', url: 'horarios', submenu: 'Monitoreo' },
    
];



