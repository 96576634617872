import React, { useEffect, useState } from 'react';
import { Box, Button, Grid  } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MenuItem from '@mui/material/MenuItem';
import {api, sendFormData} from '../../../../config/axios';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";

// ICONOS
import SaveIcon from '@mui/icons-material/Save';

// COMPONENTES
import TextInput from '../../../../components/inputs/TextInput';
import MySelect from '../../../../components/inputs/MySelect';
import MyDateInput from '../../../../components/inputs/MyDateInput';
import MyPhoneInput from '../../../../components/inputs/MyPhoneInput';
import MyInputFile from '../../../../components/inputs/MyInputFile';
import Header from '../../../../components/Header';

const DatosOperador = ({data = {}}) => {

  const navigate = useNavigate();

  // ===============================================
  // FORM DATA
  // ===============================================

  const [formdata, SetFormData] = useState({

    driverCode: '',
    nombre: '',
    apellido: '',
    rel_empresa: '',
    rel_sucursal: '',
    flota: '',
    email: '',
    codigo_postal: '',
    fecha_nacimiento: '',
    fecha_ingreso: '',
    fecha_baja: '',
    fecha_recontratacion: '',
    telefono: '',
    foto: ''

  })

  useEffect(() => {

    window.scrollTo(0, 0);

    if(Object.keys(data).length !== 0 ){

      data.foto === null 
      ?
      data.fotoActual = 'N/A'
      :
      data.fotoActual = data.foto;

      SetFormData(data);

      buscarEmpresas();

      buscarSucursales(data.rel_empresa);

    }

  },[data])

  // ===============================================
  // OPCIONES PARA LA EMPRESA
  // ===============================================

  const [empresas, setEmpresas] = useState([]);

  const buscarEmpresas = () => {

    api.get('get/empresas')
    .then(result => {

      if(result.data.success === true && result.data.success !== 'Sin resultados')
      {
        setEmpresas(result.data.result);
      }

    })
    .catch(error => {
    
      console.log(error)
    
    })

  }

  // ===============================================
  // OPCIONES PARA LA SUCURSAL
  // ===============================================

  const [sucursales, setSucursales] = useState([]);

  const buscarSucursales = (empresa) => {

    api.get(`get/sucursales/empresa/${empresa}`)
    .then(result => {

      if(result.data.success === true && result.data.success !== 'Sin resultados')
      {
        setSucursales(result.data.result);
      }else{
        setSucursales([]);
      }

    })
    .catch(error => {
    
      console.log(error)
    
    })

  }

  // ===============================================
  // OPCIONES PARA EL SELECT DE FLOTILLA
  // ===============================================

  const flotillas = [

    { value: 'Azul', label: 'Azul' },
    { value: 'Negra', label: 'Negra' },
    { value: 'Roja', label: 'Roja' },
    { value: 'Verde', label: 'Verde' },

  ];

  // ===============================================
  // SUBMIT DEL FORMULARIO
  // ===============================================

  const handleSubmit = async (values) => {

    const url = 'update/operador';

    try {

      const result = await sendFormData(url, values, "PUT"); // 

      if(result.success === true){

        Swal.fire({
          icon: 'success',
          title: 'Se ha editado el registro!',
        })
        
        navigate('/operadores');

      }

    } catch (error) {

      console.error("Upload failed:", error);

    }

  }

  return (

    <Box paddingRight={3}>

      <Header title="Editar Operador" subtitle="" />

      <Formik
        initialValues={formdata}
        enableReinitialize={true}
        validationSchema={Yup.object({
            driverCode: Yup.string().required('Requerido'),
            nombre: Yup.string().required('Requerido'),
            apellido: Yup.string().required('Requerido'),
            rel_empresa: Yup.string().required('Requerido'),
            rel_sucursal: Yup.string().required('Requerido'),
            flota: Yup.string().required('Requerido'),
            fecha_ingreso: Yup.string().required('Requerido'),
            email: Yup.string().email('Correo Invalido'),
        })}
        onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values)
        }}
    >

    {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
        
        <Form encType="multipart/form-data">

        <Grid container spacing={2} paddingBottom={"50px"}>
        
            {/* DRIVER CODE */}

            <Grid item md={4} xs={12}>

                <TextInput
                    label="Driver Code"
                    name="driverCode"
                    placeholder="Ingrese Driver Code"
                />

            </Grid>

            {/* NOMBRES */}

            <Grid item md={4} xs={12}>

                <TextInput
                    label="Nombre(s)"
                    name="nombre"
                    placeholder="Ingrese nombre(s)"
                />

            </Grid>

            {/* APELLIDOS */}

            <Grid item md={4} xs={12}>

                <TextInput
                    label="Apellido(s)"
                    name="apellido"
                    placeholder="Ingrese apellido(s)"
                />

            </Grid>

            {/* EMPRESA */}

            <Grid item md={6} xs={12}>

                <MySelect
                    label="Empresa"
                    name="rel_empresa"
                    onChange={(event) => {
                        handleChange(event);
                        buscarSucursales(event.target.value)
                    }}
                >
                {
                    empresas.map((op) => (

                        <MenuItem value={op.id} key={op.id}>{op.nombre_comercial}</MenuItem>

                    ))
                }
                </MySelect>

            </Grid>

            {/* SUCURSAL */}

            <Grid item md={6} xs={12}>

                <MySelect
                    label="Sucursal"
                    name="rel_sucursal"
                    onChange={(event) => {
                        handleChange(event);
                    }}
                >
                {
                    sucursales.map((op) => (

                        <MenuItem value={op.id} key={op.id}>{op.nombre_sucursal}</MenuItem>

                    ))
                }
                </MySelect>

            </Grid>

            {/* FLOTA */}

            <Grid item md={6} xs={12}>

                <MySelect
                    label="Flota"
                    name="flota"
                >
                {
                    flotillas.map((op) => (

                        <MenuItem value={op.value} key={op.value}>{op.label}</MenuItem>

                    ))
                }
                </MySelect>

            </Grid>

            {/* CORREO */}
            
            <Grid item md={6} xs={12}>

                <TextInput
                    label="Correo Electronico"
                    name="email"
                    placeholder="Ingrese correo"
                />

            </Grid>

            {/* CODIGO POSTAL */}
            
            <Grid item md={6} xs={12}>

                <TextInput
                    label="Codigo Postal"
                    name="codigo_postal"
                    placeholder="Ingrese correo"
                />

            </Grid>

            {/* TELEFONO */}

            <Grid item md={6} xs={12}>

                <MyPhoneInput
                    label="Telefono"
                    name="telefono"
                />

            </Grid>

            {/* FECHA NACIMIENTO */}
            
            <Grid item md={6} xs={12}>

                <MyDateInput
                    label="Fecha Nacimiento"
                    name="fecha_nacimiento"
                    format="YYYY/MM/DD"
                />


            </Grid>

            {/* FECHA INGRESO */}

            <Grid item md={6} xs={12}>

                <MyDateInput
                    name="fecha_ingreso"
                    label="Fecha Ingreso"
                    format="YYYY/MM/DD"
                />

            </Grid>

            {/* FECHA BAJA */}

            <Grid item md={6} xs={12}>

                <MyDateInput
                    name="fecha_baja"
                    label="Fecha Baja"
                    format="YYYY/MM/DD"
                />

            </Grid>

            {/* FECHA RECONTRATACION */}

            <Grid item md={6} xs={12}>

                <MyDateInput
                    name="fecha_recontratacion"
                    label="Fecha Recontratacion"
                    format="YYYY/MM/DD"
                />

            </Grid>

            {/* FOTO */}

            <Grid item md={6} xs={12}>

                <MyInputFile
                    name="foto"
                    label="Subir Foto"
                    accept=".jpg, .jpeg, .png"
                />

            </Grid>

            {/* SUBMIT */}

            <Grid item md={2} xs={12} style={{marginLeft: 'auto'}}>

                <Button type="submit" variant="contained" sx={{color: "white"}} color="success" startIcon={<SaveIcon />}>
                    Guardar
                </Button>

            </Grid>

        </Grid>

        </Form>

    )}
        
    </Formik>

    </Box>

  )

}

export default DatosOperador