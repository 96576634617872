import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../theme";
import {api} from '../../../config/axios';
import * as Yup from 'yup';
import { marcarURL } from '../../../config/functions';
import { format } from 'date-fns';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

//COMPONENTES
import Header from '../../../components/Header';
import ModalCreateGM from '../../../components/modals/ModalCreateGM';
import Cookies from 'js-cookie';

const TCs = () => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // SEGMENTO PARA BUSCAR SUCURSALES
    // ===============================================

    const [data, setData] = useState([]);

    useEffect(() => {

        buscarTC();

        marcarURL('tipodecambio');

    },[])

    const buscarTC = async () => {

        const response = await api.get('get/tc');

        if(response.data.success === true && response.data.result !== "Sin resultados"){

            setData(response.data.result);

        }else{

            setData([]);

        }

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        {
            field: "id",
            headerName: "#",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "tipocambio",
            headerName: "Tipo de Cambio",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "fecha_registro",
            headerName: "Fecha de última actualización",
            flex: 2,
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => {
                
                if(params.row.fecha_registro !== '0000-00-00'){
                    const originalDate = params.row.fecha_registro;
                    const formattedDate = format(new Date(originalDate), 'dd/MM/yyyy');
                    return formattedDate;
                }else{
                    return '00/00/0000'
                }
                   
            },
        },
        {
            field: "email",
            headerName: "Actualizó",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },

    ];

    // ===============================================
    // VALORES PARA SUBMIT DEL FORMULARIO
    // ===============================================

    const [initialFormValues, setInicialFormValues] = useState({  

        tipo_cambio: '',
        usuario_registra: Cookies.get('user')
    })

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL CREARA
    // ===============================================

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {

        setInicialFormValues({

            tipo_cambio: '',
            usuario_registra: Cookies.get('user')
    
        })

        setIsOpen(true);
    };
    
    const handleCloseModal = () => {
        setIsOpen(false);
    };

    // ===============================================
    // INPUTS PARA ARMAR EL FORMULARIO
    // ===============================================

    const inputForms = [

        {
            componente: "inputMoneda",
            label: "Tipo de Cambio",
            name: "tipo_cambio",
            placeholder: "Ingrese el Tipo de Cambio",
            md: 12,
            xs: 12
        }

    ];

    // ===============================================
    // VALIDACIONES PARA EL FORMULARIO
    // ===============================================

    const yupValidation = Yup.object().shape({

        tipo_cambio: Yup.string()
        .required('Requerido')
        .test('no_es_cero', '¡El tipo de cambio no puede ser cero!', (value) => parseFloat(value.replace(/\$/g, "").replace(/\,/g, "")) != 0)

    })

    // ===============================================
    // ===============================================

    return (

        <Box m="20px">
            
            <Header title="Tipo de Cambio" subtitle="Tipo de Cambio EBTSuite" />

             {/* BOTON PARA ABRIL MODAL */}

             <Button variant="contained" 
                sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.blueAccent[700]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={handleOpenModal}
            >
                Agregar tipo de cambio
            </Button>

            {/* TABLA  */}

            <Box
                m="40px 0 0 0"
                height="75vh"
                width="100%"
                sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`
                }
                }}
            >
                <DataGrid 
                    
                    rows={data} 
                    columns={columns} 
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                        toolbar: {
                            csvOptions : { utf8WithBom: true }
                        },
                    }}
                    hideFooterSelectedRowCount
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>

            {/* MODAL PARA CREAR SUCURSAL */}

            <ModalCreateGM
                modalTitle="Agregar Tipo de Cambio"
                swalTitle="¡Se ha guardado el Tipo de Cambio!"
                url="create/tc"
                open={isOpen} 
                onClose={handleCloseModal} 
                size='40'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarTC}
            />
            
        </Box>

    )

}

export default TCs