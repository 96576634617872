import { Popover, Typography } from "@mui/material";
import {
	ProgramBox,
	ProgramContent,
	ProgramFlex,
	ProgramStack,
	ProgramTitle,
	ProgramText,
	ProgramImage,
	useProgram,
} from "planby";
import { useState } from "react";

export const ProgramItem = ({ program, ...rest }) => {
	const { styles, formatTime, set12HoursTimeFormat, isLive, isMinWidth } =
		useProgram({ program, ...rest });
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		setAnchorPosition({ top: event.clientY, left: event.clientX });
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const { data } = program;
	const { image, title, since, till, isLunch, isLate, isOverTime ,duration, channelType } = data;

	const sinceTime = formatTime(since, set12HoursTimeFormat()).toLowerCase();
	const tillTime = formatTime(till, set12HoursTimeFormat()).toLowerCase();
	
	const hours = Math.floor(duration / 3600);
	const minutes = Math.floor((duration % 3600) / 60);
	const formattedDuration = hours >= 1 ? `${hours} horas y ${minutes} minutos` : `${minutes} minutos`;
	
	const style_ts = {
		backgroundColor: isLunch ? "#FFBB64" : isLate ? "#EF5668" : isOverTime ? "#10A997" : "#525CEB",
		borderRadius: "10px",
		height: "100%",
		width: "100%",
	};
	const style_schedule = {
		backgroundColor: "#363D9C",
		borderRadius: "10px",
		height: "100%",
		width: "100%",
	};
	return (
		<ProgramBox width={styles.width} style={styles.position}>
			<div style={channelType === 'schedule' ? style_schedule : style_ts} onClick={handleClick}>
			</div>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={anchorPosition}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				PaperProps={{
					style: {
						backgroundColor: "#141B2D",
						padding: "10px",
						borderRadius: "10px",
					},
				}}
			>
				<Typography variant="h3" align="center">
					{sinceTime} - {tillTime}
					<br />
					({formattedDuration})
				</Typography>
			</Popover>
		</ProgramBox>
	);
};
