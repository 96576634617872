import { useTheme } from "@emotion/react";
import { Button, Grid, Modal, Paper, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { tokens } from "../../../../../theme";
import { useEffect } from "react";

// ICONOS
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';


import html2canvas from "html2canvas";
import ImgSrc from "../../../../../assets/img/SIGNATURE_PLANTILLA_EBT.svg";
import Swal from "sweetalert2";
import { api } from "../../../../../config/axios";

const ModalFirmas = (props) => {
	const {open, onClose, data } = props;

	const theme  = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [display, setDisplay] = useState('flex');
	const imageRef = useRef(null);
	
	function formatPhoneNumber(phoneNumberString) {
		if (!phoneNumberString) {
			return null;
		}
		const cleaned = phoneNumberString.replace(/\D/g, '');
		const match = cleaned.match(/(\d{1,3})(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			return '+'+ match[1] + '(' + match[2] + ')-' + match[3] + '-' + match[4];
		}
		return null;
	}
	

	useEffect(() => {
        window.scrollTo(0, 0);
		if (!data || data.length === 0 || open === false) {
			return;
		}
		const image = new Image();
		image.src = ImgSrc;
		const tel_usa_formatted = formatPhoneNumber(data.telefono_usa);
		const tel_mex_formatted = formatPhoneNumber(data.telefono_mex);
		const tel_formatted 	= formatPhoneNumber(data.telefono);
		image.onload = async () => {
			const canvas = document.createElement("canvas");
			const font 	 = new FontFace("MontserratBold", "url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gnD-w.ttf)");
			const font2  = new FontFace("MontserratSemiBold", "url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3gnD-w.ttf)");
			Promise.all([font.load(), font2.load()]).then((fonts) => {
				fonts.forEach((font) => {
					document.fonts.add(font);
				});
		
				canvas.width  = image.width;
				canvas.height = image.height;
				const context = canvas.getContext("2d");
		
				// Dibujar la imagen original
				context.drawImage(image, 0, 0);
				
				{/* NOMBRE */}
				context.font = "30px MontserratBold";
				context.fillStyle = "rgb(34, 40, 88)";
				context.fillText(data.nombreCompleto, 35, 55);
				{/* PUESTO */}
				context.font = "26px MontserratBold";
				context.fillStyle = "rgb(144, 178, 109)";
				context.fillText(data.nombre_puesto, 35, 85);
				{/* TELEFONO USA */}
				context.font = "11px MontserratSemiBold";
				context.fillStyle = "rgb(0, 0, 0)";
				context.fillText(tel_usa_formatted || '', 45, 130);
				{/* TELEFONO MEXICO */}
				context.font = "11px MontserratSemiBold";
				context.fillStyle = "rgb(0, 0, 0)";
				context.fillText(tel_mex_formatted || '', 45, 160);
				{/* TELEFONO CELULAR */}
				context.font = "11px MontserratSemiBold";
				context.fillStyle = "rgb(0, 0, 0)";
				context.fillText(tel_formatted || '', 183, 130);
				// Obtener la imagen generada
				const generatedImage = new Image();
				generatedImage.src = canvas.toDataURL(); 

				// Mostrar la imagen en el componente
				const imageContainer = imageRef.current;
				imageContainer.appendChild(generatedImage);
			});
		};
    },[open, data]);

	
	const handleDownloadImage = () => {
		html2canvas(imageRef.current, {
			width: 900, // Ancho del canvas
			height: 200, // Altura del canvas
		}).then((canvas) => {
			const link = document.createElement("a");
			link.href = canvas.toDataURL();
			link.download = `${new Date().toISOString()}.png`;
			link.click();
		});
	};
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 'auto',
        width: '900',
        bgcolor: colors.blueAccent[900],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }; 
	const enviarFirma = async () => {
		try{
			let link = document.createElement("a");
			html2canvas(imageRef.current, {
				width: 900, // Ancho del canvas
				height: 200, // Altura del canvas
			}).then(async (canvas) => {
				let body = {
					link: canvas.toDataURL(),
					correo: data.correo,
					nombre: data.nombreCompleto
				}
				link.href = canvas.toDataURL();
				const response = await api.post('send/firma/empleado', body);
				if(response.data.success === true){
					Swal.fire({
						title: '¡Firma enviada!',
						icon: 'success',
						confirmButtonText: 'Aceptar',
						confirmButtonColor: colors.greenAccent[400],
					})
					onClose();
				}
			});
		}catch(e){
			console.log(e);
		}
	}	
	return(
		<Modal
			open={open}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Paper sx={{
				width: "58%",
				p: 3,
				bgcolor: colors.blueAccent[900],
				borderRadius: "10px",
			}}
			>
				<Typography id="modal-modal-title" variant="h3" align="center"> Generar Firma </Typography>                
				<Grid container spacing={2} paddingBottom={"0.1rem"} sx={{mt:'10px'}}>
					{/* IMAGEN */}
					<Grid item md={12} xs={12} xl={12} container justifyContent="center" alignItems="center">
						<div ref={imageRef}></div>
					</Grid>
					{/* SUBMIT */}
					<Grid item md={4} xs={4}>
						<Button variant='contained' sx={{color: "white",width: "100%",borderRadius: "8px" ,marginRight: '10px'}} onClick={onClose} startIcon={<CloseIcon />}>
							Cancelar
						</Button>
					</Grid>
					<Grid item md={4} xs={4}>
						<Button variant='contained' sx={{color: "white",width: "100%",borderRadius: "8px" ,marginRight: '10px'}} onClick={handleDownloadImage} startIcon={<SystemUpdateAltIcon />}>
							Descargar
						</Button>
					</Grid>
					<Grid item md={4} xs={4}>
						<Button variant='contained' color='info' sx={{color: "white",width: "100%",borderRadius: "8px" ,marginRight: '10px'}} onClick={enviarFirma} startIcon={<SendIcon />}>
							Generar y enviar
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</Modal>
	)
};
export default ModalFirmas;