import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../theme";
import {api} from '../../../config/axios';
import * as Yup from 'yup';
import { marcarURL } from '../../../config/functions';
import { format } from 'date-fns';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

//COMPONENTES
import Header from '../../../components/Header';
import ModalCreateGM from '../../../components/modals/ModalCreateGM';
import ModalEditGM from '../../../components/modals/ModalEditGM';

import Cookies from 'js-cookie';

const TipoFormatos = () => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // SEGMENTO PARA BUSCAR SUCURSALES
    // ===============================================

    const [data, setData] = useState([]);

    useEffect(() => {

        buscarTipoFormato();

        marcarURL('tipodeformato');

    },[])

    const buscarTipoFormato = async () => {

        const response = await api.get('get/tipo_formatos');

        if(response.data.success === true && response.data.result !== "Sin resultados"){

            setData(response.data.result);

        }else{

            setData([]);

        }

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        {
            field: "id",
            headerName: "#",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "descripcion",
            headerName: "Descripción",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
    ];

    // ===============================================
    // VALORES PARA SUBMIT DEL FORMULARIO
    // ===============================================

    const [initialFormValues, setInicialFormValues] = useState({
        descripcion: '',
    })

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL CREAR
    // ===============================================

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {

        setInicialFormValues({
            descripcion: '',
        })

        setIsOpen(true);
    };
    
    const handleCloseModal = () => {
        setIsOpen(false);
    };
    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL EDITAR
    // ===============================================

    const [isOpenEdit, setIsOpenEdit] = useState(false);

    const handleOpenModalEdit = () => {
        setIsOpenEdit(true);
    };
    
    const handleCloseModalEdit = () => {
        setIsOpenEdit(false);
    };

    // ===============================================
    // INPUTS PARA ARMAR EL FORMULARIO
    // ===============================================

    const inputForms = [

        {
            componente: "input",
            label: "Tipo de Formato",
            name: "descripcion",
            placeholder: "Ingrese el tipo de formato",
            type: "text",
            md: 12,
            xs: 12
        },
    ];

    // ===============================================
    // VALIDACIONES PARA EL FORMULARIO
    // ===============================================

    const yupValidation = Yup.object().shape({
		descripcion: Yup.string().required('Requerido'),
    })

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const handleRowClick = (params) => {

        setInicialFormValues(params.row);
        handleOpenModalEdit();

    }
    return (

        <Box m="20px">
            
            <Header title="Tipo de Formato" subtitle="Tipo de Formato EBTSuite" />

             {/* BOTON PARA ABRIL MODAL */}

             <Button variant="contained" 
                sx={{
					fontWeight:"bold",
					backgroundColor:colors.blueAccent[700]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={handleOpenModal}
            >
                Agregar Tipo de Formato
            </Button>

            {/* TABLA  */}

            <Box
                m="40px 0 0 0"
                height="75vh"
                width="100%"
                sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`
                }
                }}
            >
                <DataGrid
                    rows={data} 
                    columns={columns} 
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            csvOptions : { utf8WithBom: true }
                        },
                    }}
                    hideFooterSelectedRowCount
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    onRowClick={handleRowClick}
                />
            </Box>

            {/* MODAL PARA CREAR SUCURSAL */}

            <ModalCreateGM
                modalTitle="Agregar Tipo de Formato"
                swalTitle="¡Se ha guardado el Tipo de Formato!"
                url="create/tipo_formato"
                open={isOpen} 
                onClose={handleCloseModal} 
                size='40'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarTipoFormato}
            />

            <ModalEditGM
                modalTitle="Editar Tipo de Formato"
                swalTitle="Se ha editado el Tipo de Formato!"
                url="update/tipo_formato"
                urlDelete="delete/tipo_formato/"
                open={isOpenEdit} 
                onClose={handleCloseModalEdit} 
                openModal={handleOpenModalEdit}
                size='40'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarTipoFormato}
            />
            
        </Box>

    )

}

export default TipoFormatos