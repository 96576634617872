import { Box, IconButton, Modal, Paper, Typography } from "@mui/material";
import { tokens } from "../../../../theme";
// ICONOS
import { useTheme } from '@emotion/react';
import { useEffect, useState } from "react";
import { api } from "../../../../config/axios";
import BadgeButton from '../components/BadgeButton';

import { useNavigate } from "react-router-dom";
import CollapsibleTable from "../components/CollapsibleTable";
import { marcarURL } from "../../../../config/functions";
import BackspaceIcon from '@mui/icons-material/Backspace';
import Swal from "sweetalert2";

export default function ModalSelectedCotizaciones (props) {
    const {open, onClose, onOpen } = props;
    // const isNonMobile = useMediaQuery("(min-width:600px)");
    // const [formData, setFormData] = useState(data);
   
    const theme  = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [display, setDisplay] = useState('block');
    
    useEffect(() => {
        window.scrollTo(0, 0);
        
    },[]);
   
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 'auto',
        width: '900',
        bgcolor: colors.blueAccent[900],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }; 
    
	const navigate = useNavigate();
    
    const [data, setData] = useState([]);
    
    useEffect(() => {

        buscarPeticiones();

        marcarURL('peticiones');

    },[])

    const buscarPeticiones = async () => {
        const response = await api.get('get/peticiones' , {params: {estatus: 8, extraEstatus: 13,isCompras: true}});
        if(response.data.success === true && response.data.result !== "Sin resultados"){
            // Aqui se aplica para que no aparezca las peticiones que no tienen cotizaciones
            const data = response.data.result.filter((result) => result.details.length > 0);
            // Si hay requerimientos que no tienen cotizaciones, se obtienen los ids de los requerimientos para actualizar su estatus a 4
            // Aqui no es necesario ya que deberia de estar actualizado ya el estatus si es que antes se ha comprado por lo que es el estatus 
            // ids_reqs.map(async (id) =>{
            //     const resultEstatus = await api.put('update/estatus/requerimiento', {id: id});
            //     if (resultEstatus.data.success === true) {
            //         navigate('/requerimientos');
            //     }
            // });
            setData(data);
        }else{
            setData([]);
        }
    }
    const actualizarEstatus = async (ids, estatus) => {
		const response = await api.put('update/cotizacion/estatus', {ids: ids, estatus: estatus});
		if(response.data.success === true){
			Swal.fire({
				icon: 'success',
				title: response.data.text,
			})
		}else{
			Swal.fire({
				icon: 'error',
				title: response.data.text,
			})
		}
	};
    const handleDeleteAction = (row) => {
        // onClose();
        setDisplay('none');
        const id   =  row.id;
        Swal.fire({
            title: "Estas seguro de esta accion?",
            text: "No se podra revertir",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, estoy seguro!",
        }).then((result) => {
            setDisplay('block');
            if (result.isConfirmed) {
                actualizarEstatus([id], 4);
                buscarPeticiones();
            }
        });
    };
    const renderCell = (row) => {
        return  <IconButton onClick={() => handleDeleteAction(row)}>
                    <BackspaceIcon color="error"/>
                </IconButton>
    }
    const columns = [
        { id: 'descripcion',            label: 'Descripcion',       minWidth: 170 },
        { id: 'costo_total_estimado',   label: 'Monto estimado',    currency:true,  minWidth: 100 },
    ];
    const detailColumns = [
        { id: 'descripcion',    label: 'Descripcion',   minWidth: 170 },
        { id: 'cantidad',       label: 'Cantidad',      minWidth: 100 },
        { id: 'costo',          label: 'Precio',        currency:true, minWidth: 100 },
        { id: 'total',          label: 'Total',         currency:true, minWidth: 100 },
        { id: 'borrar',         label: 'Accion',        hasRenderCell: true, renderCell: renderCell }
    ];

    const [checks, setChecks] = useState([]);
    const handleChecks = (checks) => {
        setChecks([...checks]);
    }
   
    const handleOnClick = (params) => {
        let data = JSON.stringify(checks);
        navigate(`/vista_compras?data=${data}`)
    }
    return(
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{display: display}}
        >
            <Box sx={style} >
                <Typography id="modal-modal-title" variant="h3"> Selecciona las cotizaciones </Typography>
                <br/>
				{checks && checks.length > 0 
					? <BadgeButton style={{display: 'flex', justifyContent: 'flex-end'}} badgeContent={checks.length} onClick={handleOnClick}>Ver {checks.length} articulos</BadgeButton>
					: null
				}	
				<CollapsibleTable
					rows={data}
					columns={columns}
					detailColumns={detailColumns}
					handleChecks={handleChecks}
					showCheck={true}
                    header="Peticiones de compra"
				/>
            </Box>
        </Modal>
    )
} 