import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from '@mui/icons-material/Refresh';
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Checkbox, Icon, Paper } from "@mui/material";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import { useRef } from "react";
import ExportCSV from "./DocumentExport";
import { tokens } from "../theme";

const Row = (props) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const {
		index,
		row,
		columns,
		detailColumns,
		showCheck,
		handleCheck,
		handleCheckAll,
		checkedItems,
		header,
		consecutivo,
	} = props;
	const [open, setOpen] = React.useState(false);
	const getField = (column, row) => {
		return column.currency
			? formatCurrency(row[column.id])
			: column.hasRenderCell
			? column.renderCell(row)
			: row[column.id];
	};
	const formatCurrency = (value) => {
		const formatter = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
		return formatter.format(value);
	};
	React.useEffect(() => {
		const checks = [...checkedItems];
		const newTotal = checks.length;
		// getTotalChecked(newTotal);
	}, [checkedItems]);
	return (
		<React.Fragment>
			<TableRow
				sx={{
					backgroundColor: index % 2 === 0 ? colors.grey["600"] : colors.grey["500"],
					"& > *": { borderBottom: "unset" },
					"&.MuiTableRow-root	":{
						height: "10px",
					}
				}}
			>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? (<KeyboardArrowDownIcon />) 
						      : (<KeyboardArrowRight />)
						}
					</IconButton>
					{showCheck && (
						<Checkbox
							color="info"
							onChange={(event) => handleCheckAll(event, row)}
							inputProps={{ "aria-label": "select all items" }}
						/>
					)}
				</TableCell>
				{columns &&
					columns.map((column) => (
						<TableCell component="th" scope="row" align={column.alignColumn || 'center'}>
						{/* En esta parte se considera lo que tenga las columnas, si trae de formato de currency, se le da formato */}
						{/* Sino, si tiene un customRender que viene a ser como render */}
						{getField(column, row)}
						</TableCell>
					))
				}
			</TableRow>
			<TableRow>
				<TableCell
					style={{
						backgroundColor: colors.blueAccent[900],
						paddingBottom: 0,
						paddingTop: 0,
					}}
					colSpan={12}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1 }}>
							{/* Collapse component, tabla header*/}
							<Typography
								variant="h4"
								gutterBottom
								component="div"
							>
								{header} # {row[`${consecutivo}`] || row.id}
							</Typography>
							<Table size="medium" aria-label="detalles">
								<TableHead
									style={{
										backgroundColor: colors.blueAccent[700],
										borderBottom: "none",
									}}
								>
									<TableRow>
										{/* El primer renglon es de columnas */}
										{/* Se le agrega un padding de checkbox ya que se agregan la funcionalidad */}
										<TableCell padding="checkbox"></TableCell>
										{/* Recorre dinamicamente las columnas del detalle */}
										{detailColumns &&
											detailColumns.map((column) => (
												<TableCell key={column.id}>
													{column.label}
												</TableCell>
											))}
									</TableRow>
								</TableHead>
								<TableBody>
									{/* El cuerpo de la tabla */}
									{row.details && row.details.length > 0 ? (
										row.details.map((detailRow, index) => (
											<TableRow key={index}>
												<TableCell padding="checkbox">
													{showCheck && (
														<Checkbox
															color="info"
															checked={checkedItems.includes(detailRow.id)}
															onChange={(event) =>
																handleCheck(event, detailRow.id)
															}
														/>
													)}
												</TableCell>
												{detailColumns &&
													detailColumns.map(
														(column) => (
															<TableCell
																component="th"
																scope="row"
															>
																{getField(column, detailRow)}
															</TableCell>
														)
													)}
											</TableRow>
										))
									) : (
										<TableRow>
											<TableCell align="center" colSpan={detailColumns.length + 1}>
												<Typography variant="body1">
													No hay registros
												</Typography>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
};
const TablePaginationActions = (props) => {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? (
					<LastPageIcon />
				) : (
					<FirstPageIcon />
				)}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? (
					<FirstPageIcon />
				) : (
					<LastPageIcon />
				)}
			</IconButton>
		</Box>
	);
}
export default function CollapsibleTable(props) {
	const { columns, detailColumns, data, showCheck, handleChecks, header, actions,consecutivo, refresh } = props;

	const tableRef = useRef(null);

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	// const emptyRows =
	// page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	const [rows, setRows] = useState(data);
	const [checkedItems, setCheckedItems] = useState([]);
	/**
	 * Reduces an array of checked items and returns a flattened array of all checked items.
	 *
	 * @param {Array} checkedItems - The array of checked items to be reduced.
	 * @returns {Array} - A flattened array of all checked items.
	 */
	const allCheckedItems = checkedItems.reduce((accumulator, current) => {
			return [...accumulator, current];
		}, []).flat();

	const handleCheck = (event, id) => {
		if (event.target.checked) {
			setCheckedItems([...checkedItems, id]);
		} else {
			setCheckedItems(checkedItems.filter((item) => item !== id));
		}
	};

	const handleCheckAll = (event, row) => {
		const allItems = row.details.map((detailRow) => detailRow.id);
		if (event.target.checked) {
			setCheckedItems([...checkedItems, ...allItems]);
		} else {
			setCheckedItems(
				checkedItems.filter((item) => !allItems.includes(item))
			);
		}
	};
	useEffect(() => {
		setRows(data);
	}, [data]);
	useEffect(() => {
		handleChecks(allCheckedItems);
	}, [checkedItems]);
	return (
		<Box m="20px 0 0 0" width="100%" flex="1">
			{/* <TableContainer sx={{ height: "100%", width: "100%", overflowX:'auto' }}> */}
			<TableContainer component={Paper} sx={{width: "100%", overflowX:'auto'}}>
				{/* <div style={{ overflowX: 'auto' }}> */}
					<Table aria-label="collapsible table" sx={{ width: '100%', borderCollapse: 'collapse'}} ref={tableRef}>
						<TableHead
							style={{
								backgroundColor: colors.blueAccent[700],
								borderBottom: "none",
							}}
						>
							<TableRow
								style={{
									backgroundColor: colors.primary[500],
								}}
							>
								<TableCell style={{ padding: 3 }} colSpan={columns.length + 1}>
									<Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
										<Box display="flex" alignItems="center">
											<ExportCSV csvData={rows} fields={columns} fileName={`file-${Math.floor(Math.random() * 1000000)}`}/>
											<IconButton
												aria-label="expand row"
												size="small"
												onClick={() => refresh()}
											>
												<RefreshIcon />
											</IconButton>
										</Box>
										{/* AQUI SE DESPLIEGA SI TIENE ALGO DE ACCIONES */}
										<Box display="flex" alignItems="center">
											{actions}
										</Box>
									</Box>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell />
								{columns && 
									columns.map((column) => (
										<TableCell key={column.id}>
											{column.label}
										</TableCell>
									))}
							</TableRow>
						</TableHead>
						<TableBody>
							{rows && rows.length === 0 ? (
								<TableRow>
									<TableCell align="center" colSpan={columns.length + 1}>
										<Typography variant="body1">
											No hay registros
										</Typography>
									</TableCell>
								</TableRow>
							) : (
								rows && Array.isArray(rows)
								?   (rowsPerPage > 0
										? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										: rows
									).map((row, index) => (
										<Row
											index={index}
											key={row.id}
											row={row}
											consecutivo={consecutivo}
											columns={columns}
											detailColumns={detailColumns}
											checkedItems={checkedItems.filter((item) =>
												row.details.map((detailRow) => detailRow.id).includes(item)
											)}
											handleCheck={handleCheck}
											handleCheckAll={handleCheckAll}
											showCheck={showCheck}
											header={header}
										/>
									))
								: 
									(
										<TableRow>
											<TableCell align="center" colSpan={columns.length + 1}>
												<Typography variant="body1">
													No hay registros
												</Typography>
											</TableCell>
										</TableRow>
									)
							)}
						</TableBody>
						<TableFooter sx={{width:'100%', flexShrink:0}}>
							<TableRow>
								<TablePagination
									rowsPerPageOptions={[5,10,25,{ label: "All", value: -1 }]}
									colSpan={columns.length + 1}
									count={rows ? rows.length : 0}
									rowsPerPage={rowsPerPage}
									page={page}
									SelectProps={{
										inputProps: {
											"aria-label": "Registros por pagina",
										},
										native: true,
									}}
									sx={{ backgroundColor: colors.blueAccent[700] }}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
									labelRowsPerPage="Filas por página"
									labelDisplayedRows={({ from, to, count }) =>
										`${from}-${to} de ${count}`
									}
									ActionsComponent={TablePaginationActions}
								/>
							</TableRow>
						</TableFooter>
					</Table>
				{/* </div> */}
			</TableContainer>
		</Box>
	);
}
