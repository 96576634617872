import { useTheme } from "@emotion/react";
import {
	Autocomplete,
	Button,
	Grid,
	Modal,
	Paper,
	TextField,
	Typography,
} from "@mui/material";
import { useState } from "react";
import { tokens } from "../../../theme";
import { useEffect } from "react";
// ICONOS
import CloseIcon from "@mui/icons-material/Close";

import { api } from "../../../config/axios";
import { Done } from "@mui/icons-material";
import { Form, Formik } from "formik";
import MySelect from "../../../components/inputs/MySelect";
import MenuItem from '@mui/material/MenuItem';

const ModalBusqueda = (props) => {
	const { open, onClose, filters, onApplyFilters } = props;

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [display, setDisplay] = useState("flex");

	const [departamentos, setDepartamentos] = useState([]);

	const buscarDepartamentos = async () => {
		const response = await api.get("get/departamentos/qb");
		if (response.data.success === true) {
			setDepartamentos(response.data.result);
			return response.data.result;
		} else {
			setDepartamentos([]);
			return [];
		}
	};
	const [usuarios, setUsuarios] = useState([]);
	const buscarUsuarios = async () => {
		const response = await api.get("get/usuarios/qb");
		if (response.data.success === true) {
			setUsuarios(response.data.result);
			return response.data.result;
		} else {
			setUsuarios([]);
			return [];
		}
	}	
	useEffect(() => {
		window.scrollTo(0, 0);
		buscarDepartamentos();
		buscarUsuarios();
	}, []);

	// ===============================================
	//  COMPONENTE TAGBOX
	// ===============================================

	const TagBox = ({ label, options, ...props }) => {
		const handleOnChange = (event, value) => {
			props.onChange(event, value);
			// if (value.some((option) => option.name === "TODOS")) {
			// 	props.onChange(event, [{ id: 0, name: "TODOS" }]);
			// } else {
			// 	props.onChange(event, value);
			// }
		};
		return (
			<Autocomplete
				id="multiple-limit-tags"
				multiple
				options={[...options]}
				getOptionLabel={(option) => option.name}
				// defaultValue={data.ids_departamentos || []}
				onChange={handleOnChange}
				renderInput={(params) => (
					<TextField
						{...params}
						label={label}
						placeholder={props.placeholder}
					/>
				)}
			/>
		);
	};
	const handleSubmit = async (values) => {
		if(values.tipo === 1){
			let deptos = values.ids_departamentos.map((dep) => dep.id).join(",");
			onApplyFilters({...filters, tipo:values.tipo,  usuarios:[] ,departamentos: deptos});
		}else{
			let usuarios = values.ids_usuarios.map((dep) => dep.id).join(",");
			onApplyFilters({...filters, tipo:values.tipo, departamentos:[],usuarios: usuarios});
		}
		setTipo(0);
		onClose();
		// Saca los ids de los departamentos
		// values.ids_departamentos = values.ids_departamentos.map((dep) => dep.id);
		// // Elimina los ids repetidos
		// values.ids_departamentos = [...new Set(values.ids_departamentos)];
		// // Convierte el array en string separado por comas
		// values.ids_departamentos = values.ids_departamentos.join(',');
		// const url  = values.id ? 'update/formato' : 'create/formato';
		// let method = values.id ? 'put' : 'post';
		// try {

		//     const result = await sendFormData(url, values, method); //

		//     if(result.success === true){

		//         Swal.fire({
		//             icon: 'success',
		//             title: result.text,
		//         })
		//         onClose();
		//         refresh();
		//     }

		// } catch (error) {

		//     console.error("Upload failed:", error);

		// }
	};
	const opciones = [
		{
			id: 1,
			name: "Departamentos",
		},
		{
			id: 2,
			name: "Usuarios",
		},
	];
	const [tipo, setTipo] = useState(0);
	const handleClose = () => {
		setTipo(0);
		onClose();
	};
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			style={{
				display: display,
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Formik
				initialValues={{
					tipo: 0,
					ids_departamentos: "",
				}}
				onSubmit={(values, { setSubmitting }) => {
					handleSubmit(values);
				}}
				enableReinitialize={true}
			>
				{({ values, errors, touched, handleChange, handleBlur, setFieldValue,}) => (
					<Paper
						sx={{
							width: "40%",
							p: 3,
							bgcolor: colors.blueAccent[900],
							borderRadius: "10px",
						}}
					>
						<Form encType="multipart/form-data">
							<Typography
								variant="h3"
								align="center"
								paddingBottom={"2rem"}
							>
								Busqueda Avanzada
							</Typography>
							<Grid
								container
								spacing={2}
								paddingBottom={"0.1rem"}
							>
								{/* TIPO */}
								<Grid item md={12} xs={12}>
									<MySelect
                                        label="Tipo de filtro"
                                        name="tipo"
										onChange={(event) => {
											handleChange(event);
											setTipo(event.target.value);
											// buscarEntidades(event.target.value);
										}}
                                    >
                                    {
                                        opciones.map((op) => (
                                            <MenuItem value={op.id} key={op.id}>{op.name}</MenuItem>
                                        ))
                                    }
                                    </MySelect>
								</Grid>
								{/* DEPARTAMENTOS */}
								{ tipo === 1 ? 
									<Grid item md={12} xs={12}>
										<TagBox
											label="Departamentos"
											defaultValue={filters.departamentos || []}
											options={departamentos}
											placeholder="Seleccione departamentos"
											onChange={(event, value) => {
												setFieldValue("ids_departamentos",value);
											}}
										/>
									</Grid>	:
									tipo === 2 ?
									<Grid item md={12} xs={12}>
										<TagBox
											label="Usuarios"
											defaultValue={filters.usuarios || []}
											options={usuarios}
											placeholder="Seleccione usuarios"
											onChange={(event, value) => {
												setFieldValue("ids_usuarios",value);
											}}
										/>
									</Grid>
									: null
								}							
								{/* SUBMIT */}

								<Grid item md={6} xs={6}>
									<Button
										variant="contained"
										sx={{
											color: "white",
											width: "100%",
											borderRadius: "8px",
											marginRight: "10px",
										}}
										onClick={handleClose}
										startIcon={<CloseIcon />}
									>
										Cancelar
									</Button>
								</Grid>
								<Grid item md={6} xs={6}>
									<Button
										variant="contained"
										color="success"
										type="submit"
										sx={{
											color: "white",
											width: "100%",
											borderRadius: "8px",
											marginRight: "10px",
										}}
										// onClick={generarConcentrado}
										startIcon={<Done />}
									>
										Aplicar
									</Button>
								</Grid>
							</Grid>
						</Form>
					</Paper>
				)}
			</Formik>
		</Modal>
	);
};
export default ModalBusqueda;
