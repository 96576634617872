import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Modal, Paper, Typography, useMediaQuery } from "@mui/material";
import { Formik, Form } from 'formik';
import {api} from '../../../../config/axios';
import Swal from 'sweetalert2';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

// COMPONENTES
import TextInput from '../../../../components/inputs/TextInput';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../../theme';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
const ModalGrupoArticulos = ({open, data = {}, yupObject, onClose, refresh}) => {

    const theme  = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
	const [display, setDisplay] = useState('flex');
    const [formData, setFormData] = useState({});
    useEffect(() => {
        window.scrollTo(0, 0);
        if(Object.keys(data).length !== 0){
            setFormData(data);
            // buscarEntidades(data.entity_type);
        }
    },[data]);
	useEffect(() => {
		buscarArticulos();
	},[])
    // ===============================================
    // OPCIONES PARA SELECTS 
    // ===============================================
	// const [tipos_formatos, setTiposFormatos] = useState([]);
    // const buscarTiposFormatos = async () => {
	// 	const response = await api.get('/get/tipo_formatos');
	// 	if(response.data.success === true && response.data.result !== "Sin resultados"){
	// 		setTiposFormatos(response.data.result);
	// 	}else{
	// 		setTiposFormatos([]);
	// 	}
	// }

	const [articulos, setArticulos] = useState([]);
	const buscarArticulos = async () => {
		const response = await api.get("get/catalogo_articulos");

		if (response.data.success === true &&response.data.result !== "Sin resultados"){
			setArticulos(response.data.result);
		} else {
			setArticulos([]);
		}
	};
    // ===============================================
    // SUBMIT DEL FORMULARIO
    // ===============================================

    const handleSubmit = async (values) => {
		values.ids_articulos = values.ids_articulos.map((art) => art.id).join(',');
        const url  = values.id ? 'update/grupo_articulo' : 'create/grupo_articulo';
        let method = values.id ? 'put' : 'post';
        try {

            const result = await api[method](url, values, method); // 

            if(result.data.success === true){

                Swal.fire({
                    icon: 'success',
                    title: result.data.text,
                })
                onClose();
                refresh();
            }

        } catch (error) {

            console.error("Upload failed:", error);

        }

    }

    // ===============================================
    //  COMPONENTE TAGBOX
    // ===============================================

	const TagBox = ({ label, options ,...props }) => {
		return (
			<Autocomplete
				id="multiple-limit-tags"
				multiple
				options={options}
				getOptionLabel={(option) => option.articulo}
				defaultValue={data.ids_articulos || []}
				onChange={props.onChange}
				renderInput={(params) => (
				    <TextField {...params} label={label} placeholder={props.placeholder} />
				)}
			/>
		)
	}
	const handleDelete = async (id) => {
		setDisplay('none');
		const url = 'delete/grupo_articulo/' + id;
		Swal.fire({
			title: '¿Está seguro de eliminar este grupo de articulos?',
			text: "¡Esta acción no se puede revertir!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: colors.blueAccent[700],
			cancelButtonColor: colors.redAccent[700],
			confirmButtonText: 'Si, eliminar',
			cancelButtonText: 'Cancelar'
		}).then(async(result) => {
            setDisplay('flex');
			if (result.isConfirmed) {
				const response = await api.delete(url);
				if(response.data.success === true){
					Swal.fire({
						icon: 'success',
						title: response.data.text,
					})
                    onClose();
                    refresh();
				}
			}
		})
		
	};
    return(
        <Modal
            open={open}
            onClose={onClose}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: display, 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >
            <Formik
                initialValues={formData}
                validationSchema={yupObject}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values)
                }}
                enableReinitialize={true}
            >
            {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                <Paper sx={{ width: isNonMobile ? '30%' : '90%', p: 3, bgcolor: colors.blueAccent[900] }}>

                    <Form encType="multipart/form-data">
                    <Typography variant="h3" align="center" paddingBottom={'2rem'}>
                        Crear Grupo de Articulos
                    </Typography>
                    <Grid container spacing={2} paddingBottom={"0.1rem"}>
						{/* DESCRIPCION */}

                        <Grid item md={12} xs={12}>

                            <TextInput
                                label="Descripcion"
                                name="descripcion"
                                placeholder="Ingrese descripcion"
                            />
                        </Grid>

                        {/* DEPARTAMENTOS */}
                        
                        <Grid item md={12} xs={12}>
                            <TagBox
								label="Articulos"
								options={articulos}
								placeholder="Seleccione articulos"
								onChange={(event, value) => {
									setFieldValue('ids_articulos', value);
								}}
							/>
                        </Grid>
						
                        {/* SUBMIT */}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, marginLeft: 'auto' }}>
                            <Button variant='contained' color='primary' sx={{color: "white", marginRight: '10px'}} onClick={onClose} startIcon={<CloseIcon />}>
                                Cancelar
                            </Button>
							{
                                data.id 
                                ?  
                                    <Button variant='contained' color='error' sx={{color: "white", marginRight: '10px'}} onClick={()=> handleDelete(data.id)} startIcon={<DeleteIcon />}>
                                        Borrar
                                    </Button>
                                : null
                            }
                            <Button type="submit" variant="contained" sx={{color: "white"}} color="success" startIcon={<SaveIcon />}>
                                Guardar
                            </Button>
                        </Box>
                    </Grid>
                    </Form>
                </Paper>
            )}
            </Formik>  
        </Modal>
    )
}

export default ModalGrupoArticulos;
