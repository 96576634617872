import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Slide from '@mui/material/Slide';

function SlideTransition(props) {
	return <Slide {...props} direction="left" />;
}
const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function MySnackBar(props) {
	const { open, action, message, onClose,  } = props;

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		onClose();
	};

	return (
		<Stack spacing={2} sx={{ width: "100%" }}>
			<Snackbar   anchorOrigin={{vertical:'top',horizontal:'right'}} open={open} autoHideDuration={6000} onClose={handleClose} TransitionComponent={SlideTransition}>
				<Alert
					onClose={handleClose}
					severity={action}
					sx={{ width: "100%", fontweight: "bold", fontSize: "1rem", color: "white"}}
				>
					{message}
				</Alert>
			</Snackbar>
		</Stack>
	);
}
