import React, {useState, useEffect} from 'react'
import { ColorModeContext, useMode } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Topbar from "./scenes/global/Topbar";
import Dashboard from "./scenes/dashboard";
import Sidebar from "./scenes/global/Sidebar";
// import { MyProSidebarProvider } from "./scenes/global/sidebar/sidebarContext";


// COMPONENTES
import Calendar from './scenes/calendar';

// COMPONENTES EBT SUITE

import Login from './pages/login/Login';
import Catalogos from './pages/catalogos/Catalogos';
import Empresas from './pages/catalogos/empresas/Empresas';
import Operadores from './pages/catalogos/operadores/Operadores';
import Empleados from './pages/catalogos/empleados/Empleados';
import Sucursales from './pages/catalogos/sucursales/Sucursales';
import Departamentos from './pages/catalogos/departamentos/Departamentos';
import Puestos from './pages/catalogos/puestos/Puestos';
import Usuarios from './pages/catalogos/usuarios/Usuarios';
import Perfiles from './pages/catalogos/perfiles/Perfiles';
import CalificarCursos from './pages/cursos/calificarCursos/CalificarCursos';
import CrearCursos from './pages/cursos/crearCursos/CrearCursos';
import TomarCursos from './pages/cursos/tomarCursos/TomarCursos';
import Extensiones from './pages/general/extensiones/Extensiones';
import Formatos from './pages/general/formatos/Formatos';
import VistaCrearEmpleado from './pages/catalogos/empleados/general/VistaCrearEmpleado';
import VistaEditarEmpleado from './pages/catalogos/empleados/general/VistaEditarEmpleado';
import CrearOperador from './pages/catalogos/operadores/general/CrearOperador';
import VistaEditarOperador from './pages/catalogos/operadores/general/VistaEditarOperador';
import TemasCursos from './pages/cursos/temas/TemasCursos';
import VistaTemas from './pages/cursos/temas/VistaTemas';
import TipoDeCambio from './pages/contabilidad/tipodecambio/TipoDeCambio';
import Requerimientos from './pages/administracion/requerimientos/Requerimientos';
import Requisiciones from './pages/administracion/requerimientos/Requisiciones';
import VistaRequisicion from './pages/administracion/requerimientos/vistas/VistaRequisicion';
import VistaCompras from './pages/administracion/requerimientos/vistas/VistaCompras';
import VistaCarritoCompras from './pages/administracion/requerimientos/vistas/VistaCarritoCompras';
import Proveedores from './pages/catalogos/proveedores/Proveedores';
import TipoFormatos from './pages/general/formatos/TipoFormatos'
import Permisos from './pages/catalogos/permisos/Permisos';
import NominaFactura from './pages/contabilidad/nominafactura/NominaFactura';
import Viaticos from './pages/operacion/viaticos/Viaticos';
import Concentrado from './pages/contabilidad/concentrado/Concentrado';
import Articulos from './pages/catalogos/articulos/IndexArticulos';
import Tractores from './pages/catalogos/tractores/Tractores';
import Gastos from './pages/operacion/gastos/Gastos';
import Firmas from './pages/recursoshumanos/firmas/Firmas';
import Horarios from './pages/monitoreo/Horarios';
import AccesosDirectos from './pages/general/accesosDirectos/AccesosDirectos';
function App() {

  const [theme, colorMode] = useMode();

  const navigate = useNavigate();

  // ===============================================  
  // LOGIN
  // =============================================== 

  const [showLogin, setShowLogin] = useState(true);

  useEffect(() => {

    if(Cookies.get('login') === "1"){

      setShowLogin(false);

      const ruta = Cookies.get('modulo');

      navigate(`/${ruta}`);

    }

  },[])

  // ===============================================

  return (

    <ColorModeContext.Provider value={colorMode}>

      <ThemeProvider theme={theme}>

        {/* <MyProSidebarProvider> */}
          <CssBaseline>
            <div  style={{ height: "100%", width: "100%" }} className='app'>

              {showLogin ? null : <Sidebar />}

              <main className='content'>

                {showLogin ? null : <Topbar setShowLogin={setShowLogin}/>}

                <Routes>

                  {/* INICIO */}

                  <Route path='/' element={<Login setShowLogin={setShowLogin}/>}/>
                  <Route path='/dashboard' element={<Dashboard/>}/>

                  {/* GENERAL */}

                  <Route path='/extensiones' element={<Extensiones/>}/>
                  <Route path='/formatos' element={<Formatos/>}/>
                  <Route path='/accesos_directos' element={<AccesosDirectos/>}/>

                  {/* CATALOGOS */}

                  <Route path='/catalogos' element={<Catalogos/>}/>
                  <Route path='/empresas' element={<Empresas/>}/>
                  <Route path='/usuarios' element={<Usuarios/>}/>
                  
                  <Route path='/sucursales' element={<Sucursales/>}/>
                  <Route path='/departamentos' element={<Departamentos/>}/>
                  <Route path='/puestos' element={<Puestos/>}/>
                  <Route path='/perfiles' element={<Perfiles/>}/>
                  <Route path='/proveedores' element={<Proveedores/>}/>
                  <Route path='/tipoformatos' element={<TipoFormatos/>}/>
                  <Route path='/permisos' element={<Permisos/>}/>
                  <Route path='/articulos' element={<Articulos/>}/>
                  <Route path='/tractores' element={<Tractores/>}/>
                  {/* EMPLEADOS */}

                  <Route path='/empleados' element={<Empleados/>}/>
                  <Route path='/crear_empleado' element={<VistaCrearEmpleado/>}/>
                  <Route path='/datos_empleado' element={<VistaEditarEmpleado/>}/>
                  
                  {/* OPERADORES */}

                  <Route path='/operadores' element={<Operadores/>}/>
                  <Route path='/crear_operador' element={<CrearOperador/>}/>
                  <Route path='/datos_operador' element={<VistaEditarOperador/>}/>

                  {/* CURSOS */}

                  <Route path='/calificar_curso' element={<CalificarCursos/>}/>
                  <Route path='/crear_curso' element={<CrearCursos/>}/>
                  <Route path='/temas_curso' element={<TemasCursos/>}/>
                  <Route path='/temas_vista' element={<VistaTemas/>}/>

                  
                  <Route path='/tomar_curso' element={<TomarCursos/>}/>

                  {/* CONTABILIDAD */}

                  <Route path='/tipodecambio'  element={<TipoDeCambio/>}/>
                  <Route path='/comparativo' element={<NominaFactura/>}/>
                  {/* <Route path='/viaticos'      element={<AsignacionViaticos/>}/> */}
                  <Route path='/concentrado'   element={<Concentrado/>}/>
                  {/*RECURSOS HUMANOS*/}
                  <Route path='/firmas' element={<Firmas/>}/>
                  {/* ADMINISTRACION */}
                  <Route path='/requerimientos'     element={<Requerimientos />}/>
                  <Route path='/requisiciones'      element={<Requisiciones />}/>
                  <Route path='/vista_requisicion'  element={<VistaRequisicion />}/>
                  <Route path='/vista_compras'      element={<VistaCompras />}/>
                  <Route path='/vista_carrito_compras' element={<VistaCarritoCompras />}/>
                  <Route path='/calendar' element={<Calendar/>}/>
                  
                  {/* OPERACION */}
                  <Route path='/viaticos' element={<Viaticos/>}/>
                  <Route path='/gastos'   element={<Gastos/>}/>
                  {/* MONITOREO */}
                  <Route path='/horarios'   element={<Horarios/>}/>
                </Routes>

              </main>

            </div>

          </CssBaseline>
      {/* </MyProSidebarProvider> */}
      </ThemeProvider>
    </ColorModeContext.Provider>

  )
  
}

export default App