// LIBRERIAS
import React from 'react';
import { Formik, Form } from 'formik';
import { styled } from '@mui/system'
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { tokens } from '../../theme';
import Swal from 'sweetalert2';
import { Button, Modal, Paper, Typography, Box, Container, Grid  } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

// COMPONENTES
import {api} from '../../config/axios';
import TextInput from '../inputs/TextInput';
import MySelect from '../inputs/MySelect';
import MyMultipleSelect from '../inputs/MyMultipleSelect ';
import MyPhoneInput from '../inputs/MyPhoneInput';

// ===============================================
// INICIO DEL COMPONENTE
// ===============================================

const ModalEditGM = ({

    // PROPS PARA USAR EN EL COMPONENTE

    modalTitle, // TITULO DEL MODAL
    swalTitle, // TITULO QUE MOSTRARA EL SWAL
    url, // URL PARA EDITAR CON AXIOS 
    urlDelete, // URL PARA ELIMINAR REGISTRO
    open, // FUNCION PARA ABRIR MODAL
    onClose, // FUNCION PARA CERRAR MODAL
    openModal, // FUNCION PARA ABRIR EL MODAL
    size, // TAMANIO DEL MODAL EN PORCENTAJE %
    initialValues, // OBJETO DE CAMPOS PARA EL FORMULARIO
    inputForms, // OBJETO CON INPUTS PARA ARMAR FORMULARIO
    yupValidation, // ESQUEMA DE VALIDACION DE YUP
    refresh // REFRESCAR TABLA DESPUES DEL SUBMIT

}) => {

    // COMPONENTE QUE FUNCIONARA COMO BODY DEL MODAL

    const ScrollableContainer = styled(Container)({
        maxHeight: '60vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',
    });

    // VALIDAR SI SE ESTA VISUALIZANDO DESDE UN DISPOSITIVO MOVIL

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // COLORES PARA LOS ESTILOS

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // FUNCION PARA ELIMINAR REGISTRO

    const handleDelete = (id) => {

        onClose();

        Swal.fire({
            title: 'Estas seguro de borrar este registro?',
            text: "Esta accion no podra ser revertida!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, borrar!'
          }).then((result) => {
            if (result.isConfirmed) {
      
                api.delete(urlDelete+id)
                .then((response) => {
        
                    if(response.data.success === true){
        
                        refresh();
        
                        Swal.fire({
                            icon: 'success',
                            title: 'El registro ha sido eliminado!',
                        })
        
                    }
        
                })
                .catch((error) => {
                    console.log("ocurrio un error: " +error);
                })
      
            }else{

                openModal();

            }
            
        })

    }

    // FUNCION PARA EL SUBMIT

    const handleSubmit = (values) => {

        api.put(url, values)
        .then((response) => {

            if(response.data.success === true){

                onClose();

                refresh();

                Swal.fire({
                    icon: 'success',
                    title: swalTitle,
                })

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    }

    return (

        <Modal
            open={open}
            onClose={onClose}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >

            <Formik
                initialValues={initialValues}
                validationSchema={yupValidation}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values)
                }}
            >

                <Paper sx={{ width: isNonMobile ? `${size}%` : '90%', p: 3, bgcolor: colors.blueAccent[900] }}>

                    <Form>

                        {/* HEADER DEL MODAL */}

                        <Typography variant="h3" align="center">
                            {modalTitle}
                        </Typography>

                        {/* BODY DEL MODAL */}

                        <ScrollableContainer>

                            {/* Inputs go here */}

                            <Grid container spacing={2} marginTop={"10px"} marginBottom={"10px"}>

                                {
                                    inputForms.map((op) => (

                                        <Grid item md={op.md} xs={op.xs} key="gridEdit">

                                            {/* VALIDAR SI ES UN INPUT */}

                                            {
                                                op.componente === 'input' && (

                                                    <TextInput
                                                        label={op.label}
                                                        name={op.name}
                                                        key={op.name}
                                                        type={op.type}
                                                        placeholder={op.placeholder}
                                                    />

                                                )
                                            }

                                            {/* VALIDA SI ES UN SELECT */}

                                            {
                                                op.componente === 'phone' && (

                                                    <MyPhoneInput
                                                        label={op.label}
                                                        name={op.name}
                                                        key={op.name}
                                                    />

                                                )
                                            }

                                            {/* VALIDA SI ES UN SELECT */}

                                            {
                                                op.componente === 'select' && (

                                                    <MySelect label={op.label} name={op.name}>

                                                        {
                                                            op.options.map((op2) => (

                                                                <MenuItem value={op2[op.value]} key={op2[op.value]}>{op2[op.text]}</MenuItem>

                                                            ))
                                                        }

                                                    </MySelect>

                                                )
                                            }

                                            {/* VALIDAR SI ES UN SELECT MULTIPLE */}

                                            {
                                                op.componente === 'selectMultiple' && (

                                                    <MyMultipleSelect
                                                        name={op.name}
                                                        key={op.name}
                                                        label={op.label}
                                                        options={op.options}
                                                    />

                                                )
                                            }

                                        </Grid>

                                    ))
                                }

                            </Grid>

                        </ScrollableContainer>

                        {/* FOOTER DEL MODAL */}

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>

                            <Button variant="contained" color="primary" onClick={onClose}>
                                Cancelar
                            </Button>

                            <Button variant="contained" onClick={() => handleDelete(initialValues.id)} color="error"><DeleteForeverIcon/></Button>

                            <Button type="submit" variant="contained" sx={{color: "white"}} color="success" startIcon={<SaveIcon />}>
                                Guardar
                            </Button>

                        </Box>

                    </Form>

                </Paper>

            </Formik>

        </Modal>

    )

}

export default ModalEditGM