import React, {useEffect, useState} from 'react';
import { Formik, Form, Field } from 'formik';
import { api } from '../../../../config/axios';
import TextField from '@mui/material/TextField';
import {Button, Box, Grid} from '@mui/material';
import Header from '../../../../components/Header';
import SaveIcon from '@mui/icons-material/Save';
import Swal from 'sweetalert2';

const EmpleadoHorarios = ({data}) => {

    // ===============================================
    // DATOS DEL HORARIO
    // ===============================================

    const [horarios, setHorarios] = useState({

        id: '',
        horario: {
            lunes: { entrada: '', salida: '' },
            martes: { entrada: '', salida: '' },
            miercoles: { entrada: '', salida: '' },
            jueves: { entrada: '', salida: '' },
            viernes: { entrada: '', salida: '' },
            sabado: { entrada: '', salida: '' },
            domingo: { entrada: '', salida: '' },
        }

    })

    // ===============================================
    // FUNCION PARA BUSCAR EL HORARIO DEL EMPLEADO
    // ===============================================

    const buscarHorario = async () => {

        const idEmpleado = data.id;

        const response = await api.get(`get/existe/empleado/horario/${idEmpleado}`);

        if(response.data.success === true && response.data.result !== "Sin resultados"){

            const dataHorario = response.data.result[0]["horario"];

            if(dataHorario === "Sin horario"){

                return;

            }else{

                setHorarios({...horarios, ["horario"]: JSON.parse(dataHorario)});

            }

        }

    }

    useEffect(() => {

        window.scrollTo(0, 0);

        if(Object.keys(data).length !== 0 ){

            setHorarios({...horarios, ["id"]: data.id});

            buscarHorario();

        }

    },[data])

    

    const onSubmit = (values) => {

        const url = 'update/empleado/horario';

        let datah = values;

        let horario = JSON.stringify(values.horario);

        datah.horario = horario;

        api.put(url, datah)
        .then((response) => {

            if(response.data.success === true){

                buscarHorario();

                Swal.fire({
                    icon: 'success',
                    title: 'Se ha actualizado el horario!',
                })

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    };

    return (

        <Box>

            <Header title="Horarios"/>

            <Formik 
                initialValues={horarios} 
                enableReinitialize={true} 
                onSubmit={onSubmit}>
            {({ handleSubmit }) => (

                <Form onSubmit={handleSubmit}>

                    <Grid container spacing={2} paddingRight={2}>

                        {Object.keys(horarios.horario).map((dia) => (

                            <Grid item md={6} key={dia}>

                            <h3>{dia.toLocaleUpperCase()}</h3>

                            <Field
                                name={`horario.${dia}.entrada`}
                                sx={{width: "50%"}}
                                type="time"
                                as={TextField}
                                label="Entrada"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ step: 300 }} // Incremento de 5 minutos
                            />

                            <Field
                                name={`horario.${dia}.salida`}
                                sx={{width: "50%"}}
                                type="time"
                                as={TextField}
                                label="Salida"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ step: 300 }} // Incremento de 5 minutos
                            />  

                            </Grid>

                        ))}

                        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                            <Button 
                                type="submit" 
                                variant="contained" 
                                color="success" 
                                size="large"
                                sx={{color: "white"}}
                                startIcon={<SaveIcon />}
                            >
                                <b>Guardar</b>
                            </Button>

                        </Grid>

                    </Grid>

                </Form>

            )}

            </Formik>

        </Box>

    )

}

export default EmpleadoHorarios