import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, Grid, useTheme  } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from '../../../theme';
import { api } from '../../../config/axios';
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

//COMPONENTES
import Header from '../../../components/Header';
import ModalCreateGM from '../../../components/modals/ModalCreateGM';

const TemasCursos = () => {

    // ===============================================
    // OBTENER INFORMACION DEL CURSO
    // ===============================================

    const location = useLocation();
    const { info } = location.state;

    const [cursos, setCursos] = useState([]);

    const [idCurso, setIdCurso] = useState(0);

    useEffect(() => {

        // window.scrollTo(0, 0);

        const temp = JSON.parse(info);

        buscarTemas();

        setIdCurso(temp.id);

        setCursos(temp);

    },[info]);

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();

    // ===============================================
    // SEGMENTO PARA BUSCAR CURSOS
    // ===============================================

    const [data, setData] = useState([]);

    const buscarTemas = async () => {

        const temp = JSON.parse(info);

        const response = await api.get(`get/cursos/temas/${temp.id}`);

        if(response.data.success === true && response.data.result !== "Sin resultados"){

            setData(response.data.result);

        }else{

            setData([]);

        }

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        {
            field: "tema",
            headerName: "Tema",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "intentos",
            headerName: "Intentos",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "estado",
            headerName: "Estado",
            flex: 2,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => 
                params.value === 1 
                ?
                <Button variant="contained" color="success" sx={{color: "white"}}><b>Activo</b></Button>
                :
                <Button variant="contained" color="error"><b>Inactivo</b></Button>
        },

    ];

        // ===============================================
    // VALORES PARA SUBMIT DEL FORMULARIO
    // ===============================================

    const [initialFormValues, setInicialFormValues] = useState({

        rel_curso: idCurso,
        tema: '',
        intentos: '',
        tiempoExamen: '',

    })

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL CREARA
    // ===============================================

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {

        setInicialFormValues({

            rel_curso: idCurso,
            tema: '',
            intentos: '',
            tiempoExamen: '',
    
        })

        setIsOpen(true);
    };
    
    const handleCloseModal = () => {
        setIsOpen(false);
    };

    // ===============================================
    // INPUTS PARA ARMAR EL FORMULARIO
    // ===============================================

    const inputForms = [

        {
            componente: "input",
            label: "Tema",
            name: "tema",
            placeholder: "Ingrese Nombre del Tema",
            md: 12,
            xs: 12
        },
        {
            componente: "number",
            label: "Intentos",
            name: "intentos",
            placeholder: "Ingrese intentos por Tema",
            md: 12,
            xs: 12
        },
        {
            componente: "number",
            label: "Tiempo Examen (minutos)",
            name: "tiempoExamen",
            placeholder: "Ingrese tiempo del examen",
            md: 12,
            xs: 12
        },

    ];

    // ===============================================
    // VALIDACIONES PARA EL FORMULARIO
    // ===============================================

    const yupValidation = Yup.object().shape({

        tema: Yup.string().required('Requerido'),
        intentos: Yup.number().required('Requerido'),
        tiempoExamen: Yup.number().required('Requerido')

    })

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const handleRowClick = (params) => {

        let infoTemas = JSON.stringify(params.row);

        navigate('/temas_vista', { state: { info: infoTemas } });

    }

    return (

        <Box m="20px">

            <Header title="Temas" subtitle={`Temas para el curso de ${cursos.nombreCurso}`} />

            <Button variant="contained" 
                sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.blueAccent[700]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={handleOpenModal}
            >
                Agregar Tema
            </Button>

            {/* TABLA  */}

            <Box
                m="40px 0 0 0"
                height="75vh"
                width="100%"
                sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`
                },
                // "& .MuiDataGrid-footerContainer": {
                //     display: 'none'
                // }
                }}
            >
                <DataGrid 
                    showFooter={false}
                    rows={data} 
                    columns={columns} 
                    components={{ Toolbar: GridToolbar}}
                    onRowClick={handleRowClick}
                />
            </Box>

            {/* MODAL PARA CREAR TEMA */}

            <ModalCreateGM
                modalTitle="Crear Tema"
                swalTitle="Se ha guardado el tema!"
                url="create/curso/tema"
                open={isOpen} 
                onClose={handleCloseModal} 
                size='40'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarTemas}
            />

        </Box>

    )

}

export default TemasCursos