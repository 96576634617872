import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../theme";
import {api} from '../../../config/axios';
import * as Yup from 'yup';
import { marcarURL } from '../../../config/functions';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

//COMPONENTES
import Header from '../../../components/Header';
import ModalCreateGM from '../../../components/modals/ModalCreateGM';
import ModalEditGM from '../../../components/modals/ModalEditGM';

const Puestos = () => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // SEGMENTO PARA BUSCAR SUCURSALES
    // ===============================================

    const [data, setData] = useState([]);

    useEffect(() => {

        buscarPuestos();

        marcarURL('puestos');

    },[])

    const buscarPuestos = async () => {

        const response = await api.get('get/puestos');

        if(response.data.success === true && response.data.result !== "Sin resultados"){

            setData(response.data.result);

        }else{

            setData([]);

        }

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        {
            field: "nombre_puesto",
            headerName: "Puesto",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "departamento",
            headerName: "Departamento",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },

    ];

    // ===============================================
    // VALORES PARA SUBMIT DEL FORMULARIO
    // ===============================================

    const [initialFormValues, setInicialFormValues] = useState({

        nombre_puesto: '',
        rel_depa: ''

    })

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL CREARA
    // ===============================================

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {

        setInicialFormValues({

            nombre_sucursal: '',
            rel_empresa: ''
    
        })

        setIsOpen(true);
    };
    
    const handleCloseModal = () => {
        setIsOpen(false);
    };

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL EDITAR
    // ===============================================

    const [isOpenEdit, setIsOpenEdit] = useState(false);

    const handleOpenModalEdit = () => {
        setIsOpenEdit(true);
    };
    
    const handleCloseModalEdit = () => {
        setIsOpenEdit(false);
    };

    // ===============================================
    // SEGMENTO PARA TRAER DEPARTAMENTOS
    // ===============================================

    const [departamentos, setDepartamentos] = useState([]);

    useEffect(() => {
        
        api.get('get/departamentos')
        .then(result => {
    
            if(result.data.success == true)
            {
                setDepartamentos(result.data.result);
            }
    
        })
        .catch(error => {
        
            console.log(error)
        
        })
    
    },[]);

    // ===============================================
    // INPUTS PARA ARMAR EL FORMULARIO
    // ===============================================

    const inputForms = [

        {
            componente: "input",
            label: "Puesto",
            name: "nombre_puesto",
            placeholder: "Ingrese Nombre del Puesto",
            md: 12,
            xs: 12
        },
        {
            componente: "select",
            label: "Departamento",
            name: "rel_depa",
            options: departamentos,
            value: "id",
            text: "nombre_depa",
            md: 12,
            xs: 12
        }

    ];

    // ===============================================
    // VALIDACIONES PARA EL FORMULARIO
    // ===============================================

    const yupValidation = Yup.object().shape({

        nombre_puesto: Yup.string().required('Requerido'),
        rel_depa: Yup.string().required('Requerido')

    })

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const handleRowClick = (params) => {

        setInicialFormValues(params.row);
        handleOpenModalEdit();

    }

    // ===============================================
    // ===============================================

    return (

        <Box m="20px">
            
            <Header title="Puestos" subtitle="Puestos EBTSuite" />

            {/* BOTON PARA ABRIL MODAL */}

            <Button variant="contained" 
                sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.blueAccent[700]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={handleOpenModal}
            >
                Agregar Puesto
            </Button>

            {/* TABLA  */}

            <Box
                m="40px 0 0 0"
                height="75vh"
                width="100%"
                sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`
                }
                }}
            >
                <DataGrid 
                    
                    rows={data} 
                    columns={columns} 
                    components={{ Toolbar: GridToolbar}}
                    onRowClick={handleRowClick}
                />
            </Box>

            {/* MODAL PARA CREAR SUCURSAL */}

            <ModalCreateGM
                modalTitle="Crear Puesto"
                swalTitle="Se ha guardado el puesto!"
                url="create/puesto"
                open={isOpen} 
                onClose={handleCloseModal} 
                size='40'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarPuestos}
            />

            {/* MODAL PARA EDITAR SUCURSAL */}

            <ModalEditGM
                modalTitle="Editar Puesto"
                swalTitle="Se ha editado el puesto!"
                url="update/puesto"
                urlDelete="delete/puesto/"
                open={isOpenEdit} 
                onClose={handleCloseModalEdit} 
                openModal={handleOpenModalEdit}
                size='40'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarPuestos}
            />
            
        </Box>

    )

}

export default Puestos