import React from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { Tooltip, Button, IconButton } from '@mui/material';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

const ExportCSV = ({ csvData, fileName, fields }) => {
	const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";

	const exportToCSV = (csvData, fileName) => {
		
		const headers  = fields.map((field) => field.label);
		const data 	   = csvData.map((item) => fields.map((field) => item[field.id]));
		const newCsvData = [headers, ...data];
		const ws = XLSX.utils.aoa_to_sheet(newCsvData);
		const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(wb, {
			bookType: "xlsx",
			type: "array",
			cellStyles: true,
			sheetFormat: {
				defaultColWidth: 15,
				defaultRowHeight: 20,
			},
			properties: {
				title: "My Excel File",
				author: "Ed Roh",
				subject: "Data Export",
				keywords: "data,export,excel",
			},
		});
		const dataBlob = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(dataBlob, fileName + fileExtension);
	};

	return (
		<Tooltip title="Exportar a Excel">
			<IconButton
				aria-label="expand row"
				size="small"
				onClick={(e) => exportToCSV(csvData, fileName)}
			>
				<SystemUpdateAltIcon />
			</IconButton>
			{/* <Button
				variant="contained"
				color="primary"
				startIcon={<SystemUpdateAltIcon />}
				onClick={(e) => exportToCSV(csvData, fileName)}
			> Exportar </Button> */}
		</Tooltip>
	);
};

export default ExportCSV;
