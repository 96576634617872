import React from 'react';
import { useField } from 'formik';
import { TextareaAutosize } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from '../../theme';

const TextAreaInput = ({ label, ...props }) => {

    // COLORES PARA LOS ESTILOS

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    

    const [field, meta] = useField(props);

    return (
        <>
            <TextareaAutosize
                style={{ width: "100%", backgroundColor: colors.blueAccent[900], color: 'white', borderRadius: '5px', padding: '10px' }}
                minRows={3} // Puedes ajustar la cantidad de filas mínimas
                placeholder={label}
                {...field}
                {...props}
                error={meta.touched && Boolean(meta.error)}
            />
            {meta.touched && meta.error && <div style={{color: 'red', fontSize: '0.6428571428571428rem'}}>{meta.error}</div>}
        </>
    )
}

export default TextAreaInput;
