import React, { useEffect, useState } from "react";
import GridView from "../../../components/Gridview";
import { Box, Button, Grid } from "@mui/material";
import Header from "../../../components/Header";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import Canvas from "./components/Canvas";
import { Epg, Layout } from "planby";

const data = [];
const columns = [
	{ field: "id", headerName: "#", flex: 1 },
	{ field: "nombre", headerName: "Nombre", flex: 1 },
	{ field: "usuario", headerName: "Usuario", flex: 1 },
	{ field: "puesto", headerName: "Puesto", flex: 1 },
	{ field: "tel_usa", headerName: "Telefono USA", flex: 1 },
	{ field: "tel_mex", headerName: "Telefono MEX", flex: 1 },
	{ field: "tel_celular", headerName: "Telefono Cel", flex: 1 },
	{ field: "calle", headerName: "Calle", flex: 1 },
	{ field: "colonia", headerName: "Colonia", flex: 1 },
	{ field: "sucursal", headerName: "Sucursal", flex: 1 },
	{ field: "asociacion", headerName: "Asociacion", flex: 1 },
];

const Firmas = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const [filterButtonState, setFilterButtonState] = useState(0);

	// const { isLoading, getEpgProps, getLayoutProps } = useApp();

    // useEffect(() => {
    //   setIsLoading(true);

    //   // fetching data
      
    //   setIsLoading(false);
    // }, []);
	return (
		<Box m="20px">
			<Header title="Firmas" subtitle="Firmas EBT Suite" />
			<Grid container spacing={1}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
				{/* <div style={{ height: "80vh", width: "100%" }}>
					<Epg isLoading={isLoading} >
					<Layout
						// {...getLayoutProps()}
						renderTimeline={(props) => <Timeline {...props} />}
						renderProgram={({ program, ...rest }) => (
						<ProgramItem key={program.data.id} program={program} {...rest} />
						)}
						renderChannel={({ channel }) => (
						<ChannelItem key={channel.uuid} channel={channel} />
						)}
					/>
					</Epg>
				</div> */}
				</Grid>
			</Grid>
		</Box>
	);
};
export default Firmas;


