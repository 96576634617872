import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../theme";
import {api} from '../../../config/axios';
import * as Yup from 'yup';
import { marcarURL } from '../../../config/functions';
import { modulos } from '../../../data/modulos';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

//COMPONENTES
import Header from '../../../components/Header';
import ModalCreateGM from '../../../components/modals/ModalCreateGM';
import ModalEditGM from '../../../components/modals/ModalEditGM';

const Perfiles = () => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // SEGMENTO PARA BUSCAR SUCURSALES
    // ===============================================

    const [data, setData] = useState([]);

    useEffect(() => {

        buscarProveedores();

        marcarURL('proveedores');

    },[])

	const buscarProveedores = async () => {
        const response = await api.get('get/proveedores');
        if(response.data.success === true && response.data.result !== "Sin resultados"){
            setData(response.data.result);
        }else{
            setData([]);
        }
    };

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================
	const getRowId = (row) => row.value; // Define the getRowId function

    const columns = [
        { 
            field: 'id', 
            headerName: '#', 
            flex: 2,
            headerAlign: "center",
            align: "center", 
		},
        { 
            field: 'proveedor', 
            headerName: 'Proveedor', 
            flex: 2,
            headerAlign: "center",
            align: "center", },
        {
            field: 'descripcion',
            headerName: 'Descripcion',
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
    ];

    // ===============================================
    // VALORES PARA SUBMIT DEL FORMULARIO
    // ===============================================

    const [initialFormValues, setInicialFormValues] = useState({

        proveedor: '',
        descripcion: ''
    })

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL CREARA
    // ===============================================

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {

        setInicialFormValues({

            proveedor: '',
        	descripcion: ''
    
        })

        setIsOpen(true);
    };
    
    const handleCloseModal = () => {
        setIsOpen(false);
    };

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL EDITAR
    // ===============================================

    const [isOpenEdit, setIsOpenEdit] = useState(false);

    const handleOpenModalEdit = () => {
        setIsOpenEdit(true);
    };
    
    const handleCloseModalEdit = () => {
        setIsOpenEdit(false);
    };


    const inputForms = [

        {
            componente: "input",
            label: "Proveedor",
            name: "proveedor",
            placeholder: "Ingrese el nombre del proveedor",
            type: "text",
            md: 12,
            xs: 12
        },
        {
            componente: "input",
            label: "Descripcion",
            name: "descripcion",
			placeholder: "Ingrese el nombre del proveedor",
            type: "text",
			md: 12,
            xs: 12
        },
    ];

    // ===============================================
    // VALIDACIONES PARA EL FORMULARIO
    // ===============================================

    const yupValidation = Yup.object().shape({

        proveedor: Yup.string().required('Requerido'),
        descripcion: Yup.string().required('Requerido')

    })

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const handleRowClick = (params) => {

        var newData = {...params.row};

        setInicialFormValues(newData);
        handleOpenModalEdit();

    }

    // ===============================================
    // ===============================================

    return (

        <Box m="20px">

            <Header title="Proveedores" subtitle="Proveedores EBTSuite" />

            {/* BOTON PARA ABRIL MODAL */}

            <Button variant="contained" 
                sx={{
					fontWeight:"bold",
					backgroundColor:colors.blueAccent[700]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={handleOpenModal}
            >
                Agregar Proveedor
            </Button>

            {/* TABLA  */}

            <Box
                m="40px 0 0 0"
                height="75vh"
                width="100%"
                sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`
                }
                }}
            >
                <DataGrid 
                    
                    rows={data} 
                    columns={columns} 
                    components={{ Toolbar: GridToolbar}}
                    onRowClick={handleRowClick}

                     
                />
            </Box>

            {/* MODAL PARA CREAR SUCURSAL */}

            <ModalCreateGM
                modalTitle="Crear Proveedor"
                swalTitle="Se ha guardado el proveedor!"
                url="create/proveedor"
                open={isOpen} 
                onClose={handleCloseModal} 
                size='40'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarProveedores}
            />

            {/* MODAL PARA EDITAR SUCURSAL */}

            {/* <ModalEditarPerfil
                data={initialFormValues}
                open={isOpenEdit} 
                onClose={handleCloseModalEdit} 
                refresh={buscarPerfiles}
                departamentos={departamentos}
            /> */}

            <ModalEditGM
                modalTitle="Editar Proveedor"
                swalTitle="Se ha editado el proveedor!"
                url="update/proveedor"
                urlDelete="delete/proveedor/"
                open={isOpenEdit} 
                onClose={handleCloseModalEdit} 
                openModal={handleOpenModalEdit}
                size='40'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarProveedores}
            />

        </Box>
        
    )

}

export default Perfiles