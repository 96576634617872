import React, { useState } from "react";
import {
	TextField,
	Button,
	Typography,
	Box,
	Card,
	Grid,
	List,
	ListItem,
	ListItemText,
	Divider,
} from "@mui/material";
import { useEffect } from "react";
import Header from "../../../components/Header";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import RefreshIcon from "@mui/icons-material/Refresh";

import IconButton from "@mui/material/IconButton";
import { api } from "../../../config/axios";
import Cookies from "js-cookie";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RepeatIcon from "@mui/icons-material/Repeat";

import ModalGastos from "./modals/ModalGastos";
import * as Yup from "yup";

const Gastos = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [isPressed, setIsPressed] = useState(false);

	const [gastos, setGastos] = useState([]);
	const [data, setData] = useState({});
	const [showBalance, setShowBalance] = useState(false);
	const totalGastos = gastos.reduce(
		(total, item) => total + parseFloat(item.monto),
		0
	);
	const totalGastosAprobados = gastos.reduce(
		(total, item) => total + parseFloat(item.monto_aprobado),
		0
	);
	const totalAdeudo = gastos.reduce(
		(total, item) => total + parseFloat(item.adeudo),
		0
	);
	const balance = parseFloat(data.monto) - parseFloat(totalGastos);
	// FORMATEO DE MONEDA
	const formatCurrency = (value) => {
		const formatter = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
		return formatter.format(value);
	};
	useEffect(() => {
		buscarViaticos();
	}, []);
	useEffect(() => {
		if (!data.rel_orden) return;
		buscarGastosOperador(data.rel_orden);
	}, [data.rel_orden]);

	const buscarViaticos = async () => {
		const data = JSON.parse(Cookies.get("usuarioData"));
		const driver_code = data.driver_code;
		const response = await api.get("/get/orden/viaticos/driver_code", {
			params: { driver_code: driver_code },
		});
		if (
			response &&
			response.data.success === true &&
			response.data.result !== "Sin resultados"
		) {
			setData(response.data.result);
		} else {
			setData([]);
		}
	};
	const buscarGastosOperador = async (rel_orden) => {
		const response = await api.get("get/orden/gastos", {
			params: { rel_orden: rel_orden },
		});
		if (
			response &&
			response.data.success === true &&
			response.data.result !== "Sin resultados"
		) {
			setGastos(response.data.result);
		} else {
			setGastos([]);
		}
	};

	const style = {
		borderRadius: "10px",
		height: "345px",
		width: "auto",
		bgcolor: colors.blueAccent[800],
		overflow: "auto",
	};
	// cards
	const Item = ({ header, bgColor, monto }) => (
		<Card
			style={{
				backgroundColor: bgColor,
				padding: 16,
				margin: "0 0 8px 0",
				borderRadius: "10px",
				height: "auto",
				width: "auto",
			}}
		>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				style={{ height: "100%" }}
			>
				<Typography variant="h4" gutterBottom sx={{ mb: 1 }}>
					<strong>{header}</strong>
				</Typography>
				<Grid>
					<Typography variant="h2">
						{formatCurrency(monto)}
					</Typography>
				</Grid>
			</Box>
		</Card>
	);

	// MODAL
	const [open, setOpen] = useState(false);

	const [initialFormValues, setInicialFormValues] = useState({
		rel_orden: data.rel_orden,
		tipo_gasto: "",
		monto: "",
		comentario: "",
	});
	const isEditMode = initialFormValues.id ? true : false;
	const getValidationSchema = (isEditMode) => {
		const wo_ruta_validation = isEditMode
			? Yup.object({
					tipo_gasto: Yup.string().required(
						"Este campo es requerido"
					),
					monto: Yup.string().required("Este campo es requerido"),
					comentario: Yup.string().required(
						"Este campo es requerido"
					),
			  })
			: Yup.object({
					tipo_gasto: Yup.string().required(
						"Este campo es requerido"
					),
					monto: Yup.string().required("Este campo es requerido"),
					comentario: Yup.string().required(
						"Este campo es requerido"
					),
					ruta: Yup.mixed().test(
						"required",
						"Este campo es requerido",
						(file) => {
							if (file) return true;
							return false;
						}
					),
			  });
		return wo_ruta_validation;
	};
	const yupValidation = getValidationSchema(isEditMode);
	const handleOpenModal = () => {
		setInicialFormValues({
			rel_orden: data.rel_orden,
			tipo_gasto: "",
			monto: "",
			comentario: "",
		}); // reset form
		setOpen(true);
	};
	const handleClickItem = (item) => {
		setInicialFormValues({ ...item });
		setOpen(true);
	};
	const [vistaActual, setVistaActual] = useState(0);
	const toogleVista = () => {
		setVistaActual(vistaActual === 0 ? 1 : 0);
	};
	useEffect(() => {
		if (vistaActual === 1) {
			buscarMovimientos();
		}
	}, [vistaActual]);
	const buscarMovimientos = async () => {
		if (!data.rel_orden) return;
		const response = await api.get("get/movimientos/operador", {
			params: { rel_orden: data.rel_orden },
		});
		if (response && response.data.success === true && response.data.result !== "Sin resultados") {
			setMovimientos(response.data.result.movimientos);
			setGastosAprobados(response.data.result.gastos);
		} else {
			setGastosAprobados([]);
			setMovimientos([]);
		}
	};
	const VistaGastos = () => {
		return (
			<Box
				sx={{
					display: "flex",
				}}
			>
				{/*  */}

				<Grid container spacing={1} paddingBottom={"0.1rem"}>
					{/* MONTO INICIAL */}
					<Grid
						item
						md={12}
						xs={12}
						onClick={() => setShowBalance(!showBalance)}
						style={{
							transition: "transform 0.3s ease-in-out",
						}}
					>
						<Item
							header={showBalance ? "Balance" : "Monto inicial"}
							monto={showBalance ? `${balance}` : `${data.monto}`}
							bgColor={colors.greenAccent[600]}
						/>
					</Grid>
					{/*  */}

					<Grid item md={6} xs={6}>
						<Item
							header={"Gastos"}
							monto={totalGastos}
							bgColor={colors.redAccent[600]}
						/>
					</Grid>

					<Grid item md={6} xs={6}>
						<Item
							header={"Adeudo"}
							monto={totalAdeudo}
							bgColor={"#FFA500"}
						/>
					</Grid>

					{/* <Grid item md={4} xs={4}>
					<Item
						header={"Adeudo"}
						label={"Test"}
						bgColor={"#FFA500"}
					/>
				</Grid> */}
					{/*  */}
					<Grid item md={12} xs={12}>
						<Button
							variant="contained"
							sx={{
								fontWeight: "bold",
								backgroundColor: colors.blueAccent[600],
								width: "100%",
								borderRadius: "8px",
								fontSize: "0.9rem",
							}}
							startIcon={<AddCircleIcon />}
							onClick={handleOpenModal}
						>
							REGISTRAR GASTO
						</Button>
					</Grid>
					<Grid item md={12} xs={12}>
						<List sx={style}>
							{gastos && gastos.length > 0 ? (
								gastos.map((item) => (
									<>
									
									<ListItem
										key={item.id}
										onClick={() => handleClickItem(item)}
										sx={{
											marginBottom: "8px",
											borderRadius: "5px",
										}}
									>
										<ListItemText
											primary={item.tipo_gasto}
											secondary={`Cantidad: ${formatCurrency(
												item.monto
											)}`}
											sx={{ fontSize: "2rem" }}
										/>
									</ListItem>
									<Divider sx={{ bgcolor: colors.blueAccent[300] }} />
									</>
							))) : (
								<Box
									display="flex"
									justifyContent="center"
									alignItems="center"
									style={{ height: "100%" }}
								>
									<Typography
										variant="h5"
										color="textSecondary"
									>
										No hay gastos registrados en esta orden:{" "}
										<strong>{data.rel_orden}</strong>
									</Typography>
								</Box>
							)}
						</List>
					</Grid>
				</Grid>
			</Box>
		);
	};
	const [movimientos, setMovimientos] = useState([]);
	const [gastosAprobados, setGastosAprobados] = useState([]);
	const VistaMovimientos = () => {
		return (
			<Box
				sx={{
					display: "flex",
				}}
			>
				{/*  */}

				<Grid container paddingBottom={"0.1rem"}>
					<Grid item md={12} xs={12}>
						<Typography variant="h4" gutterBottom sx={{ mb: 1 }}>
							<strong>Movimientos</strong>
						</Typography>
					</Grid>
					<List sx={{...style,height:'280px',width:'100%'}}>						
						{movimientos && movimientos.length > 0 ? (
							movimientos.map((item) => (
								<>
									<ListItem
									key={item.id}
									// onClick={() => handleClickItem(item)}
									sx={{
										marginBottom: "8px",
										borderRadius: "5px",
									}}
									>
									<ListItemText
										primary={item.concepto.toUpperCase()}
										secondary={`Cantidad: ${formatCurrency(item.monto)}`}
										sx={{ fontSize: "2rem" }}
									/>
									</ListItem>
									<Divider sx={{ bgcolor: colors.blueAccent[300] }} />
								</>
								))
							) : (
							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
								style={{ height: "100%" }}
							>
								<Typography variant="h5" color="textSecondary">
									No hay movimientos aun
								</Typography>
							</Box>
						)}
					</List>
					<Grid item md={12} xs={12}>
						<Typography variant="h4" gutterBottom sx={{ mb: 1 }}>
							<strong>Gastos aprobados</strong>
						</Typography>
					</Grid>
					<List sx={{...style,height:'280px',width:'100%'}}>						
						{gastosAprobados && gastosAprobados.length > 0 ? (
							gastosAprobados.map((item) => (
								<>
									<ListItem
										key={item.id}
										// onClick={() => handleClickItem(item)}
										sx={{
											marginBottom: "8px",
											borderRadius: "5px",
										}}
									>
										<Grid container justifyContent="space-between">
											<Grid item md={12} xs={12}>
												<Typography variant="h5">
													{item.tipo_gasto.toUpperCase()}
												</Typography>
											</Grid>
											<Grid item md={12} xs={12}>
												<Typography color={colors.grey[200]} variant="h6">
													Monto aprobado: {formatCurrency(item.monto_aprobado)}
												</Typography>
											</Grid>
											<Grid item md={12} xs={12}>
												<Typography color={colors.grey[200]} variant="h6">
													Comentario: {item.comentario}
												</Typography>
											</Grid>
											<Grid item md={12} xs={12}>
												<Typography color={colors.grey[200]} variant="h6">
													Adeudo: {formatCurrency(item.adeudo)}
												</Typography>
											</Grid>
										</Grid>
										{/* <ListItemText
											primary={item.tipo_gasto.toUpperCase() + ' - ' + item.comentario.toUpperCase()}
											secondary={`Monto aprobado: ${formatCurrency(item.monto_aprobado)}`}
											sx={{ fontSize: "2rem" }}
										/> */}
									</ListItem>
									<Divider sx={{ bgcolor: colors.blueAccent[300] }} />
								</>
							))
						) : (
							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
								style={{ height: "100%" }}
							>
								<Typography variant="h5" color="textSecondary">
									No hay gastos aprobados aun
								</Typography>
							</Box>
						)}
					</List>

				</Grid>
			</Box>
		);
	};
	return (
		<Box m="20px">
			<Grid container justifyContent="space-between">
				<Grid item md={6} sx={6}>
					<Header
						title="Gastos"
						subtitle={`Orden: ${data.rel_orden}`}
					/>
				</Grid>
				<Grid item md={6} sx={6}>
					<Box display="flex" alignItems="right">
						<IconButton
							aria-label="expand row"
							size="large"
							onClick={buscarViaticos}
						>
							<RefreshIcon />
						</IconButton>
					</Box>
				</Grid>
				<Grid item md={12} xs={12}>
					<Box display="flex" justifyContent="flex-start">
						<IconButton
							aria-label="expand row"
							size="small"
							onClick={toogleVista}
						>
							<RepeatIcon />
						</IconButton>
					</Box>
				</Grid>
			</Grid>
			{/* CONTENT GOES HERE */}
			{vistaActual === 0 ? <VistaGastos /> : <VistaMovimientos />}
			{/* CONTENT ENDS HERE */}
			{/* <Modal */}
			<ModalGastos
				open={open}
				onClose={() => setOpen(false)}
				data={initialFormValues}
				yupObject={yupValidation}
				// handleDelete={handleDelete}
				refresh={buscarGastosOperador}
			/>
			{/* <SideMenu 
		
			/> */}
		</Box>
	);
};

export default Gastos;
