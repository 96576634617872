import React, { useEffect, useState } from 'react';
import { Box, Button, Grid  } from "@mui/material";
import { Formik, Form } from 'formik';
import {api} from '../../../../config/axios';
import Swal from 'sweetalert2';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';

// COMPONENTES
import TextInput from '../../../../components/inputs/TextInput';
import MyDateInput from '../../../../components/inputs/MyDateInput';
import Header from '../../../../components/Header';

const OperadoresDocumentos = ({data}) => {

  // ===============================================
  // FORM DATA
  // ===============================================

  const [formdata, SetFormData] = useState({

    rel_operador: '',
    numero_licencia: '',
    vigencia_licencia: '',
    vigencia_visa: '',
    tipo_conductor: '',
    no_vigencia_apto_medico: '',

  })

  // ===============================================
  // LLAMAR DATOS
  // ===============================================

  useEffect(() => {

    buscarDatos();

    SetFormData({...formdata, ["rel_operador"]: data.id})

  },[data]);

  // ===============================================
  // BUSCAR DATOS DEL OPERADOR
  // ===============================================

  const [submitOption, setSubmitOption] = useState('crear');

  const buscarDatos = async () => {

    const response = await api.get(`get/operadores/documentos/${data.id}`);

    if(response.data.success === true && response.data.result !== "Sin resultados"){

      SetFormData(response.data.result[0]);

      setSubmitOption('editar');

    }
    else if(response.data.success === true && response.data.result === "Sin resultados"){
      setSubmitOption('crear');

    }

  }

  // ===============================================
  // FUNCION PARA CREAR
  // ===============================================

  const crearRegistro = (values) => {

    const url = 'create/operador/documentos';

    api.post(url, values)
    .then((response) => {

        if(response.data.success === true){

          Swal.fire({
              icon: 'success',
              title: 'Se ha creado el registro',
          })

          setSubmitOption('editar');

        }

    })
    .catch((error) => {
        console.log("ocurrio un error: " +error);
    })

  }

  // ===============================================
  // FUNCION PARA EDITAR
  // ===============================================

  const editarRegistro = (values) => {

    const url = 'update/operador/documentos';

    api.put(url, values)
    .then((response) => {

        if(response.data.success === true){

          Swal.fire({
              icon: 'success',
              title: 'Se ha editado el registro!',
          })

        }

    })
    .catch((error) => {
        console.log("ocurrio un error: " +error);
    })

  }

  // ===============================================
  // SUBMIT DEL FORMULARIO
  // ===============================================

  const handleSubmit = async (values) => {

    if(submitOption === 'crear'){

      crearRegistro(values);

    }

    if(submitOption === 'editar'){

      editarRegistro(values);

    }

  }

  // ===============================================
  // FORMULARIO
  // ===============================================

  return (

    <Box paddingRight={3}>

      <Header title="Documentos Operador" subtitle="" />

      <Formik
        initialValues={formdata}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values)
        }}
      >

        {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
        
          <Form>

          <Grid container spacing={2} paddingBottom={"50px"}>
          
              {/* NUMERO DE LICENCIA */}

              <Grid item md={6} xs={12}>

                <TextInput
                    label="Numero de Licencia"
                    name="numero_licencia"
                    placeholder="Ingrese numero de licencia"
                />

              </Grid>

              {/* VIGENCIA LICENCIA */}

              <Grid item md={6} xs={12}>

                <MyDateInput
                    label="Vigencia Licencia"
                    name="vigencia_licencia"
                    format="YYYY/MM/DD"
                />

              </Grid>

              {/* VIGENCIA VISA */}

              <Grid item md={6} xs={12}>

                <MyDateInput
                    label="Vigencia VISA"
                    name="vigencia_visa"
                    format="YYYY/MM/DD"
                />

              </Grid>

              {/* TIPO CONDUCTOR */}
              
              <Grid item md={6} xs={12}>

                <TextInput
                    label="Tipo Conductor"
                    name="tipo_conductor"
                    placeholder="Ingrese tipo de conductor"
                />

              </Grid>

              {/* NO VIGENCIA MEDICO */}
              
              <Grid item md={6} xs={12}>

                <TextInput
                    label="No. Vigencia Medica"
                    name="no_vigencia_apto_medico"
                    placeholder="Ingrese no. vigencia medica"
                />

              </Grid>

              {/* SUBMIT */}

              <Grid item md={2} xs={12} style={{marginLeft: 'auto'}}>

                  <Button type="submit" variant="contained" sx={{color: "white"}} color="success" startIcon={<SaveIcon />}>
                      Guardar
                  </Button>

              </Grid>

          </Grid>

          </Form>

      )}
          
      </Formik>

    </Box>

  )

}

export default OperadoresDocumentos