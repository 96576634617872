import StepperCustomized from "../components/Stepper";
import AssignmentIcon from '@mui/icons-material/Assignment';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import GradingIcon from '@mui/icons-material/Grading';
import { Box } from "@mui/material";
import { useState } from "react";
import StepRevision from "../steps/StepRevision";
import StepCreacion from "../steps/StepCreacion";
import StepResumen from "../steps/StepResumen";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { api } from "../../../../config/axios";
import Swal from "sweetalert2";


export default function VistaRequisito() {
    const [data, setData] = useState([]);
    const [articulos, setArticulos] = useState([]);
    const location = useLocation();
    const [estatus, setEstatus] = useState('');
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const searchParams  = new URLSearchParams(location.search);
        const dataRow       = JSON.parse(searchParams.get('data'));
        const articles      = JSON.parse(searchParams.get('articulos'));
        setData(dataRow);
        setArticulos(articles);
    },[])
    // Lo que se ocupa del stepper

    const [activeStep, setActiveStep] = useState(0);
    const handleNext = (button) => {
        switch(button){
            case 'rechazar': 
                setActiveStep(2);
                actualizarEstatus(4); // RECHAZADO
                setEstatus('RECHAZADO');
                break;
            case 'autorizar':
                setActiveStep(1);
                actualizarEstatus(10); // EN PROCESO DE VALIDACION -> APROBADO -> FINALIZADO
                setEstatus('EN PROCESO DE VALIDACION');
                break;
            case 'asignar':
                setActiveStep(2);
                actualizarEstatus(12); // ASIGNADO DESDE EL INVENTARIO NO HAY QUE PEDIR ALGO MAS
                setEstatus('ASIGNADO');
                break;
            default:
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setRefresh(true);
                break;
        }
    };
    const actualizarEstatus = async (estatus) => {
        const response = await api.put('/update/requerimiento/estatus', {id: data.id, estatus: estatus});
        if(response.data.success === true){
            Swal.fire({
                icon: 'success',
                title: 'Exito',
                text: 'Se ha actualizado el estatus del requerimiento',
                showConfirmButton: false,
                timer: 2500
            });
        };
    }
    const steps = [
    {
        label: 'Revision de requerimiento',
        component: (<StepRevision handleNext={handleNext} dataRow={data} articulos={articulos}/>),
    },
    {
        label: 'Creacion de la requisicion',
        component: (<StepCreacion handleNext={handleNext} dataRow={data} articulos={articulos}/>),
    },
    {
        label: 'Resumen de la requisicion',
        component:( <StepResumen id={data.id} dataRow={[data]} articulos={articulos} estatus={estatus} refresh={refresh}/>),
    }];
    const icons = {
        1: <AssignmentIcon />,
        2: <NoteAddIcon />,
        3: <GradingIcon />,
    };
    

    return (
        <Box mx={10} mt={5}>
            <StepperCustomized 
                steps={steps}
                icons={icons}
                activeStep={activeStep}
            />
        </Box>
    );
}
