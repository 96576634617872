import React from "react";

import { fetchChannels, fetchEpg } from "./helpers";

import { useEpg } from "planby";

// Import theme
import { theme } from "./helpers/theme";

export function useApp(filters, setData) {
	const [channels, setChannels] = React.useState([]);
	const [epg, setEpg] = React.useState([]);
	const [isLoading, setIsLoading] = React.useState(false);

	const channelsData = React.useMemo(() => channels, [channels]);
	const epgData = React.useMemo(() => epg, [epg]);
	const day = new Date(filters.fecha);

	// const startDate = today.toISOString().split('T')[0] + 'T00:00:00';
	// const endDate = today.toISOString().split('T')[0] + 'T24:00:00';
	//Solo es el formato fr-CA no afecta a la fecha en si...
	const startDate = day.toLocaleDateString('fr-CA') + 'T00:00:00';
	const endDate = day.toLocaleDateString('fr-CA') + 'T24:00:00';
	const { getEpgProps, getLayoutProps } = useEpg({
		channels: channelsData,
		epg: epgData,
		dayWidth: 7200,
		sidebarWidth: 200,
		itemHeight: 80,
		isSidebar: true,
		isTimeline: true,
		isLine: true,
		startDate,
		endDate,
		isBaseTimeFormat: true,
		theme,
	});
	
	const handleFetchResources = React.useCallback(async () => {
		setIsLoading(true);
		// Consultar epg y de ahi 
		// const channels = await fetchChannels(filters);
		// const ids = channels.map((channel) => channel.id);
		const { ts, users } = await fetchEpg(filters); 
		const response = {
			timesheets: [...ts],
			users: [...users],
		};
		setData(response);
		// Horarios
		setEpg(ts);
		// Usuarios
		setChannels(users);
		setIsLoading(false);
	}, [filters]);

	React.useEffect(() => {
		handleFetchResources();
	}, [handleFetchResources]);

	return { getEpgProps, getLayoutProps, isLoading, handleFetchResources };
}
