import React, {useEffect, useState} from 'react'
import { NavLink } from "react-router-dom";
import { Grid, Box, Typography } from "@mui/material";
import Header from '../../components/Header'
import { modulos, iconos } from '../../data/modulos';
import { marcarURL } from '../../config/functions';
import Cookies from 'js-cookie';


const Catalogos = () => {

    const [catalogoMenu, setCatalogoMenu] = useState([]);

    useEffect(() => {

      marcarURL('catalogos')

      const menu = JSON.parse(Cookies.get('menu'));

      setCatalogoMenu(modulos.filter((modulo) => menu.includes(modulo.value) && modulo.value.startsWith('catalogo.')))

    },[])

    return (
        <Box m="20px">

          <Header title="Catalogos" subtitle="" />

          <Grid container spacing={2}>
            {catalogoMenu.map((modulo, index) => {
              const [, itemName] = modulo.value.split('.');
    
              const IconoDinamico = iconos[modulo.icono];
    
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <NavLink
                    to={`/${modulo.url}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    activestyle={{ fontWeight: 'bold' }}
                  >
                    <Box
                      sx={{
                        border: 1,
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                      }}
                    >
                      {IconoDinamico && <IconoDinamico sx={{ fontSize: 48 }} />}
                      <Typography variant="body1" sx={{ mt: 1 }}>
                        {modulo.label}
                      </Typography>
                    </Box>
                  </NavLink>
                </Grid>
              );
            })}
          </Grid>
        </Box>
    );

}

export default Catalogos