import React from 'react';
import { useField } from 'formik';

const MyCheckBox = ({ children, ...props }) => {

    const [field, meta] = useField({ ...props, type: 'checkbox' });

    const errorStyle = {
        color: 'red',
        marginTop: '0.25rem'
    };

    const labelStyle = {
        display: 'flex',
        alignItems: 'center'
    };

    const checkboxStyle = {
        marginRight: '0.5rem',
        width: '1rem',
        height: '1rem'
    };

    return (
        <div>
            <label style={labelStyle} className="checkbox-input">
                <input style={checkboxStyle} type="checkbox" {...field} {...props} />
                {children}
            </label>
            {meta.touched && meta.error ? (
                <div style={errorStyle} className="error">{meta.error}</div>
            ) : null}
        </div>
    )
}

export default MyCheckBox;