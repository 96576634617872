import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../../../config/axios";
import { Box, Button, CardActions, Grid, Link, Typography } from "@mui/material";
import { tokens } from "../../../../theme";
import { useTheme } from "@emotion/react";
import * as Yup from 'yup';


import React from "react";
import ModalCompras from "../modals/ModalCompras";
import Swal from "sweetalert2";
import DragDropComponent from "../components/DragDropComponent";

function VistaCompras() {
	const navigate = useNavigate();
	const location = useLocation();
	const [stores, setStores] = useState([]);
	const theme  = useTheme();
	const colors = tokens(theme.palette.mode);
	const [open, setOpen] = useState(false);
	const [formData, setFormData] = useState({});
	const yupObject = Yup.object({
        rel_proveedor           : Yup.string().required('Requerido'),
        rel_moneda              : Yup.string().required('Requerido'),
		cantidad				: Yup.string().required('Requerido'),
        costo                   : Yup.string().required('Requerido'),
    });
  	useEffect(() => {
  		window.scrollTo(0, 0);
  		const searchParams  = new URLSearchParams(location.search);
        const dataRow       = JSON.parse(searchParams.get('data'));
  		const dataString 	= Object.values(dataRow).join(',');
  
  		buscarCotizaciones(dataString);
		  
  	}, []);
  
	const buscarCotizaciones = async (dataString) => {
		const response = await api.get('get/cotizaciones', {params: {id: dataString}});
		if(response.data.success === true && response.data.result !== "Sin resultados"){
			setStores(response.data.result);
		}else{
			setStores([]);
		}

	}
	const cotizacionesEstatus = {
		requested: {
			name: "Cotizaciones",
			
			bgColor: colors.grey[500],
			hvBgColor: colors.grey[600],
			
			color: colors.blueAccent[500],
			hvColor: colors.blueAccent[700],
			
			extra: colors.blueAccent[800],
			items: []
		},
		aceptados: {
			name: "Aceptados",
			
			bgColor: colors.grey[500],
			hvBgColor: colors.greenAccent[600],
			
			color: colors.greenAccent[500],
			hvColor: colors.greenAccent[700],
			
			extra: colors.greenAccent[800],
			items: []
		},
		rechazados: {
			name: "Rechazados",
			bgColor: colors.grey[500],
			hvBgColor: colors.redAccent[600],
			
			color: colors.redAccent[600],
			hvColor: colors.redAccent[700],

			extra: colors.redAccent[800],
			items: []
		},

	};
	const [columns, setColumns] = useState(cotizacionesEstatus);

	useEffect(() => {
		cotizacionesEstatus.requested.items = stores;
		setColumns(cotizacionesEstatus);
	}, [stores]);


	const actualizarEstatus = async (ids, estatus) => {
		const response = await api.put('update/cotizacion/estatus', {ids: ids, estatus: estatus});
		if(response.data.success === true){
			Swal.fire({
				icon: 'success',
				title: response.data.text,
			})
		}else{
			Swal.fire({
				icon: 'error',
				title: response.data.text,
			})
		}
	};
	const generarListaCompras = (list) => {
		const aceptados   = list.hasOwnProperty('aceptados')    ? JSON.stringify(list.aceptados.items) 	  : JSON.stringify(list[1].items);				
		if(aceptados === '[]'){
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'No hay cotizaciones aceptadas',
			})
			return;
		}
		Swal.fire({
			title: '¿Estas seguro?',
			text: "Se generará la lista de compras sobre los aceptados, los demas se actualizarán a rechazados o pendientes",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: colors.redAccent[500],
			confirmButtonText: 'Si, estoy seguro',
			cancelButtonText: 'Cancelar'
		}).then((result) => {
			if (result.isConfirmed) {
				// Falta actualizar los estatus de cada uno para que no se vuelvan a mostrar
				// validacion para que saber si existe el indice dentro del objeto
				const pendientes  = list.hasOwnProperty('requested') 	? list.requested.items 	: list[0].items;				
				const rechazados  = list.hasOwnProperty('rechazados')   ? list.rechazados.items : list[2].items;

				const pendientesIds = pendientes.map(item => item.id).join(',');
				if(pendientesIds !== ''){
					actualizarEstatus(pendientesIds, 9);
				}
				const rechazadosIds = rechazados.map(item => item.id).join(',');
				if(rechazadosIds !== ''){
					actualizarEstatus(rechazadosIds, 4);
				}
				const encodedAceptados = encodeURIComponent(aceptados);
				navigate(`/vista_carrito_compras?data=${encodedAceptados}`);
				Swal.fire(
					'Lista de compras generada',
					'La lista de compras se generó correctamente',
					'success'
				)
			}
		});
	};
	
	const handleClickCard = (item) => {
		setFormData(item);
		setOpen(true);
	}
	const handleCloseModal = () => {
		setOpen(false);
	};
	const onUpdateList = (data) => {
		let found = false;
		const updatedColumns = Object.entries(columns).map(([columnId, column], index) => {
			const updatedItems = (column.items).map((item) => {
				if (item._dragid === data._dragid  && !found) {
					found = true;
					return {
						...item,
						rel_proveedor: 	data.rel_proveedor ? data.rel_proveedor : item.rel_proveedor,
						rel_moneda: 	data.rel_moneda ? data.rel_moneda : item.rel_moneda,
						comentarios: 	data.comentarios ? data.comentarios : item.comentarios,
						subtotal: 		item.cantidad && data.costo ? Number(item.cantidad) * Number(data.costo) : item.subtotal,
						costo: 			data.costo ? data.costo : item.costo,
					};
				} else {
					return item;
				}
			});
			return {
				...column,
				items: updatedItems,
			};
		});
		setColumns(updatedColumns);
		handleCloseModal();
	};
	const handleSubmit = async (values) => {
		console.log(values);
		// values.rel_usuario_cotizacion = Cookies.get('user');
        // values.rel_requerimiento = props.dataRow.id;
        // values.rel_articulo = props.selectedItem.entity_id;
        
        // const url = 'create/cotizacion';

        // try {

        //     const result = await sendFormData(url, values); // 

        //     if(result.success === true){

        //         Swal.fire({
        //             icon: 'success',
        //             title: result.text,
        //         })
        //         handleComplete();
        //         // onClose();
        //         // refresh();
        //     }

        // } catch (error) {

        //     console.error("Upload failed:", error);

        // }

    }
	const formatCurrency = (value) => {
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
		return formatter.format(value);
	};
	const content = (item)=>{
		return (
			<Box>
				<Grid item xs={12} sm container>
					<Grid item xs container direction="column" spacing={2}>
						<Grid item xs>
						<Typography gutterBottom sx={{ mb: 1 }} color="text.secondary">
							{item.articulo} x {item.cantidad}
						</Typography>
						<Typography 
							variant="h5"
							style={{
								display: "-webkit-box",
								WebkitLineClamp: 2,
								WebkitBoxOrient: "vertical",
								overflow: "hidden",
								textOverflow: "ellipsis",
							}}
						>
							<Link color="secondary" href={item.url} target="_blank" rel="noopener noreferrer">{item.descripcion}</Link>
						</Typography>
						</Grid>
					</Grid>
					<Grid item>
						<Typography gutterBottom sx={{ mb: 1 }}>
							{formatCurrency(item.subtotal)}
						</Typography>
					</Grid>
				</Grid>

				<CardActions>
					<Button size="small" color="info" variant="outlined"  onClick={() => handleClickCard(item)}>Editar</Button>
				</CardActions>
			</Box>
		)
	}
	return (
		<Box m='20px'>
			<Typography variant="h2" gutterBottom style={{ textAlign: "center" }}>Escoja el estatus deseado</Typography>

			<DragDropComponent
				columns={columns}
				setColumns={setColumns}
				handleClickCard={handleClickCard}
				showTotal
				content={content}
			/>
		
			<Button variant="contained" sx={{color: "white", marginRight:'3.5rem', marginTop:'1rem'}} color="info" onClick={() => generarListaCompras(columns)}>
				<strong>Generar lista compras</strong>  
			</Button>
			<ModalCompras
				open={open}
				onClose={handleCloseModal}
				data={formData}
				yupObject={yupObject}
				handleSubmit={onUpdateList}
			/>
		</Box>
	);
}

export default VistaCompras;
