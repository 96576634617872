import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Box, Typography, Paper } from '@mui/material';


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
		backgroundImage:
			'linear-gradient(to right top, #5fd5ba, #4fcaac, #3dbf9f, #28b491, #06a984);',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
		backgroundImage:
			'linear-gradient(to right top, #5fd5ba, #4fcaac, #3dbf9f, #28b491, #06a984);',
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor:
		theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
		borderRadius: 1,
	},
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
	zIndex: 1,
	color: '#fff',
	width: 50,
	height: 50,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	...(ownerState.active && {
		backgroundImage:
		'linear-gradient(to right top, #5fd5ba, #4fcaac, #3dbf9f, #28b491, #06a984);',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	}),
	...(ownerState.completed && {
		backgroundImage:
		'linear-gradient(to right top, #5fd5ba, #4fcaac, #3dbf9f, #28b491, #06a984);',
	}),
}));





export default function StepperCustomized({steps,icons, activeStep}) {
    ColorlibStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
        /**
         * The label displayed in the step icon.
         */
        icon: PropTypes.node,
    };
    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;
        
        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }
	return (
		<Stack sx={{ width: '100%' }} spacing={4}>
			<Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
				{steps.map((step) => (
					<Step key={step.label}>
						<StepLabel StepIconComponent={ColorlibStepIcon}><Typography variant='h5'>{step.label}</Typography></StepLabel>
					</Step>
				))}
			</Stepper>
			<Box>
			{steps.map((step, index) => (
				<Paper key={index} elevation={3} style={{ display: index === activeStep ? 'block' : 'none' }}>
					{step.component}
				</Paper>
			))}
		</Box>
		</Stack>
	);
}