import React from 'react';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import { tokens } from '../../../../theme';
import { useTheme } from '@emotion/react';


function BadgeButton(props) {
	const theme  = useTheme();
	const colors = tokens(theme.palette.mode);
  	const { badgeContent, children, style, ...rest } = props;

	const styles = {
		button: {
			position: 'relative',
			marginRight: 16,
			marginBottom: 16,
			fontSize: '1rem',
			fontWeight:"bold",
			backgroundColor:colors.blueAccent[700],
		},
		badge: {
			position: 'absolute',
			top: -4,
			right: -4,
			fontSize: 20,
		},
	};
  return (
		<Button variant='contained' style={{ ...styles.button, ...style }} {...rest}>
			{children}
			{/* <Badge badgeContent={badgeContent} color="secondary" style={styles.badge} size="large" /> */}
		</Button>
  );
}

export default BadgeButton;