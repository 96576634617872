import { Button, Modal, Paper, Typography, Container, Grid, MenuItem } from '@mui/material'
import { Formik, Form } from 'formik'
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect } from 'react';
import { styled } from '@mui/system'
import SaveIcon from '@mui/icons-material/Save';
import * as Yup from 'yup';
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';
import { api } from '../../../../config/axios';
import { MD5 } from 'crypto-js';
import Swal from 'sweetalert2';

// COMPONENTES
import TextInput from '../../../../components/inputs/TextInput';
import MyInputPassword from '../../../../components/inputs/MyInputPassword';
import MySelect from '../../../../components/inputs/MySelect';

const ModalEditarUsuario = ({open, onClose, initialValues, refresh, empleados, perfiles, validarUsuario}) => {

    // ===============================================
    // VALIDACIONES PARA EL FORMULARIO
    // ===============================================
    const [tipos_usuarios, setTiposUsuarios] = React.useState([
        {value: 'empleado', display: 'Empleado'},
        {value: 'operador', display: 'Operador'},
    ]);
    const [valueEntity, setValueEntity] = React.useState('');
    const [entidades, setEntidades] = React.useState([]);
    const yupValidation = Yup.object().shape({

        email: Yup.string().email('Correo Invalido').required('Requerido'),
        password: Yup.string().required('Requerido'),
        entity_id: Yup.string().required('Requerido').test('validar-empleado', 'Este usuario ya existe', async (value) => {
            const isValid = await validarUsuario(value)
            return isValid;
        }),
        rel_perfil: Yup.string().required('Requerido'),

    })
    useEffect(() => {
        buscarUsuarios(initialValues.entity_type, 'edit');
    }, [initialValues.entity_type])
    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // VALIDAR SI ES MOVIL
    // ===============================================

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // ===============================================
    // BODY DEL MODAL
    // ===============================================

    const ScrollableContainer = styled(Container)({
        maxHeight: '60vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',
    });
    // ===============================================
    // BUSCAR USUARIOS
    // ===============================================
    const buscarUsuarios = async (value, action = 'edit') => {
            
        if(!value) return;
        const url = `get/usuarios/${value}/${action}`;

        await api.get(url).then(result => {
            if(result.data.success === true && result.data.success !== 'Sin resultados'){
                setEntidades(result.data.result);
            }else{
                setEntidades([]);
            }
        })
        .catch(error => {
            console.log(error)
        })
};
    // ===============================================
    // SUBMIT DEL MODAL
    // ===============================================

    const handleSubmit = (values) => {

        let data = values;

        data.password = MD5(data.password).toString();

        const url = 'update/usuario';

        api.put(url, values)
        .then((response) => {

            if(response.data.success === true){

                onClose();

                refresh();

                Swal.fire({
                    icon: 'success',
                    title: 'Se ha creado el comenario!',
                })

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    }

    return (

        <Modal
            open={open}
            onClose={onClose}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={yupValidation}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values)
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                <Paper 
                    sx={{ 
                        width: isNonMobile ? `60%` : '90%', 
                        p: 3, 
                        bgcolor: colors.blueAccent[900] 
                    }}
                >
                    
                    <Form>
                        <Typography variant="h3" align="center">
                            Editar Usuario
                        </Typography>
                        <ScrollableContainer>
                            <Grid container spacing={2} marginTop={"10px"} marginBottom={"10px"}>
                                <Grid item md={6} xs={12}>
                                    <MySelect
                                        label="Tipo de usuario"
                                        name="entity_type"
                                        onChange={(event) => {
                                            buscarUsuarios(event.target.value);
                                            setValueEntity(event.target.value);

                                            handleChange(event);
                                        }}
                                    >
                                        {tipos_usuarios.map((op) => (

                                            <MenuItem value={op.value} key={op.value}>{op.display}</MenuItem>

                                        ))}
                                    </MySelect>
                                </Grid> 
                                <Grid item md={6} xs={12}>
                                    <MySelect
                                        label="Usuario"
                                        name="entity_id"
                                        onChange={(event) => {
                                            handleChange(event);
                                            validarUsuario(event.target.value, valueEntity)
                                        }}
                                    >
                                    {
                                        // Array.isArray(empleados)
                                        // ?     
                                        // empleados.map((op) => (

                                        //     <MenuItem value={op.entity_id} key={op.entity_id}>{op.nombreEmpleado}</MenuItem>

                                        // ))
                                        // :
                                        // null
                                        entidades.map((op) => (

                                            <MenuItem value={op.value_exp} key={op.value_exp}>{op.display_exp}</MenuItem>

                                        ))
                                    }
                                    </MySelect>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <MySelect
                                        label="Perfil"
                                        name="rel_perfil"
                                        onChange={(event) => {
                                            handleChange(event);
                                        }}
                                    >
                                    {

                                        Array.isArray(perfiles)
                                        ?     
                                        perfiles.map((op) => (

                                            <MenuItem value={op.id} key={op.id}>{op.nombre_perfil}</MenuItem>

                                        ))
                                        :
                                        null
                                    }
                                    </MySelect>
                                </Grid> 
                                <Grid item md={12} xs={12}>
                                    <TextInput
                                        label="Correo"
                                        name="email"
                                        placeholder="Ingrese el correo"
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <MyInputPassword
                                        label="Nueva Contraseña"
                                        name="password"
                                    />
                                </Grid>  
                                <Grid item md={12} xs={12} style={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                                    <Button variant="contained" color="primary" onClick={onClose}>
                                        Cancelar
                                    </Button>
                                    <Button type="submit" variant="contained" sx={{color: "white"}} color="success" startIcon={<SaveIcon />}>
                                        Guardar
                                    </Button>
                                </Grid>           
                            </Grid>
                        </ScrollableContainer>
                    </Form>
                </Paper>
                )}
            </Formik>
        </Modal>

    )

}

export default ModalEditarUsuario