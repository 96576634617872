import React, { useState, useMemo } from 'react'
import { Box, Button, Grid, TextField, Typography} from "@mui/material";
import { useDropzone } from "react-dropzone"
import * as XLSX from 'xlsx'
import Swal from 'sweetalert2';
import imgXls from '../../../assets/xlsx_icon.svg'

// ICONOS
import UploadIcon from '@mui/icons-material/Upload';
import { api } from '../../../config/axios';
import Header from '../../../components/Header';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { format } from 'date-fns';
import * as FileSaver from 'file-saver';

const ExcelJS = require('exceljs');

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#1f2a40',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#1f2a40',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};
  
  const acceptStyle = {
    borderColor: '#00e676'
};
  
  const rejectStyle = {
    borderColor: '#ff1744'
};

function NominaFactura() {
    const theme  = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // COMPONENTE PARA CARGAR ARCHIVO EXCEL FACTURAS
    // ===============================================
    const [datosJson, setDatosJson] = useState([]);
    const [datosFactura, setDatosFactura] = useState([]);
     const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        acceptedFiles
    } = useDropzone({
        accept: {'application/vnd.ms-excel': [], 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []},
        maxFiles: 1,
        onDrop:(acceptedFiles) => {
            
            const reader = new FileReader();
    
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });

                 const jsonData = workbook.SheetNames.map(sheetName => {
                    const sheet = workbook.Sheets[sheetName];
                    return XLSX.utils.sheet_to_json(sheet);
                });

                setDatosJson(jsonData);
            };
            reader.readAsBinaryString(acceptedFiles[0]);
            // reader.onload = async (e) => {
            //     const arrayBuffer = e.target.result;
            
            //     const workbook = new ExcelJS.Workbook();
            //     await workbook.xlsx.load(arrayBuffer);
            
            //     const jsonData = workbook.worksheets.map(worksheet => {
            //         const rows = worksheet.getRows(1, worksheet.rowCount);
            //         return rows.map(row => {
            //         const jsonRow = {};
            //         row.eachCell((cell, colNumber) => {
            //             jsonRow[worksheet.getRow(1).getCell(colNumber).text] = cell.text;
            //         });
            //         return jsonRow;
            //         });
            //     });
            //     console.log(jsonData);
            //     setDatosJson(jsonData);
            // };
            // reader.readAsBinaryString(acceptedFiles[0]);
        },
        onFileDialogOpen: () => {setDatosJson([])},
    });
    const handleClick = () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Hoja 1");
        worksheet.columns = [
            { header: "ORDER_ID", key: "ORDER_ID", width: 20 },
            { header: "Billing date", key: "Billing date", width: 20 },
            { header: "Billing user", key: "Billing user", width: 20 },
        ];
        worksheet.addRow({ORDER_ID: 1, 'Billing date': 2, 'Billing user': 3});
        worksheet.addRow({ORDER_ID: 4, 'Billing date': 5, 'Billing user': 6});

        worksheet.getRow(1).border = {
            top: { style: "thick", color: { argb: "FFFF0000" } },
            left: { style: "thick", color: { argb: "000000FF" } },
            bottom: { style: "thick", color: { argb: "F08080" } },
            right: { style: "thick", color: { argb: "FF00FF00" } },
        };
    
        worksheet.getRow(1).fill = {
            type: "pattern",
            pattern: "darkVertical",
            fgColor: { argb: "FFFF00" },
        };
        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            FileSaver.saveAs(blob, 'archivo.xlsx');
        });    
    };
    // ===============================================
    // COMPONENTE PARA CARGAR ARCHIVO EXCEL NOMINA
    // ===============================================
    // const [datosNomina, setDatosNomina] = useState([]);
    // const {
    //     getRootProps: getRootProps2,
    //     getInputProps: getInputProps2,
    //     isFocused: isFocused2,
    //     isDragAccept: isDragAccept2,
    //     isDragReject: isDragReject2,
    //     acceptedFiles : acceptedFiles2
    // } = useDropzone({
    //     accept: {'application/vnd.ms-excel': [], 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []},
    //     maxFiles: 1,
    //     onDrop:(acceptedFiles2) => {
            
    //         const reader = new FileReader();
    
    //         reader.onload = (e) => {
    //             const data = e.target.result;
    //             const workbook = XLSX.read(data, { type: 'binary' });
    //             const sheetName = workbook.SheetNames[0];
    //             const sheet = workbook.Sheets[sheetName];
    //             const jsonData = XLSX.utils.sheet_to_json(sheet);
        
    //             setDatosNomina(jsonData)
    //         };
    //         reader.readAsBinaryString(acceptedFiles2[0]);
    //     },
    //     onFileDialogOpen: () => {setDatosNomina([])},
    // });
    
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);
    
    // const compararArchivos = () => {
    //     if(datosFactura.length === 0 || datosNomina.length === 0){
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Oops...',
    //             text: 'Debe de cargar los archivos de factura y nomina',
    //         })
    //         return;
    //     }

    //     const newFactura = Object.values(datosFactura);
    //     const newNomina  = Object.values(datosNomina); 
        
    //     // obtener los ids de las nominas que estan en facturas (quiere decir que si estan en facturas es porque debemos de pagarlas)
    //     let ids = newNomina.filter(nominaItem => 
    //         newFactura.some(facturaItem => facturaItem.ORDER_ID === nominaItem.ORDER_ID)
    //     ).map(item => item.ORDER_ID); 
    //     ids = [...new Set(ids)]; // Elimino duplicados
    //     let newFacturas = ids.flatMap(id => newFactura.filter(facturaItem => facturaItem.ORDER_ID === id));       
    //     let newNominas  = ids.flatMap(id => newNomina.find(nominaItem => nominaItem.ORDER_ID === id));
        
    //     const filename = 'reporteNominaFactura.xlsx';

        
    //     const worksheet1 = XLSX.utils.json_to_sheet(newFacturas);
    //     const worksheet2 = XLSX.utils.json_to_sheet(newNominas); // Replace anotherJson with your second JSON data

    //     const workbook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(workbook, worksheet1, 'Facturas');
    //     XLSX.utils.book_append_sheet(workbook, worksheet2, 'Nominas'); // Add another worksheet

    //     XLSX.writeFile(workbook, filename);
    // }
    const compararArchivos = () => {
        if(datosJson.length === 0){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Debe de cargar el archivo',
            })
            return;
        }

        const newFactura = Object.values(datosJson[0]);
        const newNomina  = Object.values(datosJson[1]); 
        
        // obtener los ids de las nominas que estan en facturas (quiere decir que si estan en facturas es porque debemos de pagarlas)
        let ids = newNomina.filter(nominaItem => 
            newFactura.some(facturaItem => facturaItem.ORDER_ID === nominaItem.ORDER_ID)
        ).map(item => item.ORDER_ID); 
        ids = [...new Set(ids)]; // Elimino duplicados
        let newFacturas = ids.flatMap(id => newFactura.filter(facturaItem => facturaItem.ORDER_ID === id));       
        let newNominas  = ids.flatMap(id => newNomina.find(nominaItem => nominaItem.ORDER_ID === id));
        
        const filename = 'reporteNominaFactura.xlsx';

        
        const worksheet1 = XLSX.utils.json_to_sheet(newFacturas);
        const worksheet2 = XLSX.utils.json_to_sheet(newNominas); // Replace anotherJson with your second JSON data

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet1, 'Facturas');
        XLSX.utils.book_append_sheet(workbook, worksheet2, 'Nominas'); // Add another worksheet

        XLSX.writeFile(workbook, filename);
    }
    const procesoGenerarExcel = () => {
        // Primero seria encontrar el match entre los dos archivos TPI, FACTURACION_DIA
        if(tipoComparacion === 1){
            compararArchivos();
        }else{
            if(datosJson.length === 0){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Debe de cargar el archivo',
                })
                return;
            }
            // const result = encontrarMatch(datosJson[0], datosJson[1]);
            // console.log(result);
            // Despues obtener los valores unicos de la columna Item
            let columnValues = datosJson[0].map(item => item.DESCRIPCION);
            let uniqueValues = [...new Set(columnValues)];
            // Despues agrupar los datos, OBTENER EL ACUMULADO DE CADA ITEM
            const groupedData = agruparDatos(datosJson[0],uniqueValues);
            // console.log(groupedData);
            let columns_extra_pagos = datosJson[1].map(item => item.CONCEPTO);
            let uniqueValues_extra_pagos = [...new Set(columns_extra_pagos)];
            // Ahora encontrar match entre el archivo de extra pagos y el archivo de facturas
            const result_extra_pagos = encontrarMatch(datosJson[1], datosJson[0]);
            // Despues agrupar los datos
            const groupedDataExtraPagos = agruparDatosExtraPagos(result_extra_pagos,uniqueValues_extra_pagos);
            // console.log(groupedDataExtraPagos);
    
            const finalResult = combinar(groupedData, groupedDataExtraPagos);
            // console.log(finalResult);
            
            const filename = 'reporteNominaFactura.xlsx';
            const worksheet1 = XLSX.utils.json_to_sheet(finalResult);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet1, 'Facturas');
            XLSX.writeFile(workbook, filename);
        }
    }
    const combinar = (sheet1, sheet2) => {
        let combinedData = [];

        sheet2.forEach(data => {
            let extraData = sheet1.find(extra => data.ORDER_ID_OPERADOR === extra.ORDER_ID);
            if (extraData) {
                combinedData.push({...extraData, ...data});
            } else {
                combinedData.push(data);
            }
        });

        sheet1.forEach(extraData => {
            if (!combinedData.find(data => extraData.ORDER_ID === data.ORDER_ID_OPERADOR)) {
                combinedData.push(extraData);
            }
        });
        combinedData = combinedData.map(data => {
            let total = data.TOTAL || 0;
            let totalExp = data.TOTAL_EXP || 0;
            let diff = total - totalExp;
            return {...data, DIFERENCIA: diff};
        });
          
        return combinedData;
    }
    const encontrarMatch = (sheet1, sheet2) => {
        // Primero obtiene los match entre uno y otro
        let ids = sheet2.filter(_sheet1 => 
            sheet1.some(_sheet2 => String(_sheet2.ORDER_ID).startsWith(_sheet1.ORDER_ID))
        ).map(item => item.ORDER_ID); 

        ids = [...new Set(ids)]; // Elimino duplicados
        
        let newSheet1 = ids.flatMap(id => {
            let match = sheet1.filter(_sheet1 => _sheet1.ORDER_ID === id);
            return match;
        });        
        return newSheet1;
        // let columnValues = sheet1.map(item => item.Item);
        // let uniqueValues = [...new Set(columnValues)];
        // let grouped = newSheet1.reduce((acc, item) => {
        //     let key = item.ORDER_ID;
        //     if (!acc[key]) {
        //         const fechaUnix = (item['Invoice Date'] - 25569) * 86400000; // 86400000 milisegundos en un día
        //         const fecha = new Date(fechaUnix);
        //         acc[key] = {
        //             'ORDEN': item.ORDER_ID,
        //             'FECHA DE FACTURA': fecha.toLocaleDateString(),
        //         };
        //         uniqueValues.forEach(value => {
        //             acc[key][value] = 0;
        //         });
        //         acc[key]['TOTAL'] = 0;
        //     }

        //     uniqueValues.forEach(value => {
        //         if (item.Item === value) {
        //             acc[key][value]   += item.Amount;
        //             acc[key]['TOTAL'] += item.Amount;
        //         }
        //     });
        //     return acc;
        // }, {});
        
        // return(Object.values(grouped));
        
        // const filename = 'reporteNominaFactura.xlsx';

        
        // const worksheet1 = XLSX.utils.json_to_sheet(result);
        // // const worksheet2 = XLSX.utils.json_to_sheet(newNominas); // Replace anotherJson with your second JSON data

        // const workbook = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(workbook, worksheet1, 'Facturas');
        // // XLSX.utils.book_append_sheet(workbook, worksheet2, 'Nominas'); // Add another worksheet

        // XLSX.writeFile(workbook, filename);
    }
    const agruparDatos = (sheet1, columns) => {
       
        let grouped = sheet1.reduce((acc, item) => {
            let key = item.ORDER_ID;
            if (!acc[key]) {
                let fecha_excel = item['FECHA_FACTURA'];
                if (typeof fecha_excel === 'number')
                {
                    const fechaSerial = fecha_excel;
                    const fechaBase = new Date(1900, 0, 1);
                    const fecha = new Date(fechaBase.getTime() + (fechaSerial - 2) * 24 * 60 * 60 * 1000);
                    fecha_excel = format(fecha, 'MM/dd/yyyy');
                    //console.log(datos.invoice_date);
                }
                acc[key] = {
                    'ORDER_ID': item.ORDER_ID,
                    'FECHA_FACTURA': fecha_excel,
                };
                columns.forEach(value => {
                    acc[key][value] = 0;
                });
                acc[key]['TOTAL'] = 0;
            }

            columns.forEach(value => {
                if (item.DESCRIPCION === value) {
                    acc[key][value]   += item.CANTIDAD;
                    acc[key]['TOTAL'] += item.CANTIDAD;
                }
            });
            return acc;
        }, {});
        return(Object.values(grouped));
    }
    const agruparDatosExtraPagos = (sheet1, columns) => {
       
        let grouped = sheet1.reduce((acc, item) => {
            let key = item.ORDER_ID;
            if (!acc[key]) {
                let fecha_excel = item['FECHA_TERMINACION_VIAJE'];
                if (typeof fecha_excel === 'number')
                {
                    const fechaSerial = fecha_excel;
                    const fechaBase = new Date(1900, 0, 1);
                    const fecha = new Date(fechaBase.getTime() + (fechaSerial - 2) * 24 * 60 * 60 * 1000);
                    fecha_excel = format(fecha, 'MM/dd/yyyy');
                    //console.log(datos.invoice_date);
                }
                acc[key] = {
                    'ORDER_ID_OPERADOR': item.ORDER_ID,
                    // 'FECHA_FACTURA_EXP': fecha_excel,
                    'FECHA_TERMINACION_VIAJE': fecha_excel,
                    'DRIVER CODE': item['DRIVER CODE'],
                    
                };
                columns.forEach(value => {
                    acc[key][`${value}_EXP`] = 0;
                });
                acc[key]['TOTAL_EXP'] = 0;
            }

            columns.forEach(value => {
                // DESCRIPCION
                if (item.CONCEPTO === value) {
                    acc[key][`${value}_EXP`]   += item.CANTIDAD;
                    acc[key]['TOTAL_EXP'] += item.CANTIDAD;
                }
            });
            return acc;
        }, {});
        return(Object.values(grouped));
    }

    const [tipoComparacion, setTipoComparacion] = useState(1);
    const handleTipoComparacionChange = (event) => {
        setTipoComparacion(event.target.value);
    };
    const generarPlantilla = () => {
        if(tipoComparacion === 2){
            // / Crear un nuevo libro de trabajo
            let workbook = XLSX.utils.book_new();

            // Crear una nueva hoja de cálculo
            let worksheet = XLSX.utils.aoa_to_sheet([
                [
                    'RowNumber',
                    'ORDER_ID',
                    'Customer Name',
                    'Bill To Account',
                    'Reference #',
                    'FECHA_FACTURA',
                    'CANTIDAD',
                    'CONCEPTO',
                    'DESCRIPCION',
                    'type',
                    'Class'
                ], // Esto será la primera fila (los encabezados)
            ]);
            let worksheet2 = XLSX.utils.aoa_to_sheet([
                ['ORDER_ID', 'Billing date']
            ]);
            let worksheet3 = XLSX.utils.aoa_to_sheet([
                [
                    'OPERADOR',
                    'DRIVER CODE',
                    'UNIDAD',
                    'CONCEPTO',
                    'CANTIDAD',
                    'ORDER_ID',
                    'FECHA_TERMINACION_VIAJE',
                    'DESCRIPCION',
                    'FLOTA'
                ]
            ]);
            // Agregar la hoja de cálculo al libro de trabajo
            XLSX.utils.book_append_sheet(workbook, worksheet, 'TPI');
            // Agregar la hoja de cálculo al libro de trabajo
            XLSX.utils.book_append_sheet(workbook, worksheet2, 'FACTURACION_DIA');
            // Agregar la hoja de cálculo al libro de trabajo
            XLSX.utils.book_append_sheet(workbook, worksheet3, 'EXTRA_PAGOS');
            // Escribir el libro de trabajo en un archivo
            XLSX.writeFile(workbook, 'Plantilla_TPI_FACTURACION_EXTRAPAGOS.xlsx');
        }else{
            // / Crear un nuevo libro de trabajo
            let workbook = XLSX.utils.book_new();

            // Crear una nueva hoja de cálculo
            let worksheet = XLSX.utils.aoa_to_sheet([
                [
                    'ORDER_ID',
                    'Billing date',
                    'Billing user',
                    '# of Pallets',
                    'Pallets reqd',
                    'Consignee arrive late',
                    'Shipper arrive late',
                    'POD recv date',
                    'Hold',
                    'Hold reason',
                    'Order value',
                    'Broker',
                    'Mode',
                    'Order type',
                    'Round trip',
                    'High value',
                    'Team',
                    'Loadbd Post type',
                    'Loadbd Callback number',
                    'Loadbd Trlr length',
                    'Loadbd Trlr width',
                    'Loadbd Rate type',
                    'Loadbd Rate',
                    'Loadbd Comment 1',
                    'Loadbd Comment 2',
                    'Controlling',
                    'Shipment ID',
                    'Load tender reply transmitted',
                    'Qualification profile',
                    'Co-Broker',
                    'CBP crossing',
                    'Date',
                    'Reason',
                    'CBSA crossing',
                    'Date',
                    'Reason',
                    'Tractor type',
                    'Operations user',
                    'Company copied to',
                    'Order copied to',
                    'Company copied from',
                    'Order copied from',
                    'Local mileage',
                    'Carrier invoice #',
                    'Invoice received date',
                    'Estimated toll charges',
                    'Next rebill',
                    'Planning comment',
                    'Default/required match id(s)',
                    'Subject #',
                    'Subj void date',
                    'Subj ordr status',
                    'Shipper actual arrival',
                    'Shipper actual departure',
                    'Shipper zone',
                    'Consignee actual arrival',
                    'Consignee actual departure',
                    'Consignee zone',
                    'Customer name',
                    'Movement type'
                  ]
            ]);
            let worksheet2 = XLSX.utils.aoa_to_sheet([
                [
                    'Accrual date',
                    'BOL',
                    'Billing distance',
                    'ORDER_ID',
                    'Check number',
                    'Commodity',
                    'Delivery date',
                    'Destination',
                    'Destination state',
                    'Order pay',
                    'Driver',
                    'Driver name',
                    'Bill received date',
                    'Freight total',
                    'Move',
                    'OK to pay date',
                    'Pro-rated pay',
                    'Accrual amount',
                    'Origin',
                    'Origin state',
                    'Pay distance',
                    'Pay method',
                    'Order pay rate',
                    'Payee',
                    'Payee type',
                    'Per diem',
                    'Less preload charge',
                    'Revenue code',
                    'Ship date',
                    'Total pay',
                    'Tractor',
                    'Trailer',
                    'Trailer rent %',
                    'Transfer date',
                    'Trip number',
                    'Weight',
                    'Loaded',
                    'Carrier invoice number',
                    'Manifest',
                    'Check date',
                    'LoadPay process date',
                    'LoadPay transaction date',
                    'LoadPay status',
                    'LoadPay transaction #',
                    'LoadPay payment ID',
                    'Voided',
                    'Void date',
                    'Allocated',
                    'Contact name',
                    'Contact relationship',
                    'Contact fax',
                    'Contact phone',
                    'Contact email',
                    'Net pay'
                  ]
            ]);
            
            // Agregar la hoja de cálculo al libro de trabajo
            XLSX.utils.book_append_sheet(workbook, worksheet, 'FACTURAS');
            // Agregar la hoja de cálculo al libro de trabajo
            XLSX.utils.book_append_sheet(workbook, worksheet2, 'NOMINAS');
            // Agregar la hoja de cálculo al libro de trabajo
            // Escribir el libro de trabajo en un archivo
            XLSX.writeFile(workbook, 'Plantilla_Nomina_Factura.xlsx');
        }
    }
    return (
        <Box m="20px">
            <Header title="Comparativo" subtitle="Comparacion entre archivos EBT Suite" />
            <Grid container spacing={2}>
                <FormControl sx={{ m:'10px', width: '200px' }}>                
                    <InputLabel id="select-label">Tipo de comparacion</InputLabel>
                    <Select
                        value={tipoComparacion} // Asegúrate de tener un estado para manejar el valor seleccionado
                        label="Tipo de comparacion"
                        onChange={handleTipoComparacionChange} // Asegúrate de tener una función para manejar el cambio de valor
                    >
                        <MenuItem value={1}>Nomina Factura</MenuItem>
                        <MenuItem value={2}>TPI/FACTURAS/EXTRA PAGOS</MenuItem>
                    </Select>
                </FormControl>
                <Button variant="contained" 
                    sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.blueAccent[700],
                        m:'15px',
                        height: '45px'
                    }} 
                    onClick={() => generarPlantilla()}
                >
                    Generar Plantilla
                </Button>    
                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h4" component="div">Archivo</Typography>
                    <Box m="40px 0 0 0"
                        width="100%">
                        <div {...getRootProps({style})}>
                            <input {...getInputProps()} />
                            <p>Haga clic aqui o arrastre documentos en esta zona para adjuntar archivos</p>
                        </div>
                        <aside>
                            <h4>Archivo cargado:</h4>
                            <ul id='listaArchivoFacturas'>
                                {acceptedFiles.map(file => (
                                    <li key={file.path}>
                                        <img src={imgXls} style={{height: '20px'}}></img> {file.path} - {file.size} bytes
                                    </li>
                                ))}
                            </ul>
                        </aside>
                    </Box>
                </Grid>
				{/* <Grid item xs={12} md={6} lg={6}>
                    <Typography variant="h4" component="div">Nomina</Typography>
                    <Box m="40px 0 0 0"
                        width="100%">
                        <div {...getRootProps2({style})}>
                            <input {...getInputProps2()} />
                            <p>Haga clic aqui o arrastre documentos en esta zona para adjuntar archivos</p>
                        </div>
                        <aside>
                            <h4>Archivo cargado:</h4>
                            <ul id='listaArchivoNominas'>
                                {acceptedFiles2.map(file => (
                                    <li key={file.path}>
                                        <img src={imgXls} style={{height: '20px'}}></img> {file.path} - {file.size} bytes
                                    </li>
                                ))}
                            </ul>
                        </aside>
                    </Box>
				</Grid> */}

			</Grid> 
            {/* Boton para crear comparativo de exceles y generar uno nuevo */}
            <Button variant="contained" 
                sx={{
                    fontWeight:"bold",
                    backgroundColor:colors.blueAccent[700]
                }} 
                // onClick={() => procesoGenerarExcel()}
                onClick={() => procesoGenerarExcel()}
            >
                Generar Excel
            </Button>
        </Box>
    )
}

export default NominaFactura