import { Box } from "@mui/material";
import GridView from "../../../components/Gridview";
import getCellIcon from "../icons/exportIcons";
import { marcarURL } from "../../../config/functions";
import { useEffect, useState } from "react";
import { api } from "../../../config/axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header";

export default function Requisiciones(){
    const navigate = useNavigate();
    
    const [data, setData] = useState([]);
    const [selectedRowId, setSelectedRowId] = useState([]);
    
    useEffect(() => {

        buscarPeticiones();

        marcarURL('peticiones');

    },[])

    const buscarPeticiones = async () => {
        const response = await api.get('get/peticiones' , {params: {estatus: 2, extraEstatus: 10}});
        if(response.data.success === true && response.data.result !== "Sin resultados"){
            setData(response.data.result);
        }else{
            setData([]);
        }
    }

    const handleRowSelection = (id) => {
        setSelectedRowId(id);
    };

    const columns = [
        {
            field: "id",
            headerName: "#",
            flex: 1,
            headerAlign: "center",
            align: "center",
            checkboxSelection: true, // Enable checkbox selection
        },
        {
            field: "nombreCompletoUsuario",
            headerName: "Solicito",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "tipo",
            headerName: "Tipo",
            flex: 1,
            headerAlign: "center",
            align: "center",        
        },
        {
            field: "descripcion",
            headerName: "Descripcion",
            flex: 3,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "id_estatus",
            headerName: "Estatus",
            flex: 2,
            headerAlign: "center",
            align: "center",
            renderCell: getCellIcon
        },
    ];

    // const actions = [
    //     { icon: <AddIcon />,    name: 'addPeticion'   , tooltipTitle: 'Nuevo',    onClick: handleOnClick },
    //     { icon: <DeleteIcon />, name: 'deletePeticion', tooltipTitle: 'Eliminar', onClick: handleDeleteAction},
    // ];

    /**
     * Handles the click event on a row in the table.
     * If the entity type is 'grupoArticulos', it calls the buscarArticulos function and sets the form data.
     * Otherwise, it just sets the form data and opens the modal.
     *
     * @param {Object} params - The parameters object containing information about the clicked row.
     * @param {string} params.row.entity_type - The type of entity for the clicked row.
     * @param {string} params.row.entity_id - The ID of the entity for the clicked row.
     */
    const handleRowClick = (params) => {
        const id_req = params.row.id;
        const row = JSON.stringify(params.row);
        if (params.row.entity_type === 'grupoArticulos') {
            buscarArticulos(params.row.entity_id, row, params.row.id_estatus, params.row.id);
        }else{
            // Busca si existe una cotizacion para el articulo de ser asi no se puede editar...
            buscarSiExisteCotizacion(id_req,row);
        }

    }

    const buscarSiExisteCotizacion = async (id_req,row) => {
        const response = await api.get('/get/existeCotizacionArticulo', {params: {id: id_req}});
        if(response.data.success === true && response.data.result === true){
                Swal.fire({
                    title: "Ya se capturo",
                    text: "Desea finalizar la operacion?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si, finaliza!",
                }).then((result) => {
                    if (result.isConfirmed) {
                        actualizarEstatus(id_req);
                        buscarPeticiones();
                    }
                });
                // En caso de que no exista una cotizacion se puede editar 
        }else{
            navigate(`/vista_requisicion/?data=${row}`);
        }
    }
    const actualizarEstatus = async (id_req) => {
        // When the status is 8 it means that the requisition is completed but not finished...
        const response = await api.put('update/requerimiento/estatus', {id: id_req, estatus: 8});
        if(response.data.success === true){
            Swal.fire({
                icon: 'success',
                title: 'Exito',
                text: 'Se ha finalizado la operacion',
                showConfirmButton: false,
                timer: 2500
            });
        };
    };
    const buscarArticulos = async (id,row, estatus,id_req) => {
        const response = await api.get('get/articulos', {params: {id: id, estatus:estatus, id_requerimiento: id_req}});
        if(response.data.success === true && response.data.result !== "Sin resultados"){
            const articulos = JSON.stringify(response.data.result);
            navigate(`/vista_requisicion/?data=${row}&articulos=${articulos}`);
        }else{
            // We need to handle for those which are already completed but not updated the estatus
            // For handle that we need to send the id of the requerimiento and the estatus to "finalizado" = 8
            Swal.fire({
                title: "Ya se capturo",
                text: "Desea finalizar la operacion?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, finaliza!",
            }).then((result) => {
                if (result.isConfirmed) {
                    actualizarEstatus(id_req);
                    buscarPeticiones();
                }
            });
        }
    };
    return(
        <Box m="20px">
            <Header title="Peticiones" subtitle="Listado de peticiones" />

            <GridView 
                columns={columns}
                data={data}
                handleRowClick={handleRowClick}
                selectedRowId={selectedRowId}
                handleRowSelection={handleRowSelection}
                hidden={true}
            />
           
        </Box>
    )
}