import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../theme";
import {api} from '../../../config/axios';
import { marcarURL } from '../../../config/functions';
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

//COMPONENTES
import Header from '../../../components/Header';

const Operadores = () => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();

    // ===============================================
    // SEGMENTO PARA BUSCAR OPERADORES
    // ===============================================

    const [data, setData] = useState([]);

    useEffect(() => {

        marcarURL('operadores');

        window.scrollTo(0, 0);

        buscarOperadores();

    },[])

    // FUNCION PARA BUSCAR OPERADORES

    const buscarOperadores = async () => {

        const response = await api.get('get/operadores');

        if(response.data.success === true && response.data.result !== "Sin resultados"){

            setData(response.data.result);

        }else{

            setData([]);

        }

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        {
            field: "driverCode",
            headerName: "Driver Code",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "nombre",
            headerName: "Nombre",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "apellido",
            headerName: "Apellido",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "flota",
            headerName: "Flota",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "fecha_ingreso",
            headerName: "Ingreso",
            flex: 2,
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => {
                
                if(params.row.fecha_ingreso !== '0000-00-00'){
                    const originalDate = params.row.fecha_ingreso;
                    const formattedDate = format(new Date(originalDate), 'dd/MM/yyyy');
                    return formattedDate;
                }else{
                    return '00/00/0000'
                }
                   
            },
        },
        {
            field: "estado",
            headerName: "Estado",
            flex: 2,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => 
                params.value === 1 
                ?
                <Button variant="contained" color="success" sx={{color: "white"}}><b>Activo</b></Button>
                :
                <Button variant="contained" color="error"><b>Baja</b></Button>
        },

    ];

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const handleRowClick = (params) => {

        let info = JSON.stringify(params.row);

        navigate(`/datos_operador?data=${info}`)

    }

    return (

        <Box m="20px">

            <Header title="Operadores" subtitle="Empresas EBTSuite" />

            <Button variant="contained" 
                sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.blueAccent[700]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={() => navigate("/crear_operador")}
            >
                Agregar Operador
            </Button>

            {/* TABLA  */}

            <Box
                m="40px 0 0 0"
                height="75vh"
                width="100%"
                sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`
                }
                }}
            >
                <DataGrid 
                    
                    rows={data} 
                    columns={columns} 
                    components={{ Toolbar: GridToolbar}}
                    onRowClick={handleRowClick}
                />
            </Box>

        </Box>
    )

}

export default Operadores