import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, Grid, useTheme  } from "@mui/material";
import { tokens } from '../../../../theme';
import Avatar from '@mui/material/Avatar';
import { api } from '../../../../config/axios';
import { baseURL } from '../../../../config/url';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";

// ICONOS
import InfoIcon from '@mui/icons-material/Info';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import DescriptionIcon from '@mui/icons-material/Description';

// COMPONENTES
import DatosOperador from '../general/DatosOperador';
import OperadoresDocumentos from '../documentos/OperadoresDocumentos';
import OperadoresDatosFiscales from '../datosFiscales/OperadoresDatosFiscales';

const VistaEditarOperador = () => {

    const navigate = useNavigate();

    const location = useLocation();

    const [userData, setUserData] = useState([]);

    useEffect(()=>{

        const searchParams = new URLSearchParams(location.search);
        const values = JSON.parse(searchParams.get('data'));

        setUserData(values);

        getImage(values.foto);

    },[])

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // SECCION PARA OBTENER LA IMAGEN
    // ===============================================

    const [imageData, setImageData] = useState('');

    const getImage = async (ruta) => {

        if(ruta !== null){

            const file = ruta.split("/")[2];

            const file2 = file.split("%");
    
            const finalFile = file2[0]+"%25"+file2[1];

            setImageData(`${baseURL}fotos/operadores/${finalFile}`);

        }

    }

    // ===============================================
    // CATALOGO DE COMPONENTES
    // ===============================================

    const views = {

        datos: DatosOperador,
        documentos: OperadoresDocumentos,
        datosFiscales: OperadoresDatosFiscales

    }

    // ===============================================
    // OPCION DE COMPONENTE 
    // ===============================================

    const [opcion, setOpcion] = useState('datos');

    // ===============================================
    // COMPONENTE DINAMICO
    // ===============================================

    const ComponenteActual = views[opcion];

    // ===============================================
    // FUNCION PARA DAR DE BAJA AL EMPLEADO
    // ===============================================

    const bajaOperador = () => {

        const idUser = userData.id;

        const url = `baja/operador/${idUser}`;

        const values = {};

        Swal.fire({
            imageUrl: imageData,
            imageHeight: 100,
            imageWidth: 100,
            title: 'Desea dar de baja al operador?',
            text: "Esta accion no podra ser revertida!",
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, dar baja!'
          }).then((result) => {
            if (result.isConfirmed) {
      
                api.put(url, values)
                .then((response) => {
        
                    if(response.data.success === true){
        
                        navigate('/operadores');
        
                        Swal.fire({
                            icon: 'success',
                            title: 'El empleado ha sido dado de baja!',
                        })
        
                    }
        
                })
                .catch((error) => {
                    console.log("ocurrio un error: " +error);
                })
      
            }
            
        })

    }

    return (

        <Box m="20px">

            <Grid container spacing={2} padding={2}>

                {/* AREA DE FORMULARIOS */}

                <Grid item md={9} >

                    <ComponenteActual data={userData}/>
                    
                </Grid>

                {/* AREA PARA BOTONES */}

                <Grid 
                    item 
                    md={3}
                    sx={{
                        paddingLeft: '0px !important',
                        backgroundColor: colors.grey[900],
                        borderRadius: '15px',
                    }}
                >

                    {/* DATOS GENERALES */}

                    <Grid item container justifyContent="center" marginBottom={5}>
                        <Button 
                            variant="contained" 
                            sx={{color: "white", height: "50px", fontWeight: 'bold'}}
                            startIcon={<InfoIcon />}
                            size="large"
                            onClick={() => setOpcion('datos')}
                        >
                            General
                        </Button>
                    </Grid>

                    {/* DOCUMENTOS */}

                    <Grid item container justifyContent="center" marginBottom={5}>
                        <Button 
                            variant="contained" 
                            sx={{color: "white", height: "50px", fontWeight: 'bold'}}
                            startIcon={<DescriptionIcon />}
                            size="large"
                            onClick={() => setOpcion('documentos')}
                        >
                            Documentos
                        </Button>
                    </Grid>

                    {/* DATOS FISCALES */}

                    <Grid item container justifyContent="center" marginBottom={5}>
                        <Button 
                            variant="contained" 
                            sx={{color: "white", height: "50px", fontWeight: 'bold'}}
                            startIcon={<AccountBalanceIcon />}
                            size="large"
                            onClick={() => setOpcion('datosFiscales')}
                        >
                            Datos Fiscales
                        </Button>
                    </Grid>

                    {/* FOTO DE EMPLEADO */}

                    <Grid item container justifyContent="center" marginBottom={2}>
                        <Avatar alt="Remy Sharp" src={imageData}  sx={{ width: '150px', height: '150px' }}/>
                    </Grid> 

                    {/* BOTON DE BAJA */}

                    <Grid item container justifyContent="center" marginBottom={3}>
                        <Button 
                            variant="contained" 
                            color="error"
                            sx={{color: "white", height: "50px", fontWeight: 'bold'}}
                            startIcon={<PersonRemoveIcon />}
                            size="large"
                            onClick={bajaOperador}
                        >
                            Baja Operador
                        </Button>  
                    </Grid>
                    
                </Grid>

            </Grid>

        </Box>

    )

}

export default VistaEditarOperador