import { useTheme } from "@emotion/react";
import { Autocomplete, Button, Grid, Modal, Paper, Select, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { tokens } from "../../../theme";
import { useEffect } from "react";
// import MySelect from "../../../../components/inputs/MySelect";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// ICONOS
import CloseIcon from '@mui/icons-material/Close';
import { DoneAll, GetApp } from "@mui/icons-material";

import dayjs from 'dayjs';
import { api } from "../../../config/axios";
import Swal from "sweetalert2";
import { endOfDay, set } from "date-fns";
import * as FileSaver from 'file-saver';

import Cookies from "js-cookie";
import { getWeek, startOfWeek, endOfWeek, format, startOfYear, eachDayOfInterval, eachWeekOfInterval } from 'date-fns';
import MenuItem from '@mui/material/MenuItem';
import MyAutoComplete from "../../../components/inputs/MyAutoComplete";
const ExcelJS = require('exceljs');
const now = new Date();
const startYear = startOfYear(now);
const endWeek = endOfWeek(now, { weekStartsOn: 1 });
const weeks = eachWeekOfInterval({ start: startYear, end: endWeek }, { weekStartsOn: 1}).reverse();

const ModalReporte = (props) => {
	const {open, onClose, data, refresh, setIsGeneratingReport } = props;

    const [fechas, setFechas] = useState([]);
    const [selectedWeek, setSelectedWeek] = useState();

    const [periodo, setPeriodo] = useState(1);
    const handlePeriodoChange = (event) => {
        setPeriodo(event.target.value);
    };
	const theme  = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [display, setDisplay] = useState('flex');

    const yesterday = dayjs().subtract(1, 'day');
	const dayOfWeek = yesterday.day();

	// const inicioSemana = today.subtract(dayOfWeek - 1, 'day');
	// const finSemana = today.add(7 - dayOfWeek, 'day');
    
	// const [fecha, setFecha] = useState(dayjs().subtract(1, 'day').format('YYYY/MM/DD'));
	// const [fechaFin, setFechaFin] = useState(dayjs().format('YYYY/MM/DD'));
	const [fecha, setFecha] = useState(yesterday);
	// const [fechaFin, setFechaFin] =useState(finSemana);

    const periodos = [
        {keyExpr: 1, valueExpr: 'POR DIA'},
        {keyExpr: 2, valueExpr: 'POR SEMANA'},
    ]
	const [showDatePicker, setShowDatePicker] = useState(false);
	const [json, setJson] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);
	const generarReporte = async () => {
        setIsGeneratingReport(true);
        if(!fechas || !fechas.length) setFechas([yesterday]);
        onClose();
        const json  = JSON.stringify(data.timesheets);
        const ids   = data.users.map((user) => user.id).join(',');
        const fecha = fechas.map((fecha) => dayjs(fecha).format('YYYY-MM-DD')); 
        const params = {
            fecha:  fecha,
            // fechaFin: fechaFin,+
            periodo: periodo,
            semana: selectedWeek ? selectedWeek : null,
            user: Cookies.get('user_id'),
            ids_users: ids,
            datosgen: json
        }
		const response = await api.post("/get/reports/quickbooks", {...params});
        if (response.data.success === true && response.data.result !== 'Sin resultados') {
            setJson(response.data.result);
            setFechas([yesterday]);
		}else{
			setJson([]);
            setFechas([yesterday]);
		}
        setIsGeneratingReport(false);
	};
	useEffect(() => {
		if(!json || !json.length || json === undefined) return;
		exportToExcel();
	}, [json]);
	const exportToExcel = async () => {
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet('Reporte');
		worksheet.columns = [
			{ header: 'NOMBRE', 			key: 'nombre',           width: 45 },
            { header: 'DIA', 			    key: 'dia',              width: 15 },
			{ header: 'FECHA', 			    key: 'fecha',            width: 15 },
			// { header: 'DIAS', 				key: 'dias',             width: 20 },
            { header: 'HORA ESTABLECIDA', 	key: 'hora_establecida', width: 20 },
            { header: 'HORA CHECADA', 	    key: 'hora_checada',     width: 20 },
            { header: 'RETARDOS', 	        key: 'retardos',         width: 20 },
			{ header: 'HORAS TRABAJADAS', 	key: 'horas_trabajadas', width: 20 },
			{ header: 'HORAS DE COMIDA', 	key: 'horas_comida',     width: 20 },
            { header: 'TOTAL' , 			key: 'total',            width: 20 },
		];
		worksheet.addRows(json);
		workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const timestamp = Date.now();
			FileSaver.saveAs(blob, `reporte_horas_${timestamp}.xlsx`);
        }); 
	
	}
    const handleWeekChange = (value) => {
        const start = startOfWeek(value, { weekStartsOn: 1 });
        const end = endOfWeek(value, { weekStartsOn: 1 });
        const daysOfWeek = eachDayOfInterval({ start: start, end: end }, { weekStartsOn: 1});
        setFechas(daysOfWeek);
        setSelectedWeek(value);
    }
	return(
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
			style={{
				display: display,
				alignItems: "center",
				justifyContent: "center",
			}}
        >
            <Paper sx={{
				width: "30%",
				p: 3,
				bgcolor: colors.blueAccent[900],
				borderRadius: "10px",
			}}
			>
                <Typography id="modal-modal-title" variant="h3" align="center">Generar Reporte</Typography>				
                <Grid container spacing={2} paddingBottom={"0.1rem"} sx={{mt:'10px'}}>
                    <Grid item md={12} xs={12} xl={12}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            onChange={handlePeriodoChange}
                            value={periodo}
                            fullWidth
                            label="Periodo"
                        >
                            {periodos.map((periodo) => (
                                <MenuItem key={periodo.keyExpr} value={periodo.keyExpr}>{periodo.valueExpr}</MenuItem>
                            ))}
                        </Select>
                        {/* */}
                    </Grid>
                    {/* TIPO */}				
                    <Grid item md={12} xs={12} xl={12}>
                        {periodo === 1 ?
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    defaultValue={yesterday}
                                    sx={{width: '100%'}}
                                    views={['year', 'month', 'day']}
                                    renderInput={(props) => <TextField {...props} fullWidth label="Fecha inicial" />}
                                    onChange={(value) => setFechas([dayjs(value).format('MM/DD/YYYY')])}
                                    shouldDisableDate={(date) => dayjs(date).isAfter(dayjs()) || dayjs(date).isSame(dayjs(), 'day')}
                                />
                            </LocalizationProvider>
                            : 
                            <Autocomplete
                                id="combo-box-demo"
                                defaultValue={weeks[0]}
                                options={weeks}
                                getOptionLabel={(option) => {
                                    const weekEnd = endOfWeek(option, { weekStartsOn: 1 });
                                    return `Semana ${getWeek(option)} - ${format(option, 'dd/MM/yyyy')} - ${format(weekEnd, 'dd/MM/yyyy')}`;
                                }}
                                fullWidth
                                onChange={(event, value) => handleWeekChange(value)}
                                renderInput={(params) => <TextField {...params} label="Periodo" />}
                                disableClearable
                            /> 
                        }
                    </Grid>
                    {/* <Grid item md={6} xs={6} xl={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{width: '100%', backgroundColor: colors.redAccent[700]}}
                                defaultValue={finSemana}
                                views={['year', 'month', 'day']}
                                renderInput={(props) => <TextField {...props} fullWidth label="Fecha final" />}
                                onChange={(value) => setFechaFin(dayjs(value).format('YYYY-MM-DD'))}
                            />
                        </LocalizationProvider>
                    </Grid> */}
                    {/* SUBMIT */}
                    <Grid item md={6} xs={6}>
                        <Button variant='contained' sx={{color: "white",width: "100%",borderRadius: "8px" ,marginRight: '10px'}} onClick={onClose} startIcon={<CloseIcon />}>
                            Cancelar
                        </Button>
                    </Grid>
                    <Grid item md={6} xs={6}>
                        <Button variant='contained' sx={{color: "white",width: "100%",borderRadius: "8px" ,marginRight: '10px', backgroundColor:colors.blueAccent[500]}} onClick={generarReporte} startIcon={<GetApp />}>
                            Generar
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    )
};
export default ModalReporte;