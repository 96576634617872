import { Box, Tab, Tabs } from "@mui/material"
import Header from "../../../components/Header"
import { useState } from "react";
import Articulos from "./tabs/Articulos";
import GrupoArticulos from "./tabs/GrupoArticulos";
// import Compras from "./Compras";
export default function IndexArticulos(){
    // const theme  = useTheme();
    // const colors = tokens(theme.palette.mode);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const sx_props_tab = {
        fontSize: '14px',
    }
    const renderComponent = () => {
        return value === 0 ? <Articulos /> : <GrupoArticulos/>;//<Requisiciones />;
    }
    return(
        <Box m="20px">
            <Header title="Articulos / Grupo de articulos" subtitle="Articulos y grupo de articulos EBTSuite" />
            <Box sx={{ width: '100%', gap: '30px'}}>
                <Tabs value={value} onChange={handleChange} variant="fullWidth" indicatorColor="secondary" textColor="secondary" centered>
                    <Tab value={0} label="Articulos" sx={sx_props_tab}/>
                    <Tab value={1} label="Grupo de Articulos" sx={sx_props_tab}/>
                </Tabs>

                {renderComponent()}
            </Box>
        </Box>
    )
}