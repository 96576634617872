import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../theme";
import {api} from '../../../config/axios';
import * as Yup from 'yup';
import { marcarURL } from '../../../config/functions';
import { modulos } from '../../../data/modulos';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

//COMPONENTES
import Header from '../../../components/Header';
import ModalCreateGM from '../../../components/modals/ModalCreateGM';
import ModalEditGM from '../../../components/modals/ModalEditGM';

const Perfiles = () => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // SEGMENTO PARA BUSCAR SUCURSALES
    // ===============================================

    const [data, setData] = useState([]);

    useEffect(() => {

        buscarPerfiles();

        marcarURL('perfiles');

    },[])

    const buscarPerfiles = async () => {

        const response = await api.get('get/perfiles');

        if(response.data.success === true && response.data.result !== "Sin resultados"){

            setData(response.data.result);

        }else{

            setData([]);

        }

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [
        { 
            field: 'nombre_perfil', 
            headerName: 'Nombre de Perfil', 
            flex: 2,
            headerAlign: "center",
            align: "center", },
        { 
            field: 'departamento', 
            headerName: 'Departamento', 
            flex: 2,
            headerAlign: "center",
            align: "center", },
        {
            field: 'modulos',
            headerName: 'Módulos',
            flex: 2,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {

                const modulosArray  = JSON.parse(params.value);
              
                let currentCategory = '';
                let modulesToShow = [];

                const groupedModules = [];

                modulosArray.forEach((modulo) => {
                    const [category, moduleName] = modulo.split('.');
                    
                    if (category !== currentCategory) {
                    if (currentCategory !== '') {
                        groupedModules.push({ category: currentCategory, modules: modulesToShow });
                        modulesToShow = [];
                    }
                    currentCategory = category;
                    }
                    
                    modulesToShow.push(moduleName);
                });

                groupedModules.push({ category: currentCategory, modules: modulesToShow });

                return (
                    <div style={{ overflow: 'auto', maxHeight: '100px', width: "100%", paddingTop: "20px", paddingBottom: "20px" }}>
                    <ul>
                        {groupedModules.map((group, index) => (
                        <li key={index}>
                            {group.category.toUpperCase()}
                            <ul>
                            {group.modules.map((moduleName, subIndex) => (
                                <li key={subIndex}>{moduleName}</li>
                            ))}
                            </ul>
                        </li>
                        ))}
                    </ul>
                    </div>
                );

            },
        },
    ];

    // ===============================================
    // VALORES PARA SUBMIT DEL FORMULARIO
    // ===============================================

    const [initialFormValues, setInicialFormValues] = useState({

        nombre_perfil: '',
        rel_depa: '',
        modulos: []

    })

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL CREARA
    // ===============================================

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {

        setInicialFormValues({

            nombre_perfil: '',
            rel_depa: '',
            modulos: []
    
        })

        setIsOpen(true);
    };
    
    const handleCloseModal = () => {
        setIsOpen(false);
    };

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL EDITAR
    // ===============================================

    const [isOpenEdit, setIsOpenEdit] = useState(false);

    const handleOpenModalEdit = () => {
        setIsOpenEdit(true);
    };
    
    const handleCloseModalEdit = () => {
        setIsOpenEdit(false);
    };

    // ===============================================
    // SEGMENTO PARA TRAER DEPARTAMENTOS
    // ===============================================

    const [departamentos, setDepartamentos] = useState([]);

    useEffect(() => {
        
        api.get('get/departamentos')
        .then(result => {
    
            if(result.data.success == true)
            {
                setDepartamentos(result.data.result);
            }
    
        })
        .catch(error => {
        
            console.log(error)
        
        })
    
    },[]);

    const inputForms = [

        {
            componente: "input",
            label: "Perfil",
            name: "nombre_perfil",
            placeholder: "Ingrese Nombre del Perfil",
            type: "text",
            md: 12,
            xs: 12
        },
        {
            componente: "select",
            label: "Departamento",
            name: "rel_depa",
            options: departamentos,
            value: "id",
            text: "nombre_depa",
            md: 12,
            xs: 12
        },
        {
            componente: "selectMultiple",
            label: "Modulos",
            name: "modulos",
            options: modulos,
            md: 12,
            xs: 12
        }

    ];

    // ===============================================
    // VALIDACIONES PARA EL FORMULARIO
    // ===============================================

    const yupValidation = Yup.object().shape({

        nombre_perfil: Yup.string().required('Requerido'),
        rel_depa: Yup.string().required('Requerido')

    })

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const handleRowClick = (params) => {

        var newData = {...params.row};

        newData.modulos = JSON.parse(newData.modulos)

        setInicialFormValues(newData);
        handleOpenModalEdit();

    }

    // ===============================================
    // ===============================================

    return (

        <Box m="20px">

            <Header title="Perfiles" subtitle="Perfiles EBTSuite" />

            {/* BOTON PARA ABRIL MODAL */}

            <Button variant="contained" 
                sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.blueAccent[700]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={handleOpenModal}
            >
                Agregar Perfil
            </Button>

            {/* TABLA  */}

            <Box
                m="40px 0 0 0"
                height="75vh"
                width="100%"
                sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`
                }
                }}
            >
                <DataGrid 
                    
                    rows={data} 
                    columns={columns} 
                    components={{ Toolbar: GridToolbar}}
                    onRowClick={handleRowClick}
                     
                />
            </Box>

            {/* MODAL PARA CREAR SUCURSAL */}

            <ModalCreateGM
                modalTitle="Crear Perfil"
                swalTitle="Se ha guardado el perfil!"
                url="create/perfil"
                open={isOpen} 
                onClose={handleCloseModal} 
                size='40'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarPerfiles}
            />

            {/* MODAL PARA EDITAR SUCURSAL */}

            {/* <ModalEditarPerfil
                data={initialFormValues}
                open={isOpenEdit} 
                onClose={handleCloseModalEdit} 
                refresh={buscarPerfiles}
                departamentos={departamentos}
            /> */}

            <ModalEditGM
                modalTitle="Editar Perfil"
                swalTitle="Se ha editado el perfil!"
                url="update/perfil"
                urlDelete="delete/perfil/"
                open={isOpenEdit} 
                onClose={handleCloseModalEdit} 
                openModal={handleOpenModalEdit}
                size='40'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarPerfiles}
            />

        </Box>
        
    )

}

export default Perfiles