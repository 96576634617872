import React from 'react';
import { useField } from 'formik';
import { TextField } from "@mui/material";

const MyNumberInput = ({ label, ...props }) => {

    const [field, meta] = useField(props);

    // Esta función se encargará de asegurarse de que el valor sea un número o un decimal válido
    const handleNumberChange = (event) => {
        const { value } = event.target;
        if (!isNaN(value) || value === "" || value === "-") {
            field.onChange(event);
        }
    };

    return (
        <TextField
            sx={{ width: "100%" }}
            variant="outlined"
            className="number-input"
            autoComplete="off"
            label={label}
            type="text"
            inputProps={{
                inputMode: "decimal",
                pattern: "[0-9]*"
            }}
            {...field}
            {...props}
            onChange={handleNumberChange}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
        />
    );
    
}

export default MyNumberInput