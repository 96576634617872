import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../theme";
import {api} from '../../../config/axios';
import * as Yup from 'yup';
import { marcarURL } from '../../../config/functions';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

//COMPONENTES
import Header from '../../../components/Header';
import ModalCreateGM from '../../../components/modals/ModalCreateGM';
import ModalEditGM from '../../../components/modals/ModalEditGM';

const Empresas = () => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // SEGMENTO PARA BUSCAR EMPRESAS
    // ===============================================

    const [empresas, setEmpresas] = useState([]);

    useEffect(() => {

        buscarEmpresas();

        marcarURL('empresas');

    },[])

    const buscarEmpresas = async () => {

        const response = await api.get('get/empresas');

        if(response.data.success === true && response.data.result !== "Sin resultados"){

            setEmpresas(response.data.result);

        }else{

            setEmpresas([]);

        }

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        // {   field: "id", headerName: "ID", flex: 0.5 },
        {
            field: "nombre_comercial",
            headerName: "Nombre Comercial",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "razon_social",
            headerName: "Razon Social",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },

    ];

    // ===============================================
    // VALORES PARA SUBMIT DEL FORMULARIO
    // ===============================================

    const [initialFormValues, setInicialFormValues] = useState({

        nombre_comercial: '',
        razon_social: ''

    })

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL CREARA
    // ===============================================

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {

        setInicialFormValues({

            nombre_comercial: '',
            razon_social: ''
    
        })

        setIsOpen(true);
    };
    
    const handleCloseModal = () => {
        setIsOpen(false);
    };

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL EDITAR
    // ===============================================

    const [isOpenEdit, setIsOpenEdit] = useState(false);

    const handleOpenModalEdit = () => {
        setIsOpenEdit(true);
    };
    
    const handleCloseModalEdit = () => {
        setIsOpenEdit(false);
    };

    // ===============================================
    // INPUTS PARA ARMAR EL FORMULARIO
    // ===============================================

    const inputForms = [

        {
            componente: "input",
            label: "Nombre Comercial",
            name: "nombre_comercial",
            placeholder: "Ingrese Nombre Comercial",
            type: "text",
            md: 12,
            xs: 12
        },
        {
            componente: "input",
            label: "Razon Social",
            name: "razon_social",
            placeholder: "Ingrese Razon Social",
            type: "text",
            md: 12,
            xs: 12
        }

    ];

    // ===============================================
    // VALIDACIONES PARA EL FORMULARIO
    // ===============================================

    const yupValidation = Yup.object().shape({

        nombre_comercial: Yup.string().required('Requerido'),
        razon_social: Yup.string().required('Requerido')

    })

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const handleRowClick = (params) => {

        setInicialFormValues(params.row);
        handleOpenModalEdit();

    }

    // ===============================================
    // ===============================================

    return (

        <Box m="20px">

            <Header title="Empresas" subtitle="Empresas EBTSuite" />

            {/* BOTON PARA ABRIR MODAL */}

            <Button variant="contained" 
                sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.blueAccent[700]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={handleOpenModal}
            >
                Agregar Empresa
            </Button>

            {/* TABLA  */}

            <Box
                m="40px 0 0 0"
                height="75vh"
                width="100%"
                sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`
                }
                }}
            >
                <DataGrid 
                    
                    rows={empresas} 
                    columns={columns} 
                    components={{ Toolbar: GridToolbar}}
                    onRowClick={handleRowClick}
                />
            </Box>

            {/* MODAL PARA CREAR EMPRESAS */}

            <ModalCreateGM
                modalTitle="Crear Empresa"
                swalTitle="Se ha guardado la Empresa!"
                url="create/empresa"
                open={isOpen} 
                onClose={handleCloseModal} 
                size='40'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarEmpresas}
            />

            {/* MODAL PARA EDITAR EMPRESAS */}

            <ModalEditGM
                modalTitle="Editar Empresa"
                swalTitle="Se ha editado la Empresa!"
                url="update/empresa"
                urlDelete="delete/empresa/"
                open={isOpenEdit} 
                onClose={handleCloseModalEdit} 
                openModal={handleOpenModalEdit}
                size='40'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarEmpresas}
            />

        </Box>
    )

}

export default Empresas