import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { api, sendFormData } from "../../../../config/axios";
import DragDropComponent from "../components/DragDropComponent";
import { Box, Button, Grid, Typography, Link } from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DoneIcon from '@mui/icons-material/Done';
import ModalComprobantes from "../modals/ModalComprobantes";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const CarritoCompras = (props) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [proveedores, setProveedores] = useState([]);
	const [tiendas, setTiendas] = useState([]);
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);


	const formatCurrency = (value) => {
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
		return formatter.format(value);
	};
	const buscarProveedores = async (ids_provs) => {
		const response = await api.get('get/proveedores', {params: {ids_provs, withEstilos: true}});
		if(response.data.success === true && response.data.result !== "Sin resultados"){
			setProveedores(response.data.result);
		}else{
			setProveedores([]);
		}
	};
	useEffect(() => {
		const searchParams  = new URLSearchParams(location.search);
		const data       	= JSON.parse(searchParams.get('data'));
		setData(data);
		let ids_provs 	= [...new Set(data.map((object) => object.rel_proveedor))];
		ids_provs = ids_provs.join(',');
		buscarProveedores(ids_provs);	
	}, [location]);
	useEffect(() => {
		const newTiendas = proveedores.reduce((acc, proveedor) => {
			acc[proveedor.id] = {
				value: proveedor.id,
				bgColor: proveedor.bgColor,
				hvBgColor: proveedor.hoverBgColor,
				color: proveedor.color,
				hvColor: proveedor.hoverColor,
				extra: proveedor.extra,
				name: proveedor.proveedor.charAt(0).toUpperCase() + proveedor.proveedor.slice(1),
				items: []
			};
			return acc;
		}, {}); 

		Object.entries(newTiendas).map(([tiendaId, tienda], index) => {
			data.forEach((object) => {
				if (parseInt(object.rel_proveedor) === parseInt(tienda.value)) {
					tienda.items.push(object);
				}
			});
		});

		setTiendas(newTiendas);

		
	}, [proveedores]);

	const handleSubmit = async (values) => {
		// values.rel_usuario_cotizacion = Cookies.get('user');
		const updatedColumns = Object.entries(tiendas).map(([tiendaId, tienda]) => {
			if (tiendaId == values.rel_proveedor) {
				tienda.comprobante = values.comprobante;
			}
			return [tiendaId, tienda];
		});
		  
		// Convierte de nuevo el array de pares clave-valor a un objeto
		const updatedTiendas = Object.fromEntries(updatedColumns);
		  
		setTiendas(updatedTiendas);
		setOpen(false);
		Swal.fire({
			icon: 'success',
			title: '!Comprobante guardado exitosamente!',
			showConfirmButton: false,
			timer: 1500
		})
		// handleCloseModal();
	
	};
	
	const crearCompra = async (data) => {
		const url 	 = 'create/compra';
		// const result = await api.post(url, {data});
		const datosgen = {};
		const ids_reqs = [];
		datosgen.rel_usuario_compra = Cookies.get('user');
		
		await Promise.all(Object.entries(data).map(async ([key, element]) => {
			datosgen.rel_proveedor = key;
			datosgen.rel_moneda = element.items[0].rel_moneda;
			datosgen.rel_estatus_compra = 3;
			datosgen.total = element.items.reduce((acc, item) => acc + item.subtotal, 0);
			datosgen.comprobante = element.comprobante ? element.comprobante[0] : null;
			
			try {
				const result = await sendFormData(url, datosgen);
				
				if (result.success === true) {
					const datosdet = {};
					element.items.map(async (item) => {
						datosdet.rel_compras 	= result.id; // El que se acaba de insertar
						datosdet.rel_cotizacion = item.id; // Cada item es una cotizacion
						datosdet.cantidad 		= item.cantidad;
						datosdet.costo			= item.costo;
						datosdet.subtotal		= (item.subtotal * item.cantidad);
						datosdet.comentarios 	= item.comentarios ? item.comentarios : '';
						
						// Obtiene los ids de los requerimientos para actualizar su estatus 
						ids_reqs.push(item.rel_requerimiento);
						const resultDetalle = await api.post('create/detalle_compra', datosdet);
						if (resultDetalle.data.success === true) {
							Swal.fire({
								icon: 'success',
								title: resultDetalle.text,
							});
						} else {
							console.log(resultDetalle.data.text);
						}
					});
					
				
					// refresh();
				}
			} catch (error) {
			  	console.error('Upload failed:', error);
				navigate('/requerimientos');
			}
		}));
		// Revisa luego de insertar en detalles de compras si se hizo la compra parcial o totalmente
		ids_reqs.map(async (id) =>{
			const resultEstatus = await api.put('update/estatus/requerimiento', {id: id});
			if (resultEstatus.data.success === true) {
				navigate('/requerimientos');
			}
		});
	};
	const validationSchema = Yup.object().shape({
		comprobante: Yup.array().min(1, 'Debe seleccionar al menos un archivo'),
	});
	const content = (item) => {
		return(
			<Box>
				<Grid item xs={12} sm container>
				<Grid item xs container direction="column" spacing={2}>
					<Grid item xs>
					<Typography gutterBottom sx={{ mb: 1 }} color="text.secondary">
						{item.articulo} x {item.cantidad}
					</Typography>
					<Typography 
						variant="h5"
						style={{
							display: "-webkit-box",
							WebkitLineClamp: 2,
							WebkitBoxOrient: "vertical",
							overflow: "hidden",
							textOverflow: "ellipsis",
						}}
					>
						<Link color="secondary" href={item.url} target="_blank" rel="noopener noreferrer">{item.descripcion}</Link>
					</Typography>
					</Grid>
				</Grid>
				<Grid item>
					<Typography gutterBottom sx={{ mb: 1 }}>
						{formatCurrency(item.subtotal)}
					</Typography>
				</Grid>
			</Grid>
			</Box>
		)
	};
	return(
		<Box >
			<Typography variant="h2" gutterBottom style={{ textAlign: "center" }}>Resumen de compras</Typography>
			<DragDropComponent
				columns={tiendas}
				setColumns={setTiendas}
				handleClickCard={()=>{}}
				showTotal
				isDragDisabled
				content={content}
			/>
			{/* <Button size="small" color="info" variant="outlined"  onClick={() => handleClickCard(item)}>Finalizar</Button> */}
			<div style={{ display: "flex", justifyContent: "center", height: "100%", gap: "1rem" }}>
				{/* <MyDropzone /> */}
				<Button 
					size='large' 
					variant="contained" 
					sx={{color: "white", borderRadius: '10px', fontSize: '1rem'}} 
					color="info" 
					startIcon={<UploadFileIcon/>}
					onClick={() => setOpen(true)}
				>
					<strong> Subir comprobantes </strong>
				</Button>
				<Button 
					size='large' 
					variant="contained" 
					sx={{color: "white", borderRadius: '10px', fontSize: '1rem'}} 
					color="success" 
					startIcon={<DoneIcon/>}
					onClick={() => crearCompra(tiendas)}
				>
					<strong> Guardar compras </strong>
				</Button>
			</div>
			<ModalComprobantes
				open={open}
				onClose={() => setOpen(false)}
				tiendas={tiendas}
				proveedores={proveedores}
				handleSubmit={handleSubmit}
				validationSchema={validationSchema}
			/>
		</Box>

	);
};

export default CarritoCompras;