import { useTheme } from "@emotion/react";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Paper, Select, TextField, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { tokens } from "../../../../theme";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone"

import * as XLSX from 'xlsx'
import imgXls from '../../../../assets/xlsx_icon.svg'

import MySelect from "../../../../components/inputs/MySelect";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// ICONOS
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import PublishIcon from '@mui/icons-material/Publish';

import dayjs from 'dayjs';
import { api } from "../../../../config/axios";
import Swal from "sweetalert2";
import MySnackBar from "../../../../components/feedback/MySnackBar";
import { format, set } from 'date-fns';


const ExcelJS = require('exceljs');

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#1f2a40',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#1f2a40',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};
  
  const acceptStyle = {
    borderColor: '#00e676'
};
  
  const rejectStyle = {
    borderColor: '#ff1744'
};


const ModalArchivo = (props) => {
	const {open, onClose, onOpen } = props;
	// Customize colors
	const theme  = useTheme();
    const colors = tokens(theme.palette.mode);

	// Estilos 
	const style2 = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		height: 'auto',
		width: 'auto',
		bgcolor: colors.blueAccent[900],
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	};
	// COLUMNAS FACTURACION
    const [headersFacturacion, setHeadersFacturacion] = useState([
        'order_id',
        'nombre_cliente',
        'cuenta_facturacion',
        'num_referencia',
        'fecha_factura',
        'cantidad',
        'descripcion',
        'item',
        'tipo',
        'clase'
    ]);
    // COLUMNAS NOMINAS
    const [headersNominas, setHeadersNominas] = useState([
        'nombre_operador',
        'driver_code',
        'unidad',
        'concepto',
        'cantidad',
        'order_id',
        'order_alterna',
        'fecha_terminacion_viaje',
        'flota',
        'fecha_pago'
    ]);
	// ===============================================
    // COMPONENTE PARA CARGAR ARCHIVO EXCEL
    // ===============================================
	const [datosJson, setDatosJson] = useState([]);
    const [data, setData] = useState([]);
    const [esFactura, setEsFactura] = useState(false);
    
	const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        acceptedFiles
    } = useDropzone({
        accept: {'application/vnd.ms-excel': [], 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []},
        maxFiles: 1,
        onDrop:(acceptedFiles) => {
            
            const reader = new FileReader();
    
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });

                 const jsonData = workbook.SheetNames.map(sheetName => {
                    const sheet = workbook.Sheets[sheetName];
                    return XLSX.utils.sheet_to_json(sheet);
                });

                setDatosJson(jsonData);
            };
            reader.readAsBinaryString(acceptedFiles[0]);
        },
        onFileDialogOpen: () => {setDatosJson([])},
    });
	const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);
	// ===============================================
    // PROPIEDADES PARA EL SNACKBAR
    // ===============================================
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
    };
    const [action, setAction] = useState('success');
    const [message, setMessage] = useState('Archivo guardado correctamente');
    useEffect(() => {
        // Comparar cada uno de las columnas para saber si es de facturacion o de nominas
        if(datosJson.length > 0){
            let columnas = Object.keys(datosJson[0][0]);
            let esFactura = columnas.every(item => headersFacturacion.includes(item));
            let esNomina  = columnas.every(item => headersNominas.includes(item));

            if(esFactura){
                setEsFactura(true);
                setAction('success');
                setMessage('Archivo de facturas cargado correctamente');
                setOpenSnackbar(true);
            }else if(esNomina){
                setEsFactura(false);
                let flotas = datosJson[0].map(item => item.flota);
                let flotasUnicas = flotas.filter((value, index, self) => self.indexOf(value) === index);
                if(flotasUnicas.length > 1){
                    setAction('error');
                    setMessage('El archivo tiene mas de una flota. Favor de revisar para continuar');
                    setOpenSnackbar(true);
                    setDatosJson([]);
                }else{
                    setAction('success');
                    setMessage('Archivo de nominas cargado correctamente');
                    setOpenSnackbar(true);
                }
            }else{
                setAction('error');
                setMessage('El archivo no tiene el formato correcto. Favor de revisar para continuar');
                setOpenSnackbar(true);
                setDatosJson([]);
                return;
            }
        }
    }, [datosJson]);	
    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);
   
    const guardarArchivo = async () => {
		if(datosJson.length === 0){
            setOpenSnackbar(true);
            setAction('error');
            setMessage('Debe de cargar un archivo para guardar informacion');
			return;
		}  
        // Formateo correcto de las fechas
        if(esFactura){
            datosJson[0].forEach(item => {
                let fecha_excel = item.fecha_factura;
                if (typeof fecha_excel === 'number'){
                    const fechaSerial = fecha_excel;
                    const fechaBase = new Date(1900, 0, 1);
                    const fecha = new Date(fechaBase.getTime() + (fechaSerial - 2) * 24 * 60 * 60 * 1000);
                    fecha_excel = format(fecha, 'MM/dd/yyyy');
                    item.fecha_factura = fecha_excel;
                }
            });
        }else{
            datosJson[0].forEach(item => {
                let fecha_excel  = item.fecha_terminacion_viaje;
                let fecha_excel2 = item.fecha_pago;
                if (typeof fecha_excel === 'number'){
                    const fechaSerial = fecha_excel;
                    const fechaBase = new Date(1900, 0, 1);
                    const fecha = new Date(fechaBase.getTime() + (fechaSerial - 2) * 24 * 60 * 60 * 1000);
                    fecha_excel = format(fecha, 'MM/dd/yyyy');
                    item.fecha_terminacion_viaje = fecha_excel;
                }
                if (typeof fecha_excel2 === 'number'){
                    const fechaSerial = fecha_excel2;
                    const fechaBase = new Date(1900, 0, 1);
                    const fecha = new Date(fechaBase.getTime() + (fechaSerial - 2) * 24 * 60 * 60 * 1000);
                    fecha_excel2 = format(fecha, 'MM/dd/yyyy');
                    item.fecha_pago = fecha_excel2;
                }
            });
        }
        // determina si es facturacion o nominas dependiendo de las columnas
        let url = esFactura ? 'create/concentrado/facturas' : 'create/concentrado/nominas';
        try {
            // let values = JSON.stringify(datosJson[0]);
            const result = await api.post(url, datosJson[0]); // 
			
            if(result.data.success === true){
                onClose();
                Swal.fire({
                    icon: result.data.icon,
                    title: result.data.text,
                })
            }

        } catch (error) {
            console.error("Upload failed:", error);
        }
	}
	
	return(
		<>
			<Modal
				open={open}
				onClose={onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
                style={{
                    display: 'flex',
                    alignItems: "center",
				    justifyContent: "center",
                }}
			>	
				<Paper sx={{
                    width: "30%",
                    p: 3,
                    bgcolor: colors.blueAccent[900],
                    borderRadius: "10px",
                }} >
					<Typography id="modal-modal-title" variant="h3" align="center">Agregar un archivo</Typography>				
					<Box m="40px 0 0 0"
						width="100%">
						<div {...getRootProps({style})}>
							<input {...getInputProps()} />
							<p>Haga clic aqui o arrastre documentos en esta zona para adjuntar archivos</p>
						</div>
						<aside>
							<h4>Archivo cargado:</h4>
							<ul id='listaArchivoFacturas'>
								{acceptedFiles.map(file => (
									<li key={file.path}>
										<img src={imgXls} style={{height: '20px'}}></img> {file.path} - {file.size} bytes
									</li>
								))}
							</ul>
						</aside>
					</Box>
                    {/* <Grid container spacing={2} sx={{marginTop: '1rem'}} justifyContent="flex-end">
                        <Button variant="contained" 
                            sx={{color: "white",marginRight: '1rem'}}
                            color="success"
                            startIcon={<SaveIcon />}
                            // onClick={() => procesoGenerarExcel()}
                            onClick={() => guardarArchivo()}
                        >
                            Guardar
                        </Button>
                    </Grid> */}
                    <Grid container spacing={2} sx={{marginTop: '1rem'}} justifyContent="flex-end">
                        <Grid item md={6} xs={6}>
                            <Button variant='contained' sx={{color: "white",width: "100%",borderRadius: "8px" ,marginRight: '10px'}} onClick={onClose} startIcon={<CloseIcon />}>
                                Cancelar
                            </Button>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Button variant="contained" 
                                sx={{color: "white",width: "100%",borderRadius: "8px" ,marginRight: '10px'}}
                                color="success"
                                startIcon={<SaveIcon />}
                                // onClick={() => procesoGenerarExcel()}
                                onClick={() => guardarArchivo()}
                            >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
				</Paper>
			</Modal>
			<MySnackBar open={openSnackbar} action={action} message={message} onClose={handleCloseSnackbar}/>

		</>
    )
};
export default ModalArchivo;