import { useTheme } from "@emotion/react";
import { Box, List, ListItem, ListItemText } from "@mui/material";
import { tokens } from "../../../../theme";
import { useState } from "react";
import { useEffect } from "react";
import SideBarForm from "../components/SideBarForm";
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import * as Yup from 'yup';


/**
 * Renders a step for creating a new item in the "Requerimientos" section of the app.
 * @param {Object} props - The component props.
 * @param {Array} props.dataRow - An array of data for the current row.
 * @param {Array} props.articulos - An array of articles.
 * @param {Function} props.handleNext - A function to handle moving to the next step.
 * @returns {JSX.Element} - The JSX element for the component.
 */
export default function StepCreacion(props){
    const theme  = useTheme();
    const colors = tokens(theme.palette.mode);

    const [data, setData] = useState([]);
    const [articles, setArticulos] = useState([]);
    const [selectedItem, setSelectedItem] = useState({});

    const [formData, setFormData] = useState({});
    const yupObject = Yup.object({
        rel_proveedor           : Yup.string().required('Requerido'),
        rel_moneda              : Yup.string().required('Requerido'),
        url                     : Yup.string().required('Requerido'),
        descripcion             : Yup.string().required('Requerido'),
        costo                   : Yup.string().required('Requerido'),
    });
   
    
    useEffect(() => {
        setData(props.dataRow);
        setArticulos(props.articulos);
        setFormData({
            rel_proveedor           : '',
            rel_moneda              : '',
            url                     : '',
            descripcion             : '',
            costo                   : '',
            archivo_cotizacion      : '',
        });
    },[props.dataRow, props.articulos])
    const style = {
        borderRadius: '10px',
        height: 'auto',
        width: 'auto',
        bgcolor: colors.blueAccent[800],
    }; 

    const [open, setOpen] = useState(false);

    const handleOnClick = (item,index) => {
        setOpen(true);
        item.index = index;
        setSelectedItem(item);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleComplete = () => {
        if(articles && articles.length !== 0){
            const newArticles = [...articles];
            newArticles[selectedItem.index].completado = 1;
            setArticulos(newArticles);
            setOpen(false);
            const allCompleted = articles.every((article) => article.completado === 1);
            if(allCompleted){
                setOpen(false);
                props.handleNext();
            }
        }else{
            setOpen(false);
            props.handleNext();
        }
    };
    return(
        <Box>
            <List sx={style}>
            {articles && articles.map((item, index) => (
                item.completado === 0 ? (
                    <ListItem
                      key={index}
                      secondaryAction={
                        <IconButton edge="end" aria-label="add" onClick={() => {handleOnClick(item,index)}}>
                          <AddIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary={item.articulo}
                        secondary={`Cantidad: ${data.cantidad}`}
                        sx={{fontSize:'1rem'}}
                      />
                    </ListItem>
                  ) : null
            ))} 
            {(!articles || articles.length === 0) && [data] && [data].map((item, index) => (
                <ListItem
                key={index}
                secondaryAction={
                    <IconButton edge="end" aria-label="add" onClick={() => {handleOnClick(item,index)}}>
                    <AddIcon />
                    </IconButton>
                }
                >
                <ListItemText
                    primary={item.descripcion}
                    secondary={`Cantidad: ${item.cantidad}`}
                    sx={{fontSize:'1rem'}}
                />
                </ListItem>
            ))}
            </List>
            <SideBarForm 
                open={open} 
                handleClose={handleClose}
                initialValues={formData}
                yupObject={yupObject}
                handleComplete={handleComplete}
                dataRow={data}
                selectedItem={selectedItem}
            />                
        </Box>
    )
}