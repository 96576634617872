import { Box, Modal } from "@mui/material";
import DocumentViewer from "../components/DocumentViewer";
import { useEffect, useState } from "react";
import { baseURL } from "../../../../config/url";


export default function ModalViewer({ open, onClose, row }) {
	const [type, setType] = useState("");
	const [src, setSrc] = useState("");
	const obtenerTipo = async () => {
		if (!row.comprobante) return;
		const partes = await row.comprobante.split(".");
		return partes.length === 0 ? "" : setType(partes[partes.length - 1]);
	};
	const getFile = async (ruta) => {
		ruta = ruta.replaceAll(/\\/g, "/");
		const file = ruta.split("/")[2];

		const file2 = file.split("%");

		const finalFile = file2[0] + "%25" + file2[1];

		setSrc(`${baseURL}comprobantes/compras/${finalFile}`);
	};
	useEffect(() => {
		if (!row.comprobante) return;
		getFile(row.comprobante);
		obtenerTipo();
	}, [row]);
	return (
		<Modal 
			open={open} 
			onClose={onClose}
			// slotProps={{
			// 	backdrop: {
			// 		onClick: onClose,
			// 	},
			// }}
		>
			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<DocumentViewer type={type} src={src} />
			</Box>
		</Modal>
	);
}
