import React, { useEffect, useState } from "react";
import {
	Alert,
	Autocomplete,
	Button,
	Collapse,
	Grid,
	IconButton,
	Modal,
	Paper,
	TextField,
	Typography,
} from "@mui/material";
import { Formik, Form } from "formik";
import { api, sendFormData } from "../../../../config/axios";
import Swal from "sweetalert2";

// ICONOS
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
// COMPONENTES
import TextInput from "../../../../components/inputs/TextInput";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../../theme";
import MyMoneyInput from "../../../../components/inputs/MyMoneyInput";

import Cookies from "js-cookie";
import MyInputFile from "../../../../components/inputs/MyInputFile";
import MySnackBar from "../../../../components/feedback/MySnackBar";
import MyAutoComplete from "../../../../components/inputs/MyAutoComplete";

// const MySelect = lazy(() => import('../../../../components/inputs/MySelect'));

const ModalAsignarViatico = ({
	open,
	data = {},
	yupObject,
	onClose,
	refresh,
}) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [driverCode, setDriverCode] = useState('');
	const [sobranteData, setSobranteData] = useState({});
	const [formData, setFormData] = useState({});
	const [addTraspaso, setAddTraspaso] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
		if (Object.keys(data).length !== 0) {
			setFormData(data);
			setAddTraspaso(false);
			setShow(false);
			// buscarOrdenes();
		}
	}, [data]);
	useEffect(() => {
        buscarTractores();
		setShow(false);
		// buscarOrdenes();
	}, []);
	// BUSCAR SOBRANTES
	
	// ===============================================
	// SOBRANTES
	// ===============================================
	useEffect(() => {
		if (Object.keys(sobranteData).length !== 0) {
			if(parseFloat(sobranteData.monto) > 0){
				setShow(true);
				// setFieldValue("monto", sobranteData.monto);
			}
		}
	}, [sobranteData]);
	const buscarSobrantes = async (driver_code) => {
		if(driver_code === null) return;
		const response = await api.get("get/ordenes/sobrantes", { params: { rel_operador: driver_code } });
		if (response.data.success === true && response.data.result !== "Sin resultados") {
			setSobranteData(response.data.result);
		} else {
			setSobranteData({});
		}
	};
	// ===============================================
	// ORDENES DE MCLEOD
	// ===============================================
	// const [valueOrden, setValueOrden] = useState(null);
	const buscarOrdenes = async () => {
		if(tractorID === null) return[];
		const response = await api.get("get/ordenes/mcleod", { params: { id: tractorID } });
		if (response.data.success === true && response.data.result !== "Sin resultados") {
			return response.data.result;
		} else {
			return [];
		}
	};
    // ===============================================
    // TRACTORES
    // ===============================================
    const [tractorID, setTractorID] = useState(null);
    const [tractores, setTractores] = useState([]);
    const buscarTractores = async () => {
        const response = await api.get('get/tractores');
        if(response.data.success === true && response.data.result !== "Sin resultados"){
            setTractores(response.data.result);
        }else{
            setTractores([]);
        }
    };
    
	const formatCurrency = (value) => {
		const formatter = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
		return formatter.format(value);
	};
	
	// ===============================================
	// SUBMIT DEL FORMULARIO
	// ===============================================
	const [display, setDisplay] = useState("flex");
	const handleSubmit = async (values) => {
		if (values.rel_orden === null || values.rel_orden === undefined) {
			setDisplay("none");
			Swal.fire({
				icon: "error",
				title: "Seleccione una orden",
			}).then((result) => {
				if (result.dismiss || result.isConfirmed) {
					setDisplay("flex");
				}
			});
			return;
		}
		values.rel_orden   = values.rel_orden.id;
		values.rel_tractor = values.tractor_id 
		values.driver_code = driverCode;
		values.rel_usuario = Cookies.get("user");
		if(addTraspaso){
			values.detalles = [
				{
					sobrante_id: sobranteData.id,
					monto: sobranteData.monto,
					concepto: "traspaso",
					comentario: `Traspaso de la  orden: ${sobranteData.rel_orden} por la cantidad de ${sobranteData.monto}`,
					hasRoute: false,
				},
				{
					monto: values.monto,
					concepto: "asignacion",
					hasRoute: true,
					comentario: values.comentario,
				}
			];
		}else{
			values.detalles = [
				{
					monto: values.monto,
					concepto: "asignacion",
					hasRoute: true,
					comentario: values.comentario,

				}
			];
		}
		const url = "create/orden/viaticos";
		try {
			const result = await sendFormData(url, values); //

			if (result.success === true) {
				Swal.fire({
					icon: "success",
					title: result.text,
				});
				// setValueOrden(null);
				onClose();
				refresh();
			}
		} catch (error) {
			console.error("Upload failed:", error);
		}
	};
	const handleTraspaso = () => {
		setOpenSnackbar(true);
		setShow(false);
		setAddTraspaso(true);
	};
	const [showTraspaso, setShow] = useState(false);
	 // ===============================================
    // PROPIEDADES PARA EL SNACKBAR
    // ===============================================
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
    };
    const action  = 'success';
    const message = 'Traspaso hecho correctamente';
	return (
		<>
			<Modal
				open={open}
				onClose={onClose}
				BackdropProps={{ onClick: (event) => event.stopPropagation }}
				style={{
					display: display,
					alignItems: "center",
					justifyContent: "center",
				}}
			>
					<Formik
						initialValues={formData}
						validationSchema={yupObject}
						onSubmit={(values, { setSubmitting }) => {
							handleSubmit(values);
						}}
						enableReinitialize={true}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							setFieldValue,
						}) => (
							<Paper
								sx={{
									width: "40%",
									p: 3,
									bgcolor: colors.blueAccent[900],
									borderRadius: "10px",

								}}
							>
								<Form encType="multipart/form-data">
									<Typography
										variant="h3"
										align="center"
										paddingBottom={"2rem"}
									>
										Asignar viaticos
									</Typography>
									<Grid
										container
										spacing={2}
										paddingBottom={"0.1rem"}
									>   
										{/* TRACTOR */}
										<Grid item md={6} xs={12}>
											<Autocomplete
												id="tractor_id"
												name="tractor_id"
												options={tractores || []}
												// value={valueOrden}
												getOptionLabel={(option) => option.tractor_id }
												renderInput={(params) => (
													<TextField
														{...params}
														label={"Tractor ID"}
														placeholder={
															"Seleccione un tractor ID"
														}
													/>
												)}
												onChange={(event, newValue) => {
													// setValueOrden(newValue ? newValue : null);
													setTractorID(newValue ? newValue.tractor_id : null);
													setFieldValue("tractor_id", newValue ? newValue.id : null);
												}}
											/>
										</Grid>
										{/* ORDEN */}
										<Grid item md={6} xs={12}>
											{formData.id ? (
												<Paper
													sx={{
														width: "100%",
														p: 2,
														bgcolor: colors.blueAccent[700],
													}}
												>
													<Typography
														variant="h3"
														align="center"
													>
														<strong>
															ORDEN: {formData.rel_orden}
														</strong>
													</Typography>
												</Paper>
											) : (
												<MyAutoComplete
													label={"Orden"}
													name={"rel_orden"}
													placeholder={"Seleccione una orden"}
													fetchOptions={buscarOrdenes}
													keyExpr={"id"}
													search={tractorID}
													// value={valueOrden}
													onChange={(event, newValue) => {
														// setValueOrden(newValue ? newValue : null);
														setFieldValue("rel_orden", newValue ? newValue : null);
														setDriverCode(newValue ?   newValue.driver.id : null);
														buscarSobrantes(newValue ? newValue.driver.id : null);
													}}
												/>
												// <Autocomplete
												// 	id="rel_orden"
												// 	name="rel_orden"
												// 	options={ordenes || []}
												// 	// value={valueOrden}
												// 	getOptionLabel={(option) => option.id + " - " + option.tractor["model"]}
												// 	renderInput={(params) => (
												// 		<TextField
												// 			{...params}
												// 			label={"Orden"}
												// 			placeholder={
												// 				"Seleccione una orden"
												// 			}
												// 		/>
												// 	)}
												// 	onChange={(event, newValue) => {
												// 		// setValueOrden(newValue ? newValue : null);
												// 		setFieldValue("rel_orden", newValue ? newValue.id : null);
												// 		setDriverCode(newValue ?   newValue.driver.id : null);
												// 		buscarSobrantes(newValue ? newValue.driver.id : null);
												// 	}}
												// />
											)}
										</Grid>
										{/* MONTO */}
										<Grid item md={12} xs={12}>
											<MyMoneyInput
												label={"Monto"}
												name={"monto"}
												key={"monto"}
												placeholder={"Ingrese el monto para asignar"}
												inputProps={{ maxLength: 16 }}
											/>
										</Grid>
									
										{showTraspaso ? <Grid item md={12} xs={12}>
											<Collapse in={showTraspaso}>
												<Alert
													severity="info"
													action={
														<IconButton
															color="inherit"
															size="small"
															onClick={() => {
																handleTraspaso()
															}}
														>
															<CurrencyExchangeIcon fontSize="inherit" />
														</IconButton>
													}
													sx={{ mb: 2 }}
												>
													Hay un traspaso disponible de {<strong>{formatCurrency(sobranteData.monto)}</strong>} pesos. Deseas incluirlo?
												</Alert>
											</Collapse>
										</Grid> : null}
										{/* COMENTARIOS */}
										<Grid item md={12} xs={12}>
											<TextInput
												label="Comentarios"
												name="comentario"
												multiline
												rows={3}
												placeholder="Ingrese comentarios"
											/>
										</Grid>
										<Grid item md={12} xs={12}>
											<Typography variant="caption" align="left">Comprobante</Typography>
											<MyInputFile
												name="comprobante"
											/>
										</Grid>       
										{/* SUBMIT */}
										<Grid
											item
											// md={6}
											// xs={6}

											md={6}
											xs={6}
										>
											<Button
												variant="contained"
												color="primary"
												sx={{
													color: "white",
													marginRight: "10px",
													width: "100%",
													borderRadius: "8px",
												}}
												onClick={onClose}
												startIcon={<CloseIcon />}
											>
												Cancelar
											</Button>
										</Grid>
										<Grid
											item
											md={6}
											xs={6}
											// md={data.id ? 4 : 6}
											// xs={data.id ? 4 : 6}
										>
											<Button
												type="submit"
												variant="contained"
												sx={{
													width: "100%",
													color: "white",
													borderRadius: "8px",
												}}
												color="success"
												startIcon={<SaveIcon />}
											>
												Guardar
											</Button>
										</Grid>
										
									</Grid>
								</Form>
							</Paper>
						)}
					</Formik>
			</Modal>
			<MySnackBar open={openSnackbar} action={action} message={message} onClose={handleCloseSnackbar}/>
		</>
	);
};

export default ModalAsignarViatico;
