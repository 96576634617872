import { Box, Button, Grid, Modal, Paper, Typography, Tooltip, IconButton, useMediaQuery } from "@mui/material";
import { tokens } from "../../../../theme";
// ICONOS
import CancelIcon from '@mui/icons-material/Cancel';
import TextInput from '../../../../components/inputs/TextInput';
import MySelect from '../../../../components/inputs/MySelect';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@emotion/react';
import Swal from 'sweetalert2';
import MyMoneyInput from '../../../../components/inputs/MyMoneyInput';
import SaveIcon from '@mui/icons-material/Save';
import Cookies from 'js-cookie';
import { Formik, Form } from 'formik';
import { useState } from "react";
import { api } from "../../../../config/axios";
import { useEffect } from "react";

export default function ModalCompras (props) {
    const {open, onClose, yupObject, data ,handleSubmit} = props;
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [formData, setFormData] = useState(data);
   
    const theme  = useTheme();
    const colors = tokens(theme.palette.mode);
    
    
    useEffect(() => {
        window.scrollTo(0, 0);
        buscarProveedores();
        buscarMonedas();
        setFormData(data);
    },[data]);
    const [proveedores, setProveedores] = useState([]);
    const buscarProveedores = async () => {
        const response = await api.get('get/proveedores');
        if(response.data.success === true && response.data.result !== "Sin resultados"){
            setProveedores(response.data.result);
        }else{
            setProveedores([]);
        }
    };
    const [monedas, setMonedas] = useState([]);
    const buscarMonedas = async () => {
        const response = await api.get('get/monedas');
        if(response.data.success === true && response.data.result !== "Sin resultados"){
            setMonedas(response.data.result);
        }else{
            setMonedas([]);
        }
    };
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 'auto',
        width: '500',
        bgcolor: colors.blueAccent[900],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }; 
    
    const Group = ({label, valor, md}) => {
        return(
            <Grid item md={md}>
                <Typography variant="h5" color="textSecondary">
                    {label}
                </Typography>
                <Typography variant="h4">{valor}</Typography>
            </Grid>
        )
    }

    
      
    const handleInputChange = (event) => {
        if(!event.target){
            const { value } = event;
            const name = "costo";
            setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        }else{
            const { name, value } = event.target;
            setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
            // setFormData({...updatedValues, [name]:value}); // para que se actualice el formulario
        }
    };
    return(
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper  sx={style}>
                <Typography id="modal-modal-title" variant="h3"> Detalles del articulo </Typography>
                <br/>
                <Formik
                    initialValues={formData}
                    validationSchema={yupObject}
                    onSubmit={(values, { setSubmitting }) => {
                        handleSubmit(values)
                    }}
                    enableReinitialize={true}
                >
                {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                        <Form encType="multipart/form-data">
                            <Grid paddingBottom={"0.1rem"} container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item md={12} xs={12}>
                                    <MySelect
                                        label="Proveedor"
                                        name="rel_proveedor"
                                        onChange={handleInputChange}
                                        value={formData.rel_proveedor || ''}
                                    >
                                    {
                                        proveedores.map((op) => (
                                            <MenuItem value={op.id} key={op.id}>{op.proveedor}</MenuItem>
                                        ))
                                    }
                                    </MySelect>

                                </Grid>
                                <Grid item md={6} xs={6}>

                                    <MySelect
                                        label="Moneda"
                                        name="rel_moneda"
                                        onChange={handleInputChange}
                                        value={formData.rel_moneda || ''}
                                    >
                                    {
                                        monedas.map((op) => (
                                            <MenuItem value={op.value} key={op.value}>{op.text}</MenuItem>
                                        ))
                                    }
                                    </MySelect>

                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <MyMoneyInput
                                        label="Costo"
                                        name="costo"
                                        placeholder="Ingrese costo"
                                        onValueChange={handleInputChange}
                                    />
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <TextInput
                                        label="Comentarios (opcional)"
                                        name="comentarios"
                                        multiline
                                        rows={2}
                                        placeholder="Ingrese comentarios (opcional)"
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10, marginLeft: 'auto' }}>
                                <Button size='large' onClick={onClose} variant="contained" sx={{color: "white",marginRight: '1rem', borderRadius: '10px', fontSize: '1rem'}} color="error" startIcon={<CancelIcon />}>
                                    <strong> Cerrar </strong>
                                </Button>
                                <Button type="submit" size='large' variant="contained" sx={{color: "white", borderRadius: '10px', fontSize: '1rem'}} color="success" startIcon={<SaveIcon />}>
                                    <strong> Guardar </strong>
                                </Button>
                            </Box>
                        </Form>
                    
                )}
                </Formik>  
            </Paper>
        </Modal>
    )
} 