import { Button, Modal, Paper, Typography, Container, Grid } from '@mui/material'
import { Formik, Form } from 'formik'
import useMediaQuery from "@mui/material/useMediaQuery";
import React from 'react';
import { styled } from '@mui/system'
import TextInput from '../../../../../components/inputs/TextInput';
import MyTextArea from '../../../../../components/inputs/MyTextArea';
import MyInputFile from '../../../../../components/inputs/MyInputFile';
import SaveIcon from '@mui/icons-material/Save';
import * as Yup from 'yup';
import { useTheme } from "@mui/material";
import { tokens } from '../../../../../theme';
import { sendFormData } from '../../../../../config/axios';
import Swal from 'sweetalert2';

const CrearComentario = ({open, onClose, initialValues, refresh}) => {

    // ===============================================
    // VALIDACIONES PARA EL FORMULARIO
    // ===============================================

    const yupValidation = Yup.object().shape({

        asunto: Yup.string().required('Requerido'),
        comentario: Yup.string().required('Requerido')

    })

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // VALIDAR SI ES MOVIL
    // ===============================================

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // ===============================================
    // BODY DEL MODAL
    // ===============================================

    const ScrollableContainer = styled(Container)({
        maxHeight: '60vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',
    });

    // ===============================================
    // SUBMIT DEL MODAL
    // ===============================================

    const handleSubmit = async (values) => {

        const url = 'create/empleado/comentario';

        const result = await sendFormData(url, values);

        if(result.success === true){

            onClose();

            refresh();

            Swal.fire({
                icon: 'success',
                title: 'Se ha creado el comentario!',
            })

        }

    }

    return (

        <Modal
            open={open}
            onClose={onClose}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={yupValidation}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values)
                }}
            >
                <Paper 
                    sx={{ 
                        width: isNonMobile ? `60%` : '90%', p: 3, bgcolor: colors.blueAccent[900] 
                    }}
                >
                    <Form encType="multipart/form-data">
                        <Typography variant="h3" align="center">
                            Crear Comentario
                        </Typography>
                        <ScrollableContainer>
                            <Grid container spacing={2} marginTop={"10px"} marginBottom={"10px"}>
                                <Grid item md={12} xs={12}>
                                    <TextInput
                                        label="Asunto"
                                        name="asunto"
                                        placeholder="Ingrese asunto del comentario"
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <MyTextArea
                                        label="Comentario"
                                        name="comentario"
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <MyInputFile
                                        name="evidencia"
                                        label="Evidencia"
                                        accept=".jpg, .jpeg, .png, .pdf"
                                    />
                                </Grid>    
                                <Grid item md={12} xs={12} style={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                                    <Button variant="contained" color="primary" onClick={onClose}>
                                        Cancelar
                                    </Button>
                                    <Button type="submit" variant="contained" sx={{color: "white"}} color="success" startIcon={<SaveIcon />}>
                                        Guardar
                                    </Button>
                                </Grid>           
                            </Grid>
                        </ScrollableContainer>
                    </Form>
                </Paper>
            </Formik>
        </Modal>

    )

}

export default CrearComentario