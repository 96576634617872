import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../../theme";
import { api } from "../../../../config/axios";
import * as Yup from "yup";
import { marcarURL } from "../../../../config/functions";

// ICONOS
import AddCircleIcon from "@mui/icons-material/AddCircle";

//COMPONENTES
import ModalGrupoArticulos from "../modals/ModalGrupoArticulos";

const Articulos = () => {
	// ===============================================
	// COLORES PARA ESTILOS
	// ===============================================

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	// ===============================================
	// SEGMENTO PARA BUSCAR SUCURSALES
	// ===============================================

	const [grupoArticulos, setGrupoArticulos] = useState([]);

	useEffect(() => {
		buscarGArticulos();

		marcarURL("grupoArticulos");
	}, []);

	const buscarGArticulos = async () => {
		const response = await api.get("get/catalogo_grupo_articulos");

		if (response.data.success === true &&response.data.result !== "Sin resultados"){
			setGrupoArticulos(response.data.result);
		} else {
			setGrupoArticulos([]);
		}
	};

	// ===============================================
	// COLUMNAS PARA LA TABLA
	// ===============================================

	const columns = [
		{ field: "id", headerName: "ID", flex: 0.5 },
		{
			field: "descripcion",
			headerName: "Descripcion del Grupo",
			flex: 2,
			cellClassName: "name-column--cell",
			headerAlign: "center",
			align: "center",
		},
	];

	// ===============================================
	// VALORES PARA SUBMIT DEL FORMULARIO
	// ===============================================

	const [initialFormValues, setInicialFormValues] = useState({
		descripcion: "",
		ids_articulos: "",
	});

	// ===============================================
	// FUNCION PARA ABRIR O CERRAR EL MODAL CREARA
	// ===============================================

	const [isOpen, setIsOpen] = useState(false);

	const handleOpenModal = () => {
		setInicialFormValues({
			descripcion: "",
			ids_articulos: "",
		});

		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
	};


	// ===============================================
	// VALIDACIONES PARA EL FORMULARIO
	// ===============================================

	const yupValidation = Yup.object().shape({
		descripcion: Yup.string().required("Requerido"),
	});

	// ===============================================
	// FUNCION PARA EDITAR REGISTRO
	// ===============================================

	 // FUNCION PARA EL EVENTO ROW CLICK
	 const handleRowClick = (params) => {
        setIsOpen(true);
        
        setInicialFormValues({
            id: params.row.id,
            descripcion: params.row.descripcion,
            ids_articulos: params.row.articulos,
        })
    }

	// ===============================================
	// ===============================================

	return (
		<Box m="20px">

			{/* BOTON PARA ABRIL MODAL */}

			<Button
				variant="contained"
				sx={{
					fontWeight: "bold",
					backgroundColor: colors.blueAccent[700],
				}}
				startIcon={<AddCircleIcon />}
				onClick={handleOpenModal}
			>
				Agregar Grupo de Articulos
			</Button>

			{/* TABLA  */}

			<Box
				m="40px 0 0 0"
				height="75vh"
				width="100%"
				sx={{
					"& .MuiDataGrid-root": {
						border: "none",
					},
					"& .MuiDataGrid-cell": {
						borderBottom: "none",
					},
					"& .name-column--cell": {
						color: colors.greenAccent[300],
					},
					"& .MuiDataGrid-columnHeaders": {
						backgroundColor: colors.blueAccent[700],
						borderBottom: "none",
					},
					"& .MuiDataGrid-virtualScroller": {
						backgroundColor: colors.primary[400],
					},
					"& .MuiDataGrid-footerContainer": {
						borderTop: "none",
						backgroundColor: colors.blueAccent[700],
					},
					"& .MuiCheckbox-root": {
						color: `${colors.greenAccent[200]} !important`,
					},
					"& .MuiDataGrid-toolbarContainer .MuiButton-text": {
						color: `${colors.grey[100]} !important`,
					},
				}}
			>
				<DataGrid
					rows={grupoArticulos}
					columns={columns}
					slotProps={{ toolbar: GridToolbar }}
					onRowClick={handleRowClick}
				/>
			</Box>
			
            {/* MODAL PARA FORMATOS */}
            <ModalGrupoArticulos
                 open={isOpen}
                 onClose={handleCloseModal}
                 data={initialFormValues}
                 yupObject={yupValidation}
                 refresh={buscarGArticulos}
            />
			
		</Box>
	);
};

export default Articulos;
