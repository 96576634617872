import { Box, SpeedDial, SpeedDialAction} from "@mui/material";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";

export default function GridView({ columns, data, CustomToolbar = GridToolbar,handleRowClick, selectedRowId,handleRowSelection, actions,hidden = false, ...props}) {
    const theme  = useTheme();
    const colors = tokens(theme.palette.mode);
    
    return(
        <Box
            m="20px 0 0 0"
            height="75vh"
            width="100%"
            flex="1"
            sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                    position: "relative",
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                },
            }}
        >
            <DataGrid
                rows={data}
                columns={columns}
                slots={{ toolbar: CustomToolbar }}
                slotProps={{
                    toolbar: {
                        csvOptions: { utf8WithBom: true },
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                hideFooterSelectedRowCount
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                onRowDoubleClick={handleRowClick}
                checkboxSelection={false}
                onRowSelectionModelChange={handleRowSelection}
                rowSelectionModel={selectedRowId}
                {... props}
            />
            <SpeedDial
                ariaLabel="SpeedDial"
                sx={{
                    position: "absolute",
                    bottom: 60,
                    right: 3,
                    color: "#2196f3",
                    "& .MuiSpeedDial-fab": {
                        backgroundColor: colors.blueAccent[500],
                    }
                }}
                direction="up"
                hidden={hidden}
                icon={<SpeedDialIcon sx={{ color: "#fff" }}  />}
            >
                {actions && actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.tooltipTitle}
                        onClick={action.onClick}
                    />
                ))}
            </SpeedDial>
        </Box>
    )
}