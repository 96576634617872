import { Box, Grid, Typography } from "@mui/material";
import { ChannelBox, ChannelLogo } from "planby";

export const UserItem = ({ channel }) => {
	const { position, logo, color } = channel;
	const formattedName = (
		channel.first_name.split(" ")[0] +
		" " +
		channel.last_name.split(" ")[0]
	).toUpperCase();
	const formattedGroupName = channel.group_name.toUpperCase();
	return (
		<ChannelBox {...position}>
			{/* Overwrite styles by add eg. style={{ maxHeight: 52, maxWidth: 52,... }} */}
			{/* Or stay with default styles */}
			{/* <ChannelLogo
                src={logo}
                alt="Logo"
                style={{ maxHeight: 52, maxWidth: 52 }}
            /> */}
			<Grid container justifyContent="center" alignItems="center">
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Box
						style={{
							borderLeft: `3px solid ${color}`,
							paddingLeft: "10px",
							width: "100%",
							fontWeight: "bold",
						}}
					>
						{formattedName}
					</Box>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', alignItems: 'center' }}>
					<div
						style={{
							height: "5px",
							width: "5px",
							backgroundColor: color,
							borderRadius: "50%",
							display: "inline-block",
						}}
					/>
					<Typography
						variant="body2"
						style={{
							color: "#fff",
							paddingLeft: "10px",
							textOverflow: "ellipsis",
							overflow: "hidden",
							whiteSpace: "nowrap",
						}}
						title={formattedGroupName}
					>
						{formattedGroupName}
					</Typography>
				</Grid>
			</Grid>
		</ChannelBox>
	);
};
