import React, { useState, useMemo, useEffect } from 'react'
import { Alert, Box, Button, Collapse, Grid, IconButton, Popover, TextField, Typography} from "@mui/material";
import { useDropzone } from "react-dropzone"
import * as XLSX from 'xlsx'
import Swal from 'sweetalert2';
import imgXls from '../../../assets/xlsx_icon.svg'
import SaveAltIcon from '@mui/icons-material/SaveAlt';
// ICONOS
import SaveIcon from '@mui/icons-material/Save';
import { api } from '../../../config/axios';
import Header from '../../../components/Header';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import * as FileSaver from 'file-saver';
import MySnackBar from '../../../components/feedback/MySnackBar';
import ModalConcentrado from './modals/ModalConcentrado';
import ModalArchivo from './modals/ModalArchivo';
import ModalFiltrosAvanzados from './modals/ModalFiltrosAvanzados';
import GridView from '../../../components/Gridview';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { ExpandLess, ExpandMore, FilterAlt, Repeat } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import dayjs from 'dayjs';

const ExcelJS = require('exceljs');

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#1f2a40',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#1f2a40',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};
  
  const acceptStyle = {
    borderColor: '#00e676'
};
  
  const rejectStyle = {
    borderColor: '#ff1744'
};

const Concentrado = () => {
    const theme  = useTheme();
    const colors = tokens(theme.palette.mode);
    // 
    useEffect(() => {
        loadData();
    }, []);
    const loadData = async () => {
        let params = {
			periodo: 1,
			fecha: 0,
			fechaInicio: dayjs().subtract(1, 'day').format('YYYY/MM/DD'),
			fechaFin: dayjs().format('YYYY/MM/DD'),
			flota: 1
		}
		const response = await api.get('get/concentrado' , {params: params});
        if(response.data.success === true && response.data.result !== "Sin resultados"){
            setData(response.data.result);
			// Swal.fire({
			// 	title: 'Exito!',
			// 	text: 'Se genero el concentrado correctamente',
			// 	icon: 'success',
			// 	confirmButtonText: 'Aceptar'
			// })
        }else{
            setData([]);
        }
    };
    const crearConcentrado = async () => {
        setOpenModal(true);
    };
    // COLUMNAS FACTURACION
    const [headersFacturacion, setHeadersFacturacion] = useState([
        'order_id',
        'nombre_cliente',
        'cuenta_facturacion',
        'num_referencia',
        'fecha_factura',
        'cantidad',
        'descripcion',
        'item',
        'tipo',
        'clase'
    ]);
    // COLUMNAS NOMINAS
    const [headersNominas, setHeadersNominas] = useState([
        'nombre_operador',
        'driver_code',
        'unidad',
        'concepto',
        'cantidad',
        'order_id',
        'order_alterna',
        'fecha_terminacion_viaje',
        'flota',
        'fecha_pago'
    ]);
    // ===============================================
    // COMPONENTE PARA CARGAR ARCHIVO EXCEL
    // ===============================================
    const [datosJson, setDatosJson] = useState([]);
    const [data, setData] = useState([]);
    const [esFactura, setEsFactura] = useState(false);
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        acceptedFiles
    } = useDropzone({
        accept: {'application/vnd.ms-excel': [], 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []},
        maxFiles: 1,
        onDrop:(acceptedFiles) => {
            
            const reader = new FileReader();
    
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });

                 const jsonData = workbook.SheetNames.map(sheetName => {
                    const sheet = workbook.Sheets[sheetName];
                    return XLSX.utils.sheet_to_json(sheet);
                });

                setDatosJson(jsonData);
            };
            reader.readAsBinaryString(acceptedFiles[0]);
        },
        onFileDialogOpen: () => {setDatosJson([])},
    });
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);
    // ===============================================
    // PROPIEDADES PARA EL SNACKBAR
    // ===============================================
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
    };
    const [action, setAction] = useState('success');
    const [message, setMessage] = useState('Archivo guardado correctamente');
    useEffect(() => {
        // Comparar cada uno de las columnas para saber si es de facturacion o de nominas
        if(datosJson.length > 0){
            let columnas = Object.keys(datosJson[0][0]);
            let esFactura = columnas.every(item => headersFacturacion.includes(item));
            let esNomina  = columnas.every(item => headersNominas.includes(item));

            if(esFactura){
                setEsFactura(true);
                setAction('success');
                setMessage('Archivo de facturas cargado correctamente');
                setOpenSnackbar(true);
            }else if(esNomina){
                setEsFactura(false);
                let flotas = datosJson[0].map(item => item.flota);
                let flotasUnicas = flotas.filter((value, index, self) => self.indexOf(value) === index);
                if(flotasUnicas.length > 1){
                    setAction('error');
                    setMessage('El archivo tiene mas de una flota. Favor de revisar para continuar');
                    setOpenSnackbar(true);
                    setDatosJson([]);
                }else{
                    setAction('success');
                    setMessage('Archivo de nominas cargado correctamente');
                    setOpenSnackbar(true);
                }
            }else{
                setAction('error');
                setMessage('El archivo no tiene el formato correcto. Favor de revisar para continuar');
                setOpenSnackbar(true);
                setDatosJson([]);
                return;
            }
        }
    }, [datosJson]);
    const [openModal, setOpenModal] = useState(false);
    
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickFiltrosAv = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const [tipoArchivo, setTipoArchivo] = useState(1);
    const handleTipoComparacionChange = (event) => {
        setTipoArchivo(event.target.value);
    };
    const generarPlantilla = () => {
        // FACTURACION (1)
        if(tipoArchivo === 1){
            // / Crear un nuevo libro de trabajo
            let workbook = XLSX.utils.book_new();

            // Crear una nueva hoja de cálculo
            let worksheet = XLSX.utils.aoa_to_sheet([headersFacturacion]);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Facturas');
            // Escribir el libro de trabajo en un archivo
            XLSX.writeFile(workbook, 'Plantilla_facturacion.xlsx');
        }else{ // NOMINAS (2)
            // / Crear un nuevo libro de trabajo
            let workbook = XLSX.utils.book_new();

            // Crear una nueva hoja de cálculo
            let worksheet = XLSX.utils.aoa_to_sheet([headersNominas]);
            // Agregar la hoja de cálculo al libro de trabajo
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Nominas');
            // Agregar la hoja de cálculo al libro de trabajo
            // Escribir el libro de trabajo en un archivo
            XLSX.writeFile(workbook, 'Plantilla_nominas.xlsx');
        }
    }
	const guardarArchivo = async () => {
		if(datosJson.length === 0){
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Debe de cargar el archivo para guardar informacion',
			})
			return;
		}  
        // Formateo correcto de las fechas
        if(esFactura){
            datosJson[0].forEach(item => {
                let fecha_excel = item.fecha_factura;
                if (typeof fecha_excel === 'number'){
                    const fechaSerial = fecha_excel;
                    const fechaBase = new Date(1900, 0, 1);
                    const fecha = new Date(fechaBase.getTime() + (fechaSerial - 2) * 24 * 60 * 60 * 1000);
                    fecha_excel = format(fecha, 'MM/dd/yyyy');
                    item.fecha_factura = fecha_excel;
                }
            });
        }else{
            datosJson[0].forEach(item => {
                let fecha_excel  = item.fecha_terminacion_viaje;
                let fecha_excel2 = item.fecha_pago;
                if (typeof fecha_excel === 'number'){
                    const fechaSerial = fecha_excel;
                    const fechaBase = new Date(1900, 0, 1);
                    const fecha = new Date(fechaBase.getTime() + (fechaSerial - 2) * 24 * 60 * 60 * 1000);
                    fecha_excel = format(fecha, 'MM/dd/yyyy');
                    item.fecha_terminacion_viaje = fecha_excel;
                }
                if (typeof fecha_excel2 === 'number'){
                    const fechaSerial = fecha_excel2;
                    const fechaBase = new Date(1900, 0, 1);
                    const fecha = new Date(fechaBase.getTime() + (fechaSerial - 2) * 24 * 60 * 60 * 1000);
                    fecha_excel2 = format(fecha, 'MM/dd/yyyy');
                    item.fecha_pago = fecha_excel2;
                }
            });
        }
        // determina si es facturacion o nominas dependiendo de las columnas
        let url = esFactura ? 'create/concentrado/facturas' : 'create/concentrado/nominas';
        try {
            // let values = JSON.stringify(datosJson[0]);
            const result = await api.post(url, datosJson[0]); // 
			
            if(result.data.success === true){
                Swal.fire({
                    icon: result.data.icon,
                    title: result.data.text,
                })
            }

        } catch (error) {
            console.error("Upload failed:", error);
        }
	}
    // useEffect(() => {
    //     if(data !== null && data !== undefined){
    //         if('orders_facturadas' in data || 'orders_no_facturadas' in data){
    //             generarExcel();
    //         }
    //     }
    // }, [data]);
    const generarExcel = async () => {
        if(data === null || data === undefined || data.length === 0){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No hay datos para generar el archivo',
            })
            return;
        }
        const workbook         = new ExcelJS.Workbook();
        const ws_facturadas    = workbook.addWorksheet("Orders Facturadas");
        const ws_no_facturadas = workbook.addWorksheet("Orders No Facturadas");
        const ws_resumen       = workbook.addWorksheet("Resumen");
        const columnas_f       = Object.keys(data.orders_facturadas[0]); 
        const columnas_nf      = Object.keys(data.orders_no_facturadas[0]);
        const columnas_r       = ['TOTAL DE ORDENES', 'FACTURADAS', 'PENDIENTE DE FACTURAR'];
        // Facturadas
        ws_facturadas.columns = columnas_f.map(item => ({ header: item, key: item, width: 30 }));
        ws_facturadas.addRows(data.orders_facturadas);
        const diferenciaColumnNumber = ws_facturadas.columns.findIndex(column => column.key === 'DIFERENCIA') + 1;
        const total_facturadoColumnNumber = ws_facturadas.columns.findIndex(column => column.key === 'TOTAL_f') + 1;
        const total_nominasColumnNumber = ws_facturadas.columns.findIndex(column => column.key === 'TOTAL_n') + 1;
        ws_facturadas.eachRow(function(row, rowNumber) {
            row.eachCell(function(cell, colNumber) {
                if (colNumber === diferenciaColumnNumber) {
                    const diferencia = cell.value;
                    if (diferencia < 0) {
                        row.eachCell((cell) => {
                            cell.fill = {
                                type:'pattern',
                                pattern:'mediumGray',
                                fgColor:{argb:'FF0000'},
                            };
                        });
                    }
                }
                if(rowNumber === 1){
                    if(colNumber <= total_facturadoColumnNumber){ // Rango de las columnas de facturacion

                        cell.font = {bold: true};
                        cell.fill = {
                            type: "pattern",
                            pattern: "mediumGray",
                            fgColor: { argb: "FF66FF" },
                        };
                    }else if(colNumber <= total_nominasColumnNumber){ // Rango de las columnas de nominas
                        cell.font = {bold: true};
                        cell.fill = {
                            type: "pattern",
                            pattern: "mediumGray",
                            fgColor: { argb: "F4B084" },
                        };
                    }else if(colNumber === diferenciaColumnNumber){ // Rango de las columnas de diferencia
                        cell.font = {bold: true};
                        cell.fill = {
                            type: "pattern",
                            pattern: "mediumGray",
                            fgColor: { argb: "17F707" },
                        };
                    }
                }

                
            });
        });

        // No Facturadas
        ws_no_facturadas.columns = columnas_nf.map(item => ({ header: item, key: item, width: 30 }));
        ws_no_facturadas.addRows(data.orders_no_facturadas);

        ws_no_facturadas.eachRow(function(row, rowNumber) {
            row.eachCell(function(cell, colNumber) {
                if(rowNumber === 1){
                    cell.font = {bold: true};
                    cell.fill = {
                        type: "pattern",
                        pattern: "mediumGray",
                        fgColor: { argb: "FFFF00" },
                    };
                }
            });        
        });
        // Resumen
        ws_resumen.columns = columnas_r.map(item => ({ header: item, key: item, width: 30 }));
        const total_f   = data.orders_facturadas.length;
        const total_nf  = data.orders_no_facturadas.length;
        const total_o   = total_f + total_nf;
        ws_resumen.addRow({
            'TOTAL DE ORDENES':      total_o,
            'FACTURADAS':            total_f,
            'PENDIENTE DE FACTURAR': total_nf
        }); // Agrega una fila vacia
        ws_resumen.eachRow(function(row, rowNumber) {
            row.eachCell(function(cell, colNumber) {
                if(rowNumber === 1){
                    cell.font = {bold: true};
                    cell.fill = {
                        type: "pattern",
                        pattern: "mediumGray",
                        fgColor: { argb: "FFFF00" },
                    };
                }
            });        
        });

        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            FileSaver.saveAs(blob, 'archivo.xlsx');
        });    
    }
    const [selectedRowId, setSelectedRowId] = useState([]);
    const handleRowClick = (id) => {
        // setSelectedRowId(id);
    };
    const handleRowSelection = (id) => {
        // setSelectedRowId(id);
    };
  
    // const columns = [
    //     {
    //         field: "id",
    //         headerName: "#",
    //         flex: 1,
    //         headerAlign: "center",
    //         align: "center",
    //         // checkboxSelection: true, // Enable checkbox selection
    //     },
    //     {
    //         field: "ORDER_ID",
    //         headerName: "Order ID",
    //         flex: 2,
    //         cellClassName: "name-column--cell",
    //         headerAlign: "center",
    //         align: "center",
    //     },
    //     {
    //         field: "fecha",
    //         headerName: "Fecha",
    //         flex: 2,
    //         headerAlign: "center",
    //         align: "center",        
    //     },
    //     {
    //         field: "estatus",
    //         headerName: "Estatus",
    //         flex: 2,
    //         headerAlign: "center",
    //         align: "center",
    //     },
    //     {
    //         field: "tipo",
    //         headerName: "Tipo",
    //         flex: 2,
    //         headerAlign: "center",
    //         align: "center",
    //     },
    //     {
    //         field: "total",
    //         headerName: "Total",
    //         flex: 2,
    //         headerAlign: "center",
    //         align: "center",
    //     },
    //     {
    //         field: "diferencia",
    //         headerName: "Diferencia",
    //         flex: 2,
    //         headerAlign: "center",
    //         align: "center",
    //     },
    // ];
    const [openModalArchivo, setOpenModalArchivo] = useState(false);
    const handleOpenModalArchivo = () => {
        setOpenModalArchivo(true);
    }
    const ExportButton = () => {
        // const { classes } = useGridSlotComponentProps();
        const theme = useTheme();

        return (
            <Button 
                variant="text"
                color="inherit"
                size="small"
                startIcon={<SaveAltIcon />}
                style={{ color: theme.palette.text.primary }}
                onClick={generarExcel}
            >
                Exportar
            </Button>
        )
    }
    const [openFiltrosAvanzados, setOpenFiltrosAvanzados] = useState(false);
    const FiltrosAvanzadosButton = () => {
        // const { classes } = useGridSlotComponentProps();
        const theme = useTheme();

        return (
            <Button 
                variant="text"
                color="inherit"
                size="small"
                startIcon={<FilterAlt />}
                style={{ color: theme.palette.text.primary }}
                onClick={() => setOpenFiltrosAvanzados(true)}
            >
                Filtros Avanzados
            </Button>
        )
    };
    const [vistaActual, setVistaActual] = useState('Facturadas');
    const toggleVista = () => {
        if(vistaActual === 'Facturadas'){
            setVistaActual('No Facturadas');
        }else{
            setVistaActual('Facturadas');
        }
    }    
    const CambiarVistaButton = () => {
        // const { classes } = useGridSlotComponentProps();
        const theme = useTheme();

        return (
            <Button 
                variant="text"
                color="inherit"
                size="small"
                startIcon={<Repeat />}
                style={{ color: theme.palette.text.primary }}
                onClick={toggleVista}
            >
                {vistaActual}
            </Button>
        )
    };
    const GridToolBar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <FiltrosAvanzadosButton/>
                <CambiarVistaButton/>
                <ExportButton/>
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        )
    };
    const [columns, setColumns] = useState([{
        field: "ORDER_ID",
        flex: 2,
        headerAlign: "center",
        align: "center",
    }]);
    useEffect(() => {
        if(data === null || data === undefined || data.length === 0){
            return;
        }
        const dataset = vistaActual === 'Facturadas' ? data.orders_facturadas : data.orders_no_facturadas;
        const newColumns = Object.keys(dataset[0]).map((key) => ({
            field: key,
            headerName: key,
            flex: 2,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                let formattedValue = params.value;
                let color;
                let column = key.toLowerCase();
                // Si la clave contiene la palabra 'fecha', formatea como fecha
                if (column.includes('fecha') && params.value !== null && params.value !== undefined) {
                    formattedValue = format(new Date(params.value), 'dd/MM/yyyy', { locale: es });
                }
                // Si la clave es una de las columnas excluidas, devuelve el valor tal cual
                else if (['ORDER_ID', 'DRIVER CODE'].includes(key)) {
                    color = colors.greenAccent[300];
                    formattedValue = params.value;
                }
                // De lo contrario, formatea como moneda
                else {
                    formattedValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits:2, maximumFractionDigits: 2 }).format(params.value);
                }
        
                // Si la clave contiene la palabra 'total' o 'diferencia', cambia el color del texto
                
                if(column.includes('total_f')){
                    color = '#FF66FF';
                }
                if (column.includes('total_n')) {
                    color = '#F4B084';
                }
                if(column.includes('diferencia')){
                    color = params.value < 0 ? '#FF0000' : '#17F707';
                }
                return <div style={{ color: color, fontSize: '0.8rem' }}>{formattedValue}</div>;
            },
        }));
        setColumns(newColumns);
    }, [data, vistaActual]);

    // const generarConcentrado = async () => {
	// 	let params = {
	// 		periodo: periodo,
	// 		fecha: fecha,
	// 		fechaInicio: fechaInicio,
	// 		fechaFin: fechaFin,
	// 		flota: flota
	// 	}
	// 	const response = await api.get('get/concentrado' , {params: params});
    //     if(response.data.success === true && response.data.result !== "Sin resultados"){
    //         setData(response.data.result);
	// 		onClose();
	// 		Swal.fire({
	// 			title: 'Exito!',
	// 			text: 'Se genero el concentrado correctamente',
	// 			icon: 'success',
	// 			confirmButtonText: 'Aceptar'
	// 		})
    //     }else{
	// 		setDisplay('none');
	// 		Swal.fire({
	// 			title: 'Error!',
	// 			text: 'No se encontraron resultados para generar el concentrado',
	// 			icon: 'error',
	// 			confirmButtonText: 'Aceptar'
	// 		}).then((result) => {
	// 			if (result.isConfirmed) {
	// 				onClose();
	// 				setDisplay('block');

	// 			}
	// 		});		
    //         setData([]);
    //     }
	// };
    const [showInfo,setShowInfo] = useState(false);
    return (
        <Box m="20px">
            <Header title="Concentrado" subtitle="Concentrado EBT Suite" />
            <Grid container spacing={2}>
                <Grid item md={12} sx={12}>
                    <Button variant="contained" 
                        sx={{
                            fontWeight:"bold",
                            backgroundColor:colors.blueAccent[700],
                            ml: '10px',
                        }}
                        onClick={handleOpenModalArchivo}
                    >
                        Agregar archivo
                    </Button>
                    {data && data.orders_facturadas && data.orders_no_facturadas ?
                        <IconButton
                            onClick={() => setShowInfo(!showInfo)}
                            aria-label="expandir/contraer"
                        >
                        {showInfo ? <ExpandLess color='info' fontSize='large' /> : <ExpandMore color='info' fontSize='large' />}
                        </IconButton>
                        : null
                    }
                </Grid>
                {data && data.orders_facturadas && data.orders_no_facturadas ? 
                <Grid item md={4} xs={4}>
                    
                    <Collapse in={showInfo}>
                        <Alert
                            severity="success"
                            sx={{ mb: 1, fontSize: '1rem' }} // Aumenta el tamaño de la letra
                        >
                            Hay <strong>{data.orders_facturadas.length}</strong> ordenes facturadas
                        </Alert>
                        <Alert
                            severity="warning"
                            sx={{ mb: 1, fontSize: '1rem' }} // Aumenta el tamaño de la letra
                        >
                            Hay <strong>{data.orders_no_facturadas.length}</strong> ordenes pendientes
                        </Alert>
                        <Alert
                            severity="info"
                            sx={{ mb: 1, fontSize: '1rem' }} // Aumenta el tamaño de la letra
                        >
                            Un total de <strong>{data.orders_facturadas.length + data.orders_no_facturadas.length}</strong> ordenes
                        </Alert>
                    </Collapse>
                </Grid> : null}
                {/* <FormControl sx={{ m:'10px', width: '200px' }}>                
                    <InputLabel id="select-label">Tipo Archivo</InputLabel>
                    <Select
                        value={tipoArchivo} // Asegúrate de tener un estado para manejar el valor seleccionado
                        label="Tipo de comparacion"
                        onChange={handleTipoComparacionChange} // Asegúrate de tener una función para manejar el cambio de valor
                    >
                        <MenuItem value={1}>FACTURACION</MenuItem>
                        <MenuItem value={2}>NOMINAS</MenuItem>
                    </Select>
                </FormControl>
                <Button variant="contained" 
                    sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.blueAccent[700],
                        m:'15px',
                        height: '45px'
                    }}
                    onClick={() => generarPlantilla()}
                >
                    Generar Plantilla
                </Button>    
                <Button variant="contained" 
                    sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.greenAccent[700],
                        m:'15px',
                        height: '45px'
                    }}
                    onClick={() => crearConcentrado()}
                >
                    Crear concentrado 
                </Button> */}
                <Grid item xs={12} md={12} lg={12}>
                    <GridView 
                        columns={columns}
                        data={(data && vistaActual === 'Facturadas' ? data.orders_facturadas : data.orders_no_facturadas)|| []}
                        handleRowClick={handleRowClick}
                        selectedRowId={selectedRowId}
                        handleRowSelection={handleRowSelection}
                        CustomToolbar={GridToolBar}
                        hidden={true}
                        getRowId={(row) => row.ORDER_ID}
                    />
                </Grid>
			</Grid> 
            <MySnackBar open={openSnackbar} action={action} message={message} onClose={handleCloseSnackbar}/>
            <ModalConcentrado
                open={openModal}
                onOpen={()=>{setOpenModal(true)}}
                onClose={()=>{setOpenModal(false)}}
                setData={setData}
            />
            <ModalArchivo
                open={openModalArchivo}
                onOpen={()=>{setOpenModalArchivo(true)}}
                onClose={()=>{setOpenModalArchivo(false)}}
                setData={setData}
            />
            <ModalFiltrosAvanzados
                open={openFiltrosAvanzados}
                onOpen={()=>{setOpenFiltrosAvanzados(true)}}
                onClose={()=>{setOpenFiltrosAvanzados(false)}}
                setData={setData}

            />
        </Box>
    )
}

export default Concentrado