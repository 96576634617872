import React from 'react';
import { useField } from 'formik';
import { TextField } from "@mui/material";

const MyInputPassword = ({ label, ...props }) => {

    const [field, meta] = useField(props);

    return (
        <>
            <TextField
                sx={{ width: "100%" }}
                variant="outlined"
                className="password-input" // Puedes cambiar esta clase si es necesario
                autoComplete="off"
                label={label}
                type="password" // Cambiamos el tipo de entrada a "password"
                {...field}
                {...props}
                error={meta.touched && Boolean(meta.error)}
                helperText={meta.touched && meta.error}
            />
        </>
    );

}

export default MyInputPassword