import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Modal, Paper, Typography, useMediaQuery } from "@mui/material";
import { Formik, Form } from 'formik';
import MenuItem from '@mui/material/MenuItem';
import {api, sendFormData} from '../../../../config/axios';
import Swal from 'sweetalert2';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
// COMPONENTES
import TextInput from '../../../../components/inputs/TextInput';
import MySelect from '../../../../components/inputs/MySelect';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../../theme';
import MyNumberInput from '../../../../components/inputs/MyNumberInput';
import MyInputFile from '../../../../components/inputs/MyInputFile';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { CheckBox } from '@mui/icons-material';
import MyCheckBox from '../../../../components/inputs/MyCheckBox';
import { baseURL } from '../../../../config/url';
const ModalFormatos = ({open, data = {}, yupObject, onClose, refresh, disabled}) => {

    const theme  = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
	
    const [formData, setFormData] = useState({});
    useEffect(() => {
        window.scrollTo(0, 0);
        if(Object.keys(data).length !== 0){
            setFormData(data);
            // buscarEntidades(data.entity_type);
        }
    },[data]);
	useEffect(() => {
		buscarTiposFormatos();
		buscarDepartamentos();
	},[])
    // ===============================================
    // OPCIONES PARA SELECTS 
    // ===============================================
	const [tipos_formatos, setTiposFormatos] = useState([]);
    const buscarTiposFormatos = async () => {
		const response = await api.get('/get/tipo_formatos');
		if(response.data.success === true && response.data.result !== "Sin resultados"){
			setTiposFormatos(response.data.result);
		}else{
			setTiposFormatos([]);
		}
	}

	const [departamentos, setDepartamentos] = useState([]);
	const buscarDepartamentos = async () => {

        const response = await api.get('get/departamentos');

        if(response.data.success === true && response.data.result !== "Sin resultados"){

            setDepartamentos(response.data.result);

        }else{

            setDepartamentos([]);

        }

    }
    // ===============================================
    // SUBMIT DEL FORMULARIO
    // ===============================================

    const handleSubmit = async (values) => {
        // console.log(data);
        // Saca los ids de los departamentos
        values.ids_departamentos = values.ids_departamentos.map((dep) => dep.id);
        // Elimina los ids repetidos
        values.ids_departamentos = [...new Set(values.ids_departamentos)];
        // Convierte el array en string separado por comas
        values.ids_departamentos = values.ids_departamentos.join(',');
        const url  = values.id ? 'update/formato' : 'create/formato';
        let method = values.id ? 'put' : 'post';
        try {

            const result = await sendFormData(url, values, method); // 

            if(result.success === true){

                Swal.fire({
                    icon: 'success',
                    title: result.text,
                })
                onClose();
                refresh();
            }

        } catch (error) {

            console.error("Upload failed:", error);

        }

    }

    // ===============================================
    //  COMPONENTE TAGBOX
    // ===============================================

	const TagBox = ({ label, options ,...props }) => {
        const handleOnChange = (event, value) => {
            if (value.some(option => option.nombre_depa === 'TODOS')) {
                props.onChange(event, [{id:0, nombre_depa: 'TODOS' }]);
            } else {
                props.onChange(event, value);
            }
        };
		return (
            <Autocomplete
                id="multiple-limit-tags"
                multiple
                options={[{ nombre_depa: 'TODOS' }, ...options]}
                getOptionLabel={(option) => option.nombre_depa}
                defaultValue={data.ids_departamentos || []}
                onChange={handleOnChange}
                renderInput={(params) => (
                    <TextField {...params} label={label} placeholder={props.placeholder} />
                )}
        />
		)
	}
    const getFile = (ruta) => {
        if(!ruta) return;
		ruta = ruta.replaceAll(/\\/g, "/");
		const file = ruta.split("/")[2];

		const file2 = file.split("%");

		const finalFile = file2[0] + "%25" + file2[1];
        
        return `${baseURL}formatos/${finalFile}`;;
		// setSrc(`${baseURL}formatos/${finalFile}`);
	};
    return(
        <Modal
            open={open}
            onClose={onClose}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >
            <Formik
                initialValues={formData}
                validationSchema={yupObject}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values)
                }}
                enableReinitialize={true}
            >
            {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                <Paper sx={{ width: isNonMobile ? '50%' : '90%', p: 3, bgcolor: colors.blueAccent[900] }}>

                    <Form encType="multipart/form-data">
                    <Typography variant="h3" align="center" paddingBottom={'2rem'}>
                        Crear formato
                    </Typography>
                    <Grid container spacing={2} paddingBottom={"0.1rem"}>
						{/* NOMBRE */}

                        <Grid item md={6} xs={6}>

                            <TextInput
                                label="Nombre"
                                name="nombre"
                                placeholder="Ingrese nombre"
                            />
                        </Grid>
                        {/* TIPO */}

                        <Grid item md={6} xs={6}>

                            <MySelect
                                label="Tipo Formato"
                                name="rel_tipo_formato"
                                // value={tipo}
                                disabled={disabled}
                                onChange={(event) => {
                                    handleChange(event);
                                    // setTipo(event.target.value);
                                    // buscarEntidades(event.target.value);
                                }}
                            >
                            {
                                tipos_formatos.map((op) => (
                                    <MenuItem value={op.id} key={op.id}>{op.descripcion}</MenuItem>
                                ))
                            }
                            </MySelect>

                        </Grid>

                        {/* DESCRIPCION  */}

                        <Grid item md={12} xs={12}>
							<TextInput
                                label="Descripcion"
                                name="descripcion"
                                multiline
                                rows={2}
                                disabled={disabled}
                                placeholder="Ingrese una descripcion"
                            />
                        </Grid>

                        {/* DEPARTAMENTOS */}
                        
                        <Grid item md={12} xs={12}>
                            <TagBox
								label="Departamentos"
								options={departamentos}
								placeholder="Seleccione departamentos"
								onChange={(event, value) => {
									setFieldValue('ids_departamentos', value);
								}}
							/>
                        </Grid>
						 {/* APLICA GERENCIA */}
                        
						 <Grid item md={2} xs={12}>
						 	<MyCheckBox
								label="Aplica Gerencia"
								name="aplicaGerencia"
								children={'Aplica Gerencia'}
							/>
                        </Grid>
						{/* ARCHIVO */}
                        <Grid item md={12} xs={12}>
                            {formData.archivo 
                                ?   <Button variant="contained" color="primary" href={getFile(data.archivo)} target="_blank" rel="noopener noreferrer">Ver Archivo</Button>
                                :   <MyInputFile
                                        name="archivo"
                                        label="Subir Archivo"
                                    />
                            }
                            
                        </Grid>
                        
                        {/* SUBMIT */}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, marginLeft: 'auto' }}>
                            <Button variant='contained' color='error' sx={{color: "white", marginRight: '10px'}} onClick={onClose} startIcon={<CloseIcon />}>
                                Cancelar
                            </Button>
                            <Button type="submit" variant="contained" sx={{color: "white"}} color="success" startIcon={<SaveIcon />}>
                                Guardar
                            </Button>
                        </Box>
                    </Grid>
                    </Form>
                </Paper>
            )}
            </Formik>  
        </Modal>
    )
}

export default ModalFormatos;
