import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../../theme";
import { api } from "../../../../config/axios";
import * as Yup from "yup";
import { marcarURL } from "../../../../config/functions";

// ICONOS
import AddCircleIcon from "@mui/icons-material/AddCircle";

//COMPONENTES
import Header from "../../../../components/Header";
import ModalCreateGM from "../../../../components/modals/ModalCreateGM";
import ModalEditGM from "../../../../components/modals/ModalEditGM";
import Swal from "sweetalert2";

const Articulos = () => {
	// ===============================================
	// COLORES PARA ESTILOS
	// ===============================================

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	// ===============================================
	// SEGMENTO PARA BUSCAR SUCURSALES
	// ===============================================

	const [articulos, setArticulos] = useState([]);

	useEffect(() => {
		buscarArticulos();

		marcarURL("articulos");
	}, []);

	const buscarArticulos = async () => {
		const response = await api.get("get/catalogo_articulos");

		if (
			response.data.success === true &&
			response.data.result !== "Sin resultados"
		) {
			setArticulos(response.data.result);
		} else {
			setArticulos([]);
		}
	};

	// ===============================================
	// COLUMNAS PARA LA TABLA
	// ===============================================
	const handleDeleteAction = async (params) => {
		Swal.fire({
			title: "¿Estas seguro?",
			text: "No podras revertir esta accion!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Si, eliminar!",
			cancelButtonText: "No, cancelar!",
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
                api.delete(`/delete/articulo/${params.id}`).then((response) => {
                    if(response.data.success === true){
                        Swal.fire("Eliminada", "Tu peticion ha sido eliminada", "success");
                        buscarArticulos();
                    }
                    }).catch((error) => {
                        console.log("ocurrio un error: " +error);
                    })           
            } else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire("Cancelado", "El registro esta a salvo :)", "success");
			}
		})
	};
	const columns = [
		{ field: "id", headerName: "ID", flex: 0.5 },
		{
			field: "articulo",
			headerName: "Nombre del Articulo",
			flex: 2,
			cellClassName: "name-column--cell",
			headerAlign: "center",
			align: "center",
		},
		// {
		// 	field: "estado",
		// 	headerName: "Estado",
		// 	flex: 2,
		// 	headerAlign: "center",
		// 	align: "center",
		// 	renderCell: (params) => (
		// 		<Button
		// 			variant="contained"
		// 			color="warning"
		// 			sx={{ color: "white" }}
		// 			onClick={() => handleDeleteAction(params)}
		// 		>
		// 			<b>Eliminar</b>
		// 		</Button>
		// 	),
		// },
	];

	// ===============================================
	// VALORES PARA SUBMIT DEL FORMULARIO
	// ===============================================

	const [initialFormValues, setInicialFormValues] = useState({
		articulo: "",
	});

	// ===============================================
	// FUNCION PARA ABRIR O CERRAR EL MODAL CREARA
	// ===============================================

	const [isOpen, setIsOpen] = useState(false);

	const handleOpenModal = () => {
		setInicialFormValues({
			articulo: "",
		});

		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
	};

	// ===============================================
	// FUNCION PARA ABRIR O CERRAR EL MODAL EDITAR
	// ===============================================

	const [isOpenEdit, setIsOpenEdit] = useState(false);

	const handleOpenModalEdit = () => {
		setIsOpenEdit(true);
	};

	const handleCloseModalEdit = () => {
		setIsOpenEdit(false);
	};

	// ===============================================
	// INPUTS PARA ARMAR EL FORMULARIO
	// ===============================================

	const inputForms = [
		{
			componente: "input",
			label: "Articulo",
			name: "articulo",
			placeholder: "Ingrese Nombre del articulo",
			type: "text",
			md: 12,
			xs: 12,
		},
	];

	// ===============================================
	// VALIDACIONES PARA EL FORMULARIO
	// ===============================================

	const yupValidation = Yup.object().shape({
		articulo: Yup.string().required("Requerido"),
	});

	// ===============================================
	// FUNCION PARA EDITAR REGISTRO
	// ===============================================

	const handleRowClick = (params) => {
		setInicialFormValues(params.row);
		handleOpenModalEdit();
	};

	// ===============================================
	// ===============================================

	return (
		<Box m="20px">
			{/* BOTON PARA ABRIL MODAL */}

			<Button
				variant="contained"
				sx={{
					fontWeight: "bold",
					backgroundColor: colors.blueAccent[700],
				}}
				startIcon={<AddCircleIcon />}
				onClick={handleOpenModal}
			>
				Agregar Articulos
			</Button>

			{/* TABLA  */}

			<Box
				m="40px 0 0 0"
				height="75vh"
				width="100%"
				sx={{
					"& .MuiDataGrid-root": {
						border: "none",
					},
					"& .MuiDataGrid-cell": {
						borderBottom: "none",
					},
					"& .name-column--cell": {
						color: colors.greenAccent[300],
					},
					"& .MuiDataGrid-columnHeaders": {
						backgroundColor: colors.blueAccent[700],
						borderBottom: "none",
					},
					"& .MuiDataGrid-virtualScroller": {
						backgroundColor: colors.primary[400],
					},
					"& .MuiDataGrid-footerContainer": {
						borderTop: "none",
						backgroundColor: colors.blueAccent[700],
					},
					"& .MuiCheckbox-root": {
						color: `${colors.greenAccent[200]} !important`,
					},
					"& .MuiDataGrid-toolbarContainer .MuiButton-text": {
						color: `${colors.grey[100]} !important`,
					},
				}}
			>
				<DataGrid
					rows={articulos}
					columns={columns}
					slotProps={{ toolbar: GridToolbar }}
					onRowClick={handleRowClick}
				/>
			</Box>

			{/* MODAL PARA CREAR SUCURSAL */}

			<ModalCreateGM
				modalTitle="Crear Articulo"
				swalTitle="Se ha guardado el Articulo!"
				url="create/articulo"
				open={isOpen}
				onClose={handleCloseModal}
				size="40"
				initialValues={initialFormValues}
				inputForms={inputForms}
				yupValidation={yupValidation}
				refresh={buscarArticulos}
			/>

			{/* MODAL PARA EDITAR SUCURSAL */}

			<ModalEditGM
				modalTitle="Editar Articulo"
				swalTitle="Se ha editado el Articulo!"
				url="update/articulo"
				urlDelete="delete/articulo/"
				open={isOpenEdit}
				onClose={handleCloseModalEdit}
				openModal={handleOpenModalEdit}
				size="40"
				initialValues={initialFormValues}
				inputForms={inputForms}
				yupValidation={yupValidation}
				refresh={buscarArticulos}
			/>
		</Box>
	);
};

export default Articulos;
