import { Box, Button, Grid, Modal, Paper, Typography, Tooltip, IconButton, useMediaQuery } from "@mui/material";
import { tokens } from "../../../../theme";
// ICONOS
import CancelIcon from '@mui/icons-material/Cancel';
import TextInput from '../../../../components/inputs/TextInput';
import MySelect from '../../../../components/inputs/MySelect';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@emotion/react';
import Swal from 'sweetalert2';
import MyMoneyInput from '../../../../components/inputs/MyMoneyInput';
import SaveIcon from '@mui/icons-material/Save';
import Cookies from 'js-cookie';
import { Formik, Form, Field } from 'formik';
import { useState } from "react";
import { api } from "../../../../config/axios";
import { useEffect } from "react";
import MyDropzone from "../components/MyDropZone";

export default function ModalCompras (props) {
    const {open, onClose, validationSchema ,proveedores,handleSubmit} = props;
    const isNonMobile = useMediaQuery("(min-width:600px)");
    // const [formData, setFormData] = useState(data);
   
    const theme  = useTheme();
    const colors = tokens(theme.palette.mode);
    
    
    useEffect(() => {
        window.scrollTo(0, 0);
        
    },[]);
   
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 'auto',
        width: '500',
        bgcolor: colors.blueAccent[900],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }; 
    
    const Group = ({label, valor, md}) => {
        return(
            <Grid item md={md}>
                <Typography variant="h5" color="textSecondary">
                    {label}
                </Typography>
                <Typography variant="h4">{valor}</Typography>
            </Grid>
        )
    }

    
      
    // const handleInputChange = (event) => {
    //     if(!event.target){
    //         const { value } = event;
    //         const name = "costo";
    //         setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    //     }else{
    //         const { name, value } = event.target;
    //         setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    //         // setFormData({...updatedValues, [name]:value}); // para que se actualice el formulario
    //     }
    // };
    return(
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper  sx={style}>
                <Typography id="modal-modal-title" variant="h3"> Comprobantes </Typography>
                <br/>
					<Formik
						initialValues={{ 
							rel_proveedor: null,
							comprobante: null }} 
						validationSchema={validationSchema} // Add validation schema using Yup
						onSubmit={(values, { setSubmitting }) => {
							handleSubmit(values)
						}}
					>
					{({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
						<Form encType="multipart/form-data">
							<Grid paddingBottom={"0.1rem"} container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
								<Grid item md={12} xs={12}>
                                    {proveedores && 
                                        <MySelect
                                            label="Proveedor"
                                            name="rel_proveedor"
                                        >
                                        {
                                            proveedores.map((op) => (
                                                <MenuItem value={op.id} key={op.id}>{op.proveedor}</MenuItem>
                                            ))
                                        }
                                        </MySelect>
                                    }
								</Grid>
								<Grid item md={12} xs={12}>
									<Field
										name="comprobante"
										component={MyDropzone}
										label=""
									/>
								</Grid>
							</Grid>
							<Box sx={{ display: 'flex', justifyContent: 'center', marginLeft: 'auto' }}>
                                <Button size='small' onClick={onClose} variant="contained" sx={{ color: "white", marginRight: '1rem', borderRadius: '10px', fontSize: '1rem', width: '100%' }} color="error" startIcon={<CancelIcon />}>
                                    <strong> Cerrar </strong>
                                </Button>
                                <Button type="submit" size='small' variant="contained" sx={{ color: "white", borderRadius: '10px', fontSize: '1rem', width: '100%' }} color="success" startIcon={<SaveIcon />}>
                                    <strong> Guardar </strong>
                                </Button>
                            </Box>
						</Form>
					)}
					</Formik>
            </Paper>
        </Modal>
    )
} 