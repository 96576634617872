import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useEffect, useRef } from "react";
import { useField, useFormikContext } from "formik";

export default function MyAutoComplete({ label, search ,fetchOptions, ...props }) {
	const { keyExpr, data } = props;

	const [field, meta, helpers] = useField(props);
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const { setFieldValue } = useFormikContext();
	const prevSearchRef = useRef();

	useEffect(() => {
		prevSearchRef.current = search;
	}, []);
	useEffect(() => {
		const loadOptions = async () => {
			setOptions([]);
			setLoading(true);
			const options = await fetchOptions();
			setOptions(options);
			setLoading(false);
		};
		setFieldValue(field.name, ''); // Limpiar el valor del campo
		setOptions([]); // Limpiar las opciones
		if (search) {
			loadOptions();
		}
	}, [search]);

	return (
		<Autocomplete
			{...field}
			{...props}
			sx={{ width: '100%' }}
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			isOptionEqualToValue={(option, value) =>
				option && value && option[`${keyExpr}`] === value[`${keyExpr}`]
			}
			getOptionLabel={(option) => (option ? option[`${keyExpr}`] : "")}
			options={options}
			loading={loading}
			noOptionsText="Sin opciones" // Cambiar el texto cuando no hay opciones
			loadingText="Cargando..." // Cambiar el texto cuando se está cargando
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					error={meta.touched && !!meta.error} // Marcar el TextField como error si hay un error
  				    helperText={meta.touched && meta.error ? meta.error : ''} // Mostrar el mensaje de error
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{loading ? (
									<CircularProgress
										color="inherit"
										size={20}
									/>
								) : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			)}
		/>
	);
}
