import * as React from 'react';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import Header from '../../../components/Header';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../theme';
import { marcarURL } from '../../../config/functions';
import { useState, useEffect } from 'react';
import { api } from '../../../config/axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import SaveIcon from '@mui/icons-material/Save';
import Swal from 'sweetalert2';


export default function Permisos() {
	// ===============================================
	// ESTILOS
	// ===============================================
    const dataGridStyle = { height: 650, marginBottom: '20px' };
	//COLORES PARA ESTILOS
	const theme  = useTheme();
    const colors = tokens(theme.palette.mode);
	// ===============================================
	// VARIABLES DE ESTILOS
	// ===============================================
	const sx_grid = {
		"& .MuiDataGrid-root": {
			border: "none",
		},
		"& .MuiDataGrid-cell": {
			borderBottom: "none",
		},
		"& .name-column--cell": {
			color: colors.greenAccent[300],
		},
		"& .MuiDataGrid-columnHeaders": {
			backgroundColor: colors.blueAccent[700],
			borderBottom: "none",
		},
		"& .MuiDataGrid-virtualScroller": {
			backgroundColor: colors.primary[400],
		},
		"& .MuiDataGrid-footerContainer": {
			borderTop: "none",
			backgroundColor: colors.blueAccent[700],
			position: "relative",
		},
		"& .MuiCheckbox-root": {
			color: `${colors.greenAccent[200]} !important`,
		},
		"& .MuiDataGrid-toolbarContainer .MuiButton-text": {
			color: `${colors.grey[100]} !important`,
		},
	};
	// ===============================================
	// API REF
	// ===============================================
	// ===============================================
	// ESTADOS Y CONSTANTES
	// ===============================================
	const [perfiles, setPerfiles] = useState([]);
	const [modulos, setModulos] = useState([]);
	const [permisos, setPermisos] = useState([]);
	useEffect(() => {
		marcarURL('permisos');
		// Aqui se va a buscar los perfiles y contiene los modulos y permisos
		buscarPerfiles();
	}, []);
	const buscarPerfiles = async () => {
		// Aqui se va a buscar los perfiles y contiene los modulos y permisos
		const response = await api.get('get/perfiles');

        if(response.data.success === true && response.data.result !== "Sin resultados"){
            setPerfiles(response.data.result);
        }else{
            setPerfiles([]);
        }
	};
	// ===============================================
	// COLUMNAS
	// ===============================================
	const columns_perfiles = [
		{ 
            field: 'nombre_perfil', 
            headerName: 'Nombre de Perfil', 
            flex: 1,
            headerAlign: "center",
            align: "center", 
		},
        { 
            field: 'departamento', 
            headerName: 'Departamento', 
            flex: 1,
            headerAlign: "center",
            align: "center", 
        },
		{
			field: 'nombre_sucursal',
			headerName: 'Sucursal',
			flex: 1,
			headerAlign: "center",
			align: "center",
		}
	]
	
	// ===============================================
	// FUNCIONES
	// ===============================================
	const [selectedPerfil, setSelectedPerfil] = useState({});
	const getRowData = (id) => {
		const _id = id[0];
		const object = perfiles.find((row) => row.id === _id);
		setSelectedPerfil(object);
	};
	const [selectedPerfilId, setSelectedPerfilId] = useState([]);
	const handleRowSelection = (newSelection) => {
		setSelectedPerfilId(newSelection);
		getRowData(newSelection);
	};
	const [valueModulo, setValueModulo] = useState(null);
	useEffect(() => {
		setValueModulo(null);
		if(!selectedPerfil || !selectedPerfil.modulos) return;
		const modulos = JSON.parse(selectedPerfil.modulos);
		// return optionLabel;
		setModulos(modulos);
		// setPermisos(selectedPerfil.permisos);
	}, [selectedPerfil]);
	// ===============================================
	// JSX
	// ===============================================
	const PermisosCheck = ({onChange, permisosData}) => {
		const [permisos, setPermisos] = useState({
			...permisosData
		});
		const allPermisos = Object.keys(permisos);
		const somePermisos = allPermisos.some(permiso => {
			if(['id', 'modulo','rel_perfil'].includes(permiso)) return false;
			return permisos[permiso];
		} );
		const allChecked = allPermisos.every(permiso => permisos[permiso]);

		const handlePermissionChange = (event, permission) => {
			setPermisos({ ...permisos, [permission]: event.target.checked });
		};
		const handleTodos = (event, permission) => {
			setPermisos({ ...permisos, [permission]: event.target.checked });
		};
		useEffect(() => {
			onChange(null, permisos);
		}, [permisos]);
		const children = (
			<Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
				<FormControlLabel
					label="Crear"
					control={
						<Checkbox
							checked={permisos.create}
							onChange={(e) => handlePermissionChange(e, 'create')}
						/>
					}
				/>
				<FormControlLabel
					label="Leer"
					control={
						<Checkbox
							checked={permisos.read}
							onChange={(e) => handlePermissionChange(e, 'read')}
						/>
					}
				/>
				<FormControlLabel
					label="Editar"
					control={
						<Checkbox
							checked={permisos.update}
							onChange={(e) => handlePermissionChange(e, 'update')}
						/>
					}
				/>
				<FormControlLabel
					label="Eliminar"
					control={
						<Checkbox
							checked={permisos.delete}
							onChange={(e) => handlePermissionChange(e, 'delete')}
						/>
					}
				/>
			</Box>
		);
		return (
			<div>
				<FormControlLabel
					label="Acceso total"
					control={
						<Checkbox
							indeterminate={somePermisos && !allChecked}
							checked={allChecked}
							onChange={(e) => {
								let newPermisos = allPermisos.reduce((acc, permiso) => {
									if(['id', 'modulo','rel_perfil'].includes(permiso)) return acc;
									acc[permiso] = e.target.checked;
									return acc;
								}, {});
								
								setPermisos({ ...permisos, ...newPermisos });
							}}
						/>
					}
				/>
				{children}
			</div>
		);
	};
	const handleSubmit = async (values) => {
		let data = {};

		values.modulo = values.modulo.replace(' ', '_');
		
		data.rel_perfil = selectedPerfil.id;
		data.modulo = values.modulo.toLowerCase();
		data.create = values.permisos.create ? 1: 0;
		data.read 	= values.permisos.read ? 1: 0;
		data.update = values.permisos.update ? 1: 0;
		data.delete = values.permisos.delete ? 1: 0;
		data.id_permiso = values.permisos.id ? values.permisos.id : false;
		const response = await api.post('create/permiso', data);
		if(response.data.success === true){
			Swal.fire({
				icon: 'success',
				title: response.data.text,
			})
			setPermisos([]);
			setValueModulo(null);
			setSelectedPerfil({});
			setSelectedPerfilId([]);
			buscarPerfiles();
			// apiRefGrid.current.deselectAll();
		}
	};

	// ===============================================
	// COMPONENTE TAG BOX
	// ===============================================
	// const TagBox = ({ label, options ,...props }) => {
	// 	return (
	// 		<Autocomplete
	// 			id="multiple-limit-tags"
	// 			multiple
	// 			options={options}
	// 			getOptionLabel={(option) => option.split('.').pop()}
	// 			onChange={props.onChange}
	// 			renderInput={(params) => (
	// 				<TextField {...params} label={label} placeholder={props.placeholder} />
	// 			)}
	// 		/>
	// 	)
	// }
	// ===============================================
	// BUSCAR PERMISOS EN BASE A MODULO Y PERFIL
	// ===============================================
	const buscarPermisos = async (modulo, perfil) => {
		let moduloAct = modulo.replace(' ', '_');
		const response = await api.get(`get/permisos`, {params: {moduloAct, perfil}});
		if(response.data.success === true && response.data.result !== "Sin resultados"){
			let data = response.data.result[0];
			data.create = data.create === 1 ? true : false;
			data.read = data.read === 1 ? true : false;
			data.update = data.update === 1 ? true : false;
			data.delete = data.delete === 1 ? true : false;
			setPermisos(data);
		}else{
			setPermisos([]);
		}
	}
	// ===============================================
	// YUP VALIDATION
	// ===============================================
	const yupObject = Yup.object({
		modulo: Yup.string().required('Este campo es requerido'),
		permisos: Yup.object({
			create: Yup.boolean(),
			read: Yup.boolean(),
			update: Yup.boolean(),
			delete: Yup.boolean(),
		}).required('Este campo es requerido'),
	});
    return (
        <Box m='20px' sx={{ width: '100%' }}>
			<Header title='Permisos' subtitle='Permisos EBTSuite' />
			<Grid container spacing={2}>
				<Grid item xs={12} md={5} lg={5}>
					<Box sx={sx_grid} style={dataGridStyle}>
						<Typography variant='h4' gutterBottom component='div'>SELECCIONA UN PERFIL</Typography>
						{/* PERFILES */}
						<DataGrid
							rows={perfiles}
							columns={columns_perfiles}
							pageSize={5}
							disableSelectionOnClick
							onRowSelectionModelChange={handleRowSelection}
							rowSelectionModel={selectedPerfilId} 
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={6} lg={6}>
					<Box sx={sx_grid} style={{display: selectedPerfilId.length > 0 ? 'block' : 'none'}}>
						<Typography variant='h4' gutterBottom component='div'>SELECCIONA UN MODULO</Typography>
						<Formik
							initialValues={{
								modulo: '',
								permisos: {
									create: false,
									read: false,
									update: false,
									delete: false,
								}
							}}
							validationSchema={yupObject}
							onSubmit={(values, { setSubmitting }) => {
								handleSubmit(values)
							}}
							enableReinitialize={true}
						>
						{({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => {
							const handleAutocompleteChange = (event, value) => {
								setFieldValue('modulo', value ? value.split('.').pop().toLowerCase() : null);
								// buscar permiso en base a modulo y perfil
								if(value){
									setValueModulo(value);
									buscarPermisos(value.split('.').pop().toLowerCase(), selectedPerfil.id);
								}else{
									setValueModulo(null);
									setPermisos([]);
								}
								
							};
							
							const handlePermisosChange = (event, value) => {
								setFieldValue('permisos', value);
							};
							return(
								<Paper sx={{ p: 3, bgcolor: colors.blueAccent[900] }}>
									<Form encType="multipart/form-data">
										<Grid container spacing={2} paddingBottom={"0.1rem"}>

										<Grid item md={12} xs={12}>
											<Autocomplete
												id="multiple-limit-tags"
												name="modulo"
												options={modulos || []}
												value={valueModulo}
												getOptionLabel={(option) => option.split('.').pop()}
												renderInput={(params) => (
													<TextField {...params} label={'Modulos'} placeholder={'Seleccione un modulo'} />
												)}
												onChange={handleAutocompleteChange}
											/>
										</Grid>

											{/* PERMISOS CHECKS */}
											<Grid item md={12} xs={12} style={{display: valueModulo ? 'block' : 'none'}}>
												<PermisosCheck 
													onChange={handlePermisosChange}
													permisosData={permisos}
												/>
												
											</Grid>
											
											<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, marginLeft: 'auto' }}>
												
												<Button type="submit" variant="contained" sx={{color: "white"}} color="success" startIcon={<SaveIcon />}>
													Guardar
												</Button>
											</Box>
										</Grid>
									</Form>
								</Paper>
							)
						}}
						</Formik>
					</Box>
				</Grid>
			</Grid>
		</Box>
    );
}
