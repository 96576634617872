import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Modal, Paper, Typography, useMediaQuery } from "@mui/material";
import { Formik, Form } from 'formik';
import MenuItem from '@mui/material/MenuItem';
import {api} from '../../../../config/axios';
import Swal from 'sweetalert2';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
// COMPONENTES
import TextInput from '../../../../components/inputs/TextInput';
import MySelect from '../../../../components/inputs/MySelect';
import { useTheme } from '@emotion/react';
import { tokens } from '../../../../theme';
import MyNumberInput from '../../../../components/inputs/MyNumberInput';

const ModalPeticion = ({open, data = {}, yupObject, onClose, refresh, disabled}) => {

    const theme  = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const [formData, setFormData] = useState({});
    useEffect(() => {
        window.scrollTo(0, 0);
        if(Object.keys(data).length !== 0){
            setFormData(data);
            // setTipo(data.entity_type);
            buscarEntidades(data.entity_type);
        }
    },[data]);

    // ===============================================
    // OPCIONES PARA SELECT DE tipos
    // ===============================================
    // const [tipo, setTipo] = useState();

    const tipos = [
        {
            value   : 'grupoArticulos',
            text    : 'Grupo de articulos', 
        },
        {
            value   : 'articulos',
            text    : 'Articulos', 
        }

    ];
    
    const [entidades, setEntidades] = useState([]);    
    const buscarEntidades = (param) => {
        // La variable de tipo indica si va a buscar por el catalogo de articulos o grupo de articulos
        if(!param) return;
        api.get(`get/entidades/${param}`)
        .then(result => {
            if(result.data.success === true && result.data.success !== 'Sin resultados'){
                setEntidades(result.data.result);
            }else{
                setEntidades([]);
            }
        })
        .catch(error => {
            console.log(error)
        })

    }

    // ===============================================
    // SUBMIT DEL FORMULARIO
    // ===============================================

    const handleSubmit = async (values) => {

        const url = 'create/peticion';

        try {

            const result = await api.post(url, values); // 

            if(result.data.success === true){

                Swal.fire({
                    icon: 'success',
                    title: result.data.text,
                })
                onClose();
                refresh();
            }

        } catch (error) {

            console.error("Upload failed:", error);

        }

    }

    // ===============================================
    
    useEffect(() => { 
        buscarEntidades(data.entity_type);
    },[data.entity_type])

    // ===============================================

    
    return(
        <Modal
            open={open}
            onClose={onClose}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >
            <Formik
                initialValues={formData}
                validationSchema={yupObject}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values)
                }}
                enableReinitialize={true}
            >
            {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                <Paper sx={{ width: isNonMobile ? '50%' : '90%', p: 3, bgcolor: colors.blueAccent[900] }}>

                    <Form encType="multipart/form-data">
                    <Typography variant="h3" align="center" paddingBottom={'2rem'}>
                        Realizar Peticion
                    </Typography>
                    <Grid container spacing={2} paddingBottom={"0.1rem"}>

                        {/* TIPO */}

                        <Grid item md={6} xs={6}>

                            <MySelect
                                label="Tipo"
                                name="entity_type"
                                // value={tipo}
                                disabled={disabled}
                                onChange={(event) => {
                                    handleChange(event);
                                    // setTipo(event.target.value);
                                    buscarEntidades(event.target.value);
                                }}
                            >
                            {
                                tipos.map((op) => (
                                    <MenuItem value={op.value} key={op.value}>{op.text}</MenuItem>
                                ))
                            }
                            </MySelect>

                        </Grid>

                        {/* DESCRIPCION  */}

                        <Grid item md={6} xs={6}>

                            <MySelect
                                label="Descripcion"
                                name="entity_id"
                                disabled={disabled}
                                // onChange={(event) => {
                                //     handleChange(event);
                                // }}
                            >
                            {
                                entidades.map((op) => (
                                    <MenuItem value={op.entity_id} key={op.entity_id}>{op.entity_name}</MenuItem>
                                ))
                            }
                            </MySelect>

                        </Grid>

                        {/* CANTIDAD  */}

                        <Grid item md={3} xs={12}>

                            <MyNumberInput
                                label="Cantidad"
                                name="cantidad"
                                placeholder="Ingrese cantidad"
                                disabled={disabled}
                            />

                        </Grid>

                        {/* JUSTIFICACION */}
                        
                        <Grid item md={12} xs={12}>

                            <TextInput
                                label="Justificacion"
                                name="justificacion"
                                multiline
                                rows={4}
                                disabled={disabled}
                                placeholder="Ingrese justificacion"
                            />
                        </Grid>
                        {/* SUBMIT */}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, marginLeft: 'auto' }}>
                            <Button variant='contained' color='error' sx={{color: "white", marginRight: '10px'}} onClick={onClose} startIcon={<CloseIcon />}>
                                Cancelar
                            </Button>
                            <Button type="submit" variant="contained" sx={{color: "white"}} color="success" startIcon={<SaveIcon />}>
                                Guardar
                            </Button>
                        </Box>
                    </Grid>
                    </Form>
                </Paper>
            )}
            </Formik>  
        </Modal>
    )
}

export default ModalPeticion;
