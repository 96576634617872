import { useTheme } from '@emotion/react';
import { Box, Grid, Typography, useMediaQuery, Paper, Button} from '@mui/material';
import { tokens } from '../../../../theme';
import { useCallback, useEffect, useState } from 'react';
import { api } from '../../../../config/axios';
import CustomTable from '../components/TableCustom';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';


export default function StepResumen(props){
    const theme  = useTheme();
    const colors = tokens(theme.palette.mode);
    // const isNonMobile = useMediaQuery("(min-width:600px)");
    const navigate = useNavigate();
    
    const [cotizaciones, setCotizaciones] = useState([]);
    // const [tcActual, setTcActual] = useState(0.00);
    const [empleadoRequiere, setEmpleadoRequiere] = useState('');
    const [empleadoCotiza, setEmpleadoCotiza] = useState('');
    const [articulos, setArticulos] = useState([]);
    const [dataRow, setDataRow] = useState([]);
    const [estatus, setEstatus] = useState('');
    const buscarCotizacionesCallback = useCallback(() => {
        buscarCotizaciones(props.id);
    },[props.id]);
    const buscarCotizaciones = async (id) => {
        if(id === undefined) return;
        const response = await api.get('get/cotizaciones', {params: {id: id, isResumen: true}});
        if(response.data.success === true && response.data.result !== "Sin resultados"){
            setCotizaciones(response.data.result);
            setEmpleadoCotiza(response.data.result[0].nombreEmpRequiere);
            setEmpleadoRequiere(response.data.result[0].nombreEmpRequiere);
            setEstatus(response.data.result[0].estatus)
        }else{
            setCotizaciones([]);
            setEmpleadoCotiza('');
            setEmpleadoRequiere('');
            setEstatus(props.estatus)
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        // buscarTCActual();
        buscarCotizaciones(props.id);
        setArticulos(props.articulos);
        setDataRow(props.dataRow);
    },[props.id, props.estatus, buscarCotizacionesCallback, props.refresh]);
    
    // const buscarTCActual = async () => {
    //     const response = await api.get('get/tipoCambioActual');
    //     if(response.data.success === true){
    //         setTcActual(response.data.result[0].tipo_cambio);
    //     }else{
    //         setTcActual(0.00);
    //     }
    // }
    const style = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {flexDirection: 'row',},
        bgcolor: colors.blueAccent[900],
        boxShadow: 24,
        p: 4,
    }; 
    const Group = ({label, valor, md, title = false}) => {
        return(
            <Grid item md={md}>
                {title ? 
                    <Typography id="modal-modal-title" variant="h2">{label}</Typography>
                 :
                 <div>
                     <Typography variant="h5" color="textSecondary">
                        {label}
                     </Typography>
                    <Typography variant="h4">{valor}</Typography>
                 </div> 
                }
            </Grid>
        )
    };
    const columns = [
        { id: 'proveedor',  label: 'Proveedor', minWidth: 170 },
        { id: 'articulo',   label: 'Articulo',  minWidth: 100 },
        { id: 'cantidad',   label: 'Cantidad',  minWidth: 100 },
        { id: 'moneda',     label: 'Moneda',    minWidth: 100 },
        { id: 'costo',      label: 'Costo',     currency:true ,minWidth: 100 },
    ]
    const columnsProductos = [
        { id: 'id',        label: 'ID',        minWidth: 170 },
        { id: 'articulo',   label: 'Articulo',  minWidth: 100 },
    ]
    const columnsDataRow = [
        { id: 'descripcion',    label: 'Articulo',  minWidth: 100 },
        { id: 'cantidad',       label: 'Cantidad',  minWidth: 100 },
        { id: 'justificacion',  label: 'Justificacion',  minWidth: 100 }
    ]
    
    const actualizarEstatus = async () => {
        if(props.estatus === 'RECHAZADO' || props.estatus === 'ASIGNADO'){
            Swal.fire({
                icon:  'success',
                title: 'Exito',
                text:  `Se ha finalizado la operacion con estatus ${props.estatus}`,
                showConfirmButton: false,
                timer: 2500
            });
            navigate('/requisiciones');
            return;
        }
        const response = await api.put('update/requerimiento/estatus', {id: props.id, estatus: 8});
        if(response.data.success === true){
            Swal.fire({
                icon: 'success',
                title: 'Exito',
                text: `Se ha finalizado la operacion con estatus ${props.estatus}`,
                showConfirmButton: false,
                timer: 2500
            });
            navigate('/requisiciones');
        };
    };
    return(
        <Box>
        <Paper sx={style}>
            <Grid container spacing={2}>
                <br/>
                <Group label='Resumen' valor={''} md={12} title={true}/>
                <Group label='Nombre del solicitante' valor={empleadoRequiere && empleadoRequiere ? empleadoRequiere : dataRow && dataRow.length !== 0 && dataRow[0].nombreCompletoUsuario ? dataRow[0].nombreCompletoUsuario : 'test'} md={4}/>
                {cotizaciones && cotizaciones.length !== 0 ? (
                    <Group label='Nombre del cotizante' valor={empleadoCotiza} md={4}/>
                ):null}
                <Group label='Estatus' valor={estatus} md={4}/>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    {cotizaciones && cotizaciones.length !== 0 ? (
                        <CustomTable rows={cotizaciones} columns={columns}/>
                    ):articulos && articulos.length !== 0 ? (
                        <CustomTable rows={articulos} columns={columnsProductos}/>
                    ): (
                        <CustomTable rows={dataRow} columns={columnsDataRow}/>
                    )}
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, marginLeft: 'auto' }}>
                    <Button variant="contained" sx={{color: "white", borderRadius: '10px', fontSize: '1rem'}} onClick={actualizarEstatus} color="success">
                        <strong>FINALIZAR</strong>  
                    </Button>
                </Box>
            </Grid>
        </Paper>
    
    </Box>
    )
}