import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Card,
	Grid,
	Modal,
	Paper,
	Typography,
} from "@mui/material";
import { Formik, Form } from "formik";
import { api } from "../../../../config/axios";
import Swal from "sweetalert2";

// ICONOS
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
// COMPONENTES
import { useTheme } from "@emotion/react";
import { tokens } from "../../../../theme";
import MyMoneyInput from "../../../../components/inputs/MyMoneyInput";
import { baseURL } from "../../../../config/url";
import TextInput from "../../../../components/inputs/TextInput";


// const MySelect = lazy(() => import('../../../../components/inputs/MySelect'));

const ModalGastosOperador = ({
	open,
	data = {},
	yupObject,
	onClose,
	refresh,
}) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	
	const [formData, setFormData] = useState({});
	useEffect(() => {
		window.scrollTo(0, 0);
		if (Object.keys(data).length !== 0) {
			setFormData(data);
			// buscarOrdenes();
		}
	}, [data]);
	
	
	// ===============================================
	// SUBMIT DEL FORMULARIO
	// ===============================================
	const handleSubmit = async (values) => {
		values.despacho = 1;
		const data = { 
			rel_gasto: values.id,
			comentario: values.comentario_despacho,
			monto: values.monto_aprobado,
			adeudo: parseFloat(values.monto) - parseFloat(values.monto_aprobado),
		};
		const url = "aprobar/gastos/operador";
		try {
			const result = await api.post(url, data); //

			if (result.data.success === true) {
				Swal.fire({
					icon: result.data.icon || "success",
					title: result.data.text,
				});
				// setValueOrden(null);
				onClose();
				refresh(values.rel_orden);
			}
		} catch (error) {
			console.error("Upload failed:", error);
		}
	};
	const getFile = (ruta) => {
		if (!ruta) return;
		ruta = ruta.replaceAll(/\\/g, "/");
		const file = ruta.split("/")[2];

		const file2 = file.split("%");

		const finalFile = file2[0] + "%25" + file2[1];

		return `${baseURL}viaticos/comprobantes/gastos/operador/${finalFile}`;
		// setSrc(`${baseURL}formatos/${finalFile}`);
	};
	const formatCurrency = (value) => {
		const formatter = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
		return formatter.format(value);
	};
	const Item = () => (
		<Card
			style={{
				backgroundColor: colors.blueAccent[700],
				padding: 16,
				margin: "0 0 8px 0",
				borderRadius: "10px",
				height: "auto",
				width: "auto",
			}}
		>
			<Box display="flex" style={{ height: "100%" }}>
				<Grid container spacing={1}>
					<Grid item md={4} xs={4}>
						<Typography variant="h5" align="center">
							<strong>Driver code</strong>
						</Typography>
					</Grid>
					<Grid item md={4} xs={4}>
						<Typography variant="h5" align="center">
							<strong>Tipo de gasto</strong>
						</Typography>
					</Grid>
					<Grid item md={4} xs={4}>
						<Typography variant="h5" align="center">
							<strong>Monto</strong>
						</Typography>
					</Grid>
					<Grid item md={4} xs={4}>
						<Typography variant="h6" align="center">
							{data.driverCode}
						</Typography>
					</Grid>
					<Grid item md={4} xs={4}>
						<Typography variant="h6" align="center">
							{data.tipo_gasto}
						</Typography>
					</Grid>
					<Grid item md={4} xs={4}>
						<Typography variant="h6" align="center">
							{formatCurrency(data.monto)}
						</Typography>
					</Grid>
				</Grid>
			</Box>
		</Card>
	);

	const Content = () => (
		<Box
			sx={{
				width: "40%",
				borderRadius: "10px",
			}}
			role="presentation"
			// onClick={toggleDrawer(anchor, false)}
			// onKeyDown={toggleDrawer(anchor, false)}
		>
			<Formik
				initialValues={formData}
				validationSchema={yupObject}
				onSubmit={(values, { setSubmitting }) => {
					handleSubmit(values);
				}}
				enableReinitialize={true}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					setFieldValue,
				}) => (
					<Paper
						sx={{
							width: "100%",
							p: 3,
							bgcolor: colors.blueAccent[900],
							borderRadius: "10px",
						}}
					>
						<Form encType="multipart/form-data">
							<Typography
								variant="h3"
								align="center"
								paddingBottom={"2rem"}
							>
								Comprobar gasto
							</Typography>
							<Grid
								container
								spacing={2}
								paddingBottom={"0.1rem"}
							>
								<Grid item md={12} xs={12}>
									<Card
										style={{
											backgroundColor: colors.blueAccent[700],
											padding: 16,
											margin: "0 0 4px 0",
											borderRadius: "10px",
											height: "auto",
											width: "auto",
										}}
									>
										<Box
											display="flex"
											style={{ height: "100%" }}
										>
											<Grid container>
												<Grid item md={12} xs={12}>
													<Typography variant="h4" align="center">
														Orden: <strong>{data.rel_orden}</strong>
													</Typography>
												</Grid>
											</Grid>
										</Box>
									</Card>
									
								</Grid>
								<Grid item md={12} xs={12}>
									<Item
										header={"Gasto"}
										bgColor={colors.blueAccent[800]}
									/>
								</Grid>
								{/* MONTO */}
								<Grid item md={12} xs={12}>
									<MyMoneyInput
										label={"Monto"}
										name={"monto_aprobado"}
										key={"monto"}
										placeholder={"Ingrese el monto aprobado"}
										inputProps={{ maxLength: 16 }}
									/>
								</Grid>
								{/* COMENTARIOS */}
								<Grid item md={12} xs={12}>
									<TextInput
                                        label="Comentarios"
                                        name="comentario_despacho"
                                        placeholder="Ingrese un comentario"
										multiline
										rows={2}
                                    />
								</Grid>
								<Grid
									item
									// md={6}
									// xs={6}

									md={4}
									xs={4}
								>
									<Button
										variant="contained"
										color="primary"
										sx={{
											color: "white",
											marginRight: "10px",
											width: "100%",
											borderRadius: "8px",
										}}
										onClick={onClose}
										startIcon={<CloseIcon />}
									>
										Cancelar
									</Button>
								</Grid>
								<Grid item md={4} xs={4}>
									<Button
										variant="contained"
										color="primary"
										href={getFile(formData.ruta)}
										target="_blank"
										startIcon={<VisibilityIcon />}
										rel="noopener noreferrer"
										sx={{
											color: "white",
											marginRight: "10px",
											width: "100%",
											borderRadius: "8px",
										}}
									>
										Comprobante
									</Button>
								</Grid>
								<Grid
									item
									md={4}
									xs={4}
									// md={data.id ? 4 : 6}
									// xs={data.id ? 4 : 6}
								>
									<Button
										type="submit"
										variant="contained"
										sx={{
											width: "100%",
											color: "white",
											borderRadius: "8px",
										}}
										color="success"
										startIcon={<SaveIcon />}
									>
										Guardar
									</Button>
								</Grid>
							</Grid>
						</Form>
					</Paper>
				)}
			</Formik>
		</Box>
	);
	return (
		<Modal
			open={open}
			onClose={onClose}
			BackdropProps={{ onClick: (event) => event.stopPropagation }}
			style={{
				display: 'flex',
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Content />
		</Modal>
	);
};

export default ModalGastosOperador;
