import React from 'react';
import { useField } from 'formik';
import { RadioGroup, FormControlLabel, Radio, FormControl, FormLabel } from "@mui/material";

const MyRadioButton = ({ label, options, ...props }) => {

    const [field, meta] = useField(props);

    return (

        <FormControl component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup
                {...field}
                {...props}
                row
                aria-label={label}
                error={meta.touched && Boolean(meta.error)}
            >
                {options.map((option, index) => (
                    <FormControlLabel
                        key={index}
                        value={option.value}
                        control={<Radio color="success" sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: 20,
                            },
                          }}/>}
                        label={option.label}
                    />
                ))}
            </RadioGroup>
            {meta.touched && meta.error && (
                <div style={{ color: 'red', fontSize: '0.6428571428571428rem' }}>{meta.error}</div>
            )}
        </FormControl>

    )

}

export default MyRadioButton