import { useTheme } from "@emotion/react";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Paper, Select, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { tokens } from "../../../../theme";
import { useEffect } from "react";
import MySelect from "../../../../components/inputs/MySelect";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// ICONOS
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import PublishIcon from '@mui/icons-material/Publish';

import dayjs from 'dayjs';
import { api } from "../../../../config/axios";
import Swal from "sweetalert2";
import { Done } from "@mui/icons-material";


const ModalFiltrosAvanzados = (props) => {
	const {open, onClose, onOpen, setData } = props;

	const theme  = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [display, setDisplay] = useState('flex');
    // const [periodos, setPeriodos] = useState([
	// 	{id: 1, descripcion: 'Diario'},
	// 	{id: 2, descripcion: 'Personalizado'},
	// ]);
	const [today, setToday] = useState(dayjs());
	const [yesterday, setYesterday] = useState(dayjs().subtract(1, 'day'));

	const [fechaInicio, setFechaInicio] = useState(dayjs().subtract(1, 'day').format('YYYY/MM/DD'));
	const [fechaFin, setFechaFin] = useState(dayjs().format('YYYY/MM/DD'));

	const [flota, setFlota] = useState(1);
	const handleChangeFlota = (event) => {
		setFlota(event.target.value);
	};
	const [fecha, setFecha] = useState(0);
	const handleChangeFecha = (event) => {
		setFecha(event.target.value);
	};

	const [showDatePicker, setShowDatePicker] = useState(false);

	
    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);
   
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 'auto',
        width: '900',
        bgcolor: colors.blueAccent[900],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }; 
	const [periodo, setPeriodo] = useState(1);
	const handleChangePeriodo = (event) => {
		setPeriodo(event.target.value);
		if(event.target.value === 1){ //diario
			setShowDatePicker(false);
		}else{// personalizado
			setShowDatePicker(true);
		}
	};
	const generarConcentrado = async () => {
		let params = {
			periodo: periodo,
			fecha: fecha,
			fechaInicio: fechaInicio,
			fechaFin: fechaFin,
			flota: flota
		}
		const response = await api.get('get/concentrado' , {params: params});
        if(response.data.success === true && response.data.result !== "Sin resultados"){
            setData(response.data.result);
			onClose();
			Swal.fire({
				title: 'Exito!',
				text: 'Se genero el concentrado correctamente',
				icon: 'success',
				confirmButtonText: 'Aceptar'
			})
        }else{
			setDisplay('none');
			Swal.fire({
				title: 'Error!',
				text: 'No se encontraron resultados para generar el concentrado',
				icon: 'error',
				confirmButtonText: 'Aceptar'
			}).then((result) => {
				if (result.isConfirmed) {
					onClose();
					setDisplay('flex');
				}
			}).finally(() => {
				setDisplay('flex');
				setData([]);
			});		
        }
	};
	return(
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
			style={{
				display: display,
				alignItems: "center",
				justifyContent: "center",
			}}
        >
            <Paper sx={{
				width: "40%",
				p: 3,
				bgcolor: colors.blueAccent[900],
				borderRadius: "10px",
			}}
			>
				<Typography id="modal-modal-title" variant="h3" align="center">Filtros avanzados</Typography>				
				<Grid container spacing={2} paddingBottom={"0.1rem"} sx={{mt:'10px'}}>
					
					{/* TIPO */}
					<Grid item md={12} xs={12} xl={12}>
						<FormControl sx={{ width: '100%' }}>                
							<InputLabel id="select-label">Periodo</InputLabel>
							<Select
								value={periodo} // Asegúrate de tener un estado para manejar el valor seleccionado
								label="Periodo"
								onChange={handleChangePeriodo} // Asegúrate de tener una función para manejar el cambio de valor
							>
								<MenuItem value={1}>DIARIO</MenuItem>
								<MenuItem value={2}>PERSONALIZADO</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item md={12} xs={12} xl={12}>
						<FormControl sx={{ width: '100%' }}>                
							<InputLabel id="select-label">Flota</InputLabel>
							<Select
								value={flota} // Asegúrate de tener un estado para manejar el valor seleccionado
								label="Flota"
								onChange={handleChangeFlota} // Asegúrate de tener una función para manejar el cambio de valor
							>	
								<MenuItem value={1}>TODAS</MenuItem>
								<MenuItem value={'azul'}>AZUL</MenuItem>
								<MenuItem value={'verde'}>VERDE</MenuItem>
								<MenuItem value={'roja'}>ROJA</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{showDatePicker && (
						<Grid item md={12} xs={12} xl={12}>
							<FormControl sx={{ width: '100%' }}>                
								<InputLabel id="select-label">Fecha</InputLabel>
								<Select
									value={fecha} // Asegúrate de tener un estado para manejar el valor seleccionado
									label="Fecha"
									onChange={handleChangeFecha} // Asegúrate de tener una función para manejar el cambio de valor
								>
									<MenuItem value={0}>CARGA</MenuItem>
									<MenuItem value={1}>NOMINA</MenuItem>
									<MenuItem value={2}>FACTURA</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}
					{showDatePicker && (
						<Grid item md={12} xs={6} xl={6}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									defaultValue={yesterday}
									sx={{width: '100%', backgroundColor: colors.greenAccent[700]}}
									views={['year', 'month', 'day']}
									renderInput={(props) => <TextField {...props} fullWidth label="Fecha inicial" />}
									onChange={(value) => setFechaInicio(dayjs(value).format('YYYY/MM/DD'))}
								/>
							</LocalizationProvider>
						</Grid>
					)}
					{showDatePicker && (
						<Grid item md={12} xs={6} xl={6}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									sx={{width: '100%', backgroundColor: colors.redAccent[700]}}
									defaultValue={today}
									views={['year', 'month', 'day']}
									renderInput={(props) => <TextField {...props} fullWidth label="Fecha final" />}
									onChange={(value) => setFechaFin(dayjs(value).format('YYYY/MM/DD'))}
								/>
							</LocalizationProvider>
						</Grid>
					)}	
					{/* SUBMIT */}
					<Grid item md={6} xs={6}>
						<Button variant='contained' sx={{color: "white",width: "100%",borderRadius: "8px" ,marginRight: '10px'}} onClick={onClose} startIcon={<CloseIcon />}>
							Cancelar
						</Button>
					</Grid>
					<Grid item md={6} xs={6}>
						<Button variant='contained' color='success' sx={{color: "white",width: "100%",borderRadius: "8px" ,marginRight: '10px'}} onClick={generarConcentrado} startIcon={<Done />}>
							Aplicar
						</Button>
					</Grid>
                </Grid>
            </Paper>
        </Modal>
    )
};
export default ModalFiltrosAvanzados;