import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../theme";
import {api} from '../../../config/axios';
import Swal from 'sweetalert2';
import { marcarURL } from '../../../config/functions';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

//COMPONENTES
import Header from '../../../components/Header';
import ModalCrearUsuario from './modales/ModalCrearUsuario';
import ModalEditarUsuario from './modales/ModalEditarUsuario';

const Usuarios = () => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // SEGMENTO PARA BUSCAR EMPRESAS
    // ===============================================

    const [usuarios, setUsuarios] = useState([]);

    useEffect(() => {

        buscarUsuarios();

        marcarURL('usuarios');

    },[])

    const buscarUsuarios = async () => {

        const response = await api.get('get/usuarios');

        if(response.data.success === true && response.data.result !== "Sin resultados"){

            setUsuarios(response.data.result);

        }else{

            setUsuarios([]);

        }

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        {
            field: "nombre_completo",
            headerName: "Usuario",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "email",
            headerName: "Correo",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "nombre_perfil",
            headerName: "Perfil",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "activo",
            headerName: "Estado",
            flex: 2,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => 
                params.value === 1 
                ?
                <Button variant="contained" color="success" sx={{color: "white"}}><b>Activo</b></Button>
                :
                <Button variant="contained" color="error"><b>Desactivado</b></Button>
        },

    ];

    // ===============================================
    // VALORES PARA SUBMIT DEL FORMULARIO
    // ===============================================

    const [initialFormValues, setInicialFormValues] = useState({

        email: '',
        password: '',
        rel_empleado: '',
        rel_perfil: ''

    })

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL CREARA
    // ===============================================

    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {

        setInicialFormValues({

            email: '',
            password: '',
            rel_empleado: '',
            rel_perfil: ''
    
        })

        setIsOpen(true);
    };
    
    const handleCloseModal = () => {
        setIsOpen(false);
    };

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR EL MODAL EDITAR
    // ===============================================

    const [isOpenEdit, setIsOpenEdit] = useState(false);

    const handleOpenModalEdit = () => {
        setIsOpenEdit(true);
    };
    
    const handleCloseModalEdit = () => {
        setIsOpenEdit(false);
    };

    // ===============================================
    // SEGMENTO PARA TRAER EMPRESAS
    // ===============================================

    const [empleados, setEmpleados] = useState([]);

    useEffect(() => {
        
        api.get('get/empleados')
        .then(result => {
    
            if(result.data.success == true)
            {
                setEmpleados(result.data.result);
            }
    
        })
        .catch(error => {
        
            console.log(error)
        
        })
    
    },[]);

    // ===============================================
    // SEGMENTO PARA TRAER EMPRESAS
    // ===============================================

    const [perfiles, setPerfiles] = useState([]);

    useEffect(() => {
        
        api.get('get/perfiles')
        .then(result => {
    
            if(result.data.success == true)
            {
                setPerfiles(result.data.result);
            }
    
        })
        .catch(error => {
        
            console.log(error)
        
        })
    
    },[]);

    // ===============================================
    // FUNCION PARA VALIDAR USUARIO
    // ===============================================

    const validarUsuario = async (idUser, entidad) => {

        const response = await api.get(`get/existe/usuario/${idUser}/${entidad}`);

        if(response.data.result === "Sin resultados"){

            return true;

        }else{

            return false;

        }

    }

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const handleRowClick = (params) => {

        setInicialFormValues(params.row);
        handleOpenModalEdit();

    }

    // ===============================================
    // ===============================================

    return (

        <Box m="20px">

            <Header title="Usuarios" subtitle="Usuarios EBTSuite" />

            {/* BOTON PARA ABRIL MODAL */}

            <Button variant="contained" 
                sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.blueAccent[700]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={handleOpenModal}
            >
                Agregar Usuario
            </Button>

            {/* TABLA  */}

            <Box
                m="40px 0 0 0"
                height="75vh"
                width="100%"
                sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`
                }
                }}
            >
                <DataGrid 
                    
                    rows={usuarios} 
                    columns={columns} 
                    components={{ Toolbar: GridToolbar}}
                    onRowClick={handleRowClick}
                />
            </Box>

            {/* MODAL PARA CREAR SUCURSAL */}

            <ModalCrearUsuario
                open={isOpen} 
                onClose={handleCloseModal} 
                initialValues={initialFormValues}
                refresh={buscarUsuarios}
                empleados={empleados}
                perfiles={perfiles}
                validarUsuario={validarUsuario}
            />

            {/* MODAL PARA EDITAR SUCURSAL */}

            <ModalEditarUsuario
                open={isOpenEdit} 
                onClose={handleCloseModalEdit} 
                initialValues={initialFormValues}
                refresh={buscarUsuarios}
                empleados={empleados}
                perfiles={perfiles}
                validarUsuario={validarUsuario}
            />

            {/* <ModalEditGM
                modalTitle="Editar Sucursal"
                swalTitle="Se ha editado la Sucursal!"
                url="update/sucursal"
                urlDelete="delete/sucursal/"
                open={isOpenEdit} 
                onClose={handleCloseModalEdit} 
                openModal={handleOpenModalEdit}
                size='40'
                initialValues={initialFormValues}
                inputForms={inputForms}
                yupValidation={yupValidation}
                refresh={buscarSucursales}
            /> */}

        </Box>

    )

}

export default Usuarios