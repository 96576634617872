import React, { useRef } from 'react';
import { useField } from 'formik';
import { TextField } from "@mui/material";
import { NumericFormat } from 'react-number-format';


const MyMoneyInput = ({ label, ...props }) => {

    const [field, meta, helpers] = useField(props);
    const inputRef = useRef(null);

    const formatCurrency = (value) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return formatter.format(value);
    };
      
    const parseCurrency = (value) => {
        const parsedValue = parseFloat(value.replace(/[^0-9.-]+/g,""));
        return isNaN(parsedValue) ? '' : parsedValue;
     };

    const handleChange = (event) => {
        const value = event.target.value;
        const decimalValue = parseCurrency(value);
        helpers.setValue(decimalValue);
    }

    const handleClick = () => {
        const value = inputRef.current.value;
        const lastPosition = value.length;
        const newValue = parseCurrency(value);
        if (newValue !== 0) {
            const currentPosition = inputRef.current.selectionStart;
            inputRef.current.setSelectionRange(currentPosition, currentPosition);
        } else {
            inputRef.current.setSelectionRange(2, 2);
        }
    }

    const formattedValue = formatCurrency(field.value);


    return (

        <NumericFormat
            sx={{width: '100%'}}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            allowNegative={false}
            prefix="$"
            label={label}
            variant="outlined"
            autoComplete="off"
            customInput={TextField}
            inputRef={inputRef}
            {...field}
            {...props}
            value={formattedValue}
            onChange={handleChange}
            onClick={handleClick}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
        />

    )

}

export default MyMoneyInput